import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateStep6,
    updateStep7,
    updateStep8,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray, showToast } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { Chip, Chips } from "../themes/userq/Chips/Chips";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { MembersDropdownList } from "../themes/userq/MembersDropdownList/MembersDropdownList.js";
import { SelectCreateDropdownList } from "../themes/userq/SelectCreateDropdown/SelectCreateDropdownList.js";
import { createLovService } from "../../services/lov.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    const [startPosition, setStartPosition] = useState(0);

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        own_bank: true,
        banks: [],
        payment_methods_used: [],
        role: "Tester",
    });

    const [formErrors, setFormErrors] = useState({
        own_bank: null,
        banks: null,
        payment_methods_used: null,
        error_class: null,
    });
    const [stepsValues, setStepsValues] = useState({
        employment: "",
        relationship_status: "",
        religion: "",
        children_and_dependents: "",
        own_driving_license: true,
        driving_license: [],
        has_disability_status: true,
        disability_status: [],
        housing_status: "",
        living_arrangements: "",
        career_stage: "",
        company_size: "",
        working_environment: "",
        mobile_user_type: [],
        desktop_user_type: [],
        owned_devices: [],
        shopping_online_frequency: "",
        hobbies: [],
        fitness_activities: [],
        own_pets: true,
        pet_ownership: [],
        vehicle_ownership: "",
        own_bank: true,
        banks: [],
        payment_methods_used: [],
    });

    const [lovCategories, setLovCategories] = useState([]);

    const [lovLoading, setLovLoading] = useState(false);

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();
        document.title = "Step 8 | " + process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 8;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 7) {
                        navigate("/tester/profile/step7");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }

                var relationship_status = "";

                var religion = "";

                var children_and_dependents = "";

                var driving_license = [];

                var housing_status = "";

                var living_arrangements = "";

                var career_stage = "";

                var company_size = "";

                var working_environment = "";

                var mobile_user_type = [];

                var desktop_user_type = [];

                var owned_devices = [];

                var shopping_online_frequency = "";

                var hobbies = [];

                var fitness_activities = [];

                var pet_ownership = [];

                var vehicle_ownership = "";

                var banks = [];

                var payment_methods_used = [];

                var own_driving_license = true;

                var own_pets = true;

                var own_bank = true;

                var has_disability_status = true;

                var disability_status = [];

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Relationship status" &&
                                item.id == lov.lov_id
                            ) {
                                relationship_status = item.id;
                            }
                            if (
                                category.name == "Religion" &&
                                item.id == lov.lov_id
                            ) {
                                religion = item.id;
                            }
                            if (
                                category.name == "Children and dependents" &&
                                item.id == lov.lov_id
                            ) {
                                children_and_dependents = item.id;
                            }
                            if (
                                category.name == "Driving license" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have driving license"
                                ) {
                                    own_driving_license = false;
                                    driving_license = [];
                                } else {
                                    own_driving_license = true;
                                    driving_license.push(item.id);
                                }
                            }
                            if (
                                category.name == "Housing status" &&
                                item.id == lov.lov_id
                            ) {
                                housing_status = item.id;
                            }
                            if (
                                category.name == "Living arrangements" &&
                                item.id == lov.lov_id
                            ) {
                                living_arrangements = item.id;
                            }
                            if (
                                category.name == "Career stage" &&
                                item.id == lov.lov_id
                            ) {
                                career_stage = item.id;
                            }
                            if (
                                category.name == "Company size" &&
                                item.id == lov.lov_id
                            ) {
                                company_size = item.id;
                            }
                            if (
                                category.name == "Working environment" &&
                                item.id == lov.lov_id
                            ) {
                                working_environment = item.id;
                            }
                            if (
                                category.name == "Mobile user type" &&
                                item.id == lov.lov_id
                            ) {
                                mobile_user_type.push(item.id);
                            }
                            if (
                                category.name == "Desktop user type" &&
                                item.id == lov.lov_id
                            ) {
                                desktop_user_type.push(item.id);
                            }
                            if (
                                category.name == "Owned devices" &&
                                item.id == lov.lov_id
                            ) {
                                owned_devices.push(item.id);
                            }
                            if (
                                category.name ==
                                    "Frequency of online shopping" &&
                                item.id == lov.lov_id
                            ) {
                                shopping_online_frequency = item.id;
                            }
                            if (
                                category.name == "Hobbies" &&
                                item.id == lov.lov_id
                            ) {
                                hobbies.push(item.id);
                            }
                            if (
                                category.name == "Fitness activities" &&
                                item.id == lov.lov_id
                            ) {
                                fitness_activities.push(item.id);
                            }
                            if (
                                category.name == "Pets owned" &&
                                item.id == lov.lov_id
                            ) {
                                if (item.lov_value == "I don't have pets") {
                                    own_pets = false;
                                } else {
                                    own_pets = true;

                                    pet_ownership.push(item.id);
                                }
                            }
                            if (
                                category.name == "Vehicle ownership" &&
                                item.id == lov.lov_id
                            ) {
                                vehicle_ownership = item.id;
                            }
                            if (
                                category.name == "What bank do you have?" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have bank account"
                                ) {
                                    own_bank = false;
                                } else {
                                    own_bank = true;

                                    banks.push(item.id);
                                }
                            }
                            if (
                                category.name == "Payment methods used" &&
                                item.id == lov.lov_id
                            ) {
                                payment_methods_used.push(item.id);
                            }
                            if (
                                category.name == "Disability conditions" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have any disabilities"
                                ) {
                                    has_disability_status = false;
                                    disability_status = [];
                                } else {
                                    has_disability_status = true;
                                    disability_status.push(item.id);
                                }
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,

                    own_bank: own_bank,
                    banks: banks,
                    payment_methods_used: payment_methods_used,
                });
                setStepsValues({
                    ...stepsValues,
                    relationship_status: relationship_status,
                    religion: religion,
                    children_and_dependents: children_and_dependents,
                    housing_status: housing_status,
                    living_arrangements: living_arrangements,
                    vehicle_ownership: vehicle_ownership,
                    employment: response.data.user.employment,
                    career_stage: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : career_stage,
                    company_size: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : company_size,
                    working_environment: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : working_environment,
                    mobile_user_type: mobile_user_type,
                    desktop_user_type: desktop_user_type,
                    shopping_online_frequency: shopping_online_frequency,
                    hobbies: hobbies,
                    owned_devices: owned_devices,
                    fitness_activities: fitness_activities,
                    payment_methods_used: payment_methods_used,
                    own_driving_license: own_driving_license,
                    driving_license: driving_license,
                    has_disability_status: has_disability_status,
                    disability_status: disability_status,
                    pet_ownership: pet_ownership,
                    own_pets: own_pets,
                    own_bank: own_bank,
                    banks: banks,
                });
                
                setSteps(response.data.steps);
            }
        });
    }, []);
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var form_data = { ...formValues };

        if (!formValues.own_bank) {
            var lovCategoriesLoc = lovCategories
                .filter((category) => category.name == "What bank do you have?")
                .flatMap((category) => category.active_lov)
                .filter(
                    (active_lov) =>
                        active_lov.lov_value == "I don't have bank account"
                );

            if (lovCategoriesLoc.length == 1) {
                form_data["banks"].push(lovCategoriesLoc[0].id);
            }
        }
        if (existingTester) {
            form_data.existingTester = 1;
        }

        updateStep8(form_data, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);

                        if(response.user.is_profile_updated===0) { 
                            
                            showToast(
                                "Please complete all the profile steps to be able to continue to the dashboard.",
                                "error"
                            );
                        }
                    } else {
                        if(response.user.is_profile_updated==1) { 
                            navigate("/dashboard");
                        } else {
                            showToast(
                                "Please complete all the profile steps to be able to continue to the dashboard.",
                                "error"
                            );
                        }
                    }
                    var new_steps = [...steps];

                    for (var i=0; i<new_steps.length; i++) {
                        if(new_steps[i].label=="Financial & services information"){
                            new_steps[i].percentage = 100;
                        }
                    }
                    setSteps(new_steps);
                } else {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "sign_up",
                        usertype: "tester",
                        method: response.user.signup_type,
                        userID: response.user.user_id,
                    });

                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/dashboard");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const createLovApi = (option, category_id) => {
        let formData = {};

        formData.lov_name = option;

        formData.lov_category_id = category_id;

        let token = ReactSession.get("token");

        setLovLoading(true);

        createLovService(formData, token).then((response) => {
            if (response.success) {
                setLovLoading(false);
                var banks = Object.assign([], formValues.banks);

                banks.push(response.lov.id);

                var lovCategoriesLoc = Object.assign([], lovCategories);

                lovCategoriesLoc.forEach(function (category, index) {
                    if (category.id === category_id) {
                        lovCategoriesLoc[index]["active_lov"] =
                            response.lov_categories[0].active_lov;
                    }
                });
                setFormValues({ ...formValues, banks: banks });

                setLovCategories(lovCategoriesLoc);
            } else {
                setLovLoading(false);
                showToast(response.message, "error");
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "banks") {
            var field_value = key && inline ? value : formValues.banks;

            if (formValues.own_bank) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        banks: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "banks" : firsterrorid;
                }
            }
        }
        if (!key || key == "payment_methods_used") {
            var field_value =
                key && inline ? value : formValues.payment_methods_used;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    payment_methods_used: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "payment_methods_used"
                        : firsterrorid;
            }
        }
        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkStepsErrors = () => {
        var error = false;

        if (!stepsValues.employment) {
            error = true;
        }
        if (
            !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                stepsValues.employment
            )
        ) {
            if (!stepsValues.career_stage) {
                error = true;
            }
            if (!stepsValues.company_size) {
                error = true;
            }
            if (!stepsValues.working_environment) {
                error = true;
            }
        }

        if (!stepsValues.religion) {
            error = true;
        }
        if (!stepsValues.children_and_dependents) {
            error = true;
        }
        if (
            stepsValues.own_driving_license &&
            stepsValues.driving_license.length == 0
        ) {
            error = true;
        }
        if (
            stepsValues.has_disability_status &&
            stepsValues.disability_status.length == 0
        ) {
            error = true;
        }
        if (!stepsValues.housing_status) {
            error = true;
        }
        if (!stepsValues.living_arrangements) {
            error = true;
        }

        if (stepsValues.mobile_user_type.length == 0) {
            error = true;
        }
        if (stepsValues.desktop_user_type.length == 0) {
            error = true;
        }
        if (stepsValues.owned_devices.length == 0) {
            error = true;
        }
        if (!stepsValues.shopping_online_frequency) {
            error = true;
        }
        if (stepsValues.hobbies.length == 0) {
            error = true;
        }
        if (stepsValues.fitness_activities.length == 0) {
            error = true;
        }
        if (stepsValues.own_pets && stepsValues.pet_ownership.length == 0) {
            error = true;
        }
        if (!stepsValues.vehicle_ownership) {
            error = true;
        }
        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (existingTester) {
           // error = checkStepsErrors();

            if (!error) {
                if (formValues.own_bank && formValues.banks.length == 0) {
                    error = true;
                }
                if (formValues.payment_methods_used.length == 0) {
                    error = true;
                }
            }
        } else {
            if (formValues.payment_methods_used.length == 0) {
                error = true;
            }
            if (formValues.own_bank && formValues.banks.length == 0) {
                error = true;
            }
        }

        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            /*if (!error) {
                var step_error = checkStepsErrors();

                if (step_error) {
                    showToast(
                        "Please complete all the profile steps to be able to continue to the dashboard.",
                        "error"
                    );
                }
            }*/
            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={8}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(e, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox tester-profile-step3box progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={8}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            How do you manage your finances?
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            Tell us how you manage your finances
                                            and expenses
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox mt-32">
                                        <div className="formbox form-group mb-0">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                                cssClasses={"mb-12 color-black"}
                                            >
                                                Banking
                                            </Text>
                                            <div className="d-flex-radiobtn sm-label">
                                                <CheckBox
                                                    label="I don't have bank account"
                                                    checked={
                                                        !formValues.own_bank
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        setFormValues({
                                                            ...formValues,
                                                            banks: [],
                                                            own_bank:
                                                                !formValues.own_bank,
                                                        });

                                                        setFormErrors({
                                                            ...formErrors,
                                                            banks: null,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox form-group"
                                            id="banks"
                                        >
                                            <div className="profile-form-group">
                                                <SelectCreateDropdownList
                                                    value={formValues.banks}
                                                    type="multiselect"
                                                    onChange={(selected) => {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            banks: null,
                                                        });
                                                        setFormValues({
                                                            ...formValues,
                                                            banks: selected,
                                                        });
                                                    }}
                                                    label={`${"What bank do you have?"}`}
                                                    floatlabel={
                                                        "What bank do you have?"
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "What bank do you have?"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .filter(
                                                            (active_lov) =>
                                                                active_lov.lov_value !=
                                                                "I don't have bank account"
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                                lov_category_id:
                                                                    active_lov.lov_category_id,
                                                            };
                                                        })
                                                        .sort((a, b) =>
                                                            (
                                                                a.label || ""
                                                            ).localeCompare(
                                                                b.label || ""
                                                            )
                                                        )}
                                                    onCreateOption={(
                                                        option
                                                    ) => {
                                                        if (!lovLoading) {
                                                            var lovCategory =
                                                                lovCategories.filter(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category.name ==
                                                                        "What bank do you have?"
                                                                );

                                                            if (
                                                                lovCategory.length >
                                                                0
                                                            ) {
                                                                createLovApi(
                                                                    option,
                                                                    lovCategory[0]
                                                                        .id
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    loading={lovLoading}
                                                    error={formErrors.banks}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    readOnly={
                                                        !formValues.own_bank
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="formbox form-group"
                                            id="payment_methods_used"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={
                                                        formValues.payment_methods_used
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Payment methods used"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    iconLeft={"add"}
                                                    label={
                                                        "Payment methods used"
                                                    }
                                                    no_results_message={
                                                        "Nothing is there"
                                                    }
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            payment_methods_used:
                                                                selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            payment_methods_used:
                                                                null,
                                                        });
                                                    }}
                                                    error={
                                                        formErrors.payment_methods_used
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step7"
                                                    : "/tester/profile/step7"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label={
                                                existingTester ? "Done" : "Next"
                                            }
                                            iconRight={
                                                existingTester ? (
                                                    <></>
                                                ) : (
                                                    <Icon
                                                        colorClass="gray-50-svg"
                                                        value="forward-arrow"
                                                        size="large"
                                                    />
                                                )
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext position-relative none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    size={"large"}
                                                    value={"credits-gold"}
                                                />
                                            }
                                            title="Request a payout when you reach $30"
                                            info="Take between 15/20 short tests to redeem your cash reward."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
