import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { getTestLengthService } from '../../../../services/test';
import { ReactSession } from '../../../../lib/secure_reactsession';
import EditingUnavailableModal from '../../workspace/modals/editing-unavailable-modal';
import { allowEditAccessService, allowEditRefreshAccessService, heatbeatEventService } from '../../../../services/heartbeat';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SessionAboutToExpireModal from '../../workspace/modals/session-about-to-expire-modal';
import SessionEndedModal from '../../workspace/modals/session-ended-modal';

const IDLE_TIMEOUT = 8 * 60 * 1000; // 5 seconds


const TestBuilderContext = React.createContext();

let heartbeatinterval;

let heartbeattimeout;

let heartbeateventtimeout;

export const TestBuilderProvider = ({ from, children }) => {

    const [lastActiveTime, setLastActiveTime] = useState(Date.now());

    const [worker, setWorker] = useState(null);


    const navigate = useNavigate();
    
    const [test_time_length, setTestTimeLength] = useState(null);

    const [frozenWorkspaceModal, setFrozenWorkspaceModal] = useState({
        open: false
    });

    const [header_workspaces_list_reload, setHeaderWorkspacesListReload] = useState(false);

    const [allowEditAccessModal, setAllowEditAccessModal] = useState({open:false});

    const [sessionAboutToExpireModal, setSessionAboutToExpireModal] = useState({open:false});

    const [sessionEndedModal, setSessionEndedModal] = useState({open:false});

    const [refreshLoading, setRefreshLoading] = useState(false);

    let testController = useRef();

    const [idle, setIdle] = useState(false);

    const [stopRefreshing, setStopRefreshing] = useState(false);

    const [lockout, setLockout] = useState(false);

    const [test_id, setTestId] = useState(null);

    const location = useLocation();
    
    const [test, setTest] = useState(null);

    const [events, setEvents] = useState([]);

    const [reportList, setReportList] = useState([]);

    const [reportSubmitList, setReportSubmitList] = useState([]);
    
    //const [timestamp, setTimestamp] = useState(null);

    useEffect(() => {
        
        if(window.location.href.indexOf("/r/test")!==-1){
            const newWorker = new Worker(`${process.env.REACT_APP_URL}js/idleWorker.js`);

            setWorker(newWorker);
        
            newWorker.onmessage = (e) => {
                
                if (Date.now() - lastActiveTime >= IDLE_TIMEOUT) {
                    
                    if(window.location.href.indexOf("/r/test")!==-1 && !sessionAboutToExpireModal.open && !sessionEndedModal.open){
                                
                        setSessionAboutToExpireModal({open: true});
                    
                    }
                }
            };
        
            return () => {
                newWorker.terminate();
            };
        } else if(worker){
            worker.postMessage({ idleTimeout: IDLE_TIMEOUT, turn:"off" });
        }
      }, [location.pathname, sessionAboutToExpireModal.open, sessionEndedModal.open]);

    useEffect(()=>{
        
        if(heartbeateventtimeout){
            clearTimeout(heartbeateventtimeout);
        }
        heartbeatevent('');
        return () => {
            
           
            if(heartbeateventtimeout){
                clearTimeout(heartbeateventtimeout);
            }
        };
    },[location.pathname]);
    const heartbeatevent = (timestamp)=>{
        
        let token = ReactSession.get("token");

        let currentDate = new Date();

        let user = ReactSession.get("user");

        if(!from && user &&  user.role!='Tester' && token){

            heatbeatEventService({timestamp: timestamp ? timestamp : '' }, token).then((response) => {
                if (response.success) {

                    
                    setEvents(response.events);

                    if(heartbeateventtimeout){
                        clearTimeout(heartbeateventtimeout);
                    }

                    heartbeateventtimeout = setTimeout(()=>{
                        heartbeatevent(response.timestamp);
                    },15000)
                } else {

                    if(heartbeateventtimeout){
                        clearTimeout(heartbeateventtimeout);
                    }
                    heartbeateventtimeout = setTimeout(()=>{
                        heartbeatevent(response.timestamp);
                    },30000)
                }
            });
        }
    }
    useEffect(()=>{
        if(heartbeatinterval){
            clearInterval(heartbeatinterval);
        }
        if(heartbeattimeout){
            clearTimeout(heartbeattimeout);
        }
        if(window.location.href.indexOf("/r/test")!==-1){
           
            let test_id_array = location.pathname.split("/");

            let test_id = test_id_array[3];

            setTestId(test_id);

            if(test_id){
    
                if(!idle && !stopRefreshing)
                {
                    heartbeat(test_id);
                }
            }
            
             
        } 
        return () => {
            
            if(heartbeatinterval){
                clearInterval(heartbeatinterval);
            }
            if(heartbeattimeout){
                clearTimeout(heartbeattimeout);
            }
        };
    },[location.pathname, stopRefreshing]);

    let resetTimeout;

    let timeout;

    let about_expire_timeout;
    
    useEffect(() => {
        
    
        /*resetTimeout = () => {
            
            clearTimeout(timeout);
            
            clearTimeout(about_expire_timeout);

            if(!lockout && !stopRefreshing){

                timeout = setTimeout(function(){
                    
                    setIdle(true);

                }, 2 * 60 * 1000); 
            
                about_expire_timeout  = setTimeout(function(){
                    console.log("mouse not active");
                    if(window.location.href.indexOf("/r/test")!==-1 && !sessionAboutToExpireModal.open && !sessionEndedModal.open){
                        
                            setSessionAboutToExpireModal({open: true});
                        
                    }

                }, 1 * 60 * 1000); 
            }
        };*/
        resetTimeout = () => {
            setIdle(false);
            setLastActiveTime(Date.now());
            if (worker) {

              worker.postMessage({ idleTimeout: IDLE_TIMEOUT, turn:"on" });
            }
        };
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                
                resetTimeout();
                
            } 
            
          };
        
        const handleActivity = () => {
            
            //setIdle(false);
            
            resetTimeout();
        };
        if(location && location.pathname.indexOf("/r/test")!=-1){
            
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearTimeout(timeout);
            clearTimeout(about_expire_timeout);
            
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('keypress', handleActivity);
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }
        
        resetTimeout();

        return () => {
            
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearTimeout(timeout);
            clearTimeout(about_expire_timeout);
        };
    }, [allowEditAccessModal.open, lockout, stopRefreshing,location.pathname, sessionAboutToExpireModal.open, sessionEndedModal.open,worker]);

    const heartbeat = (test_id)=>{
        let token = ReactSession.get("token");

        if(!idle && window.location.href.indexOf("/r/test")!==-1){

            allowEditAccessService({test_id: test_id}, token).then((response) => {
                if (response.success) {

                    setTest(response.test);

                    if(response.status === "locked"){

                        setLockout(true);
                        
                        setSessionEndedModal({open:false});

                        setStopRefreshing(true);
                        
                        setAllowEditAccessModal({...allowEditAccessModal, open: true, test:response.test, user: response.locking_user_name, test: response.test});

                        /*if(resetTimeout){
                           
                            resetTimeout(false);
                        }*/
                        

                    } else {

                        setLockout(false);
                        
                        heartbeattimeout = setTimeout(()=>{
                            if(!idle && !stopRefreshing)
                                {
                            heartbeat(test_id);
                                }
                        },15000)
                    }
                }
            });
        }
    }
    const allowEditAccess = (test) => {

        let token = ReactSession.get("token");

        setRefreshLoading(true);

        allowEditRefreshAccessService({test_id: test.test_id}, token).then((response) => {
            setRefreshLoading(false);
            if (response.success) {

                setTest(response.test);

                if(response.status === "open"){
                    
                    setLockout(false);

                    setIdle(false);

                    setStopRefreshing(false);

                    setSessionEndedModal({open:false});

                    if(window.location.href.indexOf("/r/test")==-1){
                        //navigate(`/r/test/${test.test_id}/welcome`);
                    }
                } else if(response.status === "locked"){

                    setLockout(true);

                    setStopRefreshing(true);

                    setAllowEditAccessModal({...allowEditAccessModal, open: true, user: response.locking_user_name, test: response.test})
                    
                    setSessionEndedModal({open:false});

                    /*if(resetTimeout){
                           
                        resetTimeout(false);
                    }*/
                }
            }
        });
        
    }

    const updateTestLength = (time) => {
        setTestTimeLength(time);
    };
    const getTestLength = (test_id)=>{

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");


        getTestLengthService({test_id: test_id}, token, testController.current?.signal).then((response) => {
            if (response.success) {

              updateTestLength(response.label);
            }
        });
    }
    
    

    return (
        <TestBuilderContext.Provider value={{events, setAllowEditAccessModal, allowEditAccess , test_time_length, updateTestLength, getTestLength, header_workspaces_list_reload,  setHeaderWorkspacesListReload,frozenWorkspaceModal, setFrozenWorkspaceModal, reportList, setReportList, reportSubmitList, setReportSubmitList}}>
            {children}
            <EditingUnavailableModal
                open={allowEditAccessModal.open}
                user={allowEditAccessModal.user}
                test={allowEditAccessModal.test}
                close={()=>{
                    setAllowEditAccessModal({...allowEditAccessModal, open: false});

                    if(allowEditAccessModal.test){
                        if(window.location.href.indexOf("/r/test")!==-1){
                            navigate('/wsp/'+allowEditAccessModal.test.workspace_id+'/p/'+allowEditAccessModal.test.workspace_project_id);
                        }
                    }
                }} 
            />
            <SessionAboutToExpireModal 
                open={sessionAboutToExpireModal.open}
                close={()=>{
                    setIdle(false);
                    /*if(resetTimeout){
                        resetTimeout(true);
                    }*/
                    setSessionAboutToExpireModal({open:false});
                }}
                onTimeout={()=>{
                    setIdle(true);
                    /*if(resetTimeout){
                        resetTimeout(false);
                    }*/
                    setSessionAboutToExpireModal({open:false});
                    if(window.location.href.indexOf("/r/test")!==-1){
                        setSessionEndedModal({open:true});
                    }
                    setStopRefreshing(true);
                }}
            />
            <SessionEndedModal 
                open={sessionEndedModal.open}
                close={()=>{
                    setIdle(false);
                    /*if(resetTimeout){
                        resetTimeout(true);
                    }*/
                    setSessionEndedModal({open:false});

                    if(test){
                        navigate('/wsp/'+test.workspace_id+'/p/'+test.workspace_project_id);
                    }
                }}
                refreshLoading={refreshLoading}
                refresh={()=>{
                    //window.location.href = window.location.href;

                    allowEditAccess({test_id:test_id});
                }}
            />
        </TestBuilderContext.Provider>
    );
};

export const useTestBuilderData = () => {
  return React.useContext(TestBuilderContext);
};