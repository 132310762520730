import {handleApiErrorResponse} from "../lib/helpers";
export function createLovService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'lov/add', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}