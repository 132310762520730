import { useEffect, useRef } from "react";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export const ProfileCompletionSteps = ({
    current_step,
    steps,
    onClick,
    isLoading,
}) => {
    const containerRef = useRef(null);

    const navigate = useNavigate();

    const itemRefs = useRef([]);

    const scrollToElement = (index) => {
        const container = containerRef.current;
        const item = itemRefs.current[index];

        if (container && item) {
            const containerWidth = container.offsetWidth;
            const itemOffsetLeft = item.offsetLeft;
            const itemWidth = item.offsetWidth;

            // Center the element by adjusting the scroll position
            const scrollPosition =
                itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
            container.scrollTo({
                left: scrollPosition,
            });
        }
    };

    useEffect(() => {
        if (steps && steps.length > 0 && current_step) {
            scrollToElement(current_step - 1);
        }
    }, [steps]);

    return (
        <div
            className="profile-completion-steps  overflow-scroller"
            ref={containerRef}
        >
            {steps.map((step, index) => {
                return (
                    <Link
                        key={"step" + index}
                        onClick={(e) => {
                            e.preventDefault();
                           
                            if(index+1 < current_step){
                                navigate(step.link);
                            } else {
                                if (onClick) {
                                    onClick(step.link);
                                }
                            }
                        }}
                        to={step.link}
                        className="steps-link"
                    >
                        {isLoading ? (
                            <div
                                ref={(el) => (itemRefs.current[index] = el)}
                                className={`${
                                    index + 1 == current_step
                                        ? "active-step"
                                        : ""
                                } ${
                                    step.percentage == 100
                                        ? "pink-bg"
                                        : "gray-bg"
                                }`}
                            >
                                <Skeleton
                                    width={40}
                                    height={40}
                                    borderRadius={20}
                                    style={{ top: "-4px", marginBottom: "6px" }}
                                />
                                <Text
                                    type={"caption"}
                                    fontWeight={"medium"}
                                    cssClasses={"mt-0 dark-text"}
                                >
                                    {step.label}
                                </Text>
                            </div>
                        ) : (
                            <div
                                ref={(el) => (itemRefs.current[index] = el)}
                                className={`${
                                    index + 1 == current_step
                                        ? "active-step"
                                        : ""
                                } ${
                                    step.percentage == 100
                                        ? "pink-bg"
                                        : "gray-bg"
                                }`}
                            >
                                <div
                                    className="border-bg"
                                    style={{
                                        "--progress": `${
                                            3.6 * step.percentage
                                        }deg`,
                                    }}
                                >
                                    {step.percentage == 0 ? (
                                        <Icon
                                            value={"error"}
                                            size="small"
                                            colorClass={"gray-900-svg"}
                                        />
                                    ) : (
                                        <Icon
                                            value={"check-mark"}
                                            size="small"
                                            colorClass={"gray-50-svg"}
                                        />
                                    )}
                                </div>
                                <Text type={"caption"} fontWeight={"medium"}>
                                    {step.label}
                                </Text>
                            </div>
                        )}
                    </Link>
                );
            })}
        </div>
    );
};
