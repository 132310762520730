import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, useParams } from "react-router-dom";
import {
    createTest,
    updateTestSettingsService,
} from "../../../../services/test";
import { LoadingIcon } from "../../../loader/loadingIconNew";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { SelectCreateDropdownList } from "../../../themes/userq/SelectCreateDropdown/SelectCreateDropdownList";
import {
    createProjectService,
    getWorkspaceProjectsService,
    moveTestService,
} from "../../../../services/workspaceprojects";
import { showToast } from "../../../../lib/helpers";

export default function MoveTestModal({
    test_id,
    test_name,
    project_id,
    workspace_id,
    ...props
}) {
    const wrapperRef = useRef(null);
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        test_id: test_id,
        project_id: project_id,
    });

    const [projects, setProjects] = useState([]);

    const [projectsLoading, setProjectsLoading] = useState(false);

    const [movingTestLoading, setMovingTestLoading] = useState(false);

    useEffect(() => {
        
        if (project_id) {

            setFormValues({ ...formValues, project_id: project_id });

            fetchProjects();

            
        }
    }, [project_id]);

    useEffect(() => {}, [props.openModal]);

    const closeModal = () => {
        setFormValues({ test_name: "" });
        props.closeModal(false);
    };

    const fetchProjects = () => {
        let token = ReactSession.get("token");

        setProjectsLoading(true);

        getWorkspaceProjectsService({ workspace_id: workspace_id }, token).then(
            (response) => {
                if (response.success) {
                    let projects = [];

                    response.projects.forEach(function(project){
                        //if(project_id !== project.id) {
                            projects.push(project);
                        //}
                    })
                    setProjects(projects);
                } else {
                    showToast(response.message, "error");
                }

                setProjectsLoading(false);
            }
        );
    };
    const onSubmit = async (event) => {
        event.preventDefault();

        if (!movingTestLoading &&  !(project_id === formValues.project_id || !formValues.project_id) ) {
            setMovingTestLoading(true);

            const token = ReactSession.get("token");

            var data = { test_id: test_id, project_id: formValues.project_id };

            moveTestService(data, token).then((response) => {
                setMovingTestLoading(false);

                if (response.success) {
                    ReactSession.set(
                        "test_moving_id",
                        test_id && test_id.toString()
                    );

                    props.closeModal(true);

                    navigate(
                        "/wsp/" + workspace_id + "/p/" + formValues.project_id
                    );
                } else {
                    toast(
                        <ToastMessage
                            type={"error"}
                            message={response.message}
                            closable={true}
                            onClose={() => {
                                toast.dismiss();
                            }}
                        />,

                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: settings.toast_duration,
                        }
                    );
                }
            });
        }
        return false;
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const createProjectApi = (option) => {
        let formData = {};

        formData.title = option;

        formData.workspace_id = workspace_id;

        formData.members = [];

        let token = ReactSession.get("token");

        setProjectsLoading(true);

        createProjectService(formData, token).then((response) => {
            if (response.success) {
                setFormValues({
                    ...formValues,
                    project_id: response.project.id,
                });

                fetchProjects();

                //setProjectsLoading(false);
            } else {
                setProjectsLoading(false);
                showToast(response.message, "error");
            }
        });
    };
    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap test-setting-modal "
        >
            <Modal.Body ref={wrapperRef}>
                {formLoading ? (
                    <div className="modal-loader test-setting-modal-loader">
                        <LoadingIcon />
                    </div>
                ) : (
                    <div
                        className={`modal-inner-text ${
                            props.read_mode ? "test-setting-read-mode" : ""
                        }`}
                    >
                        <div className="modal-header-top">
                            <Text type="h3">Move test</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon
                                        value="Close"
                                        size="medium"
                                        hover={true}
                                    />
                                </span>
                            </button>
                        </div>

                        <div className="test-setting-middle-data mb-12">
                            <div className="test-setting-label test-language-label mb-32">
                                <Text
                                    type="body-text-2"
                                    cssClasses={"black-color mt-0 mb-0"}
                                    fontWeight="medium-font"
                                >
                                    Test name
                                </Text>
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semibold-font"
                                    cssClasses={"mb-0"}
                                >
                                    {test_name}
                                </Text>
                            </div>

                            <div className="test-setting-label test-name-label">
                                <Text
                                    type="body-text-2"
                                    fontWeight="medium-font"
                                    cssClasses={"black-color mt-0 mb-0"}
                                >
                                    Project
                                </Text>
                            </div>
                            <div className="test-setting-hint">
                                <Text
                                    type="body-text-3"
                                    fontWeight="medium-font"
                                >
                                    Select or create a new folder by creating
                                    below
                                </Text>
                            </div>

                            <SelectCreateDropdownList
                                value={formValues.project_id}
                                onChange={(selected) => {
                                    setFormValues({
                                        ...formValues,
                                        project_id: selected,
                                    });
                                }}
                                iconLeft={"folder"}
                                label={"Select or create new project folder"}
                                options={projects.map((project) => {
                                    return {
                                        label: project.title,
                                        sublabel:
                                            project
                                                .workspace_project_team_members
                                                .length == 1
                                                ? "1 member"
                                                : project
                                                      .workspace_project_team_members
                                                      .length + " members",
                                        value: project.id,
                                    };
                                })}
                                onCreateOption={(option) => {
                                    if (!projectsLoading) {
                                        createProjectApi(option);
                                    }
                                }}
                                loading={projectsLoading}
                            />
                        </div>

                        <Button
                            type="primary"
                            size="large"
                            label={"Save"}
                            onClick={onSubmit}
                            state={
                                (project_id === formValues.project_id || !formValues.project_id) 
                                    ? "disabled"
                                    : "active"
                            }
                            microLoading={movingTestLoading}
                        />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
