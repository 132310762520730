import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { LoadingIcon } from "../Loader/loadingIcon";
import { TesterHeader } from "../Header/TesterHeader";
import { useNavigate } from "react-router-dom";


export  const LayoutTester =  ({wrapClass, isLoading, skipCheck,  activeMenu, skipProfileComplete, ...props}) =>{

    
    const user = ReactSession.get("user");

    const navigate = useNavigate();

    
    useEffect(()=>{

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        
        if(!skipProfileComplete) {

            if(user){
                if (user.role == "Tester" && user.completed_step != 8) {

                    navigate('/tester/profile/step1');
                }
            }

        }
    },[])


    
    
    return (
        <SkeletonTheme baseColor="#cccccc" highlightColor="#e0e0e0">
            {!isLoading &&
            <>
            {props.profileHeader &&
            <TesterHeader isAccount={false} />
            }
            {!props.profileHeader &&
            <TesterHeader  isAccount={true} user={user?user.first_name:''} />
            }
            <div className={`page-data-wrapper ${wrapClass}`}>
                {props.children}
            </div>
            
            <Footer />
            </>
            }
            {isLoading &&
                <div className="container inner-page-container">
                    <LoadingIcon />
                </div>
                
            }
        </SkeletonTheme>
    )
}