import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateStep6,
    updateStep7,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { Chip, Chips } from "../themes/userq/Chips/Chips";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { MembersDropdownList } from "../themes/userq/MembersDropdownList/MembersDropdownList.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    const [startPosition, setStartPosition] = useState(0);

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        own_pets: true,
        pet_ownership: [],
        vehicle_ownership: "",
        hobbies: [],
        fitness_activities: [],
        desktop_user_type: "",
        owned_devices: [],
        role: "Tester",
    });

    const [formErrors, setFormErrors] = useState({
        hours_spend_online: null,
        apps: null,
        tech_gadgets: null,
        mobile_user_type: null,
        desktop_user_type: null,
        owned_devices: null,
        error_class: null,
    });

    const [lovCategories, setLovCategories] = useState([]);

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();
        document.title = "Step 7 | " + process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 7;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 6) {
                        navigate("/tester/profile/step6");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }

                var own_pets = true;

                var pet_ownership = [];

                var fitness_activities = [];

                var hobbies = [];

                var vehicle_ownership = "";

                var shopping_online_frequency = "";

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name ==
                                    "Frequency of online shopping" &&
                                item.id == lov.lov_id
                            ) {
                                shopping_online_frequency = item.id;
                            }
                            if (
                                category.name == "Hobbies" &&
                                item.id == lov.lov_id
                            ) {
                                hobbies.push(item.id);
                            }
                            if (
                                category.name == "Fitness activities" &&
                                item.id == lov.lov_id
                            ) {
                                fitness_activities.push(item.id);
                            }
                            if (
                                category.name == "Pets owned" &&
                                item.id == lov.lov_id
                            ) {
                                if (item.lov_value == "I don't have pets") {
                                    own_pets = false;
                                } else {
                                    own_pets = true;

                                    pet_ownership.push(item.id);
                                }
                            }
                            if (
                                category.name == "Vehicle ownership" &&
                                item.id == lov.lov_id
                            ) {
                                vehicle_ownership = item.id;
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,
                    shopping_online_frequency: shopping_online_frequency,
                    pet_ownership: pet_ownership,
                    own_pets: own_pets,
                    hobbies: hobbies,
                    fitness_activities: fitness_activities,
                    vehicle_ownership: vehicle_ownership,
                });
                if (existingTester) {
                    setSteps(response.data.steps);
                }
            }
        });
    }, []);
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var form_data = { ...formValues };

        if (!formValues.own_pets) {
            var lovCategoriesLoc = lovCategories
                .filter((category) => category.name == "Pets owned")
                .flatMap((category) => category.active_lov)
                .filter(
                    (active_lov) => active_lov.lov_value == "I don't have pets"
                );

            if (lovCategoriesLoc.length == 1) {
                form_data["pet_ownership"].push(lovCategoriesLoc[0].id);
            }
        }

        if (existingTester) {
            form_data.existingTester = 1;
        }
        updateStep7(form_data, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step8");
                    }
                } else {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "sign_up",
                        usertype: "tester",
                        method: response.user.signup_type,
                        userID: response.user.user_id,
                    });

                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step8");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "pet_ownership") {
            var field_value = key && inline ? value : formValues.pet_ownership;

            if (formValues.own_pets) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        pet_ownership: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "pet_ownership" : firsterrorid;
                }
            }
        }
        if (!key || key == "hobbies") {
            var field_value = key && inline ? value : formValues.hobbies;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    hobbies: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "hobbies" : firsterrorid;
            }
        }
        if (!key || key == "fitness_activities") {
            var field_value =
                key && inline ? value : formValues.fitness_activities;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    fitness_activities: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "fitness_activities" : firsterrorid;
            }
        }
        if (!key || key == "vehicle_ownership") {
            var field_value =
                key && inline ? value : formValues.vehicle_ownership;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    vehicle_ownership: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "vehicle_ownership" : firsterrorid;
            }
        }
        if (!key || key == "shopping_online_frequency") {
            var field_value =
                key && inline ? value : formValues.shopping_online_frequency;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    shopping_online_frequency: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "shopping_online_frequency"
                        : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (!formValues.shopping_online_frequency) {
            //console.log(12)
            error = true;
        }
        if (formValues.hobbies.length == 0) {
            //console.log(13)
            error = true;
        }
        if (formValues.fitness_activities.length == 0) {
            //console.log(14)
            error = true;
        }
        if (formValues.own_pets && formValues.pet_ownership.length == 0) {
            //console.log(15)
            error = true;
        }
        if (!formValues.vehicle_ownership) {
            //console.log(16)
            error = true;
        }

        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={7}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(e, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox tester-profile-step3box progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={7}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Your day-to-day vibe
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            From hobbies to habits, tell us
                                            about your rhythm
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox mt-32">
                                        <div className="formbox form-group mb-0">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                                cssClasses={"mb-12 color-black"}
                                            >
                                                Pets ownership
                                            </Text>
                                            <div className="d-flex-radiobtn sm-label">
                                                <CheckBox
                                                    label="I don't have pets"
                                                    checked={
                                                        !formValues.own_pets
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        setFormValues({
                                                            ...formValues,
                                                            pet_ownership: [],
                                                            own_pets:
                                                                !formValues.own_pets,
                                                        });

                                                        setFormErrors({
                                                            ...formErrors,
                                                            pet_ownership: null,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox  form-group"
                                            id="pet_ownership"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={
                                                        formValues.pet_ownership
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Pets owned"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .filter(
                                                            (active_lov) =>
                                                                active_lov.lov_value !=
                                                                "I don't have pets"
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    iconLeft={"add"}
                                                    label={"Pets owned"}
                                                    no_results_message={
                                                        "Nothing is there"
                                                    }
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            pet_ownership:
                                                                selected,
                                                        });

                                                        setFormErrors({
                                                            ...formErrors,
                                                            pet_ownership: null,
                                                        });
                                                    }}
                                                    error={
                                                        formErrors.pet_ownership
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    readOnly={
                                                        !formValues.own_pets
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox  form-group"
                                            id="hobbies"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={formValues.hobbies}
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Hobbies"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    iconLeft={"add"}
                                                    label={"Hobbies"}
                                                    no_results_message={
                                                        "Nothing is there"
                                                    }
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            hobbies: selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            hobbies: null,
                                                        });
                                                    }}
                                                    error={formErrors.hobbies}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox  form-group"
                                            id="fitness_activities"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={
                                                        formValues.fitness_activities
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Fitness activities"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    iconLeft={"add"}
                                                    label={"Fitness activities"}
                                                    no_results_message={
                                                        "Nothing is there"
                                                    }
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            fitness_activities:
                                                                selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            fitness_activities:
                                                                null,
                                                        });
                                                    }}
                                                    error={
                                                        formErrors.fitness_activities
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox">
                                            <div
                                                className="formbox d-flex-radiobox mb-0"
                                                id="vehicle_ownership"
                                            >
                                                <label className="bold">
                                                    <Text
                                                        type="body-text-2"
                                                        fontWeight="medium-font"
                                                        cssClasses={
                                                            "color-black"
                                                        }
                                                    >
                                                        Do you have a vehicle
                                                    </Text>
                                                </label>

                                                <div
                                                    id="vehicle-box"
                                                    className="d-flex-radiobtn width-180 bd-label"
                                                >
                                                    {lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Vehicle ownership"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return (
                                                                <RadioButton
                                                                    name="Vehicle ownership"
                                                                    label={
                                                                        active_lov.lov_value
                                                                    }
                                                                    value={
                                                                        active_lov.id
                                                                    }
                                                                    isChecked={
                                                                        formValues.vehicle_ownership ==
                                                                        active_lov.id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                vehicle_ownership:
                                                                                    active_lov.id,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                vehicle_ownership:
                                                                                    null,
                                                                            }
                                                                        );
                                                                    }}
                                                                    key={
                                                                        "Vehicle ownership" +
                                                                        active_lov.id
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            {formErrors.vehicle_ownership && (
                                                <span
                                                    className={
                                                        formErrors.error_class
                                                    }
                                                >
                                                    {
                                                        formErrors.vehicle_ownership
                                                    }
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className="formbox form-group"
                                            id="shopping_online_frequency"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.shopping_online_frequency
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Frequency of online shopping"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                shopping_online_frequency:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                shopping_online_frequency:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            shopping_online_frequency:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Frequency of online shopping"
                                                    error={
                                                        formErrors.shopping_online_frequency
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step6"
                                                    : "/tester/profile/step6"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    colorClass={
                                                        "success-green-200"
                                                    }
                                                    value={"star"}
                                                />
                                            }
                                            title="Keep your tester score high"
                                            info="Maintaining this high score will ensure you access to more tests."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
