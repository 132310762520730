import { Helmet } from "react-helmet";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { useState } from "react";
import { Icon } from "../../themes/userq/Icon/Icon";
import { ReactSession } from "../../../lib/secure_reactsession";
import { Text } from "../../themes/userq/Text/Text";

export default function CustomRecruitmentTerms({}) {
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [user, setUser] = useState(ReactSession.get("user"));

    return (
        <LayoutResearcher
            profileHeader={user && user.signup_plan ? true : false}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"rs-inner-profile-wrapper"}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>{"Custom recruitment terms & conditions"} | UserQ</title>
            </Helmet>
            <a
                onClick={(e) => {
                    e.preventDefault();

                    setShowSupportPanel(true);
                }}
                href={process.env.REACT_APP_URL + "t/support"}
                target="_blank"
                className="support-button"
            >
                <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                />
                Support
            </a>
            <div className="terms-wrapper">
                <div>
                    <Text type="h3" fontWeight={"bold-font"}>
                        Terms and conditions for custom recruitment service by
                        UserQ.com
                    </Text>
                    <Text
                        type="body-text-3"
                        fontWeight={"medium-font"}
                        cssClasses={"mt-16"}
                    >
                        Last updated 12/12/2024
                    </Text>
                </div>
                <div className="mt-40">
                    <Text type="body-text-1" fontWeight={"semi-bold-font"}>
                        1. Service scope
                    </Text>
                    <Text
                        type="body-text-3"
                        fontWeight={"medium-font"}
                        cssClasses={"mt-12 gray-text"}
                    >
                        The custom recruitment service includes the
                        identification and recruitment of participants based on
                        the criteria provided by the client. Recruitment is
                        limited to the demographic and geographic specifications
                        shared at the time of the request. <br /> <br /> This
                        service primarily focuses on providing recruitment of
                        testers residents in any country of the MENA region
                        (Middle East and North Africa).
                    </Text>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        2. Pricing
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Pricing starts at $10 per response and varies based
                            on different factors.
                        </li>
                        <li>
                            Pricing depends on factors such as the duration and
                            nature of the study, the complexity of the
                            demographics, and the residency of the participants.
                        </li>
                        <li>
                            A custom quotation will be provided before
                            recruitment begins, which must be approved by the
                            client to kick off the recruitment process.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        3. Participant criteria
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            The client is responsible for providing clear and
                            detailed participant criteria, including but not
                            limited to age, gender, residency, nationality,
                            employment status, income level, and any quotas or
                            cohorts.
                        </li>
                        <li>
                            If no specific quotas are determined, the recruited
                            participants will represent an undefined mix of
                            qualities.
                        </li>
                        <li>
                            If incomplete or unclear criteria are provided, the
                            recruitment process may be delayed.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        4. Quotas and availability
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            The fulfillment of specific quotas is subject to
                            participant availability and may vary depending on
                            the region or criteria.
                        </li>
                        <li>
                            If quotas cannot be met, the client will be notified
                            and given the option to adjust the criteria or
                            receive a partial refund for unmet quotas.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        5. Recruitment timeline
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            The standard recruitment timeline depends on the
                            number of participants, demographics, and complexity
                            of criteria. Recruitment typically starts from 1
                            business day after confirmation of the contract.
                        </li>
                        <li>
                            Timelines for more complex requirements or larger
                            volumes may extend accordingly.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        6. Replacements and refunds
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Participants who fail to meet the agreed-upon
                            criteria or do not complete the study will be
                            replaced, provided the issue is reported within 5
                            business days of study completion.
                        </li>
                        <li>
                            Refunds are offered only in cases where replacements
                            are not possible.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        7. Payment terms
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Full payment is required before recruitment begins.
                        </li>
                        <li>
                            Payments can be made via credit card or bank
                            transfer.
                        </li>
                        <li>
                            Payment methods and terms will be detailed in the
                            custom recruitment quotation.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        8. Confidentiality
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Digital of Things ensures that participant data is
                            handled in compliance with applicable privacy laws
                            and regulations.
                        </li>
                        <li>
                            Clients must agree not to misuse or share
                            participant data outside of the intended research
                            purposes.
                        </li>
                        <li>
                            If a Non-Disclosure Agreement (NDA) is required for
                            the project, the client must provide it to Digital
                            of Things. Digital of Things will ensure that all
                            recruited testers sign the NDA before participating
                            in the study.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        9. Cancellation Policy
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Requests for cancellation must be made in writing.
                        </li>
                        <li>
                            Cancellations made after recruitment has begun are
                            subject to a cancellation fee equivalent to 50% of
                            the recruitment cost.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        10. Limitations of Liability
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Digital of Things is not responsible for participant
                            behavior or incomplete responses beyond the
                            agreed-upon replacements.
                        </li>
                        <li>
                            The client assumes responsibility for any direct
                            communication or engagement with recruited
                            participants outside the recruitment process.
                        </li>
                        <li>
                            If the recruitment request is deemed inappropriate
                            or unethical, the UserQ team reserves the right to
                            refuse the request without further explanation.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        11. Amendments
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            Any changes to participant criteria or quotas after
                            recruitment has begun will require a revised
                            quotation and may impact the timeline and cost.
                        </li>
                    </ul>
                    <Text
                        type="body-text-1"
                        fontWeight={"semi-bold-font"}
                        cssClasses={"mt-24"}
                    >
                        12. Acceptance of Terms
                    </Text>
                    <ul className="text-list mt-12">
                        <li>
                            By opting for the custom recruitment service, the
                            client acknowledges and agrees to these Terms and
                            Conditions.
                        </li>
                    </ul>
                </div>
            </div>
        </LayoutResearcher>
    );
}
