import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "./../Text/Text";
import { Icon } from "./../Icon/Icon";

export const Accordian = ({
    title,
    subtitle,
    tooltip,
    open,
    children,
    onChange,
    questionCount,
    Accordion_small,
    cssClasses,
}) => {
    const [accordionopen, setAccordionOpen] = useState(open);

    useEffect(() => {
        setAccordionOpen(open);
    }, [open]);

    const HeaderTag = Accordion_small ? "p" : "h3";
    const MinimizeIcon = Accordion_small ? "chevron up" : "minimize";
    const AddIcon = Accordion_small ? "chevron" : "add";

    return (
        <>
            {accordionopen ? (
                <div
                    className={`accordian-wrapper ${
                        Accordion_small ? "accordian-small" : ""
                    } ${cssClasses ? cssClasses : ""}`}
                >
                    <div
                        className="accordian-header"
                        onClick={() => {
                            setAccordionOpen(!accordionopen);
                            if (onChange) {
                                onChange();
                            }
                        }}
                    >
                        <HeaderTag>
                            {Accordion_small ? (
                                <Text
                                    type="body-text-3"
                                    fontWeight="medium-font"
                                    children={title}
                                />
                            ) : (
                                <Text
                                    type="subtitle-1"
                                    fontWeight="medium-font"
                                    children={title}
                                />
                            )}

                            <div className="body-text body-text-1 medium-font accordian-header-subtitle">
                                {subtitle ? subtitle : ""}
                            </div>
                            {tooltip && (
                                <span className="info-wrap">
                                    <Icon value="information" size="medium" />
                                    <div className="tooltip-wrapper bottom-tooltip">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"normal-font"}
                                        >
                                            {tooltip}
                                        </Text>
                                    </div>
                                </span>
                            )}
                        </HeaderTag>

                        <div className="question-count">{questionCount}</div>
                        <div className="accordion-action">
                            {Accordion_small ? (
                                <Icon
                                    value={MinimizeIcon}
                                    colorClass={"gray-900-svg"}
                                    size="small"
                                />
                            ) : (
                                <Icon
                                    value={MinimizeIcon}
                                    colorClass={"gray-900-svg"}
                                    size="medium"
                                />
                            )}
                        </div>
                    </div>
                    <div className="accordian-body-text">{children}</div>
                </div>
            ) : (
                <div
                    className={`accordian-wrapper ${
                        Accordion_small ? "accordian-small" : ""
                    } ${cssClasses ? cssClasses : ""}`}
                >
                    <div
                        className="accordian-header"
                        onClick={() => {
                            setAccordionOpen(true);
                            if (onChange) {
                                onChange();
                            }
                        }}
                    >
                        <HeaderTag>
                            {Accordion_small ? (
                                <Text
                                    type="body-text-3"
                                    fontWeight="medium-font"
                                    children={title}
                                />
                            ) : (
                                <Text
                                    type="subtitle-1"
                                    fontWeight="medium-font"
                                    children={title}
                                />
                            )}

                            <div className="body-text body-text-1 medium-font accordian-header-subtitle">
                                {subtitle ? subtitle : ""}
                            </div>
                            {tooltip && (
                                <span className="info-wrap">
                                    <Icon value="information" size="medium" />
                                    <div className="tooltip-wrapper bottom-tooltip">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"normal-font"}
                                        >
                                            {tooltip}
                                        </Text>
                                    </div>
                                </span>
                            )}
                        </HeaderTag>
                        <div className="question-count">{questionCount}</div>
                        <div className="accordion-action">
                            {Accordion_small ? (
                                <Icon
                                    value={AddIcon}
                                    colorClass={"gray-900-svg"}
                                    size="small"
                                />
                            ) : (
                                <Icon
                                    value={AddIcon}
                                    colorClass={"gray-900-svg"}
                                    size="medium"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Accordian.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.any,
    children: PropTypes.node,
    Accordion_small: PropTypes.bool,
};
Accordian.defaultProps = {
    open: false,
    title: <></>,
    children: <></>,
    Accordion_small: false,
};