import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { getLanguagesService } from "../../services/language.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        phone_number: "",
        whatsapp_phone_number: "",
        whatsapp_checkbox: true,
    });

    const [formErrors, setFormErrors] = useState({
        phone_number: null,
        whatsapp_phone_number: null,
    });
    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();

        document.title = "Step 2 | " + process.env.REACT_APP_NAME;

        getProfileApi();
    }, []);

    const getProfileApi = () => {
        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 2;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 1) {
                        navigate("/tester/profile/step1");
                    }
                }
                setFormValues({
                    ...formValues,
                    phone_number: response.data.user.phone_number
                        ? response.data.user.phone_number &&
                          response.data.user.phone_number.startsWith("+")
                            ? response.data.user.phone_number.slice(1)
                            : response.data.user.phone_number
                        : "",
                    whatsapp_phone_number: (
                        response.data.user.whatsapp_phone_number &&
                        response.data.user.whatsapp_phone_number.startsWith("+")
                            ? response.data.user.whatsapp_phone_number.slice(1)
                            : response.data.user.whatsapp_phone_number
                    )
                        ? response.data.user.whatsapp_phone_number
                        : "",
                    whatsapp_checkbox:
                        response.data.user.whatsapp_phone_number ==
                        response.data.user.phone_number
                            ? true
                            : false,
                });
                setSteps(response.data.steps);
            }
        });
    };

    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        if (existingTester) {
            formData.existingTester = 1;
        }
        if (formValues.phone_number.startsWith("+")) {
            formValues.phone_number = formValues.phone_number.slice(1);
        }
        if (formValues.whatsapp_phone_number.startsWith("+")) {
            formValues.whatsapp_phone_number =
                formValues.whatsapp_phone_number.slice(1);
        }

        updateStep2(formData, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step3");
                    }
                } else {
                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step3");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "phone_number") {
            var field_value = key && inline ? value : formValues.phone_number;

            if (
                !field_value ||
                !isValidPhoneNumber(
                    !field_value.includes("+")
                        ? "+" + field_value.toString()
                        : field_value.toString()
                )
            ) {
                form_errors = {
                    ...form_errors,
                    phone_number: "Phone number is not valid",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "phone_number" : firsterrorid;
            }
        }
        if (!formValues.whatsapp_checkbox) {
            if (!key || key == "whatsapp_phone_number") {
                var field_value =
                    key && inline ? value : formValues.whatsapp_phone_number;
                if (
                    !field_value ||
                    !isValidPhoneNumber(
                        !field_value.includes("+")
                            ? "+" + field_value.toString()
                            : field_value.toString()
                    )
                ) {
                    form_errors = {
                        ...form_errors,
                        whatsapp_phone_number: "WhatsApp number is not valid",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null
                            ? "whatsapp_phone_number"
                            : firsterrorid;
                }
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (
            !formValues.phone_number ||
            !isValidPhoneNumber(
                !formValues.phone_number.includes("+")
                    ? "+" + formValues.phone_number.toString()
                    : formValues.phone_number.toString()
            )
        ) {
            error = true;
        }
        if (!formValues.whatsapp_checkbox) {
            if (
                !formValues.whatsapp_phone_number ||
                !isValidPhoneNumber(
                    !formValues.whatsapp_phone_number.includes("+")
                        ? "+" + formValues.whatsapp_phone_number.toString()
                        : formValues.whatsapp_phone_number.toString()
                )
            ) {
                error = true;
            }
        }
        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={2}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(null, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={2}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            What are your contact details?
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            We’ll just need your contact info to
                                            keep you informed
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-32">
                                        <div
                                            className="formbox "
                                            id="phone_number"
                                            style={{ position: "relative" }}
                                        >
                                            <PhoneNumberInput
                                                value={formValues.phone_number}
                                                onBlur={() => {
                                                    validateFullForm(
                                                        false,
                                                        "phone_number"
                                                    );
                                                }}
                                                onCountryChange={() => {
                                                    setFormErrors({
                                                        ...formErrors,
                                                        phone_number: null,
                                                        error_class:
                                                            "input_error",
                                                    });
                                                }}
                                                onChange={(value) => {
                                                    setFormErrors({
                                                        ...formErrors,
                                                        phone_number: null,
                                                    });
                                                    setFormValues(
                                                        (oldValues) => ({
                                                            ...oldValues,
                                                            phone_number: value,
                                                        })
                                                    );

                                                    validateFullForm(
                                                        false,
                                                        "phone_number",
                                                        value ? value : "",
                                                        true
                                                    );
                                                }}
                                                error={formErrors.phone_number}
                                                error_class={
                                                    formErrors.error_class
                                                }
                                            />
                                            <div className="whatsupplabel">
                                                <CheckBox
                                                    checked={
                                                        formValues.whatsapp_checkbox
                                                    }
                                                    onChange={(event) => {
                                                        toggleCheck(
                                                            "whatsapp_checkbox",
                                                            event.currentTarget
                                                                .checked
                                                        );
                                                    }}
                                                    id="whatsapp_number"
                                                    label={
                                                        "I use WhatsApp on this number"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {!formValues.whatsapp_checkbox && (
                                            <div
                                                className="formbox "
                                                id="whatsapp_phone_number"
                                                style={{ position: "relative" }}
                                            >
                                                <PhoneNumberInput
                                                    label={"WhatsApp number"}
                                                    value={
                                                        formValues.whatsapp_phone_number
                                                    }
                                                    onBlur={() => {
                                                        validateFullForm(
                                                            false,
                                                            "whatsapp_phone_number"
                                                        );
                                                    }}
                                                    onCountryChange={() => {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            whatsapp_phone_number:
                                                                null,
                                                            error_class:
                                                                "input_error",
                                                        });
                                                    }}
                                                    onChange={(value) => {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            whatsapp_phone_number:
                                                                null,
                                                        });
                                                        setFormValues(
                                                            (oldValues) => ({
                                                                ...oldValues,
                                                                whatsapp_phone_number:
                                                                    value,
                                                            })
                                                        );

                                                        validateFullForm(
                                                            false,
                                                            "whatsapp_phone_number",
                                                            value ? value : "",
                                                            true
                                                        );
                                                    }}
                                                    error={
                                                        formErrors.whatsapp_phone_number
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step1"
                                                    : "/tester/profile/step1"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext position-relative none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    size={"large"}
                                                    value={"credits-gold"}
                                                />
                                            }
                                            title="Request a payout when you reach $30"
                                            info="Take between 15/20 short tests to redeem your cash reward."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
