import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateStep4,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { getLanguagesService } from "../../services/language.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        housing_status: "",
        living_arrangements: "",
        income_slab: "",
        people: 1,
    });

    const [formErrors, setFormErrors] = useState({
        phone_number: null,
        whatsapp_phone_number: null,
    });

    const [lovCategories, setLovCategories] = useState([]);

    const incomelevelOptions = [
        { label: "$0 - No income", value: "$0 - No income" },
        { label: "$1 - $250", value: "$1 - $250" },
        { label: "$251 - $500", value: "$251 - $500" },
        { label: "$501 - $1,500", value: "$501 - $1,500" },
        { label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
        { label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
        { label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
        { label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
        { label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
        { label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
        { label: "Over $15,000", value: "Over $15,000" },
    ];

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    const [navigateStep, setNavigateStep] = useState(null);

    useEffect(() => {
        if (navigateStep) {
            onSubmit(null);
        }
    }, [navigateStep]);

    useEffect(() => {
        window.animate();

        document.title = "Step 4 | " + process.env.REACT_APP_NAME;

        getProfileApi();
    }, []);
    const getProfileApi = () => {
        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 4;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 2) {
                        navigate("/tester/profile/step2");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }

                var housing_status = "";

                var living_arrangements = "";

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Housing status" &&
                                item.id == lov.lov_id
                            ) {
                                housing_status = item.id;
                            }
                            if (
                                category.name == "Living arrangements" &&
                                item.id == lov.lov_id
                            ) {
                                living_arrangements = item.id;
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,
                    housing_status: housing_status,
                    living_arrangements: living_arrangements,
                    people:
                        response.data.user.people > 0
                            ? response.data.user.people
                            : 1,
                    income_slab: response.data.user.income_slab,
                });
                setSteps(response.data.steps);
            }
        });
    };

    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = () => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        if (existingTester) {
            formData.existingTester = 1;
        }

        updateStep4(formData, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step5");
                    }
                } else {
                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step5");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "housing_status") {
            var field_value = key && inline ? value : formValues.housing_status;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    housing_status: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "housing_status" : firsterrorid;
            }
        }
        if (!key || key == "living_arrangements") {
            var field_value =
                key && inline ? value : formValues.living_arrangements;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    living_arrangements: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "living_arrangements" : firsterrorid;
            }
        }

        if (!key || key == "income_slab") {
            var field_value = key ? value : formValues.income_slab;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    income_slab: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }
        if (!key || key == "people") {
            var field_value = key && inline ? value : formValues.people;

            if (parseInt(field_value) == 0) {
                form_errors = {
                    ...form_errors,
                    people: "People must be > 0",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (!formValues.income_slab) {
            error = true;
        }
        if (parseInt(formValues.people) == 0) {
            error = true;
        }

        if (!formValues.housing_status) {
            //console.log(4)
            error = true;
        }
        if (!formValues.living_arrangements) {
            //console.log(5)
            error = true;
        }

        return error;
    };
    const onSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile();
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={4}
                            steps={steps}
                            onClick={(navigateStep) => {
                                setNavigateStep(navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(null, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={4}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Tell us about your living situation
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            Your household info helps us
                                            understand you better
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-32">
                                        <div className="formbox">
                                            <div
                                                className="profile-form-group"
                                                id="housing_status"
                                            >
                                                <DropdownComponent
                                                    value={
                                                        formValues.housing_status
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Housing status"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                housing_status:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                housing_status:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            housing_status:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Housing status"
                                                    error={
                                                        formErrors.housing_status
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox">
                                            <div
                                                className="profile-form-group"
                                                id="living_arrangements"
                                            >
                                                <DropdownComponent
                                                    value={
                                                        formValues.living_arrangements
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Living arrangements"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                living_arrangements:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                living_arrangements:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            living_arrangements:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Living arrangements"
                                                    error={
                                                        formErrors.living_arrangements
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="income_slab"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    options={incomelevelOptions}
                                                    value={
                                                        formValues.income_slab
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                income_slab:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                income_slab: "",
                                                            });
                                                        }
                                                        validateFullForm(
                                                            false,
                                                            "income_slab",
                                                            item
                                                                ? item.value
                                                                : "",
                                                            true
                                                        );
                                                    }}
                                                    isClearable={true}
                                                    label="Monthly household income (USD)"
                                                    error={
                                                        formErrors.income_slab
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox" id="people">
                                            <label
                                                className={
                                                    "body-text body-text-2 semibold-font"
                                                }
                                            >
                                                How many people live in your
                                                household?
                                            </label>
                                            <IncDecCounter
                                                min={1}
                                                max={10}
                                                value={formValues.people}
                                                onChange={(value) => {
                                                    if (
                                                        value !=
                                                        formValues.people
                                                    ) {
                                                        setFormValues({
                                                            ...formValues,
                                                            people: value,
                                                        });

                                                        validateFullForm(
                                                            false,
                                                            "people",
                                                            value ? value : "",
                                                            true
                                                        );
                                                    }
                                                }}
                                                readOnly={false}
                                            />
                                            {formErrors.people && (
                                                <span
                                                    className={
                                                        formErrors.error_class
                                                    }
                                                >
                                                    {formErrors.people}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step3"
                                                    : "/tester/profile/step3"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext position-relative none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    colorClass={
                                                        "success-green-200"
                                                    }
                                                    value={"star"}
                                                />
                                            }
                                            title="Keep your tester score high"
                                            info="Maintaining this high score will ensure you access to more tests."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
