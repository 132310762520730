import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { getTestData } from "../../../services/test";
import toast from "react-hot-toast";
import { saveConclusion } from "../../../services/test";
import moment from "moment";
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { LayoutResearcher } from './../../themes/userq/Layouts/layout_researcher';
import { Text } from './../../themes/userq/Text/Text';
import { Input } from './../../themes/userq/Input/Input';
import { TextArea } from './../../themes/userq/TextArea/TextArea';
import { Icon } from './../../themes/userq/Icon/Icon';
import { Button } from './../../themes/userq/Button/Button';
import Header from './components/header';
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { settings } from "../../../data/settings.js";
import { Helmet } from "react-helmet";
import { renderTestTitle, renderTestTitleCreate } from "../../../lib/helpers.js";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";
import ImageLibraryModal from "../../themes/userq/ImageLibraryModal/ImageLibraryModal.js";



export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    return 'other';
}
export function matchExtensions(extns, file) {
    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function Conclusions() {

    ReactSession.setStoreType("localStorage");
    let { test_id } = useParams();
    const navigate = useNavigate();

    const { updateTestLength, getTestLength, allowEditAccess } = useTestBuilderData();

    const [test, setTest] = useState("");
    const [lastSaved, setLastSaved] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [image, setImage] = useState(null);
    const [imageId, setImageId] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [testImage, setTestImage] = useState(null);
    const [thankyouImage, setThankyouImage] = useState(null);
    const [imageDeleted, setImageDeleted] = useState(false);
    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });
    const [stepperError, setStepperError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWelcomeImage, setIsWelcomeImage] = useState(true);
    const handleImageLibraryModalClose = () => setIsModalOpen(false);
    const [previewTitle,setPreviewTitle] = useState('');
    const [previewText,setpreviewText] = useState('');
    const handleTitleChange = (e) =>{
        var value = e.target.value
        setPreviewTitle(value);
    }
    const handleTextChange = (e) =>{
        var value = e.target.value
        setpreviewText(value);
    }
    const handleRemoveImage = () =>{
    setIsWelcomeImage(false);
    removeImage();
      }
    const [selectedImageSrc, setSelectedImageSrc] = useState(`${process.env.REACT_APP_URL}img/welcome-img.png`);

    const handleSelectPhoto = (imageSrc,imageID) => {
    setSelectedImageSrc(imageSrc); 
    setIsModalOpen(false); 
    setImageId(imageID);
    setIsWelcomeImage(true);
    setThankyouImage(null);
    };
    const [formValues, setFormValues] = useState({
        thankyou_title: "",
        thankyou_description: "",
    });

    const [formErrors, setFormErrors] = useState({
        image: null,
        thankyou_title: null,
        thankyou_description: null,
        error_class: null,
    });

    const [searchParams] = useSearchParams()

    let treeEmptyNodeError = false;

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const showError = (error) => {
        localStorage.setItem("stepper_error", error);
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );

    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    // const handleFile = (file) => {

    //     resetFormErrors();
    //     //let's grab the image file
    //     var error = false;

    //     var fileType = getFileType(file);

    //     if (fileType == "image" && file.size / 1024 > 2048) {
    //         setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {
    //         setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (fileType != "image") {
    //         setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (!error) {
    //         setImage(file);
    //         setPreviewUrl(URL.createObjectURL(file));
    //     }

    // };

    // const handleOnDragOver = (event) => {
    //     event.preventDefault();
    // };
    // const handleOnDrop = (event) => {
    //     //prevent the browser from opening the image
    //     event.preventDefault();
    //     event.stopPropagation();
    //     //let's grab the image file
    //     let imageFile = event.dataTransfer.files[0];

    //     handleFile(imageFile);
    // };
    const removeImage = (e) => {
        setImageId(null);
        setPreviewUrl(null);
        setThankyouImage(null);
        setImageDeleted(true);
        // console.log('removed');
    };
    // const handleFileInput = (event) => {
    //     //prevent the browser from opening the image
    //     event.preventDefault();
    //     event.stopPropagation();
    //     //let's grab the image file
    //     let imageFile = event.target.files[0];

    //     handleFile(imageFile);
    // };



    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            setStepperError(false);
        };
    };
    function checkEmptyNodeInTree(tree){
        tree.forEach(function (item) {
          
            if (!item.title) {
              treeEmptyNodeError = true;
      
              item["error"] = true;
            } else {
              item["error"] = false;
            }
            if (item.children.length > 0) {
            
                checkEmptyNodeInTree(item.children);
            }
        });
      };

    const getTest = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);
            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/r/project/" + response.test.project_id);
                    } else {
                        getTestLength(test_id);

                        allowEditAccess({test_id:test_id, workspace_id: response.test.workspace_id, workspace_project_id: response.test.workspace_project_id});
                        
                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                            navigate("/r/test/" + test_id + "/welcome?error=true");

                        } else if (
                            response.test.methodology == "Tree Test" &&
                            response.test.tasks.length == 0
                        ) {
                            showError("Please add tasks");
                            navigate("/r/test/" + test_id + "/tree-test?error=true");

                        } else if (response.test.methodology === "Card Sorting") {
                            if (response.test.card_sorting === null || !response.test.card_sorting.title) {
                                showError(
                                    "You missed some key information. Please check again and fill out any missing fields."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting && response.test.card_sorting_card.length === 0) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }
                            else if (
                                response.test.card_sorting.card_sorting === "close" &&
                                response.test.card_sorting_category.length < 2
                            ) {
                                showError(
                                    "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting_card.length < 2) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }
                        } else if (response.test.methodology === "Five Seconds Test") {
                            console.log(response.test.fiveSecondsTestTask)
                            if (response.test.fiveSecondsTestTask == null) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }else if ((response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) && response.test.fiveSecondsTestTask.task_file == null && response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/five-seconds-test?error=true"
                                    );

                                }

                            }
                        } else if (
                            response.test.methodology == "Survey"
                        ) {
                            if (response.test.surveyQuestion.length === 0) {
                                showError(
                                    "Please add at least one question for the survey before proceeding."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/survey?error=true"
                                );

                            }
                            else if (response.test.surveyQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                validatelogic(response.test.surveyQuestion);
                                response.test.surveyQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/survey?error=true"
                                    );

                                }

                            }

                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {

                            var tree_error = false;
                            
                            if(response.test.tree){

                                if(response.test.tree.length==0){
                                    tree_error = true;
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                } else {
                                    treeEmptyNodeError = false;

                                    checkEmptyNodeInTree(response.test.tree);

                                    if(treeEmptyNodeError){
                                        tree_error = true;
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                        navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                    }
                                }
                                
                            }
                            if(!tree_error){
                                if (response.test.tasks.length === 0) {
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");

                                } else {
                                    
                                    for (var i = 0; i < response.test.tasks.length; i++) {
                                        if (!response.test.tasks[i].title || response.test.tasks[i].title == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                        else if (response.test.tasks[i].right_answers.length == 0) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                        /*else if (response.test.tasks[i].correct_answer_id == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }*/
                                    }

                                }
                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if (response.test.preference_test_setting.preference_test_title == "" || response.test.preference_test_setting.preference_test_title == null) {
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/preference-test?error=true");

                            }
                            else if (response.test.preference_test_designs.length < 2) {
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/preference-test?error=true");

                            }
                        } else if(response.test.methodology=="Prototype test"){

                            var error = false;

                            for(var i=0; i<response.test.prototype_test_tasks.length; i++){
			
                                var task = response.test.prototype_test_tasks[i];
                    
                                
                                if(!task.prototype_link){
                                    
                                    error = true;
                                    break;
                                }
                                else if(!task.is_synced){
                                    error = true;
                                    break;
                                }
                                else if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="closed" && !task.goal_screen_node_id){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length==0){
                                    error = true; 
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length>0){
                                    var no_of_optional = 0;
                    
                                    task.questions.forEach(function(item){
                                        if(item.is_optional){
                                            no_of_optional++;
                                        }
                                    })
                                    if(no_of_optional==task.questions.length){
                                        error=true;
                                        break;
                                    }
                                    
                                }
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/prototype-test?error=true");
                            }

                        } else if(response.test.methodology=="First click"){

                            var error = false;

                            for(var i=0; i<response.test.firstclick_test_tasks.length; i++){
			
                                var task = response.test.firstclick_test_tasks[i];
                    
                                
                                if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(!task.design_file){
                                    error = true;
                                    break;
                                }
                            
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/first-click?error=true");
                            }

                        }
                    }
                }

                setTest(response.test);
                setLastSaved(moment(response.test.updated_at));
                const {
                    thankyou_title,
                    thankyou_title_arabic,
                    thankyou_description,
                    thankyou_description_arabic,
                    language,
                    test_name,
                    conclusion_image,
                    is_concl_img_removed
                } = response.test;

                setFormValues((oldValues) => ({
                    ...oldValues,
                    thankyou_title: thankyou_title,
                    thankyou_description: thankyou_description,
                }));

                setLanguage(language);
                setThankyouImage(conclusion_image);
                setPreviewTitle(thankyou_title);
                setpreviewText(thankyou_description);
                 if(is_concl_img_removed == 1 && conclusion_image == null){
                    setIsWelcomeImage(false);
                 }
                if (searchParams.get("error") == "true") {
                validateFullForm(thankyou_title,thankyou_description,false);
                }
            } else {
                //   setErrorMessage(response.setErrorMessage);
            }
        });
    };

    const validateFullForm = (thankyou_title,thankyou_description,showToast) => {
        var error = false;
        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (thankyou_title == '') {

            form_errors = {
                ...form_errors,
                thankyou_title: "A page title is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "title" : firsterrorid;

            setFormErrors(form_errors);
            if(showToast){
            showError("The thank you page cannot be empty! Please enter a title and a description.");
            }
        }
        if(thankyou_description == ''){
            form_errors = {
                ...form_errors,
                thankyou_description: "A thank you message is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "message" : firsterrorid;

            setFormErrors(form_errors);
            if(showToast){
                showError("The thank you page cannot be empty! Please enter a title and a description.");
            }
        }
        if(error){
            localStorage.setItem("stepper_error", "The thank you page cannot be empty! Please enter a title and a description.");
            setStepperError(true);
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        
        
        return error;
        
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Summary";
        getTest();
        if (searchParams.get("error") == "true") {
            setStepperError(true);
        }
    }, []);

    const saveConclusionData = () => {
        const token = ReactSession.get("token");


        var data = new FormData();
        setIsSaved({ 'status': 'loading', 'message': '' });
        data.append("test_id", test_id);
        data.append("thankyou_title", formValues.thankyou_title);
        data.append("thankyou_description", formValues.thankyou_description);

        if (imageId) {
            data.append("image_id", imageId);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }

        saveConclusion(data, token).then((response) => {
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });
    };

    useEffect(() => {
        if (test.status === "draft") {
            saveConclusionData();
        }

    }, [imageId, imageDeleted,thankyouImage]);

    const conclusionSubmitHandler = (saveUsingNavigation) => {
        var error=false
        if (!saveUsingNavigation) {
        error = validateFullForm(formValues.thankyou_title,formValues.thankyou_description,true);
        }
        if (!error) {
        const token = ReactSession.get("token");
        setFormLoading(true);
        setIsSaved({ 'status': 'loading', 'message': '' });
        var data = new FormData();

        data.append("test_id", test_id);
        data.append("language", language);
        data.append("thankyou_title", formValues.thankyou_title);
        data.append("thankyou_description", formValues.thankyou_description);


        if (image) {
            data.append("conclusion_image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        if (!saveUsingNavigation) {
            data.append("saved_step", 3);
        }

        saveConclusion(data, token).then((response) => {
            setFormLoading(false);
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
                if (!saveUsingNavigation) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step3',
                        'type': test.methodology,
                        'userID' : test.user_id
                    });
                    navigate("/r/test/" + test_id + "/recruit");
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                if (response.message == "The image must be an image.") {
                    setImage(null);
                    setPreviewUrl("");
                    setFormErrors({ ...formErrors, image: "The image must be an image.", "error_class": "input_error" });
                } else {
                    showError(response.message);
                }
            }
        });
        }
    };

    const backPath = (test) => {
        if (test.methodology == "Preference Test") {
            return "/r/test/" + test_id + "/preference-test";
        }
        if (test.methodology == "Card Sorting") {
            return "/r/test/" + test_id + "/card-sorting-test";
        }
        if (test.methodology == "Five Seconds Test") {
            return "/r/test/" + test_id + "/five-seconds-test";
        }
        if (test.methodology == "Survey") {
            return "/r/test/" + test_id + "/survey";
        }
        if (test.methodology == "Prototype test") {
            return "/r/test/" + test_id + "/prototype-test";
        }
        return "/r/test/" + test_id + "/tree-test";
    };

    const validatelogic = (surveyQuestions) => {
        // console.log(surveyQuestions);
        // console.log("validatelogic");
        var error=false;
        surveyQuestions.forEach((question,index) => {
            if (JSON.parse(question).questionType != "singlechoice") {
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question<index+2){
                    
                    error=true;
                }
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question=="End survey"){
                    error=false;
                }
                //
                if(JSON.parse(question).error && JSON.parse(question).error==1){
                    // console.log(JSON.parse(question));
                    // console.log("logictobeupdated");
                    error=true;
                }
            }else{
                if(JSON.parse(question).is_logic==1){
                    JSON.parse(question).jumpTo.forEach((jumptoOption,i)=>{
                        if(jumptoOption!="End survey" && jumptoOption<index+2){
                            // console.log(JSON.parse(question));
                            // console.log("jumptoless");
                            error=true;
                        }
                        //
                        if(JSON.parse(question).error && JSON.parse(question).error[i]==1){
                            // console.log(JSON.parse(question));
                            // console.log("logictobeupdated");
                            error=true;
                        }
                    })
                }
            }
        }
        );

        if(error==true){
            showError(
                "Update the correct logic to proceed further."
            );
            navigate(
                "/r/test/" + test_id + "/survey?error=true"
            );
        }
    }


    return (
        <LayoutResearcher wrapClass={"create-test-data-wrapper"} openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}>
            <Helmet>
                <title>Create {test && test.methodology ? renderTestTitleCreate(test.methodology):"test"}  - step 3  | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                />
                <div className="three-column-layout-body">
                    <div className="three-column-layout-left">
                        <Stepper
                            test={test}
                            completed_step={2}
                            stepCallback={() => {
                                conclusionSubmitHandler(true);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading &&
                        <div className="test-section-loader"><LoadingIcon /></div>
                    }
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div className="test-welcome-page-wrap">
                                    <div class={`test-welcome-inner-data-hold ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                        <div className="test-welcome-page-left">
                                            <div className="test-welcome-top-head">
                                                <Text type='subtitle-1' fontWeight='medium' children="Thank you page" />
                                                <Text type='body-text-2' fontWeight='medium' children="You can create a customised message to thank your participants." />
                                            </div>
                                            <div className="test-welcome-form-wrap">
                                                <Input
                                                    label="Page title"
                                                    onChange={set("thankyou_title")}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                thankyou_title: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    value={formValues.thankyou_title}
                                                    maxLength="45"
                                                    error={formErrors.thankyou_title}
                                                    error_class={formErrors.error_class}
                                                    id="title"
                                                    clearField={false}
                                                    required={true}
                                                    onBlur={() => saveConclusionData()}
                                                    rtl={test.language == "ar" ? true : false}
                                                    onInput={handleTitleChange}
                                                />
                                                <TextArea
                                                    id={"message"}
                                                    autosize={true}
                                                    value={formValues.thankyou_description}
                                                    onChange={set("thankyou_description")}
                                                    maxLength="300"
                                                    label="Thank you message"
                                                    language={language}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                thankyou_description: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    onBlur={() => saveConclusionData()}
                                                    labelfont="english-font"
                                                    wrapClass="tester-text-area"
                                                    error={formErrors.thankyou_description}
                                                    error_class={formErrors.error_class}
                                                    onInput={handleTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="test-welcome-right">
                                            <div className="test-welcome-image-wrap">
                                        {isWelcomeImage ? ( 
                                        <>
                                                <div
                                                    className={`project-modal-img`}
                                                >

                                                    {!previewUrl &&
                                                        (thankyouImage ? (
                                                            <>
                                                                <div className="uploaded-project-img">
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_IMG_URL + thankyouImage
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                              
                                                            </>
                                                        ) : (

                                                            <div
                                                                className="uploaded-project-img"
                                                                
                                                                
                                                            >
                                                                <img
                                                                    src={
                                                                        selectedImageSrc
                                                                    }
                                                                    alt="img"
                                                                />


                                                            </div>
                                                        ))}
                                                    {previewUrl && (
                                                        <>
                                                            <div className="uploaded-project-img">
                                                                <img src={previewUrl} alt="img" />
                                                            </div>
                                                            {/* <div className="remove-img"
                                                                onClick={removeImage}>
                                                                Remove Image
                                                            </div> */}
                                                        </>
                                                    )}

                                                    {formErrors.image != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.image}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="change-welcome-image">
                                                  
                                                    <div className="row justify-content-between px-3">
                                                        <Button
                                            type={"ghost"}
                                            label={"Change Photo"}
                                            size={"small"}
                                            
                                             onClick={()=>{
                                                setIsModalOpen({...isModalOpen, open: true});
                                            }}
                                        />

                                         <Button
                                            type={"ghost"}
                                            label={"Remove Photo"}
                                            size={"small"}
                                            iconLeft={
                                                <Icon value="delete" colorClass="gray-900-svg" size={"small"} />
                                            }
                                            onClick={handleRemoveImage}
                                        />
                                                    </div>
                                                </div>
                                                </>
                                        ):(
                                            <>
                                                <div
                                                    className={`project-modal-img`}
                                                >

                                                            <div
                                                                className="no-img-bg d-flex justify-content-center align-items-center"
                                                            
                                                            >
                                                                <div className="pb-2 px-5">
                                                                <img src={
                                                                        process.env.REACT_APP_URL +
                                                                        "img/no-img.png" 
                                                                    }  alt="" className="d-block mx-auto"/>
                                             <Text type={'body-text-3'} cssClasses={'medium-font mt-2 text-center'}>No photo will be shown on the welcome page</Text>
                                             </div>
                                                            </div>
                                                              </div>
                                                                  <div className="change-welcome-image">
                                            
                                                   
                                                    <div className="row justify-content-between px-3">
                                                        <Button
                                            type={"ghost"}
                                            label={"Add Photo"}
                                            size={"small"}
                                            
                                             onClick={()=>{
                                                setIsModalOpen({...isModalOpen, open: true});
                                            }}
                                        />

                                         <Button
                                            type={"ghost"}
                                            label={"Remove Photo"}
                                            size={"small"}
                                            iconLeft={
                                                <Icon value="delete" colorClass="gray-900-svg" size={"small"} />
                                            }
                                            state={'disabled'}
                                        />
                                                    </div>
                                                   
                                                </div>
                                              
                                                </>
                                        )
                                                 }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns-wrap-bottom">
                                        <Button
                                            onClick={() => { navigate(backPath(test)); }}
                                            type={"ghost"}
                                            label={"Previous"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                        />
                                        <Button
                                            onClick={() => conclusionSubmitHandler(false)}
                                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                            label="Next"
                                            size={"large"}
                                            state={(formValues.thankyou_title==""||formValues.thankyou_description=="")?"disabled":""}
                                        />
                                    </div>


                                </div>

                            </div>
                            <div className="three-column-layout-right">

                                <div className="welcome-test-preview-area">
                                    <Text
                                        type={'overline'}
                                        children={'Preview'}
                                    />
                                    {/* <div className="welcome-test-preview-area-inner">
                                        {test.language == "ar" ?
                                            <img src={
                                                process.env.REACT_APP_URL + "img/thankyoupreviewarabic.svg"}
                                                alt="img" /> : <img src={
                                                    process.env.REACT_APP_URL + "img/thankyoupreview.svg"}
                                                    alt="img" />}
                                    </div> */}
                                     <div className="welcome-test-preview-area-inner">
                                       <div className="test-preview-container d-flex">
                                        <div className="col-8 p-0 pr-2 text-left">
                                            {/* {test.language == "ar" ?
                                               <img src={process.env.REACT_APP_URL+"img/staticpreviewarabic.png"} alt="img"/>:
                                                <img src={process.env.REACT_APP_URL+"img/staticpreview.png"} alt="img" />
                                            } */}
                                            <Text type='body-text-2' fontWeight='semibold-font' children={previewTitle}  cssClasses={'one-line mb-2'}/>
                                              <Text type='caption' children={previewText} cssClasses={'four-line pb-1'} />   
                                               <Button
                                                type={"primary"}
                                                label={"Get Started"}
                                                size={"medium"}
                                                cssclass={"mt-2"} />
                                        </div>
                                         <div className="col-4 p-0 left-preview-img">
                                         {isWelcomeImage ? ( 
                                            <>
                                             {!previewUrl &&
                                                (thankyouImage ? (
                                                    <img src={process.env.REACT_APP_IMG_URL+thankyouImage} alt="img"/>           
                                                        ):(
                                                             <img className="upload-image-placeholder" src={selectedImageSrc} alt="img" />
                                                            ))}
                                                        {previewUrl && (
                                                                <img src={previewUrl} alt="img" />
                                                    )}
                                        </>
                                                       ):(

                                                    <>
                                                      <div></div>
                                                    </>
                                               )}
                                           </div>
                                       </div>
                                    </div>
                                </div>
                                <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }} href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'>
                                    <Icon
                                        value={"support"}
                                        colorClass={"gray-50-svg"}
                                        size={"medium"}
                                    />
                                    Support
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
                <ImageLibraryModal 
        open={isModalOpen} 
        close={handleImageLibraryModalClose} 
        cssClass="" 
        onSelectPhoto={handleSelectPhoto}
      />
        </LayoutResearcher>
    );
}
