import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../themes/userq/Button/Button";
import { Input } from "../../../themes/userq/Input/Input";
import { MultiSelecteDropdownList } from "../../../themes/userq/MultiSelecteDropdownList/MultiSelecteDropdownList";
import { CircularNameIcon } from "../../../themes/userq/CircularNameIcon/CircularNameIcon";
import { getWorkspaceService } from "../../../../services/workspaces";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { MembersDropdownList } from "../../../themes/userq/MembersDropdownList/MembersDropdownList";
import { updateProjectService } from "../../../../services/workspaceprojects";
import "./create-new-project-modal.css";
export default function ProjectMembersModal({
    workspace_id,
    project,
    onUpdateProject,
    ...props
}) {
    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({
        project_name: "",
        members: [],
    });

    const [formErrors, setFormErrors] = useState({ project_name: null });

    const [loading, setLoading] = useState(false);

    const [updateProjectLoading, setUpdateProjectLoading] = useState(false);

    const [teamMembers, setTeamMembers] = useState([]);

    const [defaultMembers, setDefaultMembers] = useState([]);

    const [closeAction, setCloseAction] = useState(false);

    const navigate = useNavigate();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        //closeModal();

                        setCloseAction(true);
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const closeModal = () => {
        setCloseAction(true);
        /*if (props.closeModal) {
            props.closeModal();
        }*/
    };
    useEffect(() => {
        if (closeAction) {
            updateProjectApi();
        }
    }, [closeAction]);
    const checkIfFormValid = () => {
        return true;
        if (formValues.members.length == 0) {
            return false;
        }

        return true;
    };
    useEffect(() => {
        setCloseAction(false);
        setFormErrors({ project_name: null });
        getWorkspaceApi();
    }, [props.openModal]);

    useEffect(() => {
        if (project) {
            var selected = [];

            let user = ReactSession.get("user");

            project.workspace_project_team_members.forEach(function (member) {
                if (
                    user.user_id != member.user_id &&
                    member.status == "active" &&
                    member.workspace_team_member.role != "admin"
                ) {
                    selected.push(member.workspace_team_member.id);
                }
            });
            setFormValues({ project_name: project.title, members: selected });
        }
    }, [project, props.openModal]);

    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");
        setLoading(true);

        getWorkspaceService(token, workspace_id)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    //setFormValues({...formValues, project_name:"Project "+(response.total_projects_count+1)});

                    let user = ReactSession.get("user");

                    // Transform workspace members into teamMembers format
                    const transformedMembers =
                        response.workspace.workspace_team_members.map(
                            (member) => {
                                if (member.email || member.user) {
                                    const {
                                        id,
                                        user,
                                        status,
                                        role,
                                        color,
                                        email,
                                        user_id,
                                    } = member;

                                    let memberInfo = { status };

                                    if (user) {
                                        if (user.email) {
                                            memberInfo.email = user.email;
                                        }

                                        if (user.first_name || user.last_name) {
                                            memberInfo.name = `${
                                                user.first_name || ""
                                            } ${user.last_name || ""}`.trim();
                                        }

                                        // Set the role directly in the memberInfo
                                        memberInfo.role = role;
                                        memberInfo.color = color;
                                    } else {
                                        memberInfo.name = email;

                                        memberInfo.email = email;

                                        // Set the role directly in the memberInfo
                                        memberInfo.role = role;
                                        memberInfo.color = color;
                                    }
                                    memberInfo.user_id = user_id;

                                    memberInfo.id = id;

                                    return memberInfo;
                                }
                            }
                        );

                    // Filter out members with the role "admin"
                    const filteredMembers = transformedMembers.filter(
                        (member) =>
                            member &&
                            (member.status === "active" ||
                                member.status === "invitation_accepted") &&
                            member.user_id !== user.user_id &&
                            member.role != "admin"
                    );
                    //console.log(filteredMembers);
                    // Set the teamMembers state
                    setTeamMembers(filteredMembers);

                    const defaultFilterMembers = transformedMembers.filter(
                        (member) =>
                            member &&
                            (member.user_id === user.user_id ||
                                member.role == "admin")
                    );

                    setDefaultMembers(defaultFilterMembers);
                } else {
                    if (response.message == "Workspace not found.") {
                        navigate("/404/");
                    } else {
                        showToast(response.message, "error");
                    }
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);

                setLoading(false);
            });
    };
    const validateFullForm = () => {
        var error = false;

        var formErrors = { project_name: null };

        if (!formValues.project_name) {
            formErrors = {
                ...formErrors,
                project_name: "This field is required",
            };

            error = true;
        }
        if (error) {
            setFormErrors(formErrors);
        }
        return error;
    };
    const updateProjectApi = () => {
        var error = false;

        error = validateFullForm();

        if (!error && !updateProjectLoading) {
            let formData = {};

            //formData.title = formValues.project_name;

            formData.project_id = project.id;

            formData.members = formValues.members;

            let token = ReactSession.get("token");

            setUpdateProjectLoading(true);

            updateProjectService(formData, token).then((response) => {
                if (response.success) {
                    onUpdateProject(response.project);

                    if (closeAction) {
                        if (props.closeModal) {
                            props.closeModal();
                        }
                    }
                } else {
                    setCloseAction(false);
                    showToast(response.message, "error");
                }
                setUpdateProjectLoading(false);
            });
        }
    };

    return (
        <>
            <Modal
                show={props.openModal}
                //onHide={props.closeModal}
                centered
                size="lg"
                className="fade create-new-project-modal project-member-modal-wrap"
            >
                <Modal.Body ref={wrapperRef}>
                    <div className="create-new-project-modal-data">
                        <div className="modal-header-top">
                            <Text type={"h3"}>Project members</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    setCloseAction(true);
                                    //closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon
                                        value="Close"
                                        size="medium"
                                        hover={true}
                                    />
                                </span>
                            </button>
                        </div>

                        <div className="create-new-project-modal-inner-data">
                            <MembersDropdownList
                                value={formValues.members}
                                onChange={(selected) => {
                                    console.log(selected);
                                    setFormValues({
                                        ...formValues,
                                        members: selected,
                                    });
                                }}
                                defaultMembers={defaultMembers.map(
                                    (user, index) => {
                                        let backgroundColor = user.color;

                                        let current_user =
                                            ReactSession.get("user");

                                        return {
                                            label: (
                                                <>
                                                    <CircularNameIcon
                                                        style={{
                                                            backgroundColor,
                                                        }}
                                                        key={index}
                                                        label={
                                                            user && user.name
                                                                ? user.name &&
                                                                  user.name
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                                : user.email
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                        }
                                                    />
                                                    {user.name
                                                        ? user.name
                                                        : user.email}
                                                    {user.user_id ==
                                                        current_user.user_id && (
                                                        <>{" (You)"}</>
                                                    )}
                                                    {user &&
                                                        user.role ==
                                                            "admin" && (
                                                            <>{" (Admin)"}</>
                                                        )}
                                                </>
                                            ),
                                        };
                                    }
                                )}
                                options={teamMembers.map((user, index) => {
                                    let backgroundColor = user.color;

                                    return {
                                        label: (
                                            <>
                                                <CircularNameIcon
                                                    style={{
                                                        backgroundColor,
                                                    }}
                                                    key={index}
                                                    label={
                                                        user && user.name
                                                            ? user.name &&
                                                              user.name
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                            : user.email
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                    }
                                                />
                                                {user.name
                                                    ? user.name
                                                    : user.email}
                                            </>
                                        ),
                                        value: user.id,
                                    };
                                })}
                                icon={
                                    <Icon
                                        value={"user"}
                                        size={"medium"}
                                        colorClass={"gray-600-svg"}
                                    />
                                }
                                label={" Select team member"}
                                no_results_message={
                                    "No members found. Please invite them to the workspace first."
                                }
                            />
                            {false &&
                                teamMembers.length == 0 &&
                                defaultMembers.length == 0 && (
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"mt-8 gray-color"}
                                    >
                                        No team members invited to this project
                                        yet.
                                    </Text>
                                )}
                        </div>

                        <div className="confirm-buttons-wrap modal-button-wrap">
                            <Button
                                cssclass={"w-100"}
                                type="primary"
                                size={`large`}
                                label={"Done"}
                                onClick={() => {
                                    updateProjectApi();
                                }}
                                state={
                                    checkIfFormValid() ? "active" : "disabled"
                                }
                                microLoading={updateProjectLoading}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
