import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import { countries as sanctioned_countries } from "../../data/countries_sanctioned.js";

import { languages } from "../../data/languages_others.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { MultiSelectDropdown } from "../themes/userq/MultiSelectDropdown/MultiSelectDropdown";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple";
import { InputDatePicker } from "../themes/userq/InputDatePicker/InputDatePicker.js";
import { AutoCompleteSingleSelect } from "../themes/userq/Dropdown/AutoCompleteSingleSelect";
import { TesterSlider } from "./tester_slider";
import {
    getOnfidoParams,
    getOnfidoResult,
} from "../../services/id_verification";
import { Onfido } from "onfido-sdk-ui";
import { Box } from "../themes/userq/Box/Box";

import { SignUpIdentityTile } from "../themes/userq/SignUpInformationalCard/sign_up_identity_tile";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import HelpModal from "../themes/userq/Modal/HelpModal";
import { CountdownTimer } from "../idVerification/CountdownTimer";
import { Link as UserQLink } from "../themes/userq/Link/Link.js";
import { getLanguagesService } from "../../services/language.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

var onfido = {};

export default function Step1({ existingTester }) {
    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const [startPosition, setStartPosition] = useState(0);

    const navigate = useNavigate();

    let jobRolesSelect = useRef();

    const jobRoleOptions = [
        { label: "UX/UI designer", value: "UX/UI designer" },
        { label: "UX researcher", value: "UX researcher" },
        { label: "Product owner", value: "Product owner" },
        { label: "Product manager", value: "Product manager" },
        { label: "Social media manager", value: "Social media manager" },
        { label: "Marketing manager", value: "Marketing manager" },
        { label: "Entrepreneur", value: "Entrepreneur" },
        { label: "Other...", value: "Other..." },
    ];

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        document_provided_fields: [],
        captcha: "",
        profile_verification_status: "unverified",
        languages: [],
        language_ids: [],
        phone_number: "",
        whatsapp_phone_number: "",
        whatsapp_checkbox: true,
        first_name: "",
        last_name: "",
        company_name: "",
        job_role: "",
        job_role_other: "",
        nationality: "",
        spoken_language: [],
        newsletter: true,
        role: "Tester",
    });

    const [formErrors, setFormErrors] = useState({
        phone_number: null,
        whatsapp_phone_number: null,
        first_name: null,
        last_name: null,
        gender: null,
        password: null,
        company_name: null,
        job_role: null,
        job_role_other: null,
        job_role_other: null,
        terms: null,
    });

    const educationOptions = [
        { label: "Primary school", value: "Primary school" },
        { label: "High school", value: "High school" },
        { label: "Bachelor degree", value: "Bachelor degree" },
        { label: "Master degree", value: "Master degree" },
        { label: "Post graduate degree", value: "Post graduate degree" },
        { label: "Doctorate", value: "Doctorate" },
    ];

    const [idVerifyLoading, setIdVerifyLoading] = useState(false);

    const [limitReachedModal, setLimitReachedModal] = useState({ open: false });

    const [idVerificationModal, setIdVerificationModal] = useState({
        open: false,
    });

    const [timer, setTimer] = useState(0);

    const [verifyBtnDisabled, setVerifyBtnDisabled] = useState(false);

    const [displaySanctionedMessage, setDisplaySanctionedMessage] =
        useState(false);

    const [primaryLangs, setPrimaryLangs] = useState([]);

    const [langs, setLangs] = useState([]);

    const [onfidoModal, setOnfidoModal] = useState({ open: false });

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();

        document.title = "Step 1 | " + process.env.REACT_APP_NAME;

        getLanguagesApi();
    }, []);

    useEffect(() => {
        if (langs.length > 0) {
            //console.log(primaryLangs);
            //console.log(langs);
            getProfileApi();
        }
    }, [langs]);

    const getProfileApi = () => {
        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 1;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                }
                /*if(response.data.user.completed_step>=1){
                
                                    var user =  ReactSession.get("user");
                
                                    user["completed_step"] = response.data.user.completed_step;
                
                                    user["welcome_message_shown"] = response.data.user.welcome_message_shown;
                
                                    ReactSession.set("user", user);
                                    //navigate('/dashboard');
                                }*/

                /*if(response.data.user.account_status == "blocked"){
                                    if(response.data.user.blocked_reason == "Duplicate identity"){
                                        navigate('/tester/profile/duplicate_identity');
                                    } else if(response.data.user.blocked_reason == "Minor registration"){
                                        navigate('/tester/profile/under_age');
                                    }
                                }*/
                if (
                    response.data.user.profile_verification_status ==
                    "duplicate_identity"
                ) {
                    navigate("/tester/profile/duplicate_identity");
                } else if (
                    response.data.user.profile_verification_status == "minor"
                ) {
                    navigate("/tester/profile/under_age");
                }

                var sanctioned_country = false;

                sanctioned_countries.forEach(function (country) {
                    if (country.value == response.data.user.country) {
                        sanctioned_country = true;
                    }
                });
                setDisplaySanctionedMessage(sanctioned_country);

                var languages = [];

                var spoken_languages = [];

                var language_ids = [];

                //console.log(langs);

                //console.log(primaryLangs);

                response.data.user.languages.forEach(function (userLang) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.id === userLang.language_id) {
                            spoken_languages.push(primaryLang.iso_name_english);
                        }
                    });
                    langs.forEach(function (lang) {
                        if (lang.id === userLang.language_id) {
                            languages.push(lang.label);
                        }
                    });
                    language_ids.push(userLang.language_id);
                });
                if (language_ids.length == 0) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.is_selected == 1) {
                            spoken_languages.push(primaryLang.iso_name_english);

                            language_ids.push(primaryLang.id);
                        }
                    });
                }
                setFormValues({
                    ...formValues,
                    first_name: response.data.user.first_name
                        ? response.data.user.first_name
                        : "",
                    last_name: response.data.user.last_name
                        ? response.data.user.last_name
                        : "",
                    //phone_number: response.data.user.phone_number ? response.data.user.phone_number : '',
                    //whatsapp_phone_number: response.data.user.whatsapp_phone_number ? response.data.user.whatsapp_phone_number : '',
                    gender: response.data.user.gender
                        ? response.data.user.gender
                        : "",
                    date_of_birth: response.data.user.date_of_birth
                        ? moment(response.data.user.date_of_birth).format(
                              "Y-MM-DD"
                          )
                        : "",
                    nationality: response.data.user.nationality
                        ? response.data.user.nationality
                        : "",
                    //highest_education_level: response.data.user.highest_education_level ? response.data.user.highest_education_level : '',
                    //spoken_language: spoken_languages,
                    //languages: languages,
                    //language_ids: language_ids,
                    country: response.data.user.country
                        ? response.data.user.country
                        : "",
                    region: response.data.user.region
                        ? response.data.user.region
                        : "",
                    //whatsapp_checkbox: response.data.user.whatsapp_phone_number ? false : true,
                    profile_verification_status:
                        response.data.user.profile_verification_status,
                    document_provided_fields: response.data.user
                        .document_provided_fields
                        ? response.data.user.document_provided_fields.split(",")
                        : [],
                    newsletter: response.data.user.newsletter
                        ? response.data.user.newsletter
                        : true,
                });
                if (response.data.user.id_verify_btn_disabled) {
                    setVerifyBtnDisabled(true);
                }

                setSteps(response.data.steps);

                //setTimer("2023-09-18 00:00:00");

                //setLimitReachedModal({open:true});
            }
        });
    };
    const getLanguagesApi = () => {
        setLoading(true);

        getLanguagesService().then((response) => {
            if (response.success) {
                var primary_langs = [];

                var other_langs = [];

                response.languages.forEach((lang) => {
                    if (lang.is_primary == 1) {
                        primary_langs.push(lang);
                    } else {
                        other_langs.push({
                            label: lang.iso_name_english,
                            value: lang.iso_name_english,
                            id: lang.id,
                        });
                    }
                });

                setPrimaryLangs(
                    primary_langs.sort(function (a, b) {
                        if (a.iso_name_english < b.iso_name_english) {
                            return 1;
                        }
                        return -1;
                    })
                );

                setLangs(other_langs);

                //getProfileApi();
            } else {
                setLoading(false);
            }
        });
    };
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        if (existingTester) {
            formData.existingTester = 1;
        }

        /*if (formData.whatsapp_checkbox) {
                    formData.whatsapp_phone_number = "";
                }*/
        formData.date_of_birth = moment(formValues.date_of_birth).format(
            "Y-MM-DD"
        );

        updateStep1(formData, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                let user = ReactSession.get("user");

                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step2");
                    }
                } else {
                    user.completed_step = response.user.completed_step;

                    user.profile_verification_status =
                        formValues.profile_verification_status;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step2");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "first_name") {
            var field_value = key && inline ? value : formValues.first_name;

            if (field_value == "") {
                form_errors = {
                    ...form_errors,
                    first_name: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "first_name" : firsterrorid;
            }
        }
        if (!key || key == "last_name") {
            var field_value = key && inline ? value : formValues.last_name;

            if (field_value == "") {
                form_errors = {
                    ...form_errors,
                    last_name: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "last_name" : firsterrorid;
            }
        }
        if (!key || key == "gender") {
            var field_value = key && inline ? value : formValues.gender;

            if (field_value == "") {
                form_errors = {
                    ...form_errors,
                    gender: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "gender" : firsterrorid;
            }
        }
        /*if (!key || key == "phone_number") {
        
                    var field_value = key && inline ? value : formValues.phone_number;
        
                    if (!field_value || !isValidPhoneNumber('+'+field_value.toString())) {
        
                        form_errors = {
                            ...form_errors,
                            phone_number: "Phone number is not valid",
                            error_class: "input_error"
                        };
                        error = true;
                        firsterrorid = firsterrorid == null ? "phone_number" : firsterrorid;
                    }
                }
                if (!formValues.whatsapp_checkbox && formValues.whatsapp_phone_number) {
                    if (!key || key == "whatsapp_phone_number") {
        
                        var field_value = key && inline ? value : formValues.whatsapp_phone_number;
        
                        if (!field_value || !isValidPhoneNumber('+'+field_value.toString())) {
        
                            form_errors = {
                                ...form_errors,
                                whatsapp_phone_number: "WhatsApp number is not valid",
                                error_class: "input_error"
                            };
                            error = true;
                            firsterrorid = firsterrorid == null ? "whatsapp_phone_number" : firsterrorid;
                        }
                    }
                }*/
        if (!key || key == "date_of_birth") {
            var field_value = key && inline ? value : formValues.date_of_birth;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    date_of_birth: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "date_of_birth" : firsterrorid;
            } else if (getAge(formValues.date_of_birth) < 18) {
                form_errors = {
                    ...form_errors,
                    date_of_birth: "You must be 18 years of age",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "date_of_birth" : firsterrorid;
            }
        }
        if (!key || key == "nationality") {
            var field_value = key ? value : formValues.nationality;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    nationality: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "nationality" : firsterrorid;
            }
        }
        if (!key || key == "country") {
            var field_value = key ? value : formValues.country;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    country: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "country" : firsterrorid;
            }
        }

        /*if (!key || key == "spoken_language") {
        
                    var field_value = key && inline ? value : formValues.spoken_language;
        
                    if (field_value.length == 0) {
                        form_errors = {
                            ...form_errors,
                            spoken_language: "Required field",
                            error_class: "input_error"
                        };
                        error = true;
                        firsterrorid = firsterrorid == null ? "spoken_language" : firsterrorid;
                    }
                }*/

        /*if (!key || key == "highest_education_level") {
        
                    var field_value = key && inline ? value : formValues.highest_education_level;
        
                    if (!field_value) {
                        form_errors = {
                            ...form_errors,
                            highest_education_level: "Required field",
                            error_class: "input_error"
                        };
                        error = true;
                        firsterrorid = firsterrorid == null ? "highest_education_level" : firsterrorid;
                    }
                }*/
        if (!key || key == "region") {
            var field_value = key && inline ? value : formValues.region;

            if (
                (formValues.country == "United Arab Emirates" ||
                    formValues.country == "Suadi Arabia") &&
                !field_value
            ) {
                form_errors = {
                    ...form_errors,
                    region: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "region" : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (formValues.first_name == "") {
            error = true;
        }
        if (formValues.last_name == "") {
            error = true;
        }

        /*if (!formValues.phone_number || !isValidPhoneNumber('+'+formValues.phone_number.toString())) {
        
                    error = true;
        
                }
        
                if (!formValues.whatsapp_checkbox && formValues.whatsapp_phone_number) {
        
                    if (!formValues.whatsapp_phone_number || !isValidPhoneNumber('+'+formValues.whatsapp_phone_number.toString())) {
                        error = true;
                    }
        
                }
                if (!formValues.date_of_birth || (formValues.date_of_birth && getAge(formValues.date_of_birth) < 18)) {
                    error = true;
                }
                if (formValues.spoken_language.length == 0) {
                    error = true;
                }*/

        if (!formValues.nationality) {
            error = true;
        }
        /*if (!formValues.highest_education_level) {
                    error = true;
                }*/
        if (!formValues.country) {
            error = true;
        } else {
            if (
                !formValues.region &&
                (formValues.country == "United Arab Emirates" ||
                    formValues.country == "Saudi Arabia")
            ) {
                error = true;
            }
        }
        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z\s]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const formatTime = (seconds) => {
        const result = new Date(seconds * 1000).toISOString().slice(11, 19);

        return result;
    };
    const getOnfidoParamsApi = () => {
        setIdVerifyLoading(true);

        const token = ReactSession.get("token");

        getOnfidoParams(token).then((response) => {
            setIdVerifyLoading(false);

            if (response.success) {
                if (response.data.status == "invalid") {
                    setVerifyBtnDisabled(true);
                    setTimer(response.data.last_attempt);
                    setLimitReachedModal({ open: true });
                } else if (response.data.status == "invalid") {
                    setVerifyBtnDisabled(true);
                    setTimer(response.data.last_attempt);
                    setLimitReachedModal({ open: true });
                } else {
                    setVerifyBtnDisabled(false);

                    setOnfidoModal({ ...onfidoModal, open: true });

                    document.body.classList.add("modal-open");

                    onfido = Onfido.init({
                        token: response.data.sdk_token,
                        //useModal: true,
                        //isModalOpen: true,
                        /*onModalRequestClose: function() {
                                                        onfido.setOptions({isModalOpen: false})
                                                    },*/
                        enterpriseFeatures: {
                            logoCobrand: {
                                darkLogoSrc:
                                    "https://userq.com/wp-content/uploads/2022/12/UserQ-logo-positive-small.png",
                                lightLogoSrc:
                                    "https://userq.com/wp-content/uploads/2022/12/UserQ-logo-positive-small.png",
                            },
                        },
                        onComplete: function (data) {
                            getOnfidoResultApi();
                        },
                        workflowRunId: response.data.workflow_run_id,
                    });
                }
            } else {
                showError(response.message);
            }
        });
    };
    const getOnfidoResultApi = () => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        getOnfidoResult(token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                //onfido.setOptions({isModalOpen: false})

                setOnfidoModal({ ...onfidoModal, open: false });

                document.body.classList.remove("modal-open");

                if (response.status == "verified" && response.data.user) {
                    var sanctioned_country = false;

                    sanctioned_countries.forEach(function (country) {
                        if (country.value == response.data.user.country) {
                            sanctioned_country = true;
                        }
                    });
                    setDisplaySanctionedMessage(sanctioned_country);

                    setFormValues({
                        ...formValues,
                        first_name: response.data.user.first_name
                            ? response.data.user.first_name
                            : "",
                        last_name: response.data.user.last_name
                            ? response.data.user.last_name
                            : "",
                        gender: response.data.user.gender
                            ? response.data.user.gender
                            : "Male",
                        date_of_birth: response.data.user.date_of_birth
                            ? moment(response.data.user.date_of_birth).format(
                                  "YYYY-MM-DD"
                              )
                            : "",
                        nationality: response.data.user.nationality
                            ? response.data.user.nationality
                            : "",
                        country: response.data.user.country
                            ? response.data.user.country
                            : "",
                        profile_verification_status:
                            response.data.user.profile_verification_status,
                        document_provided_fields: response.data.user
                            .document_provided_fields
                            ? response.data.user.document_provided_fields.split(
                                  ","
                              )
                            : [],
                    });
                } else if (response.status == "minor_registration") {
                    navigate("/tester/profile/under_age");
                } else if (response.status == "duplicate_identity") {
                    navigate("/tester/profile/duplicate_identity");
                } else if (response.status == "invalid") {
                    setVerifyBtnDisabled(true);
                    setTimer(response.last_attempt);

                    setLimitReachedModal({ open: true });
                } else if (response.status == "invalid") {
                    setVerifyBtnDisabled(true);
                    setTimer(response.last_attempt);

                    setLimitReachedModal({ open: true });
                }
            } else {
                showError(response.message);
            }
        });
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            isAccount={existingTester ? true : false}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={1}
                            steps={steps}
                            onClick={(navigateStep) => {
                                //setNavigateStep(navigateStep);

                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(null, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div
                                className={`row tester_sign_up_step_row justify-content-center`}
                            >
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox  progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={1}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Let's get to know you!
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            Tell us a bit about yourself
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-32">
                                        <div className=" mb-40">
                                            {formValues.profile_verification_status !=
                                                "verified" && (
                                                <>
                                                    <SignUpIdentityTile
                                                        disabled={
                                                            verifyBtnDisabled
                                                        }
                                                        isLoading={
                                                            idVerifyLoading
                                                        }
                                                        title="Complete with ID"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            getOnfidoParamsApi();
                                                        }}
                                                    />
                                                    {onfidoModal.open && (
                                                        <div className="onfido-modal">
                                                            <div className="onfido-modal-body">
                                                                <span
                                                                    className="close-button-onfido"
                                                                    onClick={() => {
                                                                        document.body.classList.remove(
                                                                            "modal-open"
                                                                        );
                                                                        setOnfidoModal(
                                                                            {
                                                                                ...onfidoModal,
                                                                                open: false,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        value="Close"
                                                                        size="large"
                                                                        hover={
                                                                            true
                                                                        }
                                                                    />
                                                                </span>
                                                                <div id="onfido-mount"></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="none-for-desktop mt-20">
                                                        <UserQLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setIdVerificationModal(
                                                                    {
                                                                        ...idVerificationModal,
                                                                        open: true,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {" "}
                                                            Why it’s important
                                                            to verify your ID?{" "}
                                                        </UserQLink>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {formValues.profile_verification_status ==
                                            "verified" && (
                                            <Box cssClasses="sign-up-verified-info gray-box p-20 mb-32">
                                                <div className="signup_veri_infotop d-flex align-items-center mb-20">
                                                    <Icon
                                                        colorClass="success-green-200"
                                                        value="verified"
                                                    />
                                                    <Text
                                                        type={"body-text-1"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses="color-black"
                                                    >
                                                        {" "}
                                                        Verified information
                                                    </Text>
                                                </div>

                                                <div className="signup_user_info">
                                                    <div className="signup_user_inforepeat d-flex align-items-cener justify-content-between">
                                                        {formValues.profile_verification_status ==
                                                            "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "first_name"
                                                            ) && (
                                                                <div className="signup_user_left_info d-flex align-items-center">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses="color-black user_info_label"
                                                                    >
                                                                        First
                                                                        name
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses="color-black"
                                                                    >
                                                                        {
                                                                            formValues.first_name
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        {formValues.profile_verification_status ==
                                                            "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "last_name"
                                                            ) && (
                                                                <div className="signup_user_left_info d-flex align-items-center">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses="color-black user_info_label"
                                                                    >
                                                                        Last
                                                                        name
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses="color-black"
                                                                    >
                                                                        {
                                                                            formValues.last_name
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            )}

                                                        {formValues.profile_verification_status ==
                                                            "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "gender"
                                                            ) && (
                                                                <div className="signup_user_left_info d-flex align-items-center">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses="color-black user_info_label"
                                                                    >
                                                                        Gender
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses="color-black"
                                                                    >
                                                                        {
                                                                            formValues.gender
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        {formValues.profile_verification_status ==
                                                            "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "date_of_birth"
                                                            ) && (
                                                                <div className="signup_user_left_info d-flex align-items-center">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses="color-black user_info_label"
                                                                    >
                                                                        Date of
                                                                        birth
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses="color-black"
                                                                    >
                                                                        {moment(
                                                                            formValues.date_of_birth
                                                                        ).format(
                                                                            "DD/MM/Y"
                                                                        )}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        {formValues.profile_verification_status ==
                                                            "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "nationality"
                                                            ) && (
                                                                <div className="signup_user_left_info d-flex align-items-center">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses="color-black user_info_label"
                                                                    >
                                                                        Nationality
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses="color-black"
                                                                    >
                                                                        {
                                                                            formValues.nationality
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </Box>
                                        )}
                                        {!(
                                            formValues.profile_verification_status ==
                                                "verified" &&
                                            formValues.document_provided_fields.includes(
                                                "first_name"
                                            )
                                        ) && (
                                            <div className="formbox">
                                                <Input
                                                    type="text"
                                                    id="first_name"
                                                    name="first_name"
                                                    value={
                                                        formValues.first_name
                                                    }
                                                    onChange={set("first_name")}
                                                    label={"First name"}
                                                    onClear={() => {
                                                        clearFormValue(
                                                            "first_name"
                                                        );
                                                    }}
                                                    required={true}
                                                    error={
                                                        formErrors.first_name
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    onBlur={() => {
                                                        validateFullForm(
                                                            false,
                                                            "first_name"
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {!(
                                            formValues.profile_verification_status ==
                                                "verified" &&
                                            formValues.document_provided_fields.includes(
                                                "last_name"
                                            )
                                        ) && (
                                            <div className="formbox">
                                                <Input
                                                    type="text"
                                                    id="last_name"
                                                    name="last_name"
                                                    value={formValues.last_name}
                                                    onChange={set("last_name")}
                                                    label={"Last name"}
                                                    onClear={() => {
                                                        clearFormValue(
                                                            "last_name"
                                                        );
                                                    }}
                                                    error={formErrors.last_name}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    required={true}
                                                    onBlur={() => {
                                                        validateFullForm(
                                                            false,
                                                            "last_name"
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {!(
                                            formValues.profile_verification_status ==
                                                "verified" &&
                                            formValues.document_provided_fields.includes(
                                                "gender"
                                            )
                                        ) && (
                                            <>
                                                <div
                                                    className={`formbox ${
                                                        formErrors.gender
                                                            ? "mb-0"
                                                            : ""
                                                    }  d-flex-radiobox `}
                                                    id="gender"
                                                >
                                                    <label className="bold">
                                                        <Text
                                                            type={"body-text-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                            cssClasses={
                                                                "color-black"
                                                            }
                                                        >
                                                            Gender
                                                        </Text>
                                                    </label>
                                                    <div className="d-flex-radiobtn bd-label">
                                                        <RadioButton
                                                            name="gender"
                                                            label="Male"
                                                            isChecked={
                                                                formValues.gender ==
                                                                "Male"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    gender: "Male",
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    gender: null,
                                                                });
                                                            }}
                                                        />
                                                        <RadioButton
                                                            name="gender"
                                                            label="Female"
                                                            isChecked={
                                                                formValues.gender ==
                                                                "Female"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    gender: "Female",
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    gender: null,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {formErrors.gender && (
                                                    <div className="mb-27">
                                                        <span
                                                            className={
                                                                formErrors.error_class
                                                            }
                                                        >
                                                            {formErrors.gender}
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {!(
                                            formValues.profile_verification_status ==
                                                "verified" &&
                                            formValues.document_provided_fields.includes(
                                                "date_of_birth"
                                            )
                                        ) && (
                                            <div
                                                className="formbox w-fix"
                                                id="date_of_birth"
                                            >
                                                <InputDatePicker
                                                    value={
                                                        formValues.date_of_birth
                                                    }
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setFormValues({
                                                                ...formValues,
                                                                date_of_birth:
                                                                    date,
                                                            });
                                                            validateFullForm(
                                                                false,
                                                                "date_of_birth",
                                                                date,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                    label="Date of birth"
                                                    error={
                                                        formErrors.date_of_birth
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                    onBlur={() => {
                                                        validateFullForm(
                                                            false,
                                                            "date_of_birth"
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {!(
                                            formValues.profile_verification_status ==
                                                "verified" &&
                                            formValues.document_provided_fields.includes(
                                                "nationality"
                                            )
                                        ) && (
                                            <div
                                                className="formbox "
                                                id="nationality"
                                            >
                                                <AutoCompleteSingleSelect
                                                    id={"nationality_others"}
                                                    name="nationality_others"
                                                    title="Select levels of education..."
                                                    searchable={[
                                                        "Search for country level",
                                                        "No matching country level",
                                                    ]}
                                                    value={
                                                        formValues.nationality
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                nationality:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                nationality: "",
                                                            });
                                                        }
                                                        setFormErrors({...formErrors, nationality:null});
                                                    }}
                                                    label={"Nationality"}
                                                    isClearable={true}
                                                    type={"nationality"}
                                                    error={
                                                        formErrors.nationality
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className="formbox">
                                            <div
                                                className="profile-form-group"
                                                id={"country"}
                                            >
                                                <AutoCompleteSingleSelect
                                                    id={"country"}
                                                    name="country"
                                                    isSanctionedCountry={
                                                        displaySanctionedMessage
                                                            ? true
                                                            : false
                                                    }
                                                    title="Select levels of education..."
                                                    searchable={[
                                                        "Search for country level",
                                                        "No matching country level",
                                                    ]}
                                                    value={formValues.country}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                country:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setDisplaySanctionedMessage(
                                                                false
                                                            );
                                                            setFormValues({
                                                                ...formValues,
                                                                country: "",
                                                            });
                                                        }
                                                        var sanctioned_country = false;

                                                        sanctioned_countries.forEach(
                                                            function (country) {
                                                                if (
                                                                    item.value ==
                                                                    country.value
                                                                ) {
                                                                    sanctioned_country = true;
                                                                }
                                                            }
                                                        );
                                                        setDisplaySanctionedMessage(
                                                            sanctioned_country
                                                        );

                                                        validateFullForm(
                                                            false,
                                                            "country",
                                                            item
                                                                ? item.value
                                                                : ""
                                                        );
                                                    }}
                                                    label={
                                                        "Country of residency"
                                                    }
                                                    isClearable={true}
                                                    type={"country"}
                                                    error={formErrors.country}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {displaySanctionedMessage && (
                                            <div className="formbox sanctioned-info">
                                                <div className=" form-group input-field  input-with-right-icon mb-0  ">
                                                    <ToastMessage
                                                        closable={false}
                                                        type={"warning"}
                                                        message={
                                                            <>
                                                                Please be aware
                                                                that, in
                                                                compliance with
                                                                both local and
                                                                international
                                                                regulations, we
                                                                may not be able
                                                                to process
                                                                payouts to
                                                                certain
                                                                countries. If
                                                                you need more
                                                                information,
                                                                please refer to
                                                                our{" "}
                                                                <UserQLink
                                                                    target="_blank"
                                                                    url="https://userq.com/terms-of-use"
                                                                >
                                                                    Terms of use
                                                                </UserQLink>
                                                                .
                                                            </>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {formValues.country ==
                                            "United Arab Emirates" && (
                                            <div className="formbox">
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={emirates}
                                                        value={
                                                            formValues.region
                                                        }
                                                        onChange={(item) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                region: item.value,
                                                            });

                                                            validateFullForm(
                                                                false,
                                                                "region",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );
                                                        }}
                                                        isClearable={false}
                                                        label=" What's your emirate of residency?"
                                                        error={
                                                            formErrors.region
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {formValues.country ==
                                            "Saudi Arabia" && (
                                            <div className="formbox">
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={saregions}
                                                        value={
                                                            formValues.region
                                                        }
                                                        onChange={(item) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                region: item.value,
                                                            });

                                                            validateFullForm(
                                                                false,
                                                                "region",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );
                                                        }}
                                                        isClearable={false}
                                                        label="What's your region of residency?"
                                                        error={
                                                            formErrors.region
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/*
                                        <div className="formbox " id='phone_number' style={{ position: "relative" }}>
                                            <PhoneNumberInput
                                                value={formValues.phone_number}
                                                onBlur={() => {
                                                    validateFullForm(false, "phone_number")
                                                }}
                                                onCountryChange={() => {
                                                    setFormErrors({
                                                        ...formErrors,
                                                        phone_number: null,
                                                        error_class: "input_error"
                                                    });
                                                }}
                                                onChange={(value) => {
                                                    setFormErrors({ ...formErrors, phone_number: null })
                                                    setFormValues(oldValues => ({ ...oldValues, phone_number: value }));

                                                    validateFullForm(false, "phone_number", value ? value : '', true);
                                                }}
                                                error={formErrors.phone_number}
                                                error_class={formErrors.error_class}

                                            />
                                            <div className="whatsupplabel">
                                                <CheckBox
                                                    checked={formValues.whatsapp_checkbox}
                                                    onChange={(event) => {
                                                        toggleCheck('whatsapp_checkbox', event.currentTarget.checked) }}
                                                    id="whatsapp_number"
                                                    label={"I use WhatsApp on this number"}
                                                />
                                            </div>
                                        </div>
                                        {!formValues.whatsapp_checkbox &&
                                            <div className="formbox " id="whatsapp_phone_number" style={{ position: "relative" }}>
                                                <PhoneNumberInput
                                                    label={"WhatsApp number"}
                                                    value={formValues.whatsapp_phone_number}
                                                    onBlur={() => {
                                                        validateFullForm(false, "whatsapp_phone_number")
                                                    }}
                                                    onCountryChange={() => {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            whatsapp_phone_number: null,
                                                            error_class: "input_error"
                                                        });
                                                    }}
                                                    onChange={(value) => {
                                                        setFormErrors({ ...formErrors, whatsapp_phone_number: null })
                                                        setFormValues(oldValues => ({ ...oldValues, whatsapp_phone_number: value }));

                                                        validateFullForm(false, "whatsapp_phone_number", value ? value : '', true);

                                                    }}
                                                    error={formErrors.whatsapp_phone_number}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>
                                        }
                                        
                                        
                                        <div className="formbox" id="highest_education_level">
                                            <div className='profile-form-group'>
                                                <DropdownComponent
                                                    options={educationOptions}
                                                    value={formValues.highest_education_level}
                                                    onChange={(item) => {

                                                        if (item) {
                                                            setFormValues({ ...formValues, highest_education_level: item.value })

                                                            validateFullForm(false, "highest_education_level", item ? item.value : '', true);
                                                        } else {
                                                            setFormValues({ ...formValues, highest_education_level: '' })
                                                        }

                                                    }}
                                                    isClearable={true}
                                                    label="Highest level of education"
                                                    error={formErrors.highest_education_level}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>

                                        </div>
                                        <div className="formbox">
                                            <div className="full d-flex-radiobox" id='spoken_language'>
                                                <label className="bold">Spoken languages</label>
                                                <div className="d-flex-radiobtn">
                                                    {primaryLangs.map(function(lang) {
                                                        return (
                                                            <CheckBox
                                                                name="spoken_language"
                                                                label={lang.iso_name_english}
                                                                checked={
                                                                    formValues.language_ids.includes(
                                                                        lang.id
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(event) => {
                                                                    if (event.currentTarget.checked) {
                                                                        formValues.language_ids.push(
                                                                            lang.id
                                                                        );
                                                                        formValues.spoken_language.push(
                                                                            lang.iso_name_english
                                                                        );
                                                                        setFormValues({...formValues, spoken_language: formValues.spoken_language, language_ids:formValues.language_ids});
                                                                    } else {
                                                                        formValues.spoken_language =
                                                                            removeItemFromArray(
                                                                                formValues.spoken_language,
                                                                                lang.iso_name_english
                                                                            );

                                                                        formValues.language_ids =
                                                                            removeItemFromArray(
                                                                                formValues.language_ids,
                                                                                lang.id
                                                                            );
                                                                        setFormValues({...formValues, spoken_language: formValues.spoken_language, language_ids:formValues.language_ids});
                                                                    }
                                                                    validateFullForm(false, "spoken_language");
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                    
                                                </div>
                                            </div>
                                            {formErrors.spoken_language &&
                                                <span className={formErrors.error_class}>{formErrors.spoken_language}
                                                </span>
                                            }
                                        </div>
                                        <div className="formbox  employment-type-wrap" id='languages'>

                                            <SearchDropdownMultiple
                                                options={langs}
                                                value={formValues.languages}
                                                onChange={(items) => {

                                                    let selected = [];

                                                    let selected_id = [];

                                                    primaryLangs.forEach(function(primaryLang) {

                                                        formValues.language_ids.forEach(function(lang){

                                                            if(lang == primaryLang.id) {
                                                                selected_id.push(lang);
                                                            }
                                                        })
                                                    });
                                                    
                                                    items.forEach(function (item) {
                                                        selected.push(item.label);

                                                        langs.forEach(function(lang){
                                                            if(lang.value == item.value){
                                                                selected_id.push(lang.id);
                                                            }
                                                        })

                                                        
                                                    })
                                                    setFormValues({...formValues,"languages": selected,"language_ids": selected_id});

                                                    validateFullForm(false, "languages", selected, true);

                                                }}
                                                isClearable={true}
                                                label="Other languages"
                                                error={formErrors.languages}
                                                error_class={formErrors.error_class}
                                            />

                                        </div>
                                        */}

                                        <div className="formbox">
                                            <div className="profile-form-group">
                                                <CheckBox
                                                    checked={
                                                        formValues.newsletter ==
                                                            1 ||
                                                        formValues.newsletter
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(event) => {
                                                        toggleCheck(
                                                            "newsletter",
                                                            event.currentTarget
                                                                .checked
                                                        );
                                                    }}
                                                    id="newsletterCheckBox"
                                                    label={
                                                        "Subscribe to our awesome newsletter"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox"></div>
                                    </div>

                                    <div className="sign-up-btn-wrap sign-up-single-btn">
                                        <span className="empty-span">
                                            &nbsp;
                                        </span>
                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                            cssclass="w-100 tester-signin-step1"
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext none-for-mobile">
                                        {formValues.profile_verification_status !=
                                            "verified" && (
                                            <Box cssClasses="signofinformational-wrap gray-box p-32 mb-20">
                                                <Text
                                                    type={"subtitle-2"}
                                                    fontWeight={
                                                        "semi-bold-font"
                                                    }
                                                >
                                                    Why it’s important to verify
                                                    my identity?
                                                </Text>

                                                <div className="sign-identity-repeat mb-20 mt-32">
                                                    <div className="sign-identity-top-head d-flex align-items-center">
                                                        <Icon
                                                            value="security"
                                                            size="medium"
                                                        />
                                                        <Text
                                                            type={"body-text-2"}
                                                            cssClasses="color-black"
                                                            fontWeight={
                                                                "semi-bold-font"
                                                            }
                                                        >
                                                            Security
                                                        </Text>
                                                    </div>
                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses="gray-color"
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Verifying your identity
                                                        helps to prevent fraud
                                                        and unauthorized access
                                                        to your account.
                                                    </Text>
                                                </div>

                                                <div className="sign-identity-repeat mb-20">
                                                    <div className="sign-identity-top-head d-flex align-items-center">
                                                        <Icon
                                                            value="key"
                                                            size="medium"
                                                        />
                                                        <Text
                                                            type={"body-text-2"}
                                                            cssClasses="color-black"
                                                            fontWeight={
                                                                "semi-bold-font"
                                                            }
                                                        >
                                                            Access to tests
                                                        </Text>
                                                    </div>
                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses="gray-color"
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Some tests might be
                                                        available only for
                                                        verified users. Get
                                                        access to all tests by
                                                        verifying your identity.
                                                    </Text>
                                                </div>

                                                <div className="sign-identity-repeat">
                                                    <div className="sign-identity-top-head d-flex align-items-center">
                                                        <Icon
                                                            value="black_dollar"
                                                            size="medium"
                                                        />
                                                        <Text
                                                            type={"body-text-2"}
                                                            cssClasses="color-black"
                                                            fontWeight={
                                                                "semi-bold-font"
                                                            }
                                                        >
                                                            Payouts
                                                        </Text>
                                                    </div>
                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses="gray-color"
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Only verified accounts
                                                        are eligible to request
                                                        a payout. If you have
                                                        not yet verified your
                                                        identity, please do so
                                                        before requesting a
                                                        payout.
                                                    </Text>
                                                </div>
                                            </Box>
                                        )}
                                        {formValues.profile_verification_status ==
                                            "verified" && (
                                            <SignUpInformationalCard
                                                icon={
                                                    <Icon
                                                        colorClass={
                                                            "success-green-200"
                                                        }
                                                        value={"star"}
                                                    />
                                                }
                                                title="Keep your tester score high"
                                                info="Maintaining this high score will ensure you access to more tests."
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
            <HelpModal
                cssClass={"mobile-id-verification-modal"}
                open={idVerificationModal.open}
                close={() => {
                    setIdVerificationModal({
                        ...idVerificationModal,
                        open: false,
                    });
                }}
                disableCross={false}
                btnLoading={false}
                body={
                    <div className="id-verification-inner-wrap graytext">
                        {formValues.profile_verification_status !=
                            "verified" && (
                            <Box cssClasses="signofinformational-wrap gray-box p-32 mb-20">
                                <div className="d-flex justify-content-between modal-header-top">
                                    <Text
                                        type={"subtitle-2"}
                                        fontWeight={"semi-bold-font"}
                                    >
                                        Why it’s important to verify my
                                        identity?
                                    </Text>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={() => {
                                            setIdVerificationModal({
                                                ...idVerificationModal,
                                                open: false,
                                            });
                                        }}
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">
                                            <Icon
                                                value="Close"
                                                size="medium"
                                                hover={true}
                                            />
                                        </span>
                                    </button>
                                </div>

                                <div className="sign-identity-repeat mb-20 mt-32">
                                    <div className="sign-identity-top-head d-flex align-items-center">
                                        <Icon value="security" size="medium" />
                                        <Text
                                            type={"body-text-2"}
                                            cssClasses="color-black"
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Security
                                        </Text>
                                    </div>
                                    <Text
                                        type={"body-text-3"}
                                        cssClasses="gray-color"
                                        fontWeight={"medium-font"}
                                    >
                                        Verifying your identity helps to prevent
                                        fraud and unauthorized access to your
                                        account.
                                    </Text>
                                </div>

                                <div className="sign-identity-repeat mb-20">
                                    <div className="sign-identity-top-head d-flex align-items-center">
                                        <Icon value="key" size="medium" />
                                        <Text
                                            type={"body-text-2"}
                                            cssClasses="color-black"
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Access to tests
                                        </Text>
                                    </div>
                                    <Text
                                        type={"body-text-3"}
                                        cssClasses="gray-color"
                                        fontWeight={"medium-font"}
                                    >
                                        Some tests might be available only for
                                        verified users. Get access to all tests
                                        by verifying your identity.
                                    </Text>
                                </div>

                                <div className="sign-identity-repeat">
                                    <div className="sign-identity-top-head d-flex align-items-center">
                                        <Icon
                                            value="black_dollar"
                                            size="medium"
                                        />
                                        <Text
                                            type={"body-text-2"}
                                            cssClasses="color-black"
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Payouts
                                        </Text>
                                    </div>
                                    <Text
                                        type={"body-text-3"}
                                        cssClasses="gray-color"
                                        fontWeight={"medium-font"}
                                    >
                                        Only verified accounts are eligible to
                                        request a payout. If you have not yet
                                        verified your identity, please do so
                                        before requesting a payout.
                                    </Text>
                                </div>
                            </Box>
                        )}
                        {formValues.profile_verification_status ==
                            "verified" && (
                            <SignUpInformationalCard
                                icon={
                                    <Icon
                                        colorClass={"success-green-200"}
                                        value={"star"}
                                    />
                                }
                                title="Keep your tester score high"
                                info="Maintaining this high score will ensure you access to more tests."
                            />
                        )}
                    </div>
                }
            />
            <HelpModal
                cssClass={"limit-reached-modal"}
                open={limitReachedModal.open}
                close={() => {
                    setLimitReachedModal({ ...limitReachedModal, open: false });
                }}
                disableCross={true}
                body={
                    <div className="prototype-criteria-modal-data">
                        <div className="mb-20 text-center">
                            <Text type={"h2"} fontWeight={"medium-font"}>
                                You've reached the maximum number of attempts.
                                Please try again in
                            </Text>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-32 mb-32 time-space-between">
                            <Icon
                                colorClass="gray-900-svg"
                                value={"time"}
                                size={"extralarge"}
                            />
                            <CountdownTimer timerData={timer} />
                            {/*<Text  type={"body-text-1"} cssClasses={"ml-16"} fontWeight={"medium-font"}>{formatTime(timer)}</Text>*/}
                        </div>
                        <div className="d-flex justify-content-center duplicate-test-modal-action">
                            <Button
                                size={"large"}
                                type="primary"
                                label="Close"
                                onClick={() => {
                                    setLimitReachedModal({
                                        ...limitReachedModal,
                                        open: false,
                                    });
                                }}
                            />
                        </div>
                    </div>
                }
            />
        </LayoutTester>
    );
}
