import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { getLanguagesService } from "../../services/language.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { MembersDropdownList } from "../themes/userq/MembersDropdownList/MembersDropdownList.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        captcha: "",
        relationship_status: "",
        religion: "",
        highest_education_level: "",
        children_and_dependents: "",
        languages: [],
        language_ids: [],
        spoken_language: [],
        own_driving_license: true,
        driving_license: [],
        has_disability_status: true,
        disability_status: [],
        role: "Tester",
    });

    const [formErrors, setFormErrors] = useState({
        relationship_status: null,
        religion: null,
        highest_education_level: null,
        children_and_dependents: null,
        languages: null,
        language_ids: null,
        spoken_language: null,
        driving_license: null,
        disability_status: null,
    });
    const [lovCategories, setLovCategories] = useState([]);

    const educationOptions = [
        { label: "Primary school", value: "Primary school" },
        { label: "High school", value: "High school" },
        { label: "Bachelor degree", value: "Bachelor degree" },
        { label: "Master degree", value: "Master degree" },
        { label: "Post graduate degree", value: "Post graduate degree" },
        { label: "Doctorate", value: "Doctorate" },
    ];

    const [primaryLangs, setPrimaryLangs] = useState([]);

    const [langs, setLangs] = useState([]);

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        if (langs.length > 0) {
            getProfileApi();
        }
    }, [langs]);

    useEffect(() => {
        window.animate();
        document.title = "Step 3 | " + process.env.REACT_APP_NAME;

        getLanguagesApi();
    }, []);
    const getProfileApi = () => {
        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 3;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 2) {
                        navigate("/tester/profile/step2");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }
                var languages = [];

                var spoken_languages = [];

                var language_ids = [];

                //console.log(langs);

                //console.log(primaryLangs);

                response.data.user.languages.forEach(function (userLang) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.id === userLang.language_id) {
                            spoken_languages.push(primaryLang.iso_name_english);
                        }
                    });
                    langs.forEach(function (lang) {
                        if (lang.id === userLang.language_id) {
                            languages.push(lang.label);
                        }
                    });
                    language_ids.push(userLang.language_id);
                });
                if (language_ids.length == 0) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.is_selected == 1) {
                            spoken_languages.push(primaryLang.iso_name_english);

                            language_ids.push(primaryLang.id);
                        }
                    });
                }
                var relationship_status = "";

                var religion = "";

                var children_and_dependents = "";

                var driving_license = [];

                var own_driving_license = true;

                var has_disability_status = true;

                var disability_status = [];

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Relationship status" &&
                                item.id == lov.lov_id
                            ) {
                                relationship_status = item.id;
                            }
                            if (
                                category.name == "Religion" &&
                                item.id == lov.lov_id
                            ) {
                                religion = item.id;
                            }
                            if (
                                category.name == "Children and dependents" &&
                                item.id == lov.lov_id
                            ) {
                                children_and_dependents = item.id;
                            }
                            if (
                                category.name == "Driving license" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have driving license"
                                ) {
                                    own_driving_license = false;
                                    driving_license = [];
                                } else {
                                    own_driving_license = true;
                                    driving_license.push(item.id);
                                }
                            }
                            if (
                                category.name == "Disability conditions" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have any disabilities"
                                ) {
                                    has_disability_status = false;
                                    disability_status = [];
                                } else {
                                    has_disability_status = true;
                                    disability_status.push(item.id);
                                }
                            }
                        });
                    });
                });

                setFormValues({
                    ...formValues,
                    spoken_language: spoken_languages,
                    languages: languages,
                    language_ids: language_ids,
                    children_and_dependents: children_and_dependents,
                    highest_education_level: response.data.user
                        .highest_education_level
                        ? response.data.user.highest_education_level
                        : "",
                    religion: religion,
                    relationship_status: relationship_status,
                    own_driving_license: own_driving_license,
                    driving_license: driving_license,
                    has_disability_status: has_disability_status,
                    disability_status: disability_status,
                });

                setSteps(response.data.steps);
            }
        });
    };
    const getLanguagesApi = () => {
        setLoading(true);

        getLanguagesService().then((response) => {
            if (response.success) {
                var primary_langs = [];

                var other_langs = [];

                response.languages.forEach((lang) => {
                    if (lang.is_primary == 1) {
                        primary_langs.push(lang);
                    } else {
                        other_langs.push({
                            label: lang.iso_name_english,
                            value: lang.iso_name_english,
                            id: lang.id,
                        });
                    }
                });

                setPrimaryLangs(
                    primary_langs.sort(function (a, b) {
                        if (a.iso_name_english < b.iso_name_english) {
                            return 1;
                        }
                        return -1;
                    })
                );

                setLangs(other_langs);

                //getProfileApi();
            } else {
                setLoading(false);
            }
        });
    };
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        if (!formValues.own_driving_license) {
            var lovCategoriesLoc = lovCategories
                .filter((category) => category.name == "Driving license")
                .flatMap((category) => category.active_lov)
                .filter(
                    (active_lov) =>
                        active_lov.lov_value == "I don't have driving license"
                );

            if (lovCategoriesLoc.length == 1) {
                formData["driving_license"].push(lovCategoriesLoc[0].id);
            }
        }
        if (!formValues.has_disability_status) {
            var lovCategoriesLoc = lovCategories
                .filter((category) => category.name == "Disability conditions")
                .flatMap((category) => category.active_lov)
                .filter(
                    (active_lov) =>
                        active_lov.lov_value == "I don't have any disabilities"
                );

            if (lovCategoriesLoc.length == 1) {
                formData["disability_status"].push(lovCategoriesLoc[0].id);
            }
        }
        if (existingTester) {
            formData.existingTester = 1;
        }
        updateStep3(formData, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step4");
                    }
                } else {
                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step4");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "relationship_status") {
            var field_value =
                key && inline ? value : formValues.relationship_status;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    relationship_status: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "relationship_status" : firsterrorid;
            }
        }
        if (!key || key == "religion") {
            var field_value = key && inline ? value : formValues.religion;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    religion: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "religion" : firsterrorid;
            }
        }

        if (!key || key == "highest_education_level") {
            var field_value = key ? value : formValues.highest_education_level;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    highest_education_level: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "highest_education_level"
                        : firsterrorid;
            }
        }
        if (!key || key == "children_and_dependents") {
            var field_value =
                key && inline ? value : formValues.children_and_dependents;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    children_and_dependents: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "children_and_dependents"
                        : firsterrorid;
            }
        }
        if (!key || key == "spoken_language") {
            var field_value =
                key && inline ? value : formValues.spoken_language;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    spoken_language: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "spoken_language" : firsterrorid;
            }
        }
        if (!key || key == "driving_license") {
            var field_value = key ? value : formValues.driving_license;

            if (formValues.own_driving_license) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        driving_license: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "driving_license" : firsterrorid;
                }
            }
        }
        if (!key || key == "disability_status") {
            var field_value = key ? value : formValues.disability_status;

            if (formValues.has_disability_status) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        disability_status: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null
                            ? "disability_status"
                            : firsterrorid;
                }
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (!formValues.relationship_status) {
            error = true;
        }
        if (!formValues.religion) {
            error = true;
        }
        if (!formValues.highest_education_level) {
            error = true;
        }
        if (!formValues.children_and_dependents) {
            error = true;
        }
        if (formValues.spoken_language.length == 0) {
            error = true;
        }
        if (
            formValues.own_driving_license &&
            formValues.driving_license.length == 0
        ) {
            error = true;
        }
        if (
            formValues.has_disability_status &&
            formValues.disability_status.length == 0
        ) {
            error = true;
        }
        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={3}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(null, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox  progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={3}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Share a bit about yourself
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            Tell us a bit about your personal
                                            life
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-32">
                                        <div
                                            className="formbox"
                                            id="relationship_status"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.relationship_status
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Relationship status"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                relationship_status:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                relationship_status:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            relationship_status:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Relationship status"
                                                    error={
                                                        formErrors.relationship_status
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox" id="religion">
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={formValues.religion}
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Religion"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                religion:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                religion: "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            religion: null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Religion"
                                                    error={formErrors.religion}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="highest_education_level"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    options={educationOptions}
                                                    value={
                                                        formValues.highest_education_level
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                highest_education_level:
                                                                    item.value,
                                                            });

                                                            validateFullForm(
                                                                false,
                                                                "highest_education_level",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                highest_education_level:
                                                                    "",
                                                            });
                                                        }
                                                    }}
                                                    isClearable={true}
                                                    label="Highest level of education"
                                                    error={
                                                        formErrors.highest_education_level
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="children_and_dependents"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    value={
                                                        formValues.children_and_dependents
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Children and dependents"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                children_and_dependents:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                children_and_dependents:
                                                                    "",
                                                            });
                                                        }
                                                        setFormErrors({
                                                            ...formErrors,
                                                            children_and_dependents:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Children and dependents"
                                                    error={
                                                        formErrors.children_and_dependents
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox mb-12">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                                cssClasses={"mb-12 color-black"}
                                            >
                                                Spoken language
                                            </Text>
                                            <div
                                                className="full d-flex-radiobox"
                                                id="spoken_language"
                                            >
                                                <div className="d-flex-radiobtn sm-label">
                                                    {primaryLangs.map(function (
                                                        lang,
                                                        index
                                                    ) {
                                                        return (
                                                            <CheckBox
                                                                key={
                                                                    "lang" +
                                                                    index
                                                                }
                                                                name="spoken_language"
                                                                label={
                                                                    lang.iso_name_english
                                                                }
                                                                checked={
                                                                    formValues.language_ids.includes(
                                                                        lang.id
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        event
                                                                            .currentTarget
                                                                            .checked
                                                                    ) {
                                                                        formValues.language_ids.push(
                                                                            lang.id
                                                                        );
                                                                        formValues.spoken_language.push(
                                                                            lang.iso_name_english
                                                                        );
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                spoken_language:
                                                                                    formValues.spoken_language,
                                                                                language_ids:
                                                                                    formValues.language_ids,
                                                                            }
                                                                        );
                                                                    } else {
                                                                        formValues.spoken_language =
                                                                            removeItemFromArray(
                                                                                formValues.spoken_language,
                                                                                lang.iso_name_english
                                                                            );

                                                                        formValues.language_ids =
                                                                            removeItemFromArray(
                                                                                formValues.language_ids,
                                                                                lang.id
                                                                            );
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                spoken_language:
                                                                                    formValues.spoken_language,
                                                                                language_ids:
                                                                                    formValues.language_ids,
                                                                            }
                                                                        );
                                                                    }
                                                                    if (
                                                                        formValues
                                                                            .spoken_language
                                                                            .length >
                                                                        0
                                                                    ) {
                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                spoken_language:
                                                                                    null,
                                                                            }
                                                                        );
                                                                    }
                                                                    //validateFullForm(false, "spoken_language");
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            {formErrors.spoken_language && (
                                                <span
                                                    className={
                                                        formErrors.error_class
                                                    }
                                                >
                                                    {formErrors.spoken_language}
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className="formbox w-fix  employment-type-wrap"
                                            id="languages"
                                        >
                                            <MembersDropdownList
                                                value={
                                                    formValues.languages
                                                }
                                                options={langs}
                                                onChange={(
                                                    items
                                                ) => {
                                                    let selected = [];

                                                    let selected_id = [];

                                                    primaryLangs.forEach(
                                                        function (primaryLang) {
                                                            formValues.language_ids.forEach(
                                                                function (
                                                                    lang
                                                                ) {
                                                                    if (
                                                                        lang ==
                                                                        primaryLang.id
                                                                    ) {
                                                                        selected_id.push(
                                                                            lang
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    );

                                                    items.forEach(function (
                                                        item
                                                    ) {
                                                        selected.push(
                                                            item
                                                        );

                                                        langs.forEach(function (
                                                            lang
                                                        ) {
                                                            if (
                                                                lang.value ==
                                                                item
                                                            ) {
                                                                selected_id.push(
                                                                    lang.id
                                                                );
                                                            }
                                                        });
                                                    });
                                                    setFormValues({
                                                        ...formValues,
                                                        languages: selected,
                                                        language_ids:
                                                            selected_id,
                                                    });
                                                    /*setFormValues({
                                                        ...formValues,
                                                        language:
                                                            selected,
                                                    });*/
                                                    setFormErrors({
                                                        ...formErrors,
                                                        language:
                                                            null,
                                                    });
                                                }}
                                                isClearable={true}
                                                label="Other languages"
                                                error={
                                                    formErrors.language
                                                }
                                                error_class={
                                                    formErrors.error_class
                                                }
                                            />
                                            {/*<SearchDropdownMultiple
                                                options={langs}
                                                value={formValues.languages}
                                                onChange={(items) => {
                                                    let selected = [];

                                                    let selected_id = [];

                                                    primaryLangs.forEach(
                                                        function (primaryLang) {
                                                            formValues.language_ids.forEach(
                                                                function (
                                                                    lang
                                                                ) {
                                                                    if (
                                                                        lang ==
                                                                        primaryLang.id
                                                                    ) {
                                                                        selected_id.push(
                                                                            lang
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    );

                                                    items.forEach(function (
                                                        item
                                                    ) {
                                                        selected.push(
                                                            item.label
                                                        );

                                                        langs.forEach(function (
                                                            lang
                                                        ) {
                                                            if (
                                                                lang.value ==
                                                                item.value
                                                            ) {
                                                                selected_id.push(
                                                                    lang.id
                                                                );
                                                            }
                                                        });
                                                    });
                                                    setFormValues({
                                                        ...formValues,
                                                        languages: selected,
                                                        language_ids:
                                                            selected_id,
                                                    });

                                                    validateFullForm(
                                                        false,
                                                        "languages",
                                                        selected,
                                                        true
                                                    );
                                                }}
                                                isClearable={true}
                                                label="Other languages"
                                                error={formErrors.languages}
                                                error_class={formErrors.error_class}
                                            />*/}
                                        </div>
                                        <div className="formbox">
                                            <div className="profile-form-group">
                                                <label className="mb-0">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={
                                                            "color-black"
                                                        }
                                                    >
                                                        Driving license
                                                    </Text>
                                                </label>

                                                <div className="col-md-12 p-0 mt-12">
                                                    <div
                                                        className="formbox   d-flex-radiobox sm-label mb-12"
                                                        id="spoken_language"
                                                    >
                                                        <div className="d-flex-radiobtn mt-0">
                                                            <CheckBox
                                                                name="nodrivinglicense"
                                                                label={
                                                                    "I don't have driving license"
                                                                }
                                                                checked={
                                                                    !formValues.own_driving_license
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            driving_license:
                                                                                [],
                                                                            own_driving_license:
                                                                                !formValues.own_driving_license,
                                                                        }
                                                                    );

                                                                    setFormErrors(
                                                                        {
                                                                            ...formErrors,
                                                                            driving_license:
                                                                                null,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="formbox col-md-12 p-0 employment-type-wrap mb-0 tester_search_lan_wrap w-fix"
                                                    id="driving_license"
                                                >
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.driving_license
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Driving license"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .filter(
                                                                (active_lov) =>
                                                                    active_lov.lov_value !=
                                                                    "I don't have driving license"
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                driving_license:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                driving_license:
                                                                    null,
                                                            });
                                                        }}
                                                        isClearable={true}
                                                        label="Driving license"
                                                        error={
                                                            formErrors.driving_license
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            !formValues.own_driving_license
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formbox">
                                            <div className="profile-form-group">
                                                <label className="mb-0">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={
                                                            "color-black"
                                                        }
                                                    >
                                                        Disability conditions
                                                    </Text>
                                                </label>

                                                <div className="col-md-12 p-0 mt-12">
                                                    <div className="formbox   d-flex-radiobox mb-12">
                                                        <div className="d-flex-radiobtn mt-0 sm-label">
                                                            <CheckBox
                                                                name="nodisabilitystatus"
                                                                label={
                                                                    "I don't have any disabilities"
                                                                }
                                                                checked={
                                                                    !formValues.has_disability_status
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            disability_status:
                                                                                [],
                                                                            has_disability_status:
                                                                                !formValues.has_disability_status,
                                                                        }
                                                                    );

                                                                    setFormErrors(
                                                                        {
                                                                            ...formErrors,
                                                                            disability_status:
                                                                                null,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="formbox col-md-12 p-0 employment-type-wrap mb-0 tester_search_lan_wrap  w-fix"
                                                    id="disability_status"
                                                >
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.disability_status
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Disability conditions"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .filter(
                                                                (active_lov) =>
                                                                    active_lov.lov_value !=
                                                                    "I don't have any disabilities"
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                disability_status:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                disability_status:
                                                                    null,
                                                            });
                                                        }}
                                                        isClearable={true}
                                                        label="Disability conditions"
                                                        error={
                                                            formErrors.disability_status
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            !formValues.has_disability_status
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step2"
                                                    : "/tester/profile/step2"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext position-relative none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    colorClass={
                                                        "secondary-purple-200"
                                                    }
                                                    value={"flag"}
                                                />
                                            }
                                            title="Be part of the change"
                                            info="Help shape digital products from some of the coolest brands in the MENA region."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
