import { Link, useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/layout.js";
import { Dropdown } from "reactjs-dropdown-component";
import Select from "react-select";
import {
    getProfile,
    deleteAccount,
    updateTesterProfile,
    disconnectSocialAccountService,
} from "../../services/user.js";
import { nationalities } from "../../data/nationalities.js";
import { languages } from "../../data/languages_others.js";
import { countries } from "../../data/countries.js";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";
import { countries as sanctioned_countries } from "../../data/countries_sanctioned.js";
import IntlTelInput from "react-intl-tel-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import toast from "react-hot-toast";
import ConfirmModal from "../dialog/confirm_modal.js";
import CheckboxNormal from "../layouts/elements/fields/checkbox_normal.js";
import { getAge, removeItemFromArray } from "../../lib/helpers.js";
import SelectElement from "../layouts/elements/fields/select_element.js";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { format } from "date-fns";
import { LoadingIcon } from "../loader/loadingIcon";
import { sendChangeEmailToUser } from "./../../services/user";
import { unnormalize, normalize, DropdownIndicator } from "../../lib/helpers";
import TesterProfileNavigation from "./tester_profile_navigation.js";
import TesterAccountNavigation from "./tester_account_navigation.js";
import ChangePasswordModal from "./reset_password_modal.js";
import { Text } from "../themes/userq/Text/Text.js";
import { Input } from "../themes/userq/Input/Input.js";
import { Button } from "../themes/userq/Button/Button.js";
import { Icon } from "../themes/userq/Icon/Icon.js";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput.js";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown.js";
import { AutoCompleteSingleSelect } from "../themes/userq/Dropdown/AutoCompleteSingleSelect";
import ConfirmationModal from "../themes/userq/Modal/ConfirmationModal.js";
import DeleteAccountModal from "../themes/userq/Modal/DeleteAccountModal.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester.js";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox.js";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton.js";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar.js";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle.js";
import { Chip, Chips } from "../themes/userq/Chips/Chips.js";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter.js";
import { MultiSelectDropdown } from "../themes/userq/MultiSelectDropdown/MultiSelectDropdown.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { settings } from "../../data/settings.js";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage.js";
import { showToast } from "../../lib/helpers.js";
import { nationalities_other } from "../../data/nationalities_other";
import { InputDatePicker } from "../themes/userq/InputDatePicker/InputDatePicker.js";
import { ScoreChip } from "../themes/userq/ScoreChip/ScoreChip.js";
import Skeleton from "react-loading-skeleton";
import { Box } from "../themes/userq/Box/Box.js";
import { VerifyId } from "../idVerification/VerifyId";
import { ExpiryId } from "../idVerification/ExpiryId";
import DocumentNotMatch from "../themes/userq/Modal/DocumentNotMatch";
import { Link as UserQLink } from "../themes/userq/Link/Link";
import SetPasswordModal from "./set_password_modal.js";
import Cookies from "js-cookie";
import { getLanguagesService } from "../../services/language.js";
import { MembersDropdownList } from "../themes/userq/MembersDropdownList/MembersDropdownList.js";
import { CircularNameIcon } from "../themes/userq/CircularNameIcon/CircularNameIcon.js";
import { SelectCreateDropdownList } from "../themes/userq/SelectCreateDropdown/SelectCreateDropdownList.js";
import { createLovService } from "../../services/lov.js";

export default function ProfileTester() {
    ReactSession.setStoreType("localStorage");

    const accountstatus = ReactSession.get("accountstatus");

    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);

    const [socialDisconnectLoading, setSocialDisconnectLoading] =
        useState(false);

    let years = [];

    for (
        let year = new Date().getFullYear() - 8;
        year > new Date().getFullYear() - 8 - 50;
        year--
    ) {
        years.push({ label: year + "", value: year + "" });
    }
    const [openModal, setOpenModal] = useState(false);
    const [testerscore, setTesterScore] = useState(null);

    var today = new Date();

    const locations = nationalities;

    const hoursOptions = [
        { label: "1-3 hours", value: "1-3 hours" },
        { label: "4-6 hours", value: "4-6 hours" },
        { label: "more than 6 hours", value: "more than 6 hours" },
    ];

    const industryOptions = [
        {
            label: "Accountancy, banking and finance",
            value: "Accountancy, banking and finance",
        },
        {
            label: "Armed force, defence & military",
            value: "Armed force, defence & military",
        },
        {
            label: "Aviation",
            value: "Aviation",
        },
        {
            label: "Business, consulting and management",
            value: "Business, consulting and management",
        },
        {
            label: "Charity and voluntary work",
            value: "Charity and voluntary work",
        },
        {
            label: "Creative arts and design",
            value: "Creative arts and design",
        },
        { label: "Digital", value: "Digital" },
        { label: "Energy and utilities", value: "Energy and utilities" },
        {
            label: "Engineering and manufacturing",
            value: "Engineering and manufacturing",
        },
        {
            label: "Environment and agriculture",
            value: "Environment and agriculture",
        },
        {
            label: "Food and beverage",
            value: "Food and beverage",
        },
        { label: "Healthcare", value: "Healthcare" },
        {
            label: "Hospitality and events management",
            value: "Hospitality and events management",
        },
        { label: "Information technology", value: "Information technology" },
        { label: "Law", value: "Law" },
        {
            label: "Law enforcement and security",
            value: "Law enforcement and security",
        },
        {
            label: "Leisure, sport and tourism",
            value: "Leisure, sport and tourism",
        },
        {
            label: "Marketing, advertising and PR",
            value: "Marketing, advertising and PR",
        },
        {
            label: "Media, Internet & telecommunications",
            value: "Media, Internet & telecommunications",
        },
        {
            label: "Property and construction",
            value: "Property and construction",
        },
        { label: "Nonprofit", value: "Nonprofit" },
        {
            label: "Public services and administration",
            value: "Public services and administration",
        },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Recruitment and HR", value: "Recruitment and HR" },
        { label: "Retail", value: "Retail" },
        { label: "Sales", value: "Sales" },
        {
            label: "Science and pharmaceuticals",
            value: "Science and pharmaceuticals",
        },
        {
            label: "Security",
            value: "Security",
        },
        { label: "Social care", value: "Social care" },
        {
            label: "Teacher training and education",
            value: "Teacher training and education",
        },
        {
            label: "Transport and logistics",
            value: "Transport and logistics",
        },

        { label: "Other (please specify)", value: "Other (please specify)" },
    ];

    const employmentOptions = [
        { label: "Student", value: "Student" },
        {
            label: "Freelancer / Self employed",
            value: "Freelancer / Self employed",
        },
        { label: "Unemployed", value: "Unemployed" },
        { label: "Private employee", value: "Private employee" },
        { label: "Public employee", value: "Public employee" },
        { label: "Homemaker", value: "Homemaker" },
        { label: "Retired", value: "Retired" },
        { label: "Business Owner / CEO", value: "Business Owner / CEO" },
    ];

    const incomelevelOptions = [
        { label: "$0 - No income", value: "$0 - No income" },
        { label: "$1 - $250", value: "$1 - $250" },
        { label: "$251 - $500", value: "$251 - $500" },
        { label: "$501 - $1,500", value: "$501 - $1,500" },
        { label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
        { label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
        { label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
        { label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
        { label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
        { label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
        { label: "Over $15,000", value: "Over $15,000" },
    ];

    const [formLoading, setFormLoading] = useState(false);

    const [profileChanged, setProfileChanged] = useState(false);

    const [employmentFieldsReadOnly, setEmploymentFieldsReadOnly] =
        useState(false);

    const [documentNotMatch, setDocumentNotMatch] = useState({ open: false });

    const [primaryLangs, setPrimaryLangs] = useState([]);

    const [langs, setLangs] = useState([]);

    const [formValues, setFormValues] = useState({
        document_provided_fields: [],
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        whatsapp_checkbox: true,
        whatsapp_phone_number: "",
        date_of_birth: "",
        gender: "male",
        nationality: "",
        spoken_language: [],
        language: [],
        language_ids: [],
        country: "",
        region: "",
        apps: [],
        hours_spend_online: "",
        tech_gadgets: "",
        highest_education_level: "",
        employment: "",
        industry: "",
        industry_other: "",
        department: "",
        people: 0,
        income_slab: "",
        relationship_status: "",
        religion: "",
        children_and_dependents: "",
        own_driving_license: true,
        driving_license: [],
        has_disability_status: true,
        disability_status: [],
        housing_status: "",
        living_arrangements: "",
        career_stage: "",
        company_size: "",
        working_environment: "",
        mobile_user_type: [],
        desktop_user_type: [],
        owned_devices: [],
        shopping_online_frequency: "",
        hobbies: [],
        fitness_activities: [],
        own_pets: true,
        pet_ownership: [],
        vehicle_ownership: "",
        own_bank: true,
        banks: [],
        payment_methods_used: [],
    });

    const [formErrors, setFormErrors] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        whatsapp_checkbox: true,
        whatsapp_phone_number: null,
        date_of_birth: null,
        gender: null,
        nationality: null,
        spoken_language: null,
        language: null,
        country: null,
        region: null,
        apps: null,
        hours_spend_online: null,
        tech_gadgets: null,
        highest_education_level: null,
        employment: null,
        industry: null,
        industry_other: null,
        department: null,
        people: null,
        income_slab: null,
        relationship_status: null,
        religion: null,
        children_and_dependents: null,
        driving_license: null,
        disability_status: null,
        housing_status: null,
        living_arrangements: null,
        career_stage: null,
        company_size: null,
        working_environment: null,
        mobile_user_type: null,
        desktop_user_type: null,
        owned_devices: null,
        shopping_online_frequency: null,
        hobbies: null,
        fitness_activities: null,
        pet_ownership: null,
        vehicle_ownership: null,
        banks: null,
        payment_methods_used: null,
        error_class: "input_error",
    });

    const [isLoading, setLoading] = useState(false);

    const [username, setUsername] = useState("");

    const [accountLoading, setAccountLoading] = useState(false);

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [confirm, setConfirm] = useState({ open: false });

    const [changeEmailModal, setChangeEmailModal] = useState({ open: false });

    const [isProfileVerified, setIsProfileVerified] = useState("");
    const [isProfileVerifiedAt, setIsProfileVerifiedAt] = useState("");
    const [isProfileExpiredAt, setIsProfileExpiredAt] = useState("");
    const [isProfileAttempts, setIsProfileAttempts] = useState(0);

    const gadgetsOptions = [
        { label: "Desktop", value: "Desktop" },
        { label: "Smartphone", value: "Smartphone" },
        { label: "Tablet", value: "Tablet" },
    ];

    const appsOptions = [
        "Shopping",
        "Hobbies",
        "Social media",
        "News",
        "Gaming",
        "Chat",
        "Collaboration",
        "Banking & finance",
        "Health",
        "Transportation",
        "Travel",
        "Office",
        "Mail",
        "Food delivery",
        "Content creation",
        "Learning",
        "Maintenance",
        "Streaming",
        "Messaging",
        "Fitness",
    ];

    const educationOptions = [
        { label: "Primary school", value: "Primary school" },
        { label: "High school", value: "High school" },
        { label: "Bachelor degree", value: "Bachelor degree" },
        { label: "Master degree", value: "Master degree" },
        { label: "Post graduate degree", value: "Post graduate degree" },
        { label: "Doctorate", value: "Doctorate" },
    ];

    const departmentOptions = [
        { label: "Finance", value: "Finance" },
        { label: "HR", value: "HR" },
        { label: "Admin", value: "Admin" },
        { label: "Design & research", value: "Design & research" },
        { label: "IT & tech", value: "IT & tech" },
        { label: "Branding & marketing", value: "Branding & marketing" },
        { label: "Sales", value: "Sales" },
        { label: "Purchase", value: "Purchase" },
        { label: "Legal", value: "Legal" },
        { label: "Other (please specify)", value: "Other (please specify)" },
    ];

    let user = ReactSession.get("user");
    let score = ReactSession.get("testerscore");

    const [whatsapp_country, setWhatsAppCountry] = useState("+971");

    const [displaySanctionedMessage, setDisplaySanctionedMessage] =
        useState(false);

    const [lovCategories, setLovCategories] = useState([]);

    const [lovLoading, setLovLoading] = useState(false);

    const industryRef = useRef(null);

    const departmentRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            industryRef &&
            industryRef.current &&
            formValues.industry == "Other (please specify)"
        ) {
            industryRef.current.focus();
        }
    }, [formValues.industry, industryRef]);

    useEffect(() => {
        if (
            departmentRef &&
            departmentRef.current &&
            formValues.department == "Other (please specify)"
        ) {
            departmentRef.current.focus();
        }
    }, [formValues.department, departmentRef]);

    useEffect(() => {
        document.title = "Your profile | " + process.env.REACT_APP_NAME;

        getLanguagesApi();
    }, []);

    useEffect(() => {
        if (langs.length > 0) {
            getProfileData();
        }
    }, [langs]);
    const getProfileData = () => {
        let token = ReactSession.get("token");
        setLoading(true);

        getProfile(token).then((response) => {
            setLoading(false);

            // check document not matched then open madal
            if (ReactSession.get("document_not_matched_model")) {
                setDocumentNotMatch({
                    open: true,
                });
                console.log("adsa");
                ReactSession.set("document_not_matched_model", false);
            }

            if (response.success) {
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }
                // check profile is verified or not
                setIsProfileVerified(
                    response.data.user.profile_verification_status
                );
                setIsProfileVerifiedAt(response.data.user.verified_at);

                // checked profile expired date
                // if(response.data.user.document_expiry_date_left){
                setIsProfileExpiredAt(
                    response.data.user.document_expiry_date_left
                );
                setIsProfileAttempts(
                    response.data.user.expired_document_attempt
                );
                // }else{
                //     setIsProfileExpiredAt('');
                // }

                setTesterScore(response.data.user.score);

                var sanctioned_country = false;

                sanctioned_countries.forEach(function (country) {
                    if (country.value == response.data.user.country) {
                        sanctioned_country = true;
                    }
                });
                setDisplaySanctionedMessage(sanctioned_country);

                // console.log(response.data.user.date_of_birth)
                // console.log(format(new Date(response.data.user.date_of_birth), "d/M/Y"))
                // console.log(moment(response.data.user.date_of_birth).format("d/M/Y"))
                // console.log(moment(response.data.user.date_of_birth).format("Y-MM-DD"))

                var languages = [];

                var spoken_languages = [];

                var language_ids = [];

                response.data.user.languages.forEach(function (userLang) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.id === userLang.language_id) {
                            spoken_languages.push(primaryLang.iso_name_english);
                        }
                    });
                    langs.forEach(function (lang) {
                        if (lang.id === userLang.language_id) {
                            languages.push(lang.label);
                        }
                    });
                    language_ids.push(userLang.language_id);
                });
                if (language_ids.length == 0) {
                    primaryLangs.forEach(function (primaryLang) {
                        if (primaryLang.is_selected == 1) {
                            spoken_languages.push(primaryLang.iso_name_english);

                            language_ids.push(primaryLang.id);
                        }
                    });
                }

                var relationship_status = "";

                var religion = "";

                var children_and_dependents = "";

                var driving_license = [];

                var housing_status = "";

                var living_arrangements = "";

                var career_stage = "";

                var company_size = "";

                var working_environment = "";

                var mobile_user_type = [];

                var desktop_user_type = [];

                var owned_devices = [];

                var shopping_online_frequency = "";

                var hobbies = [];

                var fitness_activities = [];

                var pet_ownership = [];

                var vehicle_ownership = "";

                var banks = [];

                var payment_methods_used = [];

                var own_driving_license = true;

                var own_pets = true;

                var own_bank = true;

                var has_disability_status = true;

                var disability_status = [];

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Relationship status" &&
                                item.id == lov.lov_id
                            ) {
                                relationship_status = item.id;
                            }
                            if (
                                category.name == "Religion" &&
                                item.id == lov.lov_id
                            ) {
                                religion = item.id;
                            }
                            if (
                                category.name == "Children and dependents" &&
                                item.id == lov.lov_id
                            ) {
                                children_and_dependents = item.id;
                            }
                            if (
                                category.name == "Driving license" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have driving license"
                                ) {
                                    own_driving_license = false;
                                    driving_license = [];
                                } else {
                                    own_driving_license = true;
                                    driving_license.push(item.id);
                                }
                            }
                            if (
                                category.name == "Housing status" &&
                                item.id == lov.lov_id
                            ) {
                                housing_status = item.id;
                            }
                            if (
                                category.name == "Living arrangements" &&
                                item.id == lov.lov_id
                            ) {
                                living_arrangements = item.id;
                            }
                            if (
                                category.name == "Career stage" &&
                                item.id == lov.lov_id
                            ) {
                                career_stage = item.id;
                            }
                            if (
                                category.name == "Company size" &&
                                item.id == lov.lov_id
                            ) {
                                company_size = item.id;
                            }
                            if (
                                category.name == "Working environment" &&
                                item.id == lov.lov_id
                            ) {
                                working_environment = item.id;
                            }
                            if (
                                category.name == "Mobile user type" &&
                                item.id == lov.lov_id
                            ) {
                                mobile_user_type.push(item.id);
                            }
                            if (
                                category.name == "Desktop user type" &&
                                item.id == lov.lov_id
                            ) {
                                desktop_user_type.push(item.id);
                            }
                            if (
                                category.name == "Owned devices" &&
                                item.id == lov.lov_id
                            ) {
                                owned_devices.push(item.id);
                            }
                            if (
                                category.name ==
                                    "Frequency of online shopping" &&
                                item.id == lov.lov_id
                            ) {
                                shopping_online_frequency = item.id;
                            }
                            if (
                                category.name == "Hobbies" &&
                                item.id == lov.lov_id
                            ) {
                                hobbies.push(item.id);
                            }
                            if (
                                category.name == "Fitness activities" &&
                                item.id == lov.lov_id
                            ) {
                                fitness_activities.push(item.id);
                            }
                            if (
                                category.name == "Pets owned" &&
                                item.id == lov.lov_id
                            ) {
                                if (item.lov_value == "I don't have pets") {
                                    own_pets = false;
                                } else {
                                    own_pets = true;

                                    pet_ownership.push(item.id);
                                }
                            }
                            if (
                                category.name == "Vehicle ownership" &&
                                item.id == lov.lov_id
                            ) {
                                vehicle_ownership = item.id;
                            }
                            if (
                                category.name == "What bank do you have?" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have bank account"
                                ) {
                                    own_bank = false;
                                } else {
                                    own_bank = true;

                                    banks.push(item.id);
                                }
                            }
                            if (
                                category.name == "Payment methods used" &&
                                item.id == lov.lov_id
                            ) {
                                payment_methods_used.push(item.id);
                            }
                            if (
                                category.name == "Disability conditions" &&
                                item.id == lov.lov_id
                            ) {
                                if (
                                    item.lov_value ==
                                    "I don't have any disabilities"
                                ) {
                                    has_disability_status = false;
                                    disability_status = [];
                                } else {
                                    has_disability_status = true;
                                    disability_status.push(item.id);
                                }
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,
                    password_updated_at: response.data.user.password_updated_at,
                    password_set: response.data.user.password_set,
                    provider: response.data.user.provider,
                    first_name: response.data.user.first_name,
                    last_name: response.data.user.last_name,
                    email: response.data.user.email,
                    phone_number:
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number &&
                              response.data.user.phone_number.startsWith("+")
                                ? response.data.user.phone_number.slice(1)
                                : response.data.user.phone_number
                            : "",
                    whatsapp_phone_number:
                        response.data.user.whatsapp_phone_number != null
                            ? response.data.user.whatsapp_phone_number &&
                              response.data.user.whatsapp_phone_number.startsWith(
                                  "+"
                              )
                                ? response.data.user.whatsapp_phone_number.slice(
                                      1
                                  )
                                : response.data.user.whatsapp_phone_number
                            : "",
                    whatsapp_checkbox:
                        response.data.user.whatsapp_phone_number ==
                        response.data.user.phone_number
                            ? true
                            : false,
                    date_of_birth: response.data.user.date_of_birth
                        ? moment(response.data.user.date_of_birth).format(
                              "Y-MM-DD"
                          )
                        : "",
                    gender:
                        response.data.user.gender != null
                            ? response.data.user.gender
                            : "male",
                    nationality: response.data.user.nationality,
                    /*spoken_language:
                                            response.data.user.spoken_language == null
                                                ? []
                                                : response.data.user.spoken_language.split(","),*/
                    //language: response.data.user.language == null || response.data.user.language == ''? [] : response.data.user.language.split(","),
                    spoken_language: spoken_languages,
                    language: languages,
                    language_ids: language_ids,
                    country: response.data.user.country,
                    region: response.data.user.region,
                    hours_spend_online: response.data.user.hours_spend_online,
                    tech_gadgets: response.data.user.tech_gadgets,
                    apps:
                        response.data.user.apps != null
                            ? response.data.user.apps.split(",")
                            : [],
                    highest_education_level:
                        response.data.user.highest_education_level,
                    employment: response.data.user.employment,
                    industry: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.industry,
                    industry_other: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.industry_other
                        ? response.data.user.industry_other
                        : "",
                    department: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : (response.data.user.department!="Other"?response.data.user.department:""),
                    department_other: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : response.data.user.department_other
                        ? response.data.user.department_other
                        : "",
                    people: response.data.user.people,
                    income_slab: response.data.user.income_slab,
                    document_provided_fields: response.data.user
                        .document_provided_fields
                        ? response.data.user.document_provided_fields.split(",")
                        : [],
                    relationship_status: relationship_status,
                    religion: religion,
                    children_and_dependents: children_and_dependents,
                    housing_status: housing_status,
                    living_arrangements: living_arrangements,
                    vehicle_ownership: vehicle_ownership,
                    career_stage: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : career_stage,
                    company_size: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : company_size,
                    working_environment: [
                        "Student",
                        "Unemployed",
                        "Homemaker",
                        "Retired",
                    ].includes(response.data.user.employment)
                        ? ""
                        : working_environment,
                    mobile_user_type: mobile_user_type,
                    desktop_user_type: desktop_user_type,
                    shopping_online_frequency: shopping_online_frequency,
                    hobbies: hobbies,
                    owned_devices: owned_devices,
                    fitness_activities: fitness_activities,
                    payment_methods_used: payment_methods_used,
                    own_driving_license: own_driving_license,
                    driving_license: driving_license,
                    has_disability_status: has_disability_status,
                    disability_status: disability_status,
                    pet_ownership: pet_ownership,
                    own_pets: own_pets,
                    own_bank: own_bank,
                    banks: banks,
                });

                if (
                    ["Student", "Unemployed", "Homemaker", "Retired"].includes(
                        response.data.user.employment
                    )
                ) {
                    setEmploymentFieldsReadOnly(true);
                } else {
                    setEmploymentFieldsReadOnly(false);
                }
            } else {
                let message = response.message;
                if (response.message == "Unauthenticated.") {
                    message =
                        "If you are unable to see your profile, kindly logout and log in again";
                }
                var id = toast(
                    <div className="toastinner">
                        {message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getLanguagesApi = () => {
        setLoading(true);

        getLanguagesService().then((response) => {
            if (response.success) {
                var primary_langs = [];

                var other_langs = [];

                response.languages.forEach((lang) => {
                    if (lang.is_primary == 1) {
                        primary_langs.push(lang);
                    } else {
                        other_langs.push({
                            label: lang.iso_name_english,
                            value: lang.iso_name_english,
                            id: lang.id,
                        });
                    }
                });

                setPrimaryLangs(
                    primary_langs.sort(function (a, b) {
                        if (a.iso_name_english < b.iso_name_english) {
                            return 1;
                        }
                        return -1;
                    })
                );

                setLangs(other_langs);

                //getProfileApi();
            } else {
                setLoading(false);
            }
        });
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            if (name == "income") {
                if (value == 0) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value.slice(0, -1),
                    }));
                } else {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                    setFormErrors((oldValues) => ({
                        ...oldValues,
                        [name]: null,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
                setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            }
            setProfileChanged(true);
        };
    };

    const setAppOption = (option) => {
        return (event) => {
            let apps = formValues.apps;
            if (apps.includes(option)) {
                const index = apps.indexOf(option);
                if (index > -1) {
                    apps.splice(index, 1);
                }
            } else {
                apps.push(option);
            }
            setFormValues((oldValues) => ({ ...oldValues, ["apps"]: apps }));
            setFormErrors((oldValues) => ({ ...oldValues, apps: null }));
        };
    };

    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const userToClass = (option) => {
        if (formValues.apps.includes(option)) {
            return "active";
        }
        return "";
    };
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={false}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const closeModal = (reload) => {
        setOpenModal(false);
    };
    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        console.log(form_errors);
        var firsterrorid = null;

        if (!key || key == "first_name") {
            var field_value = key && inline ? value : formValues.first_name;

            if (field_value == "") {
                form_errors = {
                    ...form_errors,
                    first_name: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "first_name" : firsterrorid;
            }
        }
        if (!key || key == "last_name") {
            var field_value = key && inline ? value : formValues.last_name;

            if (field_value == "") {
                form_errors = {
                    ...form_errors,
                    last_name: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "last_name" : firsterrorid;
            }
        }
        if (!key || key == "date_of_birth") {
            var field_value = key && inline ? value : formValues.date_of_birth;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    date_of_birth: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "date_of_birth" : firsterrorid;
            } else if (getAge(formValues.date_of_birth) < 18) {
                form_errors = {
                    ...form_errors,
                    date_of_birth: "You must be 18 years of age",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "date_of_birth" : firsterrorid;
            }
        }
        if (!key || key == "nationality") {
            var field_value =
                key == "nationality" ? value : formValues.nationality;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    nationality: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "nationality" : firsterrorid;
            }
        }
        if (!key || key == "country") {
            var field_value = key == "country" ? value : formValues.country;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    country: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "country" : firsterrorid;
            }
        }
        if (!key || key == "region") {
            var field_value = key && inline ? value : formValues.region;

            if (
                (formValues.country == "United Arab Emirates" ||
                    formValues.country == "Suadi Arabia") &&
                !field_value
            ) {
                form_errors = {
                    ...form_errors,
                    region: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "region" : firsterrorid;
            }
        }

        if (!key || key == "relationship_status") {
            var field_value =
                key && inline ? value : formValues.relationship_status;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    relationship_status: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "relationship_status" : firsterrorid;
            }
        }
        if (!key || key == "religion") {
            var field_value = key && inline ? value : formValues.religion;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    religion: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "religion" : firsterrorid;
            }
        }
        if (!key || key == "children_and_dependents") {
            var field_value =
                key && inline ? value : formValues.children_and_dependents;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    children_and_dependents: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "children_and_dependents"
                        : firsterrorid;
            }
        }
        if (!key || key == "highest_education_level") {
            var field_value = key ? value : formValues.highest_education_level;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    highest_education_level: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "highest_education_level"
                        : firsterrorid;
            }
        }

        if (!key || key == "spoken_language") {
            var field_value =
                key && inline ? value : formValues.spoken_language;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    spoken_language: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "spoken_language" : firsterrorid;
            }
        }
        if (!key || key == "driving_license") {
            var field_value = key ? value : formValues.driving_license;

            if (formValues.own_driving_license) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        driving_license: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "driving_license" : firsterrorid;
                }
            }
        }
        if (!key || key == "disability_status") {
            var field_value = key ? value : formValues.disability_status;

            if (formValues.has_disability_status) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        disability_status: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null
                            ? "disability_status"
                            : firsterrorid;
                }
            }
        }

        if (!key || key == "phone_number") {
            var field_value = key && inline ? value : formValues.phone_number;

            // console.log(field_value.toString())
            // console.log('+'+field_value.toString())
            // console.log(isValidPhoneNumber('+918266064171'))
            // console.log(isValidPhoneNumber('+'+field_value.toString()))

            if (
                !field_value ||
                !isValidPhoneNumber("+" + field_value.toString())
            ) {
                form_errors = {
                    ...form_errors,
                    phone_number: "Phone number is not valid",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "phone_number" : firsterrorid;
            }
        }
        if (
            formValues.whatsapp_phone_number != "" &&
            formValues.whatsapp_phone_number != undefined &&
            formValues.whatsapp_phone_number != "+" + whatsapp_country
        ) {
            if (!key || key == "whatsapp_phone_number") {
                var field_value =
                    key && inline ? value : formValues.whatsapp_phone_number;

                if (
                    !field_value ||
                    !isValidPhoneNumber("+" + field_value.toString())
                ) {
                    form_errors = {
                        ...form_errors,
                        whatsapp_phone_number: "WhatsApp number is not valid",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null
                            ? "whatsapp_phone_number"
                            : firsterrorid;
                }
            }
        }

        /*if(!key || key=="language"){
        
                    var field_value = key && inline ? value : formValues.language;
        
                    if (field_value.length==0) {
                        form_errors = {
                            ...form_errors,
                            language: "Please select atleast one other language",
                            error_class: "input_error"
                        };
                        error = true;
                        firsterrorid = firsterrorid == null ? "language" : firsterrorid;
                    }
                }*/
        if (!key || key == "housing_status") {
            var field_value = key && inline ? value : formValues.housing_status;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    housing_status: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "housing_status" : firsterrorid;
            }
        }
        if (!key || key == "living_arrangements") {
            var field_value =
                key && inline ? value : formValues.living_arrangements;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    living_arrangements: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "living_arrangements" : firsterrorid;
            }
        }
        if (!key || key == "income_slab") {
            var field_value = key ? value : formValues.income_slab;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    income_slab: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }
        if (!key || key == "people") {
            var field_value = key && inline ? value : formValues.people;

            if (parseInt(field_value) == 0) {
                form_errors = {
                    ...form_errors,
                    people: "People must be > 0",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }

        if (!key || key == "employment") {
            var field_value = key ? value : formValues.employment;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    employment: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "employment" : firsterrorid;
            }
            if (
                ["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    field_value
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry: null,
                    industry_other: null,
                    department: null,
                };
            }
        }
        if (!key || key == "industry") {
            var field_value = key ? value : formValues.industry;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry" : firsterrorid;
            }
        }
        if (!key || key == "industry_other") {
            var field_value = key && inline ? value : formValues.industry_other;

            if (
                formValues.industry == "Other (please specify)" &&
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    industry_other: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "industry_other" : firsterrorid;
            }
        }
        if (!key || key == "department") {
            var field_value = key ? value : formValues.department;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    department: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "department" : firsterrorid;
            }
        }
        if (!key || key == "department_other") {
            var field_value =
                key && inline ? value : formValues.department_other;

            if (
                formValues.department == "Other (please specify)" &&
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    department_other: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "department_other" : firsterrorid;
            }
        }

        if (!key || key == "career_stage") {
            var field_value = key && inline ? value : formValues.career_stage;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    career_stage: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "career_stage" : firsterrorid;
            }
        }
        if (!key || key == "company_size") {
            var field_value = key && inline ? value : formValues.company_size;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    company_size: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "company_size" : firsterrorid;
            }
        }
        if (!key || key == "working_environment") {
            var field_value =
                key && inline ? value : formValues.working_environment;

            if (
                !field_value &&
                !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                    formValues.employment
                )
            ) {
                form_errors = {
                    ...form_errors,
                    working_environment: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "working_environment" : firsterrorid;
            }
        }

        if (!key || key == "hours_spend_online") {
            var field_value = key ? value : formValues.hours_spend_online;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    hours_spend_online: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "hours_spend_online" : firsterrorid;
            }
        }
        if (!key || key == "tech_gadgets") {
            var field_value = key ? value : formValues.tech_gadgets;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    tech_gadgets: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "tech_gadgets" : firsterrorid;
            }
        }

        if (!key || key == "mobile_user_type") {
            var field_value =
                key && inline ? value : formValues.mobile_user_type;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    mobile_user_type: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "mobile_user_type" : firsterrorid;
            }
        }
        if (!key || key == "desktop_user_type") {
            var field_value =
                key && inline ? value : formValues.desktop_user_type;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    desktop_user_type: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "desktop_user_type" : firsterrorid;
            }
        }
        if (!key || key == "owned_devices") {
            var field_value = key && inline ? value : formValues.owned_devices;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    owned_devices: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "owned_devices" : firsterrorid;
            }
        }
        if (!key || key == "apps") {
            var field_value = key && inline ? value : formValues.apps;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    apps: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "apps" : firsterrorid;
            }
        }

        if (!key || key == "shopping_online_frequency") {
            var field_value =
                key && inline ? value : formValues.shopping_online_frequency;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    shopping_online_frequency: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "shopping_online_frequency"
                        : firsterrorid;
            }
        }
        if (!key || key == "hobbies") {
            var field_value = key && inline ? value : formValues.hobbies;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    hobbies: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "hobbies" : firsterrorid;
            }
        }
        if (!key || key == "fitness_activities") {
            var field_value =
                key && inline ? value : formValues.fitness_activities;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    fitness_activities: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "fitness_activities" : firsterrorid;
            }
        }

        if (!key || key == "pet_ownership") {
            var field_value = key && inline ? value : formValues.pet_ownership;

            if (formValues.own_pets) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        pet_ownership: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "pet_ownership" : firsterrorid;
                }
            }
        }
        if (!key || key == "vehicle_ownership") {
            var field_value =
                key && inline ? value : formValues.vehicle_ownership;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    vehicle_ownership: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "vehicle_ownership" : firsterrorid;
            }
        }

        if (!key || key == "banks") {
            var field_value = key && inline ? value : formValues.banks;

            if (formValues.own_bank) {
                if (field_value.length == 0) {
                    form_errors = {
                        ...form_errors,
                        banks: "Required field",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid =
                        firsterrorid == null ? "banks" : firsterrorid;
                }
            }
        }
        if (!key || key == "payment_methods_used") {
            var field_value =
                key && inline ? value : formValues.payment_methods_used;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    payment_methods_used: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null
                        ? "payment_methods_used"
                        : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                console.log(firsterrorid);
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        //console.log(formValues);

        var error = false;

        if (formValues.first_name == "") {
            error = true;
        }
        if (formValues.last_name == "") {
            error = true;
        }

        if (
            !formValues.phone_number ||
            !isValidPhoneNumber("+" + formValues.phone_number.toString())
        ) {
            error = true;
        }
        if (
            formValues.whatsapp_phone_number != "" &&
            formValues.whatsapp_phone_number != undefined &&
            formValues.whatsapp_phone_number != "+" + whatsapp_country
        ) {
            if (
                !formValues.whatsapp_checkbox
            ) {
                if (
                    !formValues.whatsapp_phone_number ||
                    !isValidPhoneNumber(
                        "+" + formValues.whatsapp_phone_number.toString()
                    )
                ) {
                    error = true;
                }
            }
        }
        if (
            !formValues.date_of_birth ||
            (formValues.date_of_birth && getAge(formValues.date_of_birth) < 18)
        ) {
            error = true;
        }
        if (!formValues.nationality) {
            error = true;
        }
        if (formValues.spoken_language.length == 0) {
            error = true;
        }
        /*if (formValues.language.length==0) {
                    error = true;
                }*/
        if (!formValues.hours_spend_online) {
            error = true;
        }
        if (!formValues.tech_gadgets) {
            error = true;
        }

        if (formValues.apps.length == 0) {
            error = true;
        }
        if (!formValues.highest_education_level) {
            error = true;
        }
        if (
            !["Student", "Unemployed", "Homemaker", "Retired"].includes(
                formValues.employment
            )
        ) {
            if (!formValues.industry) {
                error = true;
            }
            if (
                formValues.industry == "Other (please specify)" &&
                !formValues.industry_other
            ) {
                error = true;
            }

            if (!formValues.department) {
                error = true;
            }
            if (
                formValues.department == "Other (please specify)" &&
                !formValues.department_other
            ) {
                error = true;
            }
            if (!formValues.career_stage) {
                //console.log(6)
                error = true;
            }
            if (!formValues.company_size) {
                //console.log(7)
                error = true;
            }
            if (!formValues.working_environment) {
                //console.log(8)
                error = true;
            }
        }
        if (!formValues.employment) {
            error = true;
        }

        if (!formValues.country) {
            error = true;
        } else {
            if (
                !formValues.region &&
                (formValues.country == "United Arab Emirates" ||
                    formValues.country == "Saudi Arabia")
            ) {
                error = true;
            }
        }

        if (!formValues.income_slab) {
            error = true;
        }
        if (parseInt(formValues.people) == 0) {
            error = true;
        }
        if (!formValues.religion) {
            // console.log(1)
            error = true;
        }
        if (!formValues.children_and_dependents) {
            //console.log(2)
            error = true;
        }
        if (
            formValues.own_driving_license &&
            formValues.driving_license.length == 0
        ) {
            //console.log(3)
            error = true;
        }
        if (
            formValues.has_disability_status &&
            formValues.disability_status.length == 0
        ) {
            error = true;
        }
        if (!formValues.housing_status) {
            //console.log(4)
            error = true;
        }
        if (!formValues.living_arrangements) {
            //console.log(5)
            error = true;
        }

        if (formValues.mobile_user_type.length == 0) {
            //console.log(9)
            error = true;
        }
        if (formValues.desktop_user_type.length == 0) {
            //console.log(10)
            error = true;
        }
        if (formValues.owned_devices.length == 0) {
            //console.log(11)
            error = true;
        }
        if (!formValues.shopping_online_frequency) {
            //console.log(12)
            error = true;
        }
        if (formValues.hobbies.length == 0) {
            //console.log(13)
            error = true;
        }
        if (formValues.fitness_activities.length == 0) {
            //console.log(14)
            error = true;
        }
        if (formValues.own_pets && formValues.pet_ownership.length == 0) {
            //console.log(15)
            error = true;
        }
        if (!formValues.vehicle_ownership) {
            //console.log(16)
            error = true;
        }
        if (formValues.own_bank && formValues.banks.length == 0) {
            //console.log(17)
            error = true;
        }
        if (formValues.payment_methods_used.length == 0) {
            //console.log(18)
            error = true;
        }
        return error;
    };
    const onSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();

        if (!formLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                setFormLoading(true);

                setProfileChanged(false);

                const token = ReactSession.get("token");

                var formData = Object.assign({}, formValues);

                /*if (formData.whatsapp_checkbox) {
                                    formData.whatsapp_phone_number = "";
                                }*/
                formData.date_of_birth = moment(
                    formValues.date_of_birth
                ).format("Y-MM-DD");

                formData["extra_date_of_birth"] = format(
                    new Date(formValues.date_of_birth),
                    "dd-MM-yy"
                );
                //formData["language"] = normalize(formValues["language"]).join(",");
                //formData["industry"] = normalize(formValues["industry"]);
                //formData["department"] = normalize(formValues["department"]);

                //console.log(normalize(formValues["industry"]))

                if (!formValues.own_driving_license) {
                    var lovCategoriesLoc = lovCategories
                        .filter(
                            (category) => category.name == "Driving license"
                        )
                        .flatMap((category) => category.active_lov)
                        .filter(
                            (active_lov) =>
                                active_lov.lov_value ==
                                "I don't have driving license"
                        );

                    if (lovCategoriesLoc.length == 1) {
                        formData["driving_license"].push(
                            lovCategoriesLoc[0].id
                        );
                    }
                }
                if (!formValues.has_disability_status) {
                    var lovCategoriesLoc = lovCategories
                        .filter(
                            (category) =>
                                category.name == "Disability conditions"
                        )
                        .flatMap((category) => category.active_lov)
                        .filter(
                            (active_lov) =>
                                active_lov.lov_value ==
                                "I don't have any disabilities"
                        );

                    if (lovCategoriesLoc.length == 1) {
                        formData["disability_status"].push(
                            lovCategoriesLoc[0].id
                        );
                    }
                }
                if (!formValues.own_pets) {
                    var lovCategoriesLoc = lovCategories
                        .filter((category) => category.name == "Pets owned")
                        .flatMap((category) => category.active_lov)
                        .filter(
                            (active_lov) =>
                                active_lov.lov_value == "I don't have pets"
                        );

                    if (lovCategoriesLoc.length == 1) {
                        formData["pet_ownership"].push(lovCategoriesLoc[0].id);
                    }
                }
                if (!formValues.own_bank) {
                    var lovCategoriesLoc = lovCategories
                        .filter(
                            (category) =>
                                category.name == "What bank do you have?"
                        )
                        .flatMap((category) => category.active_lov)
                        .filter(
                            (active_lov) =>
                                active_lov.lov_value ==
                                "I don't have bank account"
                        );

                    if (lovCategoriesLoc.length == 1) {
                        formData["banks"].push(lovCategoriesLoc[0].id);
                    }
                }
                updateTesterProfile(formData, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        let user = ReactSession.get("user");

                        user.first_name = response.user.first_name;

                        user.last_name = response.user.last_name;

                        ReactSession.set("user", user);

                        //toast.success(response.message);

                        //setSuccessMessage(response.message);

                        setUsername(user.first_name + " " + user.last_name);

                        showSuccess(response.message);
                        /*toast(
                                                    <div className="toastinner">
                                                        {response.message}
                                                        <a
                                                            onClick={() => {
                                                                toast.dismiss();
                                                            }}
                                                        >
                                                            &times;
                                                        </a>
                                                    </div>,
                                                    {
                                                        className: "successtoast",
                                                        position: "bottom-center",
                                                        duration: 3000,
                                                    }
                                                );*/
                    } else {
                        //setErrorMessage(response.message);

                        showError(response.message);

                        /* toast(
                                                     <div className="toastinner">
                                                         {response.message}
                                                         <a
                                                             onClick={() => {
                                                                 toast.dismiss();
                                                             }}
                                                         >
                                                             &times;
                                                         </a>
                                                     </div>,
                                                     {
                                                         className: "errortoast",
                                                         position: "bottom-center",
                                                         duration: 3000,
                                                     }
                                                 );*/
                    }
                });
            } else {
                showError(
                    <FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />
                );
                /*toast(
                                    <div className="toastinner">
                                        <FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />
                                        <a
                                            onClick={() => {
                                                toast.dismiss();
                                            }}
                                        >
                                            &times;
                                        </a>
                                    </div>,
                                    {
                                        className: "errortoast",
                                        position: "bottom-center",
                                        duration: 3000,
                                    }
                                );*/
            }
        }
        return false;
    };
    const deleteUserAccount = (event) => {
        setAccountLoading(false);

        if (!accountLoading) {
            setAccountLoading(true);

            const token = ReactSession.get("token");

            deleteAccount(token).then((response) => {
                setAccountLoading(false);

                if (response.success) {
                    ReactSession.set("user", {});

                    ReactSession.set("token", null);

                    Cookies.set("last_login_method_tester", null);

                    navigate("/tester/login");
                } else {
                    //setErrorMessage(response.message);

                    showToast(response.message, "error");
                }
            });
        }
    };
    const disconnectSocialAccount = async (provider) => {
        const token = ReactSession.get("token");

        let body = Object.assign({}, { provider: provider });

        setSocialDisconnectLoading(true);

        disconnectSocialAccountService(body, token).then((response) => {
            setSocialDisconnectLoading(false);

            if (response.success) {
                Cookies.set("last_login_method_tester", null);

                let form_values = { ...formValues };

                form_values.provider = null;

                setFormValues(form_values);

                showToast(response.message, "success");
            } else {
                showToast(response.message, "error");
            }
        });
        return false;
    };
    const sendChangeEmail = (event) => {
        setAccountLoading(false);
        if (!accountLoading) {
            setAccountLoading(true);

            const token = ReactSession.get("token");

            sendChangeEmailToUser(token).then((response) => {
                setAccountLoading(false);

                if (response.success) {
                    setChangeEmailModal({ ...changeEmailModal, open: false });

                    showToast(response.message, "success");

                    // ReactSession.set("user",{});

                    // ReactSession.set("token",null);

                    // navigate("/researcher/login");
                } else {
                    showToast(response.message, "error");
                    // setErrorMessage(response.message);
                }
            });
        }
    };
    const createLovApi = (option, category_id) => {
        let formData = {};

        formData.lov_name = option;

        formData.lov_category_id = category_id;

        let token = ReactSession.get("token");

        setLovLoading(true);

        createLovService(formData, token).then((response) => {
            if (response.success) {
                setLovLoading(false);
                var banks = Object.assign([], formValues.banks);

                banks.push(response.lov.id);

                var lovCategoriesLoc = Object.assign([], lovCategories);

                lovCategoriesLoc.forEach(function (category, index) {
                    if (category.id === category_id) {
                        lovCategoriesLoc[index]["active_lov"] =
                            response.lov_categories[0].active_lov;
                    }
                });
                //banks.push(response.lov.id);

                setFormValues({ ...formValues, banks: banks });

                setLovCategories(lovCategoriesLoc);
            } else {
                setLovLoading(false);
                showToast(response.message, "error");
            }
        });
    };
    return (
        <LayoutTester
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            username={username}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"profile"}
        >
            {accountstatus == "blocked" && (
                <div className="account-blocked-message">
                    <div>
                        <Icon
                            colorClass={"danger-red-200"}
                            value={"blocked"}
                            size="large"
                        />
                    </div>
                    <div>
                        <Text
                            type={"subtitle-1"}
                            children="Your payout cannot be redeemed as your profile is currently blocked."
                        />
                        <Text type={"body-text-2"}>
                            Please contact{" "}
                            <a
                                className="faq-support-link disabled-support-link"
                                href={
                                    process.env.REACT_APP_URL +
                                    "t/support/contact?reason=Blocked profile"
                                }
                                target="_blank"
                            >
                                UserQ support
                            </a>
                            , to guide you through the next steps.
                        </Text>
                    </div>
                </div>
            )}
            <div className="page-heading-wrap accounts-page--heading justify-content-between tester-profile-account-head-wrap">
                <div className="tester-profile-account-head d-flex align-items-center">
                    <Text type="h1">
                        Account
                        <div className="researcher-profile-form-btn none-for-desktop">
                            {user.role == "Tester" &&
                                accountstatus != "blocked" && (
                                    <>
                                        {profileChanged ? (
                                            <Button
                                                type="primary"
                                                size="large"
                                                label="Save updates"
                                                state={
                                                    checkBtnState()
                                                        ? "disabled"
                                                        : "active"
                                                }
                                                onClick={onSubmit}
                                                microLoading={formLoading}
                                            />
                                        ) : (
                                            <Button
                                                type="primary"
                                                size="large"
                                                label="Save updates"
                                                state={
                                                    formLoading
                                                        ? "active"
                                                        : "disabled"
                                                }
                                                onClick={() => {
                                                    validateFullForm(
                                                        true,
                                                        null
                                                    );
                                                }}
                                                microLoading={formLoading}
                                            />
                                        )}
                                    </>
                                )}
                            {user.role == "Tester" &&
                                accountstatus == "blocked" && (
                                    <Button
                                        type="primary"
                                        size="large"
                                        label="Save updates"
                                        state={"disabled"}
                                        onClick={() => {}}
                                    />
                                )}
                        </div>
                    </Text>

                    <div className="accounts-score-chip">
                        <ScoreChip testerscore={score} />
                        <div className="account-guidelines-link">
                            <Text type="body-text-3">
                                Read more about{" "}
                                <a
                                    className="faq-support-link"
                                    href={
                                        process.env.REACT_APP_URL +
                                        "t/tester-guidelines"
                                    }
                                    target="_blank"
                                >
                                    tester guidelines
                                </a>
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="researcher-profile-form-btn none-for-mobile mt-32">
                    {user.role == "Tester" && accountstatus != "blocked" && (
                        <>
                            {profileChanged ? (
                                <Button
                                    type="primary"
                                    size="large"
                                    label="Save updates"
                                    state={
                                        checkBtnState() ? "disabled" : "active"
                                    }
                                    onClick={onSubmit}
                                    microLoading={formLoading}
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    size="large"
                                    label="Save updates"
                                    state={formLoading ? "active" : "disabled"}
                                    onClick={() => {
                                        validateFullForm(true, null);
                                    }}
                                    microLoading={formLoading}
                                />
                            )}
                        </>
                    )}
                    {user.role == "Tester" && accountstatus == "blocked" && (
                        <Button
                            type="primary"
                            size="large"
                            label="Save updates"
                            state={"disabled"}
                            onClick={() => {}}
                        />
                    )}
                </div>
            </div>
            <TesterAccountNavigation activeMenu="profile" />

            {isLoading ? (
                <div className="accountloading-box">
                    {" "}
                    <LoadingIcon />
                </div>
            ) : (
                <div
                    className={`tester-profile ${
                        accountstatus == "blocked"
                            ? "blocked-profile"
                            : "active-profile"
                    }`}
                >
                    <div className="idverify-message mb-32">
                        {(isProfileVerified === "id_not_matched_profile" ||
                            isProfileVerified === "unverified" ||
                            (isProfileVerified === "expired_document" &&
                                isProfileExpiredAt === "") ||
                            isProfileVerified === "invalid_document") && (
                            <>
                                <VerifyId
                                    isType={"banner"}
                                    isProfileVerifiedAt={isProfileVerifiedAt}
                                    isProfileVerified={isProfileVerified}
                                    confirmHandler={(status) => {
                                        if (status) {
                                            navigate("/dashboard");
                                        } else {
                                            getProfileData();
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>

                    <div className="idexpiry-message mb-32">
                        {(isProfileVerified === "verified" ||
                            isProfileVerified === "expired_document") &&
                            isProfileExpiredAt !== "" &&
                            isProfileExpiredAt >= 0 && (
                                <ExpiryId
                                    profileExpiredDate={isProfileExpiredAt}
                                    isProfileAttempts={isProfileAttempts}
                                    isProfileVerifiedAt={isProfileVerifiedAt}
                                    confirmHandler={() => {
                                        getProfileData();
                                    }}
                                />
                            )}
                    </div>

                    <div className="tester_profole_guide_info mb-32">
                        <Text type="body-text-1">
                            {" "}
                            Please note that sensitive information in your
                            profile, such as your name, surname, or contact
                            details, will never be shared with the researchers.
                        </Text>
                        <Link className={"link-text"} to={"/t/support/"}>
                            Learn more about how we use your data.
                        </Link>
                    </div>

                    <div className="profile-form-wrap profile-tester-wrap ">
                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Personal information
                                </Text>
                            </div>

                            {/* After onfido verification */}
                            {isProfileVerified === "verified" && (
                                <div
                                    className={`tester_verified_profile_info ${
                                        formValues.document_provided_fields.includes(
                                            "date_of_birth"
                                        ) &&
                                        formValues.document_provided_fields.includes(
                                            "first_name"
                                        ) &&
                                        formValues.document_provided_fields.includes(
                                            "last_name"
                                        ) &&
                                        formValues.document_provided_fields.includes(
                                            "nationality"
                                        ) &&
                                        formValues.document_provided_fields.includes(
                                            "gender"
                                        )
                                            ? ""
                                            : "mb-32"
                                    } `}
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Box cssClasses="sign-up-verified-info gray-box p-20 tester_verified_profile_box">
                                                <div className="signup_veri_infotop d-flex align-items-center mb-20 verified_info_success">
                                                    <Icon
                                                        colorClass="success-green-200"
                                                        size={"medium"}
                                                        value="ID"
                                                    />
                                                    <Text
                                                        type={"body-text-1"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses="green-color"
                                                    >
                                                        {" "}
                                                        Verified information
                                                    </Text>
                                                </div>

                                                <div className="signup_user_info">
                                                    {formValues.document_provided_fields.includes(
                                                        "first_name"
                                                    ) && (
                                                        <div className="signup_user_inforepeat">
                                                            <div className="signup_user_left_info d-flex align-items-center justify-content-between">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    cssClasses="color-black user_info_label"
                                                                >
                                                                    First name
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses="color-black"
                                                                >
                                                                    {formValues.first_name
                                                                        ? formValues.first_name
                                                                        : "-"}
                                                                    <Icon
                                                                        colorClass="success-green-200"
                                                                        value="verified"
                                                                    />
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {formValues.document_provided_fields.includes(
                                                        "last_name"
                                                    ) && (
                                                        <div className="signup_user_inforepeat">
                                                            <div className="signup_user_left_info d-flex align-items-center justify-content-between">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    cssClasses="color-black user_info_label"
                                                                >
                                                                    Last name
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses="color-black"
                                                                >
                                                                    {formValues.last_name
                                                                        ? formValues.last_name
                                                                        : "-"}
                                                                    <Icon
                                                                        colorClass="success-green-200"
                                                                        value="verified"
                                                                    />
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {formValues.document_provided_fields.includes(
                                                        "date_of_birth"
                                                    ) && (
                                                        <div className="signup_user_inforepeat">
                                                            <div className="signup_user_left_info d-flex align-items-center justify-content-between">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    cssClasses="color-black user_info_label"
                                                                >
                                                                    Date of
                                                                    birth
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses="color-black"
                                                                >
                                                                    {format(
                                                                        new Date(
                                                                            formValues.date_of_birth
                                                                        ),
                                                                        "dd/MM/yyyy"
                                                                    )}
                                                                    {/*{(new Date(formValues.date_of_birth).getDate()) + '/' + (new Date(formValues.date_of_birth).getMonth() + 1) + '/' + new Date(formValues.date_of_birth).getFullYear()}*/}
                                                                    <Icon
                                                                        colorClass="success-green-200"
                                                                        value="verified"
                                                                    />{" "}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {formValues.document_provided_fields.includes(
                                                        "nationality"
                                                    ) && (
                                                        <div className="signup_user_inforepeat">
                                                            <div className="signup_user_left_info d-flex align-items-center justify-content-between">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    cssClasses="color-black user_info_label"
                                                                >
                                                                    Nationality
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses="color-black"
                                                                >
                                                                    {
                                                                        formValues.nationality
                                                                    }{" "}
                                                                    <Icon
                                                                        colorClass="success-green-200"
                                                                        value="verified"
                                                                    />
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {formValues.document_provided_fields.includes(
                                                        "gender"
                                                    ) && (
                                                        <div className="signup_user_inforepeat">
                                                            <div className="signup_user_left_info d-flex align-items-center justify-content-between">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    cssClasses="color-black user_info_label"
                                                                >
                                                                    Gender
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses="color-black text-capitalize"
                                                                >
                                                                    {
                                                                        formValues.gender
                                                                    }{" "}
                                                                    <Icon
                                                                        colorClass="success-green-200"
                                                                        value="verified"
                                                                    />
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div
                                            className={`row ${
                                                !(
                                                    isProfileVerified ==
                                                        "verified" &&
                                                    formValues.document_provided_fields.includes(
                                                        "first_name"
                                                    )
                                                ) ||
                                                !(
                                                    isProfileVerified ==
                                                        "verified" &&
                                                    formValues.document_provided_fields.includes(
                                                        "last_name"
                                                    )
                                                )
                                                    ? ""
                                                    : "green-flow-tester-profile"
                                            }`}
                                        >
                                            {!(
                                                isProfileVerified ==
                                                    "verified" &&
                                                formValues.document_provided_fields.includes(
                                                    "first_name"
                                                )
                                            ) && (
                                                <div className="formbox col-md-6">
                                                    <div className="profile-form-group">
                                                        <Input
                                                            label="First name"
                                                            onChange={set(
                                                                "first_name"
                                                            )}
                                                            onClear={() => {
                                                                setFormValues(
                                                                    (
                                                                        oldValues
                                                                    ) => ({
                                                                        ...oldValues,
                                                                        first_name:
                                                                            "",
                                                                    })
                                                                );
                                                            }}
                                                            onBlur={() => {
                                                                validateFullForm(
                                                                    false,
                                                                    "first_name"
                                                                );
                                                            }}
                                                            value={
                                                                formValues.first_name
                                                            }
                                                            maxLength="45"
                                                            hideMaxChars={true}
                                                            error={
                                                                formErrors.first_name
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            id="first_name"
                                                            clearField={false}
                                                            required={true}
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                            disabled={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {!(
                                                isProfileVerified ==
                                                    "verified" &&
                                                formValues.document_provided_fields.includes(
                                                    "last_name"
                                                )
                                            ) && (
                                                <div className="formbox col-md-6">
                                                    <div className="profile-form-group">
                                                        <Input
                                                            label="Last name"
                                                            onChange={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? () => {}
                                                                    : set(
                                                                          "last_name"
                                                                      )
                                                            }
                                                            onBlur={() => {
                                                                validateFullForm(
                                                                    false,
                                                                    "last_name"
                                                                );
                                                            }}
                                                            onClear={() => {
                                                                setFormValues(
                                                                    (
                                                                        oldValues
                                                                    ) => ({
                                                                        ...oldValues,
                                                                        last_name:
                                                                            "",
                                                                    })
                                                                );
                                                            }}
                                                            value={
                                                                formValues.last_name
                                                            }
                                                            maxLength="45"
                                                            hideMaxChars={true}
                                                            error={
                                                                formErrors.last_name
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            id="last_name"
                                                            clearField={false}
                                                            required={true}
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                            disabled={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {!(
                                                isProfileVerified ==
                                                    "verified" &&
                                                formValues.document_provided_fields.includes(
                                                    "date_of_birth"
                                                )
                                            ) && (
                                                <div
                                                    className="formbox col-md-6"
                                                    id="date_of_birth"
                                                >
                                                    <InputDatePicker
                                                        value={
                                                            formValues.date_of_birth
                                                        }
                                                        onChange={(date) => {
                                                            //console.log(date);
                                                            if (
                                                                date &&
                                                                accountstatus !=
                                                                    "blocked"
                                                            ) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    date_of_birth:
                                                                        date,
                                                                });
                                                                validateFullForm(
                                                                    false,
                                                                    "date_of_birth",
                                                                    date,
                                                                    true
                                                                );
                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        label="Date of birth"
                                                        error={
                                                            formErrors.date_of_birth
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        onBlur={() => {
                                                            validateFullForm(
                                                                false,
                                                                "date_of_birth"
                                                            );
                                                        }}
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {!(
                                                isProfileVerified ==
                                                    "verified" &&
                                                formValues.document_provided_fields.includes(
                                                    "nationality"
                                                )
                                            ) && (
                                                <div
                                                    className={`formbox col-md-6 ${
                                                        !(
                                                            isProfileVerified ==
                                                                "verified" &&
                                                            formValues.document_provided_fields.includes(
                                                                "gender"
                                                            )
                                                        )
                                                            ? ""
                                                            : ""
                                                    }`}
                                                    id="nationality"
                                                >
                                                    <AutoCompleteSingleSelect
                                                        type={"nationality"}
                                                        id={
                                                            "nationality_others"
                                                        }
                                                        name="nationality_others"
                                                        title="Select levels of education..."
                                                        searchable={[
                                                            "Search for country level",
                                                            "No matching country level",
                                                        ]}
                                                        value={
                                                            formValues.nationality
                                                        }
                                                        onChange={(item) => {
                                                            if (
                                                                accountstatus !=
                                                                "blocked"
                                                            ) {
                                                                if (item) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            nationality:
                                                                                item.value,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            nationality:
                                                                                "",
                                                                        }
                                                                    );
                                                                }

                                                                validateFullForm(
                                                                    false,
                                                                    "nationality",
                                                                    item
                                                                        ? item.value
                                                                        : ""
                                                                );

                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        options={
                                                            nationalities_other
                                                        }
                                                        label={"Nationality"}
                                                        isClearable={true}
                                                        error={
                                                            formErrors.nationality
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div className="formbox col-md-6 mb-0">
                                                <div
                                                    className="profile-form-group"
                                                    id={"country"}
                                                >
                                                    <AutoCompleteSingleSelect
                                                        id={"country"}
                                                        type={"country"}
                                                        name="country"
                                                        title="Select levels of education..."
                                                        searchable={[
                                                            "Search for country level",
                                                            "No matching country level",
                                                        ]}
                                                        isSanctionedCountry={
                                                            displaySanctionedMessage
                                                                ? true
                                                                : false
                                                        }
                                                        options={countries}
                                                        value={
                                                            formValues.country
                                                        }
                                                        onChange={(item) => {
                                                            if (
                                                                accountstatus !=
                                                                "blocked"
                                                            ) {
                                                                if (item) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            country:
                                                                                item.value,
                                                                            region: "",
                                                                        }
                                                                    );
                                                                } else {
                                                                    setDisplaySanctionedMessage(
                                                                        false
                                                                    );
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            country:
                                                                                "",
                                                                            region: "",
                                                                        }
                                                                    );
                                                                }
                                                                validateFullForm(
                                                                    false,
                                                                    "country",
                                                                    item
                                                                        ? item.value
                                                                        : ""
                                                                );
                                                                setProfileChanged(
                                                                    true
                                                                );

                                                                var sanctioned_country = false;

                                                                sanctioned_countries.forEach(
                                                                    function (
                                                                        country
                                                                    ) {
                                                                        if (
                                                                            item.value ==
                                                                            country.value
                                                                        ) {
                                                                            sanctioned_country = true;
                                                                        }
                                                                    }
                                                                );
                                                                setDisplaySanctionedMessage(
                                                                    sanctioned_country
                                                                );
                                                            }
                                                        }}
                                                        label={
                                                            "Country of residency"
                                                        }
                                                        isClearable={true}
                                                        error={
                                                            formErrors.country
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {displaySanctionedMessage && (
                                                <div className="formbox col-md-12 mb-0 mt-20">
                                                    <div className="row">
                                                        <div className="formbox col-md-6 mb-0">
                                                            <div className="profile-form-group  sanctioned-info">
                                                                <ToastMessage
                                                                    closable={
                                                                        false
                                                                    }
                                                                    type={
                                                                        "warning"
                                                                    }
                                                                    message={
                                                                        <>
                                                                            Please
                                                                            be
                                                                            aware
                                                                            that,
                                                                            in
                                                                            compliance
                                                                            with
                                                                            both
                                                                            local
                                                                            and
                                                                            international
                                                                            regulations,
                                                                            we
                                                                            may
                                                                            not
                                                                            be
                                                                            able
                                                                            to
                                                                            process
                                                                            payouts
                                                                            to
                                                                            certain
                                                                            countries.
                                                                            If
                                                                            you
                                                                            need
                                                                            more
                                                                            information,
                                                                            please
                                                                            refer
                                                                            to
                                                                            our{" "}
                                                                            <UserQLink
                                                                                target="_blank"
                                                                                url="https://userq.com/terms-of-use"
                                                                            >
                                                                                Terms
                                                                                of
                                                                                use
                                                                            </UserQLink>
                                                                            .
                                                                        </>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {formValues.country ==
                                                "United Arab Emirates" && (
                                                <div className="formbox col-md-6 mb-0">
                                                    <div className="profile-form-group">
                                                        <DropdownComponent
                                                            options={emirates}
                                                            value={
                                                                formValues.region
                                                            }
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    region: item.value,
                                                                });

                                                                validateFullForm(
                                                                    false,
                                                                    "region",
                                                                    item
                                                                        ? item.value
                                                                        : "",
                                                                    true
                                                                );
                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={false}
                                                            label="Emirates"
                                                            error={
                                                                formErrors.region
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {formValues.country ==
                                                "Saudi Arabia" && (
                                                <div className="formbox col-md-6 mb-0 saudi_arabia_row">
                                                    <div className="profile-form-group">
                                                        <DropdownComponent
                                                            options={saregions}
                                                            value={
                                                                formValues.region
                                                            }
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    region: item.value,
                                                                });

                                                                validateFullForm(
                                                                    false,
                                                                    "region",
                                                                    item
                                                                        ? item.value
                                                                        : "",
                                                                    true
                                                                );

                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={false}
                                                            label="What's your region of residency?"
                                                            error={
                                                                formErrors.region
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            {!(
                                                isProfileVerified ==
                                                    "verified" &&
                                                formValues.document_provided_fields.includes(
                                                    "gender"
                                                )
                                            ) && (
                                                <div
                                                    className="formbox col-md-6 d-flex-radiobox mb-0 mt-20"
                                                    id="gender"
                                                >
                                                    <label className="bold">
                                                        Gender
                                                    </label>
                                                    <div className="d-flex-radiobtn bold">
                                                        <RadioButton
                                                            name="gender"
                                                            label="Male"
                                                            isChecked={
                                                                formValues.gender ==
                                                                "Male"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                if (
                                                                    accountstatus !=
                                                                    "blocked"
                                                                ) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            gender: "Male",
                                                                        }
                                                                    );
                                                                    setProfileChanged(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <RadioButton
                                                            name="gender"
                                                            label="Female"
                                                            isChecked={
                                                                formValues.gender ==
                                                                "Female"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                if (
                                                                    accountstatus !=
                                                                    "blocked"
                                                                ) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            gender: "Female",
                                                                        }
                                                                    );
                                                                    setProfileChanged(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Personal background
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div
                                                className="formbox col-md-6"
                                                id="relationship_status"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.relationship_status
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Relationship status"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    relationship_status:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    relationship_status:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                relationship_status:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Relationship status"
                                                        error={
                                                            formErrors.relationship_status
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6"
                                                id="religion"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.religion
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Religion"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    religion:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    religion:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                religion: null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Religion"
                                                        error={
                                                            formErrors.religion
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6"
                                                id="children_and_dependents"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.children_and_dependents
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Children and dependents"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    children_and_dependents:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    children_and_dependents:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                children_and_dependents:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Children and dependents"
                                                        error={
                                                            formErrors.children_and_dependents
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6"
                                                id="highest_education_level"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={
                                                            educationOptions
                                                        }
                                                        value={
                                                            formValues.highest_education_level
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    highest_education_level:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    highest_education_level:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "highest_education_level",
                                                                item
                                                                    ? item.value
                                                                    : ""
                                                            );

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Highest level of education"
                                                        error={
                                                            formErrors.highest_education_level
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="formbox col-md-6  mb-0">
                                                <div className="profile-form-group">
                                                    <label className="mb-0">
                                                        <Text
                                                            cssClasses={
                                                                "color-black"
                                                            }
                                                            type={"body-text-2"}
                                                            fontWeight="semi-bold-font"
                                                        >
                                                            Spoken language
                                                        </Text>
                                                    </label>

                                                    <div className="col-md-12 p-0 mt-12">
                                                        <div
                                                            className="formbox   d-flex-radiobox dropdown-label"
                                                            id="spoken_language"
                                                        >
                                                            <div className="d-flex-radiobtn mt-0">
                                                                {primaryLangs.map(
                                                                    function (
                                                                        lang
                                                                    ) {
                                                                        return (
                                                                            <CheckBox
                                                                                name="spoken_language"
                                                                                label={
                                                                                    lang.iso_name_english
                                                                                }
                                                                                checked={
                                                                                    formValues.language_ids.includes(
                                                                                        lang.id
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    if (
                                                                                        event
                                                                                            .currentTarget
                                                                                            .checked
                                                                                    ) {
                                                                                        formValues.language_ids.push(
                                                                                            lang.id
                                                                                        );
                                                                                        formValues.spoken_language.push(
                                                                                            lang.iso_name_english
                                                                                        );
                                                                                        setFormValues(
                                                                                            {
                                                                                                ...formValues,
                                                                                                spoken_language:
                                                                                                    formValues.spoken_language,
                                                                                                language_ids:
                                                                                                    formValues.language_ids,
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        formValues.spoken_language =
                                                                                            removeItemFromArray(
                                                                                                formValues.spoken_language,
                                                                                                lang.iso_name_english
                                                                                            );

                                                                                        formValues.language_ids =
                                                                                            removeItemFromArray(
                                                                                                formValues.language_ids,
                                                                                                lang.id
                                                                                            );
                                                                                        setFormValues(
                                                                                            {
                                                                                                ...formValues,
                                                                                                spoken_language:
                                                                                                    formValues.spoken_language,
                                                                                                language_ids:
                                                                                                    formValues.language_ids,
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                    setProfileChanged(
                                                                                        true
                                                                                    );

                                                                                    validateFullForm(
                                                                                        false,
                                                                                        "spoken_language"
                                                                                    );
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            {formErrors.spoken_language && (
                                                                <span
                                                                    className={
                                                                        formErrors.error_class
                                                                    }
                                                                >
                                                                    {
                                                                        formErrors.spoken_language
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="formbox col-md-12 p-0 employment-type-wrap mb-0 tester_search_lan_wrap"
                                                        id="language"
                                                    >
                                                        <MembersDropdownList
                                                            value={
                                                                formValues.language
                                                            }
                                                            options={langs}
                                                            onChange={(
                                                                items
                                                            ) => {
                                                                
                                                                let selected = [];

                                                                let selected_id = [];

                                                                primaryLangs.forEach(
                                                                    function (primaryLang) {
                                                                        formValues.language_ids.forEach(
                                                                            function (
                                                                                lang
                                                                            ) {
                                                                                if (
                                                                                    lang ==
                                                                                    primaryLang.id
                                                                                ) {
                                                                                    selected_id.push(
                                                                                        lang
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                    }
                                                                );

                                                                items.forEach(function (
                                                                    item
                                                                ) {
                                                                    selected.push(
                                                                        item
                                                                    );

                                                                    langs.forEach(function (
                                                                        lang
                                                                    ) {
                                                                        if (
                                                                            lang.value ==
                                                                            item
                                                                        ) {
                                                                            selected_id.push(
                                                                                lang.id
                                                                            );
                                                                        }
                                                                    });
                                                                });
                                                                setFormValues({
                                                                    ...formValues,
                                                                    languages: selected,
                                                                    language_ids:
                                                                        selected_id,
                                                                });
                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={true}
                                                            label="Other languages"
                                                            error={
                                                                formErrors.language
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {/*<SearchDropdownMultiple
                                                            options={langs}
                                                            value={
                                                                formValues.language
                                                            }
                                                            onChange={(
                                                                items
                                                            ) => {
                                                                if (
                                                                    accountstatus !=
                                                                    "blocked"
                                                                ) {
                                                                    let selected =
                                                                        [];

                                                                    let selected_id =
                                                                        [];

                                                                    primaryLangs.forEach(
                                                                        function (
                                                                            primaryLang
                                                                        ) {
                                                                            formValues.language_ids.forEach(
                                                                                function (
                                                                                    lang
                                                                                ) {
                                                                                    if (
                                                                                        lang ==
                                                                                        primaryLang.id
                                                                                    ) {
                                                                                        selected_id.push(
                                                                                            lang
                                                                                        );
                                                                                    }
                                                                                }
                                                                            );
                                                                        }
                                                                    );

                                                                    items.forEach(
                                                                        function (
                                                                            item
                                                                        ) {
                                                                            selected.push(
                                                                                item.label
                                                                            );

                                                                            langs.forEach(
                                                                                function (
                                                                                    lang
                                                                                ) {
                                                                                    if (
                                                                                        lang.value ==
                                                                                        item.value
                                                                                    ) {
                                                                                        selected_id.push(
                                                                                            lang.id
                                                                                        );
                                                                                    }
                                                                                }
                                                                            );
                                                                        }
                                                                    );
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            language:
                                                                                selected,
                                                                            language_ids:
                                                                                selected_id,
                                                                        }
                                                                    );

                                                                    validateFullForm(
                                                                        false,
                                                                        "language",
                                                                        selected,
                                                                        true
                                                                    );

                                                                    setProfileChanged(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                            isClearable={true}
                                                            label="Other languages"
                                                            error={
                                                                formErrors.language
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                        />
                                                        {formErrors.language && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class
                                                                }
                                                            >
                                                                {
                                                                    formErrors.language
                                                                }
                                                            </span>
                                                        )}*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formbox col-md-6 mb-0">
                                                <div className="profile-form-group">
                                                    <label className="mb-0">
                                                        <Text
                                                            cssClasses={
                                                                "color-black"
                                                            }
                                                            type={"body-text-2"}
                                                            fontWeight="semi-bold-font"
                                                        >
                                                            Driving license
                                                        </Text>
                                                    </label>

                                                    <div className="col-md-12 p-0 mt-12">
                                                        <div
                                                            className="formbox   d-flex-radiobox dropdown-label"
                                                            id="no-license"
                                                        >
                                                            <div className="d-flex-radiobtn mt-0">
                                                                <CheckBox
                                                                    name="nodrivinglicense"
                                                                    label={
                                                                        "I don't have driving license"
                                                                    }
                                                                    checked={
                                                                        !formValues.own_driving_license
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                driving_license:
                                                                                    [],
                                                                                own_driving_license:
                                                                                    !formValues.own_driving_license,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                driving_license:
                                                                                    null,
                                                                            }
                                                                        );

                                                                        setProfileChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="formbox col-md-12 p-0 employment-type-wrap mb-0 tester_search_lan_wrap"
                                                        id="driving_license"
                                                    >
                                                        <MembersDropdownList
                                                            value={
                                                                formValues.driving_license
                                                            }
                                                            options={lovCategories
                                                                .filter(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category.name ==
                                                                        "Driving license"
                                                                )
                                                                .flatMap(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category.active_lov
                                                                )
                                                                .filter(
                                                                    (
                                                                        active_lov
                                                                    ) =>
                                                                        active_lov.lov_value !=
                                                                        "I don't have driving license"
                                                                )
                                                                .map(
                                                                    (
                                                                        active_lov
                                                                    ) => {
                                                                        return {
                                                                            value: active_lov.id,
                                                                            label: active_lov.lov_value,
                                                                        };
                                                                    }
                                                                )}
                                                            onChange={(
                                                                selected
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    driving_license:
                                                                        selected,
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    driving_license:
                                                                        null,
                                                                });

                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={true}
                                                            label="Driving license"
                                                            error={
                                                                formErrors.driving_license
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : !formValues.own_driving_license
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formbox col-md-6 mb-0 mt-4">
                                                <div className="profile-form-group">
                                                    <label className="mb-0">
                                                        <Text
                                                            cssClasses={
                                                                "color-black"
                                                            }
                                                            type={"body-text-2"}
                                                            fontWeight="semi-bold-font"
                                                        >
                                                            Disability
                                                            conditions
                                                        </Text>
                                                    </label>

                                                    <div className="col-md-12 p-0 mt-12">
                                                        <div
                                                            className="formbox   d-flex-radiobox dropdown-label"
                                                            id="no-disability"
                                                        >
                                                            <div className="d-flex-radiobtn mt-0">
                                                                <CheckBox
                                                                    name="nodisabilitystatus"
                                                                    label={
                                                                        "I don't have any disabilities"
                                                                    }
                                                                    checked={
                                                                        !formValues.has_disability_status
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                disability_status:
                                                                                    [],
                                                                                has_disability_status:
                                                                                    !formValues.has_disability_status,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                disability_status:
                                                                                    null,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="formbox col-md-12 p-0 employment-type-wrap mb-0 tester_search_lan_wrap"
                                                        id="disability_status"
                                                    >
                                                        <MembersDropdownList
                                                            value={
                                                                formValues.disability_status
                                                            }
                                                            options={lovCategories
                                                                .filter(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category.name ==
                                                                        "Disability conditions"
                                                                )
                                                                .flatMap(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category.active_lov
                                                                )
                                                                .filter(
                                                                    (
                                                                        active_lov
                                                                    ) =>
                                                                        active_lov.lov_value !=
                                                                        "I don't have any disabilities"
                                                                )
                                                                .map(
                                                                    (
                                                                        active_lov
                                                                    ) => {
                                                                        return {
                                                                            value: active_lov.id,
                                                                            label: active_lov.lov_value,
                                                                        };
                                                                    }
                                                                )}
                                                            onChange={(
                                                                selected
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    disability_status:
                                                                        selected,
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    disability_status:
                                                                        null,
                                                                });

                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={true}
                                                            label="Disability conditions"
                                                            error={
                                                                formErrors.disability_status
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            readOnly={
                                                                !formValues.has_disability_status
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Contact details
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row change-email-row">
                                            <div className="col-md-6 formbox fullformbox">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                        <Input
                                                            label="Email address"
                                                            onChange={() => {}}
                                                            onClear={() => {}}
                                                            value={user.email}
                                                            error={
                                                                formErrors.email
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            id="email"
                                                            clearField={false}
                                                            required={true}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 formbox fullformbox">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            label="Change email"
                                                            onClick={() => {
                                                                if (
                                                                    formValues.provider
                                                                ) {
                                                                    showError(
                                                                        "You can’t change your email because your account is authenticated with " +
                                                                            formValues.provider +
                                                                            ". For more information, contact our support team.",
                                                                        "error"
                                                                    );
                                                                    return;
                                                                }
                                                                if (
                                                                    accountstatus !=
                                                                    "blocked"
                                                                ) {
                                                                    setChangeEmailModal(
                                                                        {
                                                                            ...changeEmailModal,
                                                                            open: true,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            state={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? "disabled"
                                                                    : "active"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row phoneno-field-row">
                                            <div className="col-md-6 formbox mb-0">
                                                <div
                                                    className="profile-form-group"
                                                    id="phone_number"
                                                >
                                                    <PhoneNumberInput
                                                        value={
                                                            formValues.phone_number
                                                        }
                                                        onBlur={() => {
                                                            validateFullForm(
                                                                false,
                                                                "phone_number"
                                                            );
                                                        }}
                                                        onCountryChange={() => {
                                                            setFormErrors({
                                                                ...formErrors,
                                                                phone_number:
                                                                    null,
                                                                error_class:
                                                                    "input_error",
                                                            });
                                                        }}
                                                        onChange={(value) => {
                                                            if (
                                                                accountstatus !=
                                                                "blocked"
                                                            ) {
                                                                var form_values =
                                                                    Object.assign(
                                                                        {},
                                                                        formValues
                                                                    );
                                                                if (
                                                                    value !=
                                                                    formValues.whatsapp_phone_number
                                                                ) {
                                                                    form_values =
                                                                        {
                                                                            ...form_values,
                                                                            whatsapp_checkbox: false,
                                                                        };
                                                                } else {
                                                                    form_values =
                                                                        {
                                                                            ...form_values,
                                                                            whatsapp_checkbox: true,
                                                                        };
                                                                }
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_number:
                                                                        null,
                                                                });
                                                                setFormValues({
                                                                    ...form_values,
                                                                    phone_number:
                                                                        value,
                                                                });
                                                                validateFullForm(
                                                                    false,
                                                                    "phone_number",
                                                                    value
                                                                        ? value
                                                                        : "",
                                                                    true
                                                                );
                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        error={
                                                            formErrors.phone_number
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <div className="whatsupplabel">
                                                        <CheckBox
                                                            checked={
                                                                formValues.whatsapp_checkbox
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    accountstatus !=
                                                                    "blocked"
                                                                ) {
                                                                    var form_values =
                                                                        Object.assign(
                                                                            {},
                                                                            formValues
                                                                        );

                                                                    if (
                                                                        event
                                                                            .currentTarget
                                                                            .checked
                                                                    ) {
                                                                        form_values =
                                                                            {
                                                                                ...form_values,
                                                                                whatsapp_phone_number:
                                                                                    form_values.phone_number,
                                                                                whatsapp_checkbox: true,
                                                                            };

                                                                        if (
                                                                            formValues.phone_number
                                                                        ) {
                                                                            if (
                                                                                isValidPhoneNumber(
                                                                                    "+" +
                                                                                        formValues.phone_number
                                                                                )
                                                                            ) {
                                                                                setFormErrors(
                                                                                    {
                                                                                        ...formErrors,
                                                                                        whatsapp_phone_number:
                                                                                            null,
                                                                                        error_class:
                                                                                            "input_error",
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                setFormErrors(
                                                                                    {
                                                                                        ...formErrors,
                                                                                        whatsapp_phone_number:
                                                                                            "WhatsApp number is not valid",
                                                                                        error_class:
                                                                                            "input_error",
                                                                                    }
                                                                                );
                                                                            }
                                                                        } else {
                                                                            setFormErrors(
                                                                                {
                                                                                    ...formErrors,
                                                                                    whatsapp_phone_number:
                                                                                        null,
                                                                                    error_class:
                                                                                        "input_error",
                                                                                }
                                                                            );
                                                                        }
                                                                    } else {
                                                                        form_values =
                                                                            {
                                                                                ...form_values,
                                                                                whatsapp_phone_number:
                                                                                    "",
                                                                                whatsapp_checkbox: false,
                                                                            };

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                whatsapp_phone_number:
                                                                                    null,
                                                                                error_class:
                                                                                    "input_error",
                                                                            }
                                                                        );
                                                                    }
                                                                    setFormValues(
                                                                        form_values
                                                                    );

                                                                    setProfileChanged(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                            id="whatsapp_number"
                                                            label={
                                                                "I use WhatsApp on this number"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*!formValues.whatsapp_checkbox &&*/}
                                            <div
                                                className="formbox col-md-6  mb-0"
                                                id="whatsapp_phone_number"
                                                style={{ position: "relative" }}
                                            >
                                                <div className="profile-form-group">
                                                    <PhoneNumberInput
                                                        value={
                                                            formValues.whatsapp_phone_number
                                                        }
                                                        onBlur={() => {
                                                            validateFullForm(
                                                                false,
                                                                "whatsapp_phone_number"
                                                            );
                                                        }}
                                                        label={
                                                            "WhatsApp number"
                                                        }
                                                        onCountryChange={(
                                                            country
                                                        ) => {
                                                            setWhatsAppCountry(
                                                                country
                                                            );

                                                            setFormErrors({
                                                                ...formErrors,
                                                                whatsapp_phone_number:
                                                                    null,
                                                                error_class:
                                                                    "input_error",
                                                            });
                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        onChange={(value) => {
                                                            if (
                                                                accountstatus !=
                                                                "blocked"
                                                            ) {
                                                                var form_values =
                                                                    Object.assign(
                                                                        {},
                                                                        formValues
                                                                    );

                                                                if (
                                                                    value !=
                                                                    formValues.phone_number
                                                                ) {
                                                                    form_values =
                                                                        {
                                                                            ...form_values,
                                                                            whatsapp_checkbox: false,
                                                                        };
                                                                } else {
                                                                    form_values =
                                                                        {
                                                                            ...form_values,
                                                                            whatsapp_checkbox: true,
                                                                        };
                                                                }

                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    whatsapp_phone_number:
                                                                        null,
                                                                });
                                                                setFormValues({
                                                                    ...form_values,
                                                                    whatsapp_phone_number:
                                                                        value,
                                                                });

                                                                validateFullForm(
                                                                    false,
                                                                    "whatsapp_phone_number",
                                                                    value
                                                                        ? value
                                                                        : "",
                                                                    true
                                                                );
                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        error={
                                                            formErrors.whatsapp_phone_number
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Household
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-6">
                                                <div
                                                    className="profile-form-group"
                                                    id="housing_status"
                                                >
                                                    <DropdownComponent
                                                        value={
                                                            formValues.housing_status
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Housing status"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    housing_status:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    housing_status:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                housing_status:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Housing status"
                                                        error={
                                                            formErrors.housing_status
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="formbox col-md-6">
                                                <div
                                                    className="profile-form-group"
                                                    id="living_arrangements"
                                                >
                                                    <DropdownComponent
                                                        value={
                                                            formValues.living_arrangements
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Living arrangements"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    living_arrangements:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    living_arrangements:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                living_arrangements:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Living arrangements"
                                                        error={
                                                            formErrors.living_arrangements
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox  col-md-6"
                                                id="income_slab"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={
                                                            incomelevelOptions
                                                        }
                                                        value={
                                                            formValues.income_slab
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    income_slab:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    income_slab:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "income_slab",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Monthly household income (USD)"
                                                        error={
                                                            formErrors.income_slab
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-12 mb-0"
                                                id="people"
                                            >
                                                <label
                                                    className={
                                                        "body-text body-text-2 semibold-font"
                                                    }
                                                >
                                                    How many people live in your
                                                    household?
                                                </label>
                                                <IncDecCounter
                                                    min={1}
                                                    max={10}
                                                    cssClasses="mb-0"
                                                    value={formValues.people}
                                                    onChange={(value) => {
                                                        if (
                                                            value !=
                                                            formValues.people
                                                        ) {
                                                            setFormValues({
                                                                ...formValues,
                                                                people: value,
                                                            });

                                                            validateFullForm(
                                                                false,
                                                                "people",
                                                                value
                                                                    ? value
                                                                    : "",
                                                                true
                                                            );

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                    readOnly={
                                                        accountstatus ==
                                                        "blocked"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {formErrors.people && (
                                                    <span
                                                        className={
                                                            formErrors.error_class
                                                        }
                                                    >
                                                        {formErrors.people}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Employment &amp; career
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div
                                                className="formbox col-md-6"
                                                id="employment"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={
                                                            employmentOptions
                                                        }
                                                        value={
                                                            formValues.employment
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                if (
                                                                    [
                                                                        "Student",
                                                                        "Unemployed",
                                                                        "Homemaker",
                                                                        "Retired",
                                                                    ].includes(
                                                                        item.value
                                                                    )
                                                                ) {
                                                                    setEmploymentFieldsReadOnly(
                                                                        true
                                                                    );
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            employment:
                                                                                item.value,
                                                                            department:
                                                                                "",
                                                                            industry:
                                                                                "",
                                                                            industry_other:
                                                                                "",
                                                                            department_other:
                                                                                "",
                                                                            career_stage:
                                                                                "",
                                                                            company_size:
                                                                                "",
                                                                            working_environment:
                                                                                "",
                                                                        }
                                                                    );
                                                                    setFormErrors(
                                                                        {
                                                                            ...formErrors,
                                                                            employment:
                                                                                null,
                                                                            department:
                                                                                null,
                                                                            department_other:
                                                                                null,
                                                                            industry:
                                                                                null,
                                                                            industry_other:
                                                                                null,
                                                                            career_stage:
                                                                                null,
                                                                            company_size:
                                                                                null,
                                                                            working_environment:
                                                                                null,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setEmploymentFieldsReadOnly(
                                                                        false
                                                                    );
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            employment:
                                                                                item.value,
                                                                        }
                                                                    );
                                                                    setFormErrors(
                                                                        {
                                                                            ...formErrors,
                                                                            employment:
                                                                                null,
                                                                        }
                                                                    );
                                                                }
                                                            } else {
                                                                setEmploymentFieldsReadOnly(
                                                                    false
                                                                );
                                                                setFormValues({
                                                                    ...formValues,
                                                                    employment:
                                                                        "",
                                                                });
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    employment:
                                                                        null,
                                                                });
                                                            }
                                                            //validateFullForm(false,"employment",item?item.value:'', true);
                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Your employment status"
                                                        error={
                                                            formErrors.employment
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="formbox col-md-6"
                                                id="career_stage"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.career_stage
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Career stage"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    career_stage:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    career_stage:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                career_stage:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Career stage"
                                                        error={
                                                            formErrors.career_stage
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : employmentFieldsReadOnly
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {formValues.industry !=
                                                "Other (please specify)" && (
                                                <div
                                                    className="formbox col-md-6"
                                                    id="industry"
                                                >
                                                    <div className="profile-form-group">
                                                        <DropdownComponent
                                                            options={
                                                                industryOptions
                                                            }
                                                            value={
                                                                formValues.industry
                                                            }
                                                            readOnly={
                                                                accountstatus ==
                                                                "blocked"
                                                                    ? true
                                                                    : employmentFieldsReadOnly
                                                            }
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                if (item) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            industry:
                                                                                item.value,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            industry:
                                                                                "",
                                                                        }
                                                                    );
                                                                }
                                                                validateFullForm(
                                                                    false,
                                                                    "industry",
                                                                    item
                                                                        ? item.value
                                                                        : "",
                                                                    true
                                                                );

                                                                setProfileChanged(
                                                                    true
                                                                );
                                                            }}
                                                            isClearable={true}
                                                            label="What industry do you work in?"
                                                            error={
                                                                formErrors.industry
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {formValues.industry ==
                                                "Other (please specify)" && (
                                                <div className="formbox  col-md-6">
                                                    <Input
                                                        ref={industryRef}
                                                        type="text"
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : employmentFieldsReadOnly
                                                        }
                                                        id="industry_other"
                                                        name="industry_other"
                                                        value={
                                                            formValues.industry_other
                                                        }
                                                        onChange={(e) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                industry_other:
                                                                    e.target
                                                                        .value,
                                                            });
                                                            validateFullForm(
                                                                false,
                                                                "industry_other",
                                                                e.target.value,
                                                                true
                                                            );
                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        label={
                                                            "Industry (Other)"
                                                        }
                                                        onClear={() => {
                                                            setFormValues(
                                                                (
                                                                    oldValues
                                                                ) => ({
                                                                    ...oldValues,
                                                                    industry_other:
                                                                        "",
                                                                    industry:
                                                                        "",
                                                                })
                                                            );
                                                        }}
                                                        required={true}
                                                        error={
                                                            formErrors.industry_other
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        onBlur={(e) => {
                                                            validateFullForm(
                                                                false,
                                                                "industry_other"
                                                            );

                                                            if (
                                                                !e.target.value
                                                            ) {
                                                                setFormValues(
                                                                    (
                                                                        oldValues
                                                                    ) => ({
                                                                        ...oldValues,
                                                                        industry_other:
                                                                            "",
                                                                        industry:
                                                                            "",
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            )}

                                            <div className="col-md-6 formbox mb-32">
                                                <div className="row">
                                                    {formValues.department !=
                                                        "Other (please specify)" && (
                                                        <div
                                                            className="formbox col-md-12 mb-0"
                                                            id="department"
                                                        >
                                                            <div className="profile-form-group">
                                                                <DropdownComponent
                                                                    options={
                                                                        departmentOptions
                                                                    }
                                                                    value={
                                                                        formValues.department
                                                                    }
                                                                    readOnly={
                                                                        accountstatus ==
                                                                        "blocked"
                                                                            ? true
                                                                            : employmentFieldsReadOnly
                                                                    }
                                                                    onChange={(
                                                                        item
                                                                    ) => {
                                                                        if (
                                                                            item
                                                                        ) {
                                                                            setFormValues(
                                                                                {
                                                                                    ...formValues,
                                                                                    department:
                                                                                        item.value,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setFormValues(
                                                                                {
                                                                                    ...formValues,
                                                                                    department:
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                        validateFullForm(
                                                                            false,
                                                                            "department",
                                                                            item
                                                                                ? item.value
                                                                                : "",
                                                                            true
                                                                        );

                                                                        setProfileChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    isClearable={
                                                                        true
                                                                    }
                                                                    label="What department do you work in?"
                                                                    error={
                                                                        formErrors.department
                                                                    }
                                                                    error_class={
                                                                        formErrors.error_class
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formValues.department ==
                                                        "Other (please specify)" && (
                                                        <div className="formbox  col-md-12 mb-0">
                                                            <Input
                                                                ref={
                                                                    departmentRef
                                                                }
                                                                type="text"
                                                                readOnly={
                                                                    accountstatus ==
                                                                    "blocked"
                                                                        ? true
                                                                        : employmentFieldsReadOnly
                                                                }
                                                                id="department_other"
                                                                name="department_other"
                                                                value={
                                                                    formValues.department_other
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            department_other:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    validateFullForm(
                                                                        false,
                                                                        "department_other",
                                                                        e.target
                                                                            .value,
                                                                        true
                                                                    );
                                                                    setProfileChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                label={
                                                                    "Department (Other)"
                                                                }
                                                                onClear={() => {
                                                                    setFormValues(
                                                                        (
                                                                            oldValues
                                                                        ) => ({
                                                                            ...oldValues,
                                                                            department_other:
                                                                                "",
                                                                            department:
                                                                                "",
                                                                        })
                                                                    );
                                                                }}
                                                                required={true}
                                                                error={
                                                                    formErrors.department_other
                                                                }
                                                                error_class={
                                                                    formErrors.error_class
                                                                }
                                                                onBlur={(e) => {
                                                                    validateFullForm(
                                                                        false,
                                                                        "department_other"
                                                                    );

                                                                    if (
                                                                        !e
                                                                            .target
                                                                            .value
                                                                    ) {
                                                                        setFormValues(
                                                                            (
                                                                                oldValues
                                                                            ) => ({
                                                                                ...oldValues,
                                                                                department_other:
                                                                                    "",
                                                                                department:
                                                                                    "",
                                                                            })
                                                                        );
                                                                    }
                                                                }}
                                                                disabled={
                                                                    accountstatus ==
                                                                    "blocked"
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6 mb-0"
                                                id="company_size"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.company_size
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Company size"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    company_size:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    company_size:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                company_size:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Company size"
                                                        error={
                                                            formErrors.company_size
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : employmentFieldsReadOnly
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6 mb-0"
                                                id="working_environment"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.working_environment
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Working environment"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    working_environment:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    working_environment:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                working_environment:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Working environment"
                                                        error={
                                                            formErrors.working_environment
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : employmentFieldsReadOnly
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Technology usage &amp; preferences
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div
                                                className="formbox col-md-6"
                                                id="hours_spend_online"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={hoursOptions}
                                                        value={
                                                            formValues.hours_spend_online
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    hours_spend_online:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    hours_spend_online:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "hours_spend_online",
                                                                item
                                                                    ? item.value
                                                                    : "",
                                                                true
                                                            );

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Daily screen time"
                                                        error={
                                                            formErrors.hours_spend_online
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="formbox col-md-6"
                                                id="tech_gadgets"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        options={gadgetsOptions}
                                                        value={
                                                            formValues.tech_gadgets
                                                        }
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    tech_gadgets:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    tech_gadgets:
                                                                        "",
                                                                });
                                                            }
                                                            validateFullForm(
                                                                false,
                                                                "tech_gadgets",
                                                                item
                                                                    ? item.value
                                                                    : ""
                                                            );

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Most used device"
                                                        error={
                                                            formErrors.tech_gadgets
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6"
                                                id="mobile_user_type"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.mobile_user_type
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Mobile user type"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                mobile_user_type:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                mobile_user_type:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Mobile user type"
                                                        error={
                                                            formErrors.mobile_user_type
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-6"
                                                id="desktop_user_type"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.desktop_user_type
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Desktop user type"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                desktop_user_type:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                desktop_user_type:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Desktop user type"
                                                        error={
                                                            formErrors.desktop_user_type
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="formbox col-md-12 full-width">
                                                <div
                                                    className="profile-form-group"
                                                    id="owned_devices"
                                                >
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.owned_devices
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Owned devices"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        iconLeft={"add"}
                                                        label={"Owned devices"}
                                                        no_results_message={
                                                            "Nothing is there"
                                                        }
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                owned_devices:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                owned_devices:
                                                                    null,
                                                            });
                                                        }}
                                                        error={
                                                            formErrors.owned_devices
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="formbox col-md-12 full-width mb-0"
                                                id="apps"
                                            >
                                                <MembersDropdownList
                                                    value={formValues.apps}
                                                    options={appsOptions.map(
                                                        (app) => {
                                                            return {
                                                                value: app,
                                                                label: app,
                                                            };
                                                        }
                                                    )}
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            apps: selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            apps: null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Which apps do you rely on every day?"
                                                    error={formErrors.apps}
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-form-repeat pb-0 profile-details">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Lifestyle
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div
                                                className="formbox col-md-6"
                                                id="shopping_online_frequency"
                                            >
                                                <div className="profile-form-group">
                                                    <DropdownComponent
                                                        value={
                                                            formValues.shopping_online_frequency
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Frequency of online shopping"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    shopping_online_frequency:
                                                                        item.value,
                                                                });
                                                            } else {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    shopping_online_frequency:
                                                                        "",
                                                                });
                                                            }
                                                            setFormErrors({
                                                                ...formErrors,
                                                                shopping_online_frequency:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        label="Frequency of online shopping"
                                                        error={
                                                            formErrors.shopping_online_frequency
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className="formbox col-md-12 full-width"
                                                id="hobbies"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.hobbies
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Hobbies"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        iconLeft={"add"}
                                                        label={"Hobbies"}
                                                        no_results_message={
                                                            "Nothing is there"
                                                        }
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                hobbies:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                hobbies: null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        error={
                                                            formErrors.hobbies
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className="formbox col-md-12 full-width"
                                                id="fitness_activities"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.fitness_activities
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Fitness activities"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        iconLeft={"add"}
                                                        label={
                                                            "Fitness activities"
                                                        }
                                                        no_results_message={
                                                            "Nothing is there"
                                                        }
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                fitness_activities:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                fitness_activities:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        error={
                                                            formErrors.fitness_activities
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="formbox  col-md-12 full-width mb-0">
                                                <div className="profile-form-group ">
                                                    <label className="mb-0">
                                                        <Text
                                                            cssClasses={
                                                                "color-black"
                                                            }
                                                            type={"body-text-2"}
                                                            fontWeight="semi-bold-font"
                                                        >
                                                            Pets ownership
                                                        </Text>
                                                    </label>

                                                    <div class="col-md-12 p-0 mt-12">
                                                        <div
                                                            className="formbox   d-flex-radiobox dropdown-label"
                                                            id="no-pet"
                                                        >
                                                            <div className="d-flex-radiobtn mt-0">
                                                                <CheckBox
                                                                    label="I don't have pets"
                                                                    checked={
                                                                        !formValues.own_pets
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                pet_ownership:
                                                                                    [],
                                                                                own_pets:
                                                                                    !formValues.own_pets,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                pet_ownership:
                                                                                    null,
                                                                            }
                                                                        );

                                                                        setProfileChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className="formbox  col-md-12 full-width"
                                                id="pet_ownership"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.pet_ownership
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Pets owned"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .filter(
                                                                (active_lov) =>
                                                                    active_lov.lov_value !=
                                                                    "I don't have pets"
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        iconLeft={"add"}
                                                        label={"Pets owned"}
                                                        no_results_message={
                                                            "Nothing is there"
                                                        }
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                pet_ownership:
                                                                    selected,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );

                                                            setFormErrors({
                                                                ...formErrors,
                                                                pet_ownership:
                                                                    null,
                                                            });
                                                        }}
                                                        error={
                                                            formErrors.pet_ownership
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : !formValues.own_pets
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-32">
                                            <div
                                                className="formbox col-md-6 d-flex-radiobox mb-0"
                                                id="vehicle_ownership"
                                            >
                                                <label className="bold">
                                                    <Text
                                                        type="body-text-2"
                                                        fontWeight="medium-font"
                                                    >
                                                        Do you own a vehicle
                                                    </Text>
                                                </label>
                                                <div
                                                    id="vehicle-box"
                                                    className="d-flex-radiobtn bold width-180"
                                                >
                                                    {lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Vehicle ownership"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return (
                                                                <RadioButton
                                                                    name="Vehicle ownership"
                                                                    label={
                                                                        active_lov.lov_value
                                                                    }
                                                                    value={
                                                                        active_lov.id
                                                                    }
                                                                    isChecked={
                                                                        formValues.vehicle_ownership ==
                                                                        active_lov.id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                vehicle_ownership:
                                                                                    active_lov.id,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                vehicle_ownership:
                                                                                    null,
                                                                            }
                                                                        );

                                                                        setProfileChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    key={
                                                                        "Vehicle ownership" +
                                                                        active_lov.id
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            {formErrors.vehicle_ownership && (
                                                <div className="col-md-12">
                                                    <span
                                                        className={
                                                            formErrors.error_class
                                                        }
                                                    >
                                                        {
                                                            formErrors.vehicle_ownership
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-form-repeat pb-0 profile-details">
                            <div className="account-subtitle mb-32">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Financial &amp; services information
                                </Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox form-group col-md-6">
                                                <div className="profile-form-group">
                                                    <label className="mb-0">
                                                        <Text
                                                            cssClasses={
                                                                "color-black "
                                                            }
                                                            type={"body-text-2"}
                                                            fontWeight="semi-bold-font"
                                                        >
                                                            Banking
                                                        </Text>
                                                    </label>
                                                    <div class="col-md-12 p-0 mt-12">
                                                        <div
                                                            class="formbox   d-flex-radiobox dropdown-label"
                                                            id="no-bank"
                                                        >
                                                            <div className="d-flex-radiobtn mt-0">
                                                                <CheckBox
                                                                    label="I don't have bank account"
                                                                    checked={
                                                                        !formValues.own_bank
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                banks: [],
                                                                                own_bank:
                                                                                    !formValues.own_bank,
                                                                            }
                                                                        );

                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                banks: null,
                                                                            }
                                                                        );

                                                                        setProfileChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className="formbox  col-md-12 full-width"
                                                id="banks"
                                            >
                                                <div className="profile-form-group">
                                                    <SelectCreateDropdownList
                                                        value={formValues.banks}
                                                        type="multiselect"
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormErrors({
                                                                ...formErrors,
                                                                banks: null,
                                                            });
                                                            setFormValues({
                                                                ...formValues,
                                                                banks: selected,
                                                            });
                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        label={`${"What bank do you have?"}`}
                                                        floatlabel={
                                                            "What bank do you have?"
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "What bank do you have?"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .filter(
                                                                (active_lov) =>
                                                                    active_lov.lov_value !=
                                                                    "I don't have bank account"
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                        lov_category_id:
                                                                            active_lov.lov_category_id,
                                                                    };
                                                                }
                                                            )
                                                            .sort((a, b) =>
                                                                (
                                                                    a.label ||
                                                                    ""
                                                                ).localeCompare(
                                                                    b.label ||
                                                                        ""
                                                                )
                                                            )}
                                                        onCreateOption={(
                                                            option
                                                        ) => {
                                                            if (!lovLoading) {
                                                                var lovCategory =
                                                                    lovCategories.filter(
                                                                        (
                                                                            category
                                                                        ) =>
                                                                            category.name ==
                                                                            "What bank do you have?"
                                                                    );

                                                                if (
                                                                    lovCategory.length >
                                                                    0
                                                                ) {
                                                                    createLovApi(
                                                                        option,
                                                                        lovCategory[0]
                                                                            .id
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        loading={lovLoading}
                                                        error={formErrors.banks}
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : !formValues.own_bank
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className="formbox  col-md-12 full-width"
                                                id="payment_methods_used"
                                            >
                                                <div className="profile-form-group">
                                                    <MembersDropdownList
                                                        value={
                                                            formValues.payment_methods_used
                                                        }
                                                        options={lovCategories
                                                            .filter(
                                                                (category) =>
                                                                    category.name ==
                                                                    "Payment methods used"
                                                            )
                                                            .flatMap(
                                                                (category) =>
                                                                    category.active_lov
                                                            )
                                                            .map(
                                                                (
                                                                    active_lov
                                                                ) => {
                                                                    return {
                                                                        value: active_lov.id,
                                                                        label: active_lov.lov_value,
                                                                    };
                                                                }
                                                            )}
                                                        label={
                                                            "Payment methods used"
                                                        }
                                                        no_results_message={
                                                            "Nothing is there"
                                                        }
                                                        onChange={(
                                                            selected
                                                        ) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                payment_methods_used:
                                                                    selected,
                                                            });
                                                            setFormErrors({
                                                                ...formErrors,
                                                                payment_methods_used:
                                                                    null,
                                                            });

                                                            setProfileChanged(
                                                                true
                                                            );
                                                        }}
                                                        error={
                                                            formErrors.payment_methods_used
                                                        }
                                                        error_class={
                                                            formErrors.error_class
                                                        }
                                                        readOnly={
                                                            accountstatus ==
                                                            "blocked"
                                                                ? true
                                                                : false
                                                        }
                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="formbox  col-md-12 mb-0">
                                                <div className="researcher-profile-form-btn">
                                                    {user.role == "Tester" &&
                                                        accountstatus !=
                                                            "blocked" && (
                                                            <div className="change-email-button">
                                                                {profileChanged ? (
                                                                    <Button
                                                                        type="primary"
                                                                        size="large"
                                                                        label="Save updates"
                                                                        state={
                                                                            checkBtnState()
                                                                                ? "disabled"
                                                                                : "active"
                                                                        }
                                                                        onClick={
                                                                            onSubmit
                                                                        }
                                                                        microLoading={
                                                                            formLoading
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Button
                                                                        type="primary"
                                                                        size="large"
                                                                        label="Save updates"
                                                                        state={
                                                                            formLoading
                                                                                ? "active"
                                                                                : "disabled"
                                                                        }
                                                                        onClick={() => {
                                                                            validateFullForm(
                                                                                true,
                                                                                null
                                                                            );
                                                                        }}
                                                                        microLoading={
                                                                            formLoading
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    {user.role == "Tester" &&
                                                        accountstatus ==
                                                            "blocked" && (
                                                            <div className="change-email-button">
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    label="Save updates"
                                                                    state={
                                                                        "disabled"
                                                                    }
                                                                    onClick={() => {}}
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="change-password-section profile-details">
                            <Text type="subtitle-2" fontWeight="semi-bold-font">
                                {formValues.provider &&
                                !formValues.password_set ? (
                                    <>Password settings</>
                                ) : (
                                    <>Password settings</>
                                )}
                            </Text>
                            {formValues && formValues.provider ? (
                                <>
                                    <div className="profile-connected-with-social mt-32">
                                        <div
                                            className={`profile-social-login-btn mb-20 d-flex align-items-center ${formValues.provider.toLowerCase()}-login`}
                                        >
                                            <div className="social-btn-icon">
                                                <Icon
                                                    value={formValues.provider}
                                                    size={"extralarge"}
                                                />
                                            </div>
                                            <div className="social-btn-info">
                                                <Text
                                                    type={"body-text-2"}
                                                    fontWeight={
                                                        "semi-bold-font"
                                                    }
                                                >
                                                    You are connected with{" "}
                                                    {formValues.provider}
                                                </Text>
                                                <Text
                                                    type={"body-text-2"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={"gray-color"}
                                                >
                                                    {formValues.email.includes(
                                                        "privaterelay"
                                                    )
                                                        ? "Email is hidden"
                                                        : formValues.email}
                                                </Text>
                                            </div>
                                        </div>
                                        {formValues.password_set ? (
                                            <>
                                                <Button
                                                    type="secondary"
                                                    microLoading={
                                                        socialDisconnectLoading
                                                    }
                                                    size="medium"
                                                    label={
                                                        "Disconnect " +
                                                        formValues.provider
                                                    }
                                                    onClick={() => {
                                                        disconnectSocialAccount(
                                                            formValues.provider
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    {formValues.password_set ? (
                                        <div className="profile-change-password-btn-wrap d-flex align-items-center mt-32">
                                            <Text
                                                type="body-text-2"
                                                fontWeight="medium-font"
                                                cssClasses={"mr-4"}
                                            >
                                                Password created on{" "}
                                                {moment(
                                                    formValues.password_updated_at,
                                                    "YYYY-MM-DD"
                                                ).format("DD/MM/YYYY")}
                                            </Text>
                                            <Button
                                                type="secondary"
                                                size="medium"
                                                label="Change password"
                                                onClick={() => {
                                                    if (
                                                        accountstatus !=
                                                        "blocked"
                                                    ) {
                                                        setOpenModal(true);
                                                    }
                                                }}
                                                iconLeft={
                                                    <Icon
                                                        value={"lock"}
                                                        size={"small"}
                                                    />
                                                }
                                                state={
                                                    accountstatus == "blocked"
                                                        ? "disabled"
                                                        : ""
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div className="mt-32 setapassword-info">
                                            <Text
                                                type="body-text-2"
                                                cssClasses={"mb-8"}
                                                fontWeight="medium-font"
                                            >
                                                Do you want to enable a
                                                password?
                                            </Text>
                                            <Text
                                                type="body-text-3"
                                                cssClasses={"gray-text"}
                                                fontWeight="medium-font"
                                            >
                                                Once you set a password for your
                                                registered email, you'll have
                                                the option to log in using
                                                either your{" "}
                                                {formValues.provider} account or
                                                your
                                                <br /> email and password.
                                            </Text>
                                            <Button
                                                type="secondary"
                                                cssclass={"mt-20"}
                                                size="medium"
                                                label="Set a password"
                                                onClick={() => {
                                                    setOpenSetPasswordModal(
                                                        true
                                                    );
                                                }}
                                                iconLeft={
                                                    <Icon
                                                        value={"lock"}
                                                        size={"small"}
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Button
                                    type="secondary"
                                    cssclass="mt-20"
                                    size="large"
                                    label="Change password"
                                    onClick={() => {
                                        if (accountstatus != "blocked") {
                                            setOpenModal(true);
                                        }
                                    }}
                                    iconLeft={
                                        <Icon value={"lock"} size={"small"} />
                                    }
                                    state={
                                        accountstatus == "blocked"
                                            ? "disabled"
                                            : ""
                                    }
                                />
                            )}
                        </div>

                        {user.role == "Tester" && (
                            <div className="delete-account-section mt-60">
                                <Text
                                    type="subtitle-2"
                                    fontWeight="semi-bold-font"
                                >
                                    Delete account
                                </Text>
                                <p className={`body-text body-text-2`}>
                                    Once you delete your account, all tests and
                                    unused credits will be removed.{" "}
                                    <b>This action cannot be undone.</b>
                                </p>
                                <Button
                                    type="secondary"
                                    size="medium"
                                    label="Delete account"
                                    onClick={() => {
                                        setConfirm({ open: true });
                                    }}
                                />
                            </div>
                        )}

                        <ChangePasswordModal
                            openModal={openModal}
                            closeModal={closeModal}
                        />
                        <SetPasswordModal
                            openModal={openSetPasswordModal}
                            closeModal={(user) => {
                                setOpenSetPasswordModal(false);

                                if (user) {
                                    let form_values = { ...formValues };

                                    form_values.password_updated_at =
                                        user.password_updated_at;

                                    form_values.password_set =
                                        user.password_set;

                                    setFormValues(form_values);
                                }
                            }}
                        />
                        <DeleteAccountModal
                            openModal={confirm.open}
                            confirm_title="Delete account"
                            confirm_title_class="text-danger"
                            cancel_btn_title={"No, keep"}
                            confirm_btn_title={"Yes, delete"}
                            close={() => {
                                setConfirm({ ...confirm, open: false });
                            }}
                            confirm={() => {
                                deleteUserAccount();
                            }}
                            btnLoading={accountLoading}
                        />

                        <ConfirmationModal
                            openModal={changeEmailModal.open}
                            confirm_title="Change email"
                            confirm_message="An email will be sent to current email address for you to verify."
                            confirm_btn_title={"Confirm"}
                            cancel_btn_title={"Cancel"}
                            close={() => {
                                setChangeEmailModal({
                                    ...changeEmailModal,
                                    open: false,
                                });
                            }}
                            confirm={() => {
                                sendChangeEmail();
                            }}
                            btnLoading={accountLoading}
                        />

                        <DocumentNotMatch
                            confirm_title={
                                "We need to update your profile to match your ID"
                            }
                            openModal={documentNotMatch.open}
                            close={() => {
                                setDocumentNotMatch({ open: false });
                            }}
                            confirmHandler={(status) => {
                                if (status) {
                                    navigate("/dashboard");
                                } else {
                                    getProfileData();
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </LayoutTester>
    );
}
