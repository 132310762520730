import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import { Input } from "../../themes/userq/Input/Input";
import { Icon } from "../../themes/userq/Icon/Icon.js";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox.js";
import { submitRecruitmentService } from "../../../services/recruitment.js";
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { showToast } from "../../../lib/helpers.js";
import { TextArea } from "../../themes/userq/TextArea/TextArea.js";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton.js";

export default function RecruitmentModal({ open, close }) {
    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({
        study: "",
        participants: "",
        demographics: "",
        requirements: "",
        terms: false,
    });
    const [formErrors, setFormErrors] = useState({
        study: null,
        participants: null,
        demographics: null,
        requirements: null,
        terms: null,
    });
    const [recruitmentLoading, setRecruitmentLoading] = useState(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        close();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(()=>{
        setFormValues({
            ...formValues,
            study: "",
            participants: "",
            demographics: "",
            requirements: "",
            terms: false,
        });
        setFormErrors({
            ...formErrors,
            study: null,
            participants: null,
            demographics: null,
            requirements: null,
            terms: null,
        });
    },[open])

    const checkBtnState = () => {
        var error = false;

        if (!formValues.study) {
            error = true;
        }
        if (!formValues.participants) {
            error = true;
        }
        if (!formValues.demographics) {
            error = true;
        }
        if (!formValues.terms) {
            error = true;
        }
        return error;
    };
    const validateFullForm = (focus) => {
        var error = false;

        var formErrors = {
            study: null,
            participants: null,
            demographics: null,
            requirements: null,
            terms: null,
        };
        var formElementId = null;

        if (!formValues.study) {
            error = true;

            formElementId = "study";

            formErrors = { ...formErrors, study: "Required field" };
        }
        if (!formValues.participants) {
            error = true;

            formElementId = "participants";

            formErrors = { ...formErrors, participants: "Required field" };
        }
        if (!formValues.demographics) {
            error = true;

            if (!formElementId) {
                formElementId = "demographics";
            }
            formErrors = { ...formErrors, demographics: "Required field" };
        }
        if (!formValues.terms) {
            error = true;

            if (!formElementId) {
                formElementId = "terms";
            }
            formErrors = { ...formErrors, terms: "Required field" };
        }
        if (error) {
            setFormErrors(formErrors);
        }
        if (focus) {
            if (formElementId != null) {
                document.getElementById(formElementId).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
        return error;
    };
    const submitForm = (e) => {
        e.preventDefault();
        var error = validateFullForm(true);

        if (!error) {
            setRecruitmentLoading(true);

            let token = ReactSession.get("token");

            submitRecruitmentService(formValues, token).then((response) => {
                if (response.success) {
                    setRecruitmentLoading(false);
                    showToast(response.message, "success");
                    close();
                } else {
                    setRecruitmentLoading(false);
                    showToast(response.message, "error");
                }
            });
        }
    };
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap welcom-userq-modal welcome_tester_userq_modal"
            close={close}
            id="recruitment-modal"
        >
            <Modal.Body ref={wrapperRef} className="">
                <>
                    <div className="modal-header-top">
                        <Text type={"h3"} fontWeight={"bold-font"}>
                            Recruit participants
                        </Text>
                        <button
                            type="button"
                            className="close"
                            onClick={close}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="col-md-12 p-0">
                        <div className="formbox mt-32">
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mb-20"}
                            >
                                Starting at $10 per response, you can recruit
                                participants from any demographic in the Middle
                                East for UserQ studies or your own research.
                                Simply fill out the form below, and our team
                                will review your request and respond within 2
                                business days.
                            </Text>
                            <Text
                                type={"body-text-1"}
                                fontWeight={"semi-bold-font"}
                                cssClasses={"mb-4x"}
                            >
                                Your research study
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mb-20"}
                            >
                                Please select whether you want to recruit
                                participants for a UserQ study or an external
                                research project (moderated or unmoderated).
                            </Text>
                            <div className="d-flex recruit-radio">
                                <RadioButton
                                    name="research"
                                    label="UserQ study"
                                    isChecked={
                                        formValues.study == "UserQ study"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        setFormValues({
                                            ...formValues,
                                            study: "UserQ study",
                                        });

                                        setFormErrors({
                                            ...formErrors,
                                            study: null,
                                        });
                                    }}
                                />
                                <RadioButton
                                    name="research"
                                    label="External study"
                                    isChecked={
                                        formValues.study == "External study"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        setFormValues({
                                            ...formValues,
                                            study: "External study",
                                        });

                                        setFormErrors({
                                            ...formErrors,
                                            study: null,
                                        });
                                    }}
                                    cssClasses={"ml-40"}
                                />
                            </div>
                            {formErrors.study && (
                                <span className={"input_error"}>
                                    {formErrors.study}
                                </span>
                            )}
                        </div>
                        <div className="formbox">
                            <Text
                                type={"body-text-1"}
                                fontWeight={"semi-bold-font"}
                                cssClasses={"mb-4x"}
                            >
                                Number of Participants
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mb-20"}
                            >
                                Please specify the number or range of
                                participants you wish to recruit
                            </Text>

                            <Input
                                autocomplete="off"
                                label="Number of Participants"
                                value={formValues.participants}
                                id="participants"
                                clearField={false}
                                required={true}
                                error={formErrors.participants}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        participants: e.target.value,
                                    });

                                    setFormErrors({
                                        ...formErrors,
                                        participants: null,
                                    });
                                }}
                            />
                        </div>
                        <div className="formbox">
                            <Text
                                type={"body-text-1"}
                                fontWeight={"semi-bold-font"}
                                cssClasses={"mb-4x"}
                            >
                                Demographics
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mb-20"}
                            >
                                Include details such as age, gender, residency,
                                nationality, employment status, income level,
                                and any other relevant demographic information.
                                Specify quotas and cohorts if applicable.
                            </Text>

                            <TextArea
                                autosize={true}
                                value={formValues.demographics}
                                type="text"
                                label={"Demographics"}
                                wrapClass="tester-text-area"
                                id={"Demographics"}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        demographics: e.target.value,
                                    });

                                    setFormErrors({
                                        ...formErrors,
                                        demographics: null,
                                    });
                                }}
                                autocomplete="off"
                                error={formErrors.demographics}
                            />
                        </div>
                        <div className="formbox">
                            <Text
                                type={"body-text-1"}
                                fontWeight={"semi-bold-font"}
                            >
                                Additional requirements
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mb-20"}
                            >
                                If you have any other specific criteria or
                                requirements, please include them here.
                            </Text>
                            <TextArea
                                autosize={true}
                                value={formValues.requirements}
                                type="text"
                                label={"Additional requirements (Optional)"}
                                wrapClass="tester-text-area"
                                id={"additional-requirement"}
                                required={false}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        requirements: e.target.value,
                                    });

                                    setFormErrors({
                                        ...formErrors,
                                        requirements: null,
                                    });
                                }}
                                error={formErrors.demographics}
                            />
                        </div>
                        <div className="formbox">
                            <div className="profile-form-group label-gray d-flex ">
                                <CheckBox
                                    checked={formValues.terms}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            terms: e.target.checked,
                                        });

                                        setFormErrors({
                                            ...formErrors,
                                            terms: null,
                                        });
                                    }}
                                    id="termsCheckBox"
                                    label={
                                        <>
                                            I’ve read the custom recruitment
                                            service{" "}
                                            <Link
                                                to={
                                                    "/r/custom-recruitment-terms-&-conditions"
                                                }
                                                target="_blank"
                                                className="link-text"
                                            >
                                                Terms & Conditions
                                            </Link>
                                        </>
                                    }
                                />
                            </div>
                            {formErrors.terms && (
                                <span className={"input_error"}>
                                    {formErrors.terms}
                                </span>
                            )}
                        </div>
                        <div className="formbox d-flex justify-content-center">
                            <Button
                                type="primary"
                                size="large"
                                label="Submit request"
                                state={!checkBtnState() ? "active" : "disabled"}
                                onClick={submitForm}
                                microLoading={recruitmentLoading}
                            />
                        </div>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
}
