import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import "./incomplete-profile-modal.css";

export default function IncompleteProfileModal({
    open,
    close,
    confirm,
    isLoading,
}) {
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap welcom-userq-modal welcome_tester_userq_modal"
            close={close}
            id="missinginfoPushmodal"
        >
            <Modal.Body className="">
                <>
                    <div className="congrats-modal-header-step-second">
                        <img
                            src={
                                process.env.REACT_APP_URL +
                                "img/missing-banner.png"
                            }
                        />
                    </div>
                    <div className="congrats-modal-text">
                        <div className="tester-id-verified-modal-data width-md">
                            <Text
                                type={"h3"}
                                fontWeight={"bold-font"}
                                cssClasses={"font-md-24"}
                            >
                                Profile update required
                            </Text>
                            <Text
                                type={"body-text-2"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text mt-20"}
                            >
                                We’re expanding the list of information required
                                on your profile to provide our clients with more
                                detailed demographics to choose from for their
                                studies.
                            </Text>
                            <Text
                                type={"body-text-1"}
                                fontWeight={"semi-bold-font"}
                                cssClasses={"mt-20 font-md-16"}
                            >
                                Why update your tester profile?
                            </Text>
                            <ul className="modal-center-list mt-12">
                                <li>
                                    Get matched with more test opportunities
                                </li>
                                <li>
                                    Improve your profile credibility with ID
                                    verification
                                </li>
                                <li>
                                    Continue enjoying seamless access to the
                                    platform
                                </li>
                            </ul>

                            <Text
                                type={"body-text-2"}
                                fontWeight={"bold-font"}
                                cssClasses={"gray-text mt-20 mb-0 "}
                            >
                                This update is mandatory to continue using UserQ
                                <span className="medium-font">
                                    {" "}
                                    and only takes a few minutes.
                                </span>
                            </Text>
                        </div>
                        <div className="d-flex justify-content-center mt-32">
                            <Button
                                size={"large"}
                                type="primary"
                                label="Update"
                                onClick={confirm}
                                microLoading={isLoading}
                            />
                        </div>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
}
