import {
    useNavigate,
    useParams,
    Link,
    useSearchParams,
} from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import {
    getTargetGroupOptions,
    getAgeDistribution,
    getTestTargetGroupService,
    updateTargetGroupService,
} from "../../../services/target_group.js";
import "react-multi-carousel/lib/styles.css";
import toast from "react-hot-toast";
import { Accordian } from "../../themes/userq/Accordion/Accordion";
import { FilterButtons } from "../../themes/userq/FilterButtons/FilterButtons";
import { ChartSliderCombo } from "../../themes/userq/ChartSliderCombo/ChartSliderCombo";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { Chip, Chips } from "../../themes/userq/Chips/Chips";
import { Text } from "../../themes/userq/Text/Text";
import { DropdownComponent } from "../../themes/userq/Dropdown/Dropdown";
import { MultiSelectDropdown } from "../../themes/userq/MultiSelectDropdown/MultiSelectDropdown";
import { IncDecCounter } from "../../themes/userq/IncDecCounter/IncDecCounter";
import { Button } from "../../themes/userq/Button/Button.js";
import { CountryDropdown } from "../../themes/userq/CountryDropdown/CountryDropdown";
import { Icon } from "../../themes/userq/Icon/Icon";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { LoadingIcon } from "../../themes/userq/Loader/loadingIcon";
import { ImageCheckBoxes } from "../../themes/userq/ImageCheckBox/ImageCheckBox";
import EstimatedPanel from "./components/estimated_panel.js";
import Skeleton from "react-loading-skeleton";
import { settings } from "../../../data/settings";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import { SearchDropdownMultiple } from "../../themes/userq/CountryDropdown/SearchDropdownMultiple";
import { settings as globalsettings } from "../../../data/settings";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import ScreeningQuestions from "./components/screening_questions";
import { RecruitmentSlider } from "../../themes/userq/RecruitmentSlider/RecruitmentSlider";
import {
    getTestOrderData,
    saveScreeningQuestions,
} from "../../../services/test";
import { Tooltip } from "../../themes/userq/Tooltip/Tooltip";
import { MultiSelecteDropdownList } from "../../themes/userq/MultiSelecteDropdownList/MultiSelecteDropdownList.js";
import OrderSummary from "./components/order_summary.js";
import RecruitmentModal from "../recruitment/recruitment_modal.js";

var jump_to_error = false;

export default function RecruitmentTargetGroup({
    test,
    order,
    formData,
    setFormData,
    setIsSaved,
    updateTargetGroupLoading,
    perParticipantsCreditCharge,
    saveTargetGroupApi,
    setStepperError,
    publishing_credits,
    test_publishing_fees,
    setShowSupportPanel,
}) {
    let { test_id } = useParams();

    const [formLoading, setFormLoading] = useState(false);

    const [initialLoad, setInitialLoad] = useState(true);

    const [validate_form, setValidateForm] = useState(false);

    let targetGroupController = useRef();

    let ageDistController = useRef();

    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);

    const [showAdvanceOptions, setShowAdvanceOptions] = useState(
        ReactSession.get("show_target_options_advance")
            ? ReactSession.get("show_target_options_advance")
            : 0
    );

    const [ageDistLoading, setAgeDisLoading] = useState(false);

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [clearSelectionsModal, setClearSelectionsModal] = useState({
        open: false,
    });
    const [clearQuestionModal, setClearQuestionModal] = useState({
        open: false,
    });

    const [employmentFieldsReadOnly, setEmploymentFieldsReadOnly] =
        useState(false);

    const [firstErrorId, setFirstErrorId] = useState(null);

    let firsterrorid = null;

    const [formErrors, setFormErrors] = useState({
        questions: null,
        error_class: null,
    });

    const [questionErrors, setQuestionErrors] = useState({
        question: null,
        options: [],
    });

    const [searchParams] = useSearchParams();

    const [formValues, setFormValues] = useState({
        panel_size: 0,
        participants_selected: 50,
        gender: "Any",
        min_age: 18,
        max_age: 90,
        nationalities: [],
        nationalities_match: {
            "East Asian": 0,
            "South Asian": 0,
            "Arab Expats": 0,
            Emirati: 0,
            "Saudi Arabian": 0,
            Europeans: 0,
        },
        countries: [],
        emirates: [],
        ksa: [],
        education: null,
        hours_spend_online: [],
        devices: [],
        apps: [],
        employment: [],
        industry: [],
        department: [],
        household_members_condition: null,
        people: 1,
        income_slab: [],
        is_demographics: 0,
        is_screening_questions: 0,
    });
    const [bar_data, setBarData] = useState([]);

    const [options, setOptions] = useState({
        step: 2,
        min_age: 18,
        max_age: 90,
        min_gender: 0,
        max_gender: 100,
        nationalities: [],
        group_nationalities: {
            "East Asian": [],
            "South Asian": [],
            "Arab Expats": [],
            Emirati: [],
            "Saudi Arabian": [],
            Europeans: [],
        },
        emirates: [],
        ksa: [],
        countries: [],
        education: null,
        hours_spend_online: [],
        devices: [],
        apps: [],
        employment: [],
        industry: [],
        department: [],
        min_income: 0,
        max_income: 1,
        income_levels: [],
        bar_data: [],
    });

    const regions = [
        { label: "Emirati", value: "Emirati" },
        { label: "Saudi Arabian", value: "Saudi Arabian" },
        { label: "East Asian", value: "East Asian" },
        { label: "Asian Expats", value: "South Asian" },
        { label: "Arab Expats", value: "Arab Expats" },
        { label: "Europeans", value: "Europeans" },
    ];
    let min_age = 18;
    let max_age = 90;

    let nationalities_match_checked = false;

    //ReactSession.get("accordians_recruitment_test") ? ReactSession.get("accordians_recruitment_test") :
    const [accordians, setAccordians] = useState({
        age_gender: false,
        residency: false,
        nationalities: false,
        education: false,
        technology: false,
        employment: false,
        earnings: false,
        requirements: false,
    });

    const [addQuestion, setAddQuestion] = useState(null);

    const [editQuestion, setEditQuestion] = useState(null);

    const [isRecruitmentModal, setIsRecruitmentModal] = useState(false);

    let screenController = useRef();

    useEffect(() => {
        if (validate_form) {
            jump_to_error = true;

            setValidateForm(false);

            if (searchParams.get("error") == "true") {
                setStepperError(true);

                validateFullForm();
            }
        }
    }, [validate_form]);
    const getAgeDistributionApi = (gender = null) => {
        if (ageDistController.current) {
            ageDistController.current.abort();
        }
        const controller = new AbortController();
        ageDistController.current = controller;

        let token = ReactSession.get("token");

        setAgeDisLoading(true);

        var formData = {};

        formData["step"] = options.step;

        formData["gender"] = gender ? gender : formValues.gender;

        formData["language"] = test.language;

        getAgeDistribution(
            formData,
            token,
            ageDistController.current?.signal
        ).then((response) => {
            setAgeDisLoading(false);

            if (response.success) {
                setBarData(response.bar_data);

                setTimeout(() => {
                    jump_to_error = true;

                    setValidateForm(true);
                }, 100);
            } else {
                toast.error(response.setErrorMessage);
            }
        });
    };

    const getTargetGroupOptionsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        targetGroupLoaded = true;

        //updatePageLoading(true);

        targetGroupLoaded = true;

        getTargetGroupOptions({}, token).then((response) => {
            if (response.success) {
                /*if (formValues.min_age < response.options.age_range.min) {
                    setFormValue("min_age", response.options.age_range.min);
                }
                if (formValues.max_age > response.options.age_range.max) {
                    setFormValue("max_age", response.options.age_range.max);
                }*/

                //saveTargetGroupApi({ ...formData, target_type: "target-group" }, 0);

                var other_countries = [];

                for (var i = 0; i < response.options.countries.length; i++) {
                    if (
                        response.options.countries[i].value !=
                            "United Arab Emirates" &&
                        response.options.countries[i].value != "Saudi Arabia"
                    ) {
                        other_countries.push(response.options.countries[i]);
                    }
                }
                let devices = [];

                response.options.devices.forEach(function (item) {
                    let device = item;

                    device.image = device.value + ".svg";

                    if (device.value == "Smartphone") {
                        device.image = "phone.svg";
                    }
                    devices.push(device);
                });

                setOptions({
                    ...options,
                    step: 2,
                    min_age: response.options.age_range.min,
                    max_age: response.options.age_range.max,
                    min_gender: 0,
                    max_gender: 100,
                    nationalities: response.options.nationalities,
                    group_nationalities: response.options.group_nationalities,
                    emirates: response.options.emirates,
                    ksa: response.options.ksa,
                    countries: other_countries,
                    education: response.options.education,
                    hours_spend_online: response.options.hours_spend_online,
                    devices: devices,
                    apps: response.options.apps,
                    employment: response.options.employment,
                    industry: response.options.industry,
                    department: response.options.department,
                    min_income: response.options.income.min.value,
                    max_income: response.options.income.max.value,
                    min_income_label: response.options.income.min.label,
                    max_income_label: response.options.income.max.label,
                    income_levels: response.options.income_levels,
                });

                setInitialLoad(true);
            } else {
                toast.error(response.setErrorMessage);
            }
            //setLoading(false);
        });
    };
    const getTestTargetGroupApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestTargetGroupService({ test_id: test.id }, token).then(
            (response) => {
                if (response.success) {
                    ///setFormValues({...formValues, panel_size:response.testers_count});

                    //updatePanelSize(response.testers_count);

                    saveTargetGroupApi(
                        { ...formData, target_type: "target-group" },
                        0
                    );

                    var target_group = response.target_group;

                    var ksa = [];

                    if (target_group.countries) {
                        options["ksa"].forEach(function (item) {
                            target_group.countries
                                .split(",")
                                .forEach(function (item_country) {
                                    if (item_country == item.value) {
                                        ksa.push(item.value);
                                    }
                                });
                        });
                    }
                    ksa = ksa.filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                    var emirates = [];
                    if (target_group.countries) {
                        options["emirates"].forEach(function (item) {
                            target_group.countries
                                .split(",")
                                .forEach(function (item_country) {
                                    if (item_country == item.value) {
                                        emirates.push(item.value);
                                    }
                                });
                        });
                    }
                    var other_countries = [];

                    if (target_group.countries) {
                        options["countries"].forEach(function (item) {
                            target_group.countries
                                .split(",")
                                .forEach(function (item_country) {
                                    if (item_country == item.value) {
                                        other_countries.push(item.value);
                                    }
                                });
                        });
                    }
                    let nationalities_match = {
                        ...formValues.nationalities_match,
                    };

                    if (response.target_group.nationalities) {
                        for (var key in options.group_nationalities) {
                            var exist = false;

                            options.group_nationalities[key].forEach(function (
                                item
                            ) {
                                var nationality_exist = false;

                                response.target_group.nationalities
                                    .split(",")
                                    .forEach(function (nationality) {
                                        if (nationality == item) {
                                            nationality_exist = true;
                                        }
                                    });
                                if (nationality_exist) {
                                    exist = true;
                                }
                            });
                            if (exist) {
                                nationalities_match[key] = 1;
                            } else {
                                nationalities_match[key] = 0;
                            }
                        }
                    }
                    //var members_condition = {"Exact number of members in household":"All", "Minimum number of members in household": "Minimum", "Maximum number of members in household":"Upto" };

                    var members_condition = {
                        All: "Exact number of members in household",
                        Minimum: "Minimum number of members in household",
                        Upto: "Maximum number of members in household",
                    };

                    min_age = response.target_group.min_age;

                    max_age = response.target_group.max_age;

                    var employmentArray = response.target_group.employment
                        ? response.target_group.employment.split(",")
                        : [];

                    var employmentFieldsDisabled = true;

                    employmentArray.forEach(function (option) {
                        if (
                            ![
                                "Student",
                                "Unemployed",
                                "Homemaker",
                                "Retired",
                            ].includes(option)
                        ) {
                            employmentFieldsDisabled = false;
                        }
                    });
                    if (employmentArray.length == 0) {
                        employmentFieldsDisabled = false;
                    }

                    if (employmentFieldsDisabled) {
                        setEmploymentFieldsReadOnly(true);
                    } else {
                        setEmploymentFieldsReadOnly(false);
                    }
                    var screening_questions = [];

                    response.screening_questions.forEach(function (item) {
                        var que = JSON.parse(item.questions);

                        var options = [];

                        var option_ids = [];

                        var screen_options = [];

                        item.options.forEach(function (option) {
                            options.push(option.option_value);
                            option_ids.push(option.id);
                            screen_options.push(option.screening);
                        });

                        screening_questions.push({
                            id: item.id,
                            questionType: item.question_type,
                            question: que.question,
                            options: options,
                            screeningOptions: screen_options,
                            options_ids: option_ids,
                        });
                    });

                    if (test.is_duplicate_test_accordian == 1) {
                        let new_accordians = { ...accordians };
                        if (
                            (response.target_group.gender_draft &&
                                response.target_group.gender_draft != "Any") ||
                            response.target_group.min_age_draft != 18 ||
                            response.target_group.max_age_draft != 90
                        ) {
                            new_accordians["age_gender"] = true;
                        }
                        if (
                            ksa.length > 0 ||
                            emirates.length > 0 ||
                            other_countries.length > 0
                        ) {
                            new_accordians["residency"] = true;
                        }
                        if (
                            ksa.length > 0 ||
                            emirates.length > 0 ||
                            other_countries.length > 0
                        ) {
                            new_accordians["residency"] = true;
                        }
                        if (
                            response.target_group.nationalities &&
                            response.target_group.nationalities.split(",")
                                .length > 0
                        ) {
                            new_accordians["nationalities"] = true;
                        }
                        if (response.target_group.education) {
                            new_accordians["education"] = true;
                        }
                        var hours_spend_online = response.target_group
                            .hours_spend_online
                            ? response.target_group.hours_spend_online.split(
                                  ","
                              )
                            : [];
                        var devices = response.target_group.devices
                            ? response.target_group.devices.split(",")
                            : [];
                        var apps = response.target_group.apps
                            ? response.target_group.apps.split(",")
                            : [];
                        if (
                            hours_spend_online.length > 0 ||
                            devices.length > 0 ||
                            apps.length > 0
                        ) {
                            new_accordians["technology"] = true;
                        }
                        var employment = response.target_group.employment
                            ? response.target_group.employment.split(",")
                            : [];
                        var industry = response.target_group.industry
                            ? response.target_group.industry.split("|")
                            : [];
                        var department = response.target_group.department
                            ? response.target_group.department.split(",")
                            : [];

                        if (
                            employment.length > 0 ||
                            industry.length > 0 ||
                            department.length > 0
                        ) {
                            new_accordians["employment"] = true;
                        }
                        var household_members_condition = response.target_group
                            .household_members_condition
                            ? members_condition[
                                  response.target_group
                                      .household_members_condition
                              ]
                            : null;
                        var people = response.target_group.people
                            ? response.target_group.people
                            : 1;
                        var income_slab = response.target_group.income_slab
                            ? response.target_group.income_slab.split("|")
                            : [];

                        if (
                            (household_members_condition && people > 0) ||
                            income_slab.length > 0
                        ) {
                            new_accordians["earnings"] = true;
                        }
                        setAccordians(new_accordians);
                    }

                    setFormValues({
                        ...formValues,
                        panel_size: response.testers_count,
                        gender: response.target_group.gender_draft,
                        min_age: response.target_group.min_age_draft,
                        max_age: response.target_group.max_age_draft,
                        target_group_id: response.target_group.id,
                        emirates_match: emirates.length > 0 ? 1 : 0,
                        emirates: emirates,
                        ksa_match: ksa.length > 0 ? 1 : 0,
                        ksa: ksa,
                        countries_match: other_countries.length > 0 ? 1 : 0,
                        countries: other_countries,
                        nationalities: response.target_group.nationalities
                            ? response.target_group.nationalities.split(",")
                            : [],
                        nationalities_match: nationalities_match,
                        education: response.target_group.education
                            ? response.target_group.education
                            : "",
                        hours_spend_online: response.target_group
                            .hours_spend_online
                            ? response.target_group.hours_spend_online.split(
                                  ","
                              )
                            : [],
                        devices: response.target_group.devices
                            ? response.target_group.devices.split(",")
                            : [],
                        apps: response.target_group.apps
                            ? response.target_group.apps.split(",")
                            : [],
                        employment: response.target_group.employment
                            ? response.target_group.employment.split(",")
                            : [],
                        industry: response.target_group.industry
                            ? response.target_group.industry.split("|")
                            : [],
                        department: response.target_group.department
                            ? response.target_group.department.split(",")
                            : [],
                        household_members_condition: response.target_group
                            .household_members_condition
                            ? members_condition[
                                  response.target_group
                                      .household_members_condition
                              ]
                            : null,
                        people: response.target_group.people
                            ? response.target_group.people
                            : 1,
                        income_slab: response.target_group.income_slab
                            ? response.target_group.income_slab.split("|")
                            : [],
                        is_screening_questions:
                            response.target_group.is_screening_questions,
                        is_demographics: response.target_group.is_demographics,
                        questions: screening_questions,
                    });
                    getAgeDistributionApi(response.target_group.gender);
                } else {
                    toast(
                        <ToastMessage
                            type={"error"}
                            message={response}
                            closable={true}
                            onClose={() => {
                                toast.dismiss();
                            }}
                        />,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: settings.toast_duration,
                        }
                    );
                    //toast.error(response.setErrorMessage)
                }
                setLoading(false);
            }
        );
    };
    let targetGroupLoaded = false;
    useEffect(() => {
        getTargetGroupOptionsApi();
    }, []);

    useEffect(() => {
        if (!targetGroupLoaded) {
            getTestTargetGroupApi();
        }
    }, [options]);
    const showError = (error) => {
        localStorage.setItem("stepper_error", error);
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={false}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                id: "error",
                className: "errortoast",
                position: "bottom-center",
                duration: globalsettings.toast_duration,
            }
        );
    };
    const getEstimatedPanelColor = () => {
        var multiplication_factor = 1;

        if (formValues.is_screening_questions == 1) {
            multiplication_factor = 1;
        }
        if (
            formValues.panel_size >=
            formData.participants_selected * (100 / 10) * multiplication_factor
        ) {
            //setStepperError(false);

            return "green";
        } else if (
            formValues.panel_size >=
                formData.participants_selected *
                    (100 / 15) *
                    multiplication_factor &&
            formValues.panel_size <
                formData.participants_selected *
                    (100 / 10) *
                    multiplication_factor
        ) {
            // setStepperError(false);
            return "yellow";
        } else if (
            formValues.panel_size >=
                formData.participants_selected *
                    (100 / 20) *
                    multiplication_factor &&
            formValues.panel_size <
                formData.participants_selected *
                    (100 / 15) *
                    multiplication_factor
        ) {
            // setStepperError(false);
            return "red";
        } else {
            return "danger";
        }
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const toggleCheck = (name, value) => {
        if (name == "age_match") {
            if (value !== 1) {
                setFormValue("min_age", options.min_age);
                setFormValue("max_age", options.max_age);
            }
        }
        if (name == "gender_match") {
            if (value !== 1) {
                setFormValue("male_perc", 50);
                setFormValue("female_perc", 50);
                setFormValue("gender", "Any");
            }
        }
        if (name == "nationality_match") {
            if (value !== 1) {
                setFormValue("nationalities", []);
            }
        }
        if (name == "residency_match") {
            if (value !== 1) {
                setFormValue("ksa", []);
                setFormValue("emirates", []);
                setFormValue("countries", []);

                setFormValue("ksa_match", 0);
                setFormValue("emirates_match", 0);
                setFormValue("countries_match", 0);
            }
        }
        if (name == "emirates_match") {
            if (value !== 1) {
                setFormValue("emirates", []);
                setFormValue("emirates_match", 0);
            }
        }
        if (name == "ksa_match") {
            if (value !== 1) {
                setFormValue("ksa", []);
                setFormValue("ksa_match", 0);
            }
        }
        if (name == "countries_match") {
            if (value !== 1) {
                setFormValue("countries", []);
                setFormValue("countries_match", 0);
            }
        }
        if (name == "education_match") {
            if (value !== 1) {
                setFormValue("education", []);
            }
        }
        if (name == "technology_match") {
            if (value !== 1) {
                setFormValue("hours_spend_online", null);

                setFormValue("devices", []);

                setFormValue("apps", []);
            }
        }
        if (name == "employment_match") {
            if (value !== 1) {
                setFormValue("employment", []);

                setFormValue("industry", []);

                setFormValue("department", []);
            }
        }
        if (name == "earnings_match") {
            if (value !== 1) {
                setFormValue("household_members_condition", "All");

                setFormValue("people", 1);

                setFormValue("income", 0);

                setFormValue("income_slab", []);
            }
        }
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const removeQuestions = () => {
        if (screenController.current) {
            screenController.current.abort();
        }
        const controller = new AbortController();

        screenController.current = controller;

        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test.id);

        setIsSaved({ status: "loading", message: "" });

        saveScreeningQuestions(
            data,
            token,
            screenController.current?.signal
        ).then((response) => {
            if (response.success) {
                setIsSaved({ status: "success", message: "" });
            } else {
                setIsSaved({ status: "error", message: response.message });
            }
        });
    };
    const resetSelections = (type) => {
        setClearSelectionsModal({ ...clearSelectionsModal, open: false });
        setClearQuestionModal({ ...clearQuestionModal, open: false });

        let formValuesLoc = { ...formValues };

        if (type === "all" || type === "demographics") {
            formValuesLoc["gender"] = "Any";

            formValuesLoc["min_age"] = 18;

            formValuesLoc["max_age"] = 90;

            formValuesLoc["countries"] = [];

            formValuesLoc["emirates"] = [];

            formValuesLoc["ksa"] = [];

            formValuesLoc["nationalities"] = [];

            formValuesLoc["education"] = null;

            formValuesLoc["hours_spend_online"] = [];

            formValuesLoc["devices"] = [];

            formValuesLoc["emirates_match"] = 0;

            formValuesLoc["ksa_match"] = 0;

            formValuesLoc["countries_match"] = 0;

            formValuesLoc["devices"] = [];

            formValuesLoc["apps"] = [];

            formValuesLoc["employment"] = [];

            formValuesLoc["industry"] = [];

            formValuesLoc["department"] = [];

            formValuesLoc["household_members_condition"] = null;

            formValuesLoc["is_demographics"] = 0;

            if (type === "all") {
                formValuesLoc["is_screening_questions"] = 0;
            }

            formValuesLoc["people"] = 1;

            formValuesLoc["income_slab"] = [];

            if (type === "all") {
                formValuesLoc["questions"] = [];
            }

            //formValuesLoc["participants_selected"] = 50;

            //console.log(formValuesLoc)

            if (type === "all") {
                setFormData({
                    ...formData,
                    participants_selected: 50,
                    clear_selections: 1,
                });
                var form_data = { ...formData, participants_selected: 50 };
            } else {
                setFormData({ ...formData, clear_selections: 1 });
                var form_data = { ...formData };
            }

            saveTargetGroupApi(form_data, 0);
        } else {
            formValuesLoc["questions"] = [];

            setFormData({ ...formData, clear_selections: 1 });
        }

        setFormValues({ ...formValuesLoc });

        updateTargetGroupApi({ ...formValuesLoc, clear_selections: 1 }, 0);
    };
    const updateTargetGroupApi = (form_data, shouldnavigate) => {
        resetFormErrors();

        if (targetGroupController.current) {
            targetGroupController.current.abort();

            updateTargetGroupLoading({ status: "", message: "" });
            //setFormLoading(false);
        }
        const controller = new AbortController();

        targetGroupController.current = controller;

        //if (!formLoading) {
        var error = false;

        if (!error) {
            if (!shouldnavigate) {
                updateTargetGroupLoading({ status: "loading", message: "" });
            }
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formDataApi = Object.assign(
                {},
                form_data ? form_data : formValues
            );

            ReactSession.set(
                "screening_question_data",
                form_data.is_screening_questions ? "yes" : "no"
            );

            formDataApi["target_group_id"] = form_data.target_group_id;

            formDataApi["gender"] = form_data.gender;

            formDataApi["min_age"] = form_data.min_age;

            formDataApi["max_age"] = form_data.max_age;

            formDataApi["countries"] = form_data.countries;

            formDataApi["emirates"] = form_data.emirates;

            formDataApi["ksa"] = form_data.ksa;

            formDataApi["nationalities"] = form_data.nationalities;

            formDataApi["education"] = form_data.education;

            formDataApi["hours_spend_online"] = form_data.hours_spend_online;

            formDataApi["devices"] = form_data.devices;

            formDataApi["apps"] = form_data.apps;

            formDataApi["employment"] = form_data.employment;

            formDataApi["industry"] = form_data.industry;

            var members_condition = {
                "Exact number of members in household": "All",
                "Minimum number of members in household": "Minimum",
                "Maximum number of members in household": "Upto",
            };

            formDataApi["household_members_condition"] =
                members_condition[form_data.household_members_condition];

            formDataApi["people"] = form_data.people;

            formDataApi["income_slab"] = form_data.income_slab;

            updateTargetGroupService(
                formDataApi,
                token,
                targetGroupController.current?.signal
            ).then((response) => {
                //

                //updateTargetGroupLoading(false);

                if (typeof response == "undefined") {
                    return;
                }
                setFormLoading(false);
                if (response.success) {
                    updateTargetGroupLoading({
                        status: "success",
                        message: "",
                    });

                    //updatePanelSize(response.testers_count);

                    setFormValues({
                        ...form_data,
                        panel_size: response.testers_count,
                    });

                    if (!shouldnavigate || shouldnavigate == 0) {
                    } else {
                        /*toast(
                            <ToastMessage type={"success"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );*/

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: "create_test_step4",
                            type: test.methodology,
                            testers: formData.participants_selected,
                            option: "recruit",
                            userID: ReactSession.get("user")
                                ? ReactSession.get("user").user_id
                                : "",
                        });

                        navigate("/r/test/" + test.id + "/publish");
                    }

                    // setSuccessMessage(response.message);
                } else {
                    updateTargetGroupLoading({
                        status: "error",
                        message: response.message,
                    });
                    toast(
                        <ToastMessage
                            type={"error"}
                            message={response.message}
                            closable={true}
                            onClose={() => {
                                toast.dismiss();
                            }}
                        />,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: settings.toast_duration,
                        }
                    );
                    // setErrorMessage(response.message);
                }
            });
        }
        //}
    };
    const renderNationalityCategories = () => {
        return (
            <ul className="nationalities-categories">
                {regions.map(function (item) {
                    var checked = true;

                    var nationalities = formValues.nationalities;

                    let all_regions =
                        options["group_nationalities"][item.value];

                    if (nationalities.length == 0) {
                        checked = false;
                    }

                    all_regions.every(function (item_3) {
                        var exist = false;
                        nationalities.forEach(function (item_2) {
                            if (item_2 == item_3) {
                                //console.log(item_2+"==="+item_3)
                                exist = true;
                            }
                        });
                        if (!exist) {
                            checked = false;

                            return false;
                        } else {
                            return true;
                        }
                    });
                    return (
                        <li key={"liregions" + item.value}>
                            <>
                                <CheckBox
                                    value={1}
                                    checked={checked}
                                    label={item.label}
                                    onChange={(event) => {
                                        //event.preventDefault();

                                        let formValuesLoc = Object.assign(
                                            {},
                                            formValues
                                        );

                                        if (event.currentTarget.checked) {
                                            let nationalities_match =
                                                Object.assign(
                                                    {},
                                                    formValues.nationalities_match
                                                );

                                            nationalities_match[item.value] = 1;

                                            formValuesLoc[
                                                "nationalities_match"
                                            ] = nationalities_match;
                                        } else {
                                            let nationalities_match =
                                                Object.assign(
                                                    {},
                                                    formValues.nationalities_match
                                                );

                                            nationalities_match[item.value] = 0;

                                            formValuesLoc[
                                                "nationalities_match"
                                            ] = nationalities_match;
                                        }
                                        let regions = Object.assign(
                                            [],
                                            formValues.nationalities
                                        );

                                        let all_regions =
                                            options["group_nationalities"][
                                                item.value
                                            ];

                                        let new_regions = [];

                                        all_regions.forEach(function (item) {
                                            var exist = false;

                                            regions.forEach(function (
                                                nationality
                                            ) {
                                                if (item == nationality) {
                                                    exist = true;
                                                }
                                            });
                                            if (!exist) {
                                                regions.push(item);
                                            }
                                        });
                                        regions.forEach(function (nationality) {
                                            var exist = false;

                                            all_regions.forEach(function (
                                                item
                                            ) {
                                                if (item == nationality) {
                                                    exist = true;
                                                }
                                            });
                                            if (!exist) {
                                                new_regions.push(nationality);
                                            }
                                        });

                                        if (event.currentTarget.checked) {
                                            setFormValues({
                                                ...formValuesLoc,
                                                nationalities: regions,
                                            });

                                            updateTargetGroupApi({
                                                ...formValuesLoc,
                                                nationalities: regions,
                                            });
                                        } else {
                                            setFormValues({
                                                ...formValuesLoc,
                                                nationalities: new_regions,
                                            });

                                            updateTargetGroupApi({
                                                ...formValuesLoc,
                                                nationalities: new_regions,
                                            });
                                        }
                                    }}
                                    id={"nationality-checkbox" + item.label}
                                />

                                {/*options.group_nationalities[item.value].length > 1 && formValues.nationalities_match[item.value] == 1 &&
                                <Chips>
                                    {options.group_nationalities[item.value].map(function (nationality) {

                                        return (
                                            <>

                                                <Chip key={"nationality-" + nationality}
                                                    title={nationality}
                                                    value={nationality}
                                                    type={formValues.nationalities.includes(nationality) ? "selected" : "default"}
                                                    onClick={(value) => {

                                                        let nationalities = Object.assign([], formValues.nationalities);

                                                        let exist = false;

                                                        let nationalitiesNew = [];

                                                        nationalities.forEach(function (item) {
                                                            if (item == value) {
                                                                exist = true;
                                                            } else {
                                                                nationalitiesNew.push(item);
                                                            }
                                                        });


                                                        if (!exist) {

                                                            nationalities.push(value);
                                                            setFormValues({ ...formValues, nationalities: nationalities });

                                                            updateTargetGroupApi({ ...formValues, nationalities: nationalities }, 0);
                                                        } else {
                                                            setFormValues({ ...formValues, nationalities: nationalitiesNew });

                                                            updateTargetGroupApi({ ...formValues, nationalities: nationalitiesNew }, 0);

                                                        }
                                                        var all_unchecked = true;

                                                        options.group_nationalities[item.value].forEach(function (item) {
                                                            nationalitiesNew.forEach(function (regionLoc) {
                                                                if (item == regionLoc) {
                                                                    all_unchecked = false;
                                                                }
                                                            })
                                                        });

                                                        if (all_unchecked) {
                                                            let nationalities_match = Object.assign({}, formValues.nationalities_match);
                                                            nationalities_match[item.value] = 0;
                                                            setFormValues({ ...formValues, nationalities_match: nationalities_match })
                                                        }
                                                    }}
                                                />
                                            </>
                                        );

                                    })}

                                </Chips>
                            */}
                            </>
                        </li>
                    );
                })}
            </ul>
        );
    };
    const checkBtnState = () => {
        if (getEstimatedPanelColor() == "danger") {
            return false;
        } else if (
            formValues.is_screening_questions == 1 &&
            formValues.questions.length == 0
        ) {
            return false;
        } else {
            var add_question_error = false;

            if (addQuestion) {
                if (!addQuestion.question) {
                    add_question_error = true;
                } else {
                    let nulloptioncount = 0;
                    addQuestion.options.forEach((element) => {
                        if (element !== "") {
                            nulloptioncount = nulloptioncount + 1;
                        }
                    });
                    for (var i = 0; i < addQuestion.options.length; i++) {
                        if (!addQuestion.options[i]) {
                            add_question_error = true;
                            break;
                        }
                    }

                    const notEmptyOptions = [...addQuestion.options];
                    const notEmptyScreeningOptions = [
                        ...addQuestion.screeningOptions,
                    ];
                    for (var i = 0; i < notEmptyOptions.length; i++) {
                        if (
                            notEmptyOptions[i] == "" ||
                            notEmptyOptions[i] == " "
                        ) {
                            notEmptyOptions.splice(i, 1);
                            notEmptyScreeningOptions.splice(i, 1);
                            i--;
                        }
                    }
                    var not_fit_count = 0;

                    var fit_count = 0;

                    notEmptyScreeningOptions.forEach(function (item) {
                        if (item == "Not a fit (end test)") {
                            not_fit_count++;
                        }
                        if (item == "Good fit (continue)") {
                            fit_count++;
                        }
                    });

                    if (nulloptioncount !== 0) {
                        if (nulloptioncount < 2) {
                            add_question_error = true;
                        } else if (fit_count == 0) {
                            add_question_error = true;
                        } else if (not_fit_count == 0) {
                            add_question_error = true;
                        }
                    } else {
                        add_question_error = true;
                    }
                }
                if (add_question_error) {
                    return false;
                }
            }
        }

        return true;
    };
    const validateFullForm = () => {
        var error = false;

        var required_participants_error = false;

        var form_errors = { ...formErrors, questions: null };

        var question_errors = {
            ...questionErrors,
            question: null,
            options: [],
        };

        firsterrorid = null;

        setFirstErrorId(null);

        var add_question_error = false;

        if (addQuestion) {
            if (!addQuestion.question) {
                add_question_error = true;

                if (firsterrorid == null) {
                    firsterrorid = "question";
                }
                question_errors = {
                    ...question_errors,
                    question: "Required field",
                };
            } else {
                let nulloptioncount = 0;

                var option_errors = [];

                var option_error = false;

                addQuestion.options.forEach((item, index) => {
                    option_errors.push(null);
                });

                addQuestion.options.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                for (var i = 0; i < addQuestion.options.length; i++) {
                    if (!addQuestion.options[i]) {
                        option_errors[i] = "Required field";
                        document
                            .getElementById("question-option-" + i)
                            .scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        option_error = true;
                        add_question_error = true;
                        break;
                    }
                }
                question_errors.options = option_errors;

                const notEmptyOptions = [...addQuestion.options];
                const notEmptyScreeningOptions = [
                    ...addQuestion.screeningOptions,
                ];
                for (var i = 0; i < notEmptyOptions.length; i++) {
                    if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                        notEmptyOptions.splice(i, 1);
                        notEmptyScreeningOptions.splice(i, 1);
                        i--;
                    }
                }
                var not_fit_count = 0;

                var fit_count = 0;

                notEmptyScreeningOptions.forEach(function (item) {
                    if (item == "Not a fit (end test)") {
                        not_fit_count++;
                    }
                    if (item == "Good fit (continue)") {
                        fit_count++;
                    }
                });
                if (!option_error) {
                    if (nulloptioncount !== 0) {
                        if (nulloptioncount < 2) {
                            add_question_error = true;

                            showError(
                                "Single choice questions require at least two options."
                            );
                        } else if (fit_count == 0) {
                            add_question_error = true;

                            showError(
                                "Please include a response that qualifies for the good fit logic."
                            );
                        } else if (not_fit_count == 0) {
                            add_question_error = true;

                            showError(
                                "Please include a response that qualifies for the no fit logic."
                            );
                        }
                    } else {
                        add_question_error = true;

                        showError(
                            "Your answer is empty - please enter option."
                        );
                    }
                }
            }
            if (add_question_error) {
                error = true;
            }
        } else if (
            !add_question_error &&
            formValues.is_screening_questions == 1 &&
            formValues.questions.length == 0
        ) {
            error = true;

            if (firsterrorid == null) {
                firsterrorid = "question_error";
            }
            form_errors = {
                ...form_errors,
                questions: "Atleast one screening question is required",
            };
        } else if (getEstimatedPanelColor() == "danger") {
            error = true;

            required_participants_error = true;

            showError(
                <span className={"w-100"}>
                    Uh oh! Try again by reducing the number of participants or
                    changing the selected criteria. For additional support,
                    <a
                        href={"mailto:support@userq.com"}
                        className={"font-weight-600"}
                        style={{ fontSize: "16px" }}
                    >
                        get in touch with us.
                    </a>
                </span>
            );
        }

        if (error && firsterrorid != null && jump_to_error) {
            if (document.getElementById(firsterrorid)) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        if (error && !add_question_error) {
            if (required_participants_error) {
                localStorage.setItem(
                    "stepper_error",
                    "Uh oh! Try again by reducing the number of participants or changing the selected criteria."
                );

                if (jump_to_error) {
                    setStepperError(true);
                    //showError("Some required information for the recruitment step is missing or incomplete. Please review and try again.");
                }
            } else {
                localStorage.setItem(
                    "stepper_error",
                    "Some required information for the recruitment step is missing or incomplete. Please review and try again."
                );

                if (jump_to_error) {
                    setStepperError(true);
                    showError(
                        "Some required information for the recruitment step is missing or incomplete. Please review and try again."
                    );
                }
            }
        } else {
            setStepperError(false);
            localStorage.setItem("stepper_error", null);
        }
        setFormErrors(form_errors);
        console.log(question_errors);

        setQuestionErrors(question_errors);

        return error;
    };
    const buildTargetGroupStep = () => {
        var error = validateFullForm();

        jump_to_error = true;

        if (!error) {
            updateTargetGroupApi(formValues, 1);
        }
    };
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const panelRef = useRef(null);
    const orderRef = useRef(null);

    const togglePanel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPanelVisible((prevIsPanelVisible) => !prevIsPanelVisible);
    };

    const handleClickOutside = (event) => {
        if (
            panelRef.current &&
            !panelRef.current.contains(event.target) &&
            orderRef.current &&
            !orderRef.current.contains(event.target) &&
            !event.target.classList.contains("panel-info-right")
        ) {
            setIsPanelVisible(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
            {isLoading && (
                <div className="test-section-loader">
                    <LoadingIcon />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="three-column-layout-center recruit-layout-center recuruitment-mid-sec">
                        {/* /* side panel for tab and mobile start here --> */}
                        <div className="mobile-panel-hold position-relative">
                            <div className="estimatepanel-top-info d-flex align-items-center justify-content-between">
                                <div className="panel-info-left d-flex align-items-center">
                                    <div className="tooltip-deviceinfo tooltip-estimatedpanel">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                        >
                                            Estimated panel size
                                        </Text>
                                        <span>
                                            <Icon
                                                value={"information"}
                                                colorClass={"gray-600-svg"}
                                                size="medium"
                                            />
                                            <Tooltip
                                                type={"bottom"}
                                                data={
                                                    <>
                                                        This displays an
                                                        approximate count of the
                                                        number of people in the
                                                        UserQ panel, based on
                                                        the recruitment criteria
                                                        you have selected, along
                                                        with the estimated
                                                        response time.
                                                    </>
                                                }
                                                className="tooltipChart"
                                            />
                                        </span>
                                    </div>
                                    <EstimatedPanel
                                        panel_color={getEstimatedPanelColor()}
                                        panel_size={formValues.panel_size}
                                    />
                                </div>
                                <div className="panel-info-right">
                                    <div
                                        className="clickable-wrap"
                                        onClick={togglePanel}
                                        ref={orderRef}
                                    >
                                        &nbsp;{" "}
                                    </div>
                                    <div
                                        className={`order-summary-link d-flex align-items-center cursor-pointer ${
                                            isPanelVisible ? "active" : ""
                                        }`}
                                    >
                                        <Text
                                            cssClasses={"gray-color"}
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                        >
                                            Order Summary
                                        </Text>
                                        <Icon
                                            value={
                                                isPanelVisible
                                                    ? "Chevron-Up"
                                                    : "Chevron"
                                            }
                                            size={"medium"}
                                            hover={"true"}
                                        />
                                    </div>
                                </div>
                            </div>

                            {isPanelVisible && (
                                <div
                                    className="estimated-panel-side-bar pricepnl-small"
                                    ref={panelRef}
                                >
                                    {formLoading && (
                                        <div className="create-test-preview-area estimated-panel-size">
                                            <div className="create-test-preview-area-inner skeleton-loading-estimated-panel">
                                                <>
                                                    <Skeleton
                                                        width={246}
                                                        height={29}
                                                    />

                                                    <div className="estimatepanel-panel-mid-area">
                                                        <div
                                                            className={`estimate-progress-wrap `}
                                                        >
                                                            <h1 className="heading h1">
                                                                <Skeleton
                                                                    width={50}
                                                                    height={30}
                                                                />
                                                            </h1>
                                                            <Skeleton
                                                                width={"100%"}
                                                                height={5}
                                                            />
                                                            <div className="estimate-responses-wrap">
                                                                <Skeleton
                                                                    width={"20"}
                                                                    height={5}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        <Skeleton
                                                            width={"100%"}
                                                            height={16}
                                                        />
                                                    </Text>

                                                    <div className="estimate-count-area">
                                                        <Skeleton
                                                            width={200}
                                                            height={45}
                                                        />
                                                    </div>

                                                    <div className="estimate-panel-footer">
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            <Skeleton
                                                                width={140}
                                                                height={16}
                                                            />
                                                        </Text>
                                                        <Text
                                                            type={"body-text-2"}
                                                            fontWeight={
                                                                "semi-bold-font"
                                                            }
                                                        >
                                                            <Skeleton
                                                                width={66}
                                                                height={16}
                                                            />
                                                        </Text>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    )}
                                    {!formLoading && (
                                        <div className="create-test-preview-area estimated-panel-size">
                                            <div className="create-test-preview-area-inner">
                                                <>
                                                    <div className="tooltip-deviceinfo tooltip-estimatedpanel">
                                                        <Text type={"h4"}>
                                                            Estimated panel size
                                                        </Text>

                                                        <span>
                                                            <Icon
                                                                value={
                                                                    "information"
                                                                }
                                                                colorClass={
                                                                    "gray-600-svg"
                                                                }
                                                                size="medium"
                                                            />
                                                            <Tooltip
                                                                type={"bottom"}
                                                                data={
                                                                    <>
                                                                        This
                                                                        displays
                                                                        an
                                                                        approximate
                                                                        count of
                                                                        the
                                                                        number
                                                                        of
                                                                        people
                                                                        in the
                                                                        UserQ
                                                                        panel,
                                                                        based on
                                                                        the
                                                                        recruitment
                                                                        criteria
                                                                        you have
                                                                        selected,
                                                                        along
                                                                        with the
                                                                        estimated
                                                                        response
                                                                        time.
                                                                    </>
                                                                }
                                                                className="tooltipChart"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="estimatepanel-panel-mid-area mb-40">
                                                        <EstimatedPanel
                                                            panel_color={getEstimatedPanelColor()}
                                                            panel_size={
                                                                formValues.panel_size
                                                            }
                                                        />
                                                    </div>
                                                    {order && (
                                                        <OrderSummary
                                                            workspace_id={
                                                                test.workspace_id
                                                            }
                                                            order={order}
                                                            participants={
                                                                formData.participants_selected
                                                            }
                                                            researcher_fee={
                                                                test.researcher_fee
                                                            }
                                                        />
                                                    )}
                                                </>
                                            </div>
                                            {getEstimatedPanelColor() ==
                                                "red" && (
                                                <div className="inline-error-message">
                                                    <Icon
                                                        value={"error"}
                                                        colorClass={
                                                            "danger-200-svg"
                                                        }
                                                        size={"medium"}
                                                    />
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        You may get results{" "}
                                                        <span className="text-color-red">
                                                            very slowly
                                                        </span>
                                                        . Try to reduce the
                                                        number of participants
                                                        or change the selected
                                                        criteria.
                                                    </Text>
                                                </div>
                                            )}
                                            {formValues.is_screening_questions ==
                                                1 && (
                                                <div className="inline-error-message">
                                                    <Icon
                                                        value={"warning"}
                                                        colorClass={
                                                            "warning-200-svg"
                                                        }
                                                        size={"medium"}
                                                    />
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Please note that the
                                                        responses may be slower
                                                        due to the specific
                                                        requirements you have
                                                        mentioned.
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* /* side panel for tab and mobile end here--> */}

                        <div className="mid-spacing">
                            <div className="section-minimum-height">
                                <div className="recruit-participants-wrapper">
                                    <div className="recurit-panel-top-head">
                                        <div className="recruit-participant-top-heading">
                                            <Text
                                                type={"h3"}
                                                fontWeight={"bold-font"}
                                            >
                                                Recruit from UserQ panel
                                            </Text>

                                            <div>
                                                <Button
                                                    type={"secondary"}
                                                    size={"medium"}
                                                    label={"Clear selections"}
                                                    iconLeft={
                                                        <Icon
                                                            value={"refresh"}
                                                            size={"small"}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        setClearSelectionsModal(
                                                            {
                                                                ...clearSelectionsModal,
                                                                open: true,
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                        >
                                            Get results from our extensive panel
                                            in minutes, complete with
                                            demographic data! Choose the number
                                            of participants and segmentation
                                            required.
                                            <span className="share-participants-link">
                                                {" "}
                                                Switch to{" "}
                                                <a
                                                    href="#"
                                                    className="link-text"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setLoading(true);
                                                        resetSelections("all");
                                                        //setFormData({ ...formData, "participants_selected": 0, "target_type": "test-links" });
                                                        saveTargetGroupApi(
                                                            {
                                                                ...formData,
                                                                target_type:
                                                                    "test-links",
                                                            },
                                                            1
                                                        );
                                                    }}
                                                >
                                                    share only with your
                                                    participants
                                                </a>
                                            </span>
                                        </Text>
                                    </div>
                                    <div className="recurit-panel-tabs-wrap">
                                        <>
                                            {!isLoading && (
                                                <>
                                                    <div className="card-sorting-test-wrap tree-test-section-wrap">
                                                        <div className="cardsorting-wrap-mid-data participants-selection-holder">
                                                            <Text
                                                                type={
                                                                    "subtitle-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                How many
                                                                participants do
                                                                you want to
                                                                recruit?
                                                            </Text>
                                                            <div className="mt-32">
                                                                <div className="mb-32 recruitment-slider-holder">
                                                                    <RecruitmentSlider
                                                                        value={
                                                                            formData.participants_selected
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                parseInt(
                                                                                    value
                                                                                ) <
                                                                                5
                                                                            ) {
                                                                                value = 5;
                                                                            }
                                                                            setFormData(
                                                                                {
                                                                                    ...formData,
                                                                                    participants_selected:
                                                                                        value,
                                                                                }
                                                                            );
                                                                            var form_data =
                                                                                {
                                                                                    ...formData,
                                                                                    participants_selected:
                                                                                        value,
                                                                                };

                                                                            setValidateForm(
                                                                                true
                                                                            );

                                                                            saveTargetGroupApi(
                                                                                form_data,
                                                                                0
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="estimate-count-area inc-dec-holder">
                                                                    <IncDecCounter
                                                                        readOnly={
                                                                            false
                                                                        }
                                                                        value={
                                                                            formData.participants_selected
                                                                        }
                                                                        min={5}
                                                                        max={
                                                                            1000
                                                                        }
                                                                        onChange={(
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                !value ||
                                                                                value ==
                                                                                    ""
                                                                            ) {
                                                                                setFormData(
                                                                                    {
                                                                                        ...formData,
                                                                                        participants_selected:
                                                                                            value,
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                if (
                                                                                    value >=
                                                                                    5
                                                                                ) {
                                                                                    setFormData(
                                                                                        {
                                                                                            ...formData,
                                                                                            participants_selected:
                                                                                                value,
                                                                                        }
                                                                                    );
                                                                                    var form_data =
                                                                                        {
                                                                                            ...formData,
                                                                                            participants_selected:
                                                                                                value,
                                                                                        };

                                                                                    setValidateForm(
                                                                                        true
                                                                                    );

                                                                                    saveTargetGroupApi(
                                                                                        form_data,
                                                                                        0
                                                                                    );
                                                                                } else {
                                                                                    setFormData(
                                                                                        {
                                                                                            ...formData,
                                                                                            participants_selected:
                                                                                                value,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    {/*perParticipantsCreditCharge*/}
                                                                    {/*<Text type={"caption"} fontWeight={"medium-font"} >{test.researcher_fee} credits per participant</Text>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="demographics_group">
                                                        <div className="screen-question-radio">
                                                            <ToggleButton
                                                                label={
                                                                    "Demographics"
                                                                }
                                                                isChecked={
                                                                    formValues.is_demographics ===
                                                                    1
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            is_demographics:
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                                    ? 1
                                                                                    : 0,
                                                                        }
                                                                    );

                                                                    if (
                                                                        !e
                                                                            .target
                                                                            .checked
                                                                    ) {
                                                                        resetSelections(
                                                                            "demographics"
                                                                        );
                                                                    } else {
                                                                        updateTargetGroupApi(
                                                                            {
                                                                                ...formValues,
                                                                                is_demographics:
                                                                                    e
                                                                                        .target
                                                                                        .checked
                                                                                        ? 1
                                                                                        : 0,
                                                                            },
                                                                            0
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {formValues.is_demographics ==
                                                            1 && (
                                                            <div className="mt-40">
                                                                <Accordian
                                                                    title={
                                                                        "Age and gender"
                                                                    }
                                                                    tooltip={
                                                                        "Move the bar to change the target group age range and the tab to select a specific gender"
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "age_gender"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "age_gender"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );
                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="recuit-age-and-gender-wrap">
                                                                        <Text
                                                                            type={
                                                                                "body-text-2"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            Move
                                                                            the
                                                                            bar
                                                                            to
                                                                            change
                                                                            the
                                                                            target
                                                                            group
                                                                            age
                                                                            range
                                                                            and
                                                                            the
                                                                            tab
                                                                            to
                                                                            select
                                                                            a
                                                                            specific
                                                                            gender
                                                                        </Text>

                                                                        <div className="agegender-graph-wrap">
                                                                            <div className="agegender-data-count">
                                                                                <p>
                                                                                    Gender:{" "}
                                                                                    <strong>
                                                                                        {
                                                                                            formValues.gender
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    Age
                                                                                    range:{" "}
                                                                                    <strong>
                                                                                        {
                                                                                            formValues.min_age
                                                                                        }{" "}
                                                                                        to{" "}
                                                                                        {
                                                                                            formValues.max_age
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                            <div className="agegender-graphoption-wrap">
                                                                                <div className="graph-filter-buttons">
                                                                                    <FilterButtons
                                                                                        options={[
                                                                                            "Any",
                                                                                            "Male",
                                                                                            "Female",
                                                                                        ]}
                                                                                        selected={
                                                                                            formValues.gender
                                                                                        }
                                                                                        onChange={(
                                                                                            value
                                                                                        ) => {
                                                                                            if (
                                                                                                value !=
                                                                                                formValues.gender
                                                                                            ) {
                                                                                                getAgeDistributionApi(
                                                                                                    value
                                                                                                );
                                                                                                setFormValues(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        gender: value,
                                                                                                    }
                                                                                                );
                                                                                                updateTargetGroupApi(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        gender: value,
                                                                                                    },
                                                                                                    0
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="graph-hold-wrap">
                                                                                    {ageDistLoading && (
                                                                                        <div className="graph-data-loder">
                                                                                            <LoadingIcon />
                                                                                        </div>
                                                                                    )}
                                                                                    {!ageDistLoading && (
                                                                                        <ChartSliderCombo
                                                                                            min_val={
                                                                                                formValues.min_age
                                                                                            }
                                                                                            max_val={
                                                                                                formValues.max_age
                                                                                            }
                                                                                            barData={
                                                                                                bar_data
                                                                                            }
                                                                                            min={
                                                                                                options.min_age
                                                                                            }
                                                                                            max={
                                                                                                options.max_age
                                                                                            }
                                                                                            step={
                                                                                                options.step
                                                                                            }
                                                                                            onChange={(
                                                                                                min,
                                                                                                max
                                                                                            ) => {
                                                                                                setFormLoading(
                                                                                                    true
                                                                                                );

                                                                                                setFormValues(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        min_age:
                                                                                                            min,
                                                                                                        max_age:
                                                                                                            max,
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            onStartSliding={() => {
                                                                                                setFormLoading(
                                                                                                    false
                                                                                                );
                                                                                                min_age =
                                                                                                    formValues.min_age;
                                                                                                max_age =
                                                                                                    formValues.max_age;
                                                                                            }}
                                                                                            onStopSliding={() => {
                                                                                                setFormLoading(
                                                                                                    false
                                                                                                );
                                                                                                if (
                                                                                                    min_age !=
                                                                                                        formValues.min_age ||
                                                                                                    max_age !=
                                                                                                        formValues.max_age
                                                                                                ) {
                                                                                                    updateTargetGroupApi(
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                            min_age:
                                                                                                                formValues.min_age,
                                                                                                            max_age:
                                                                                                                formValues.max_age,
                                                                                                        },
                                                                                                        0
                                                                                                    );
                                                                                                } else {
                                                                                                    setFormLoading(
                                                                                                        false
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Accordian>

                                                                <Accordian
                                                                    title={
                                                                        "Residency"
                                                                    }
                                                                    tooltip={
                                                                        "Choose participants from certain countries and regions."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "residency"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "residency"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );
                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Choose
                                                                        participants
                                                                        from
                                                                        certain
                                                                        countries
                                                                        and
                                                                        regions.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap">
                                                                        <CheckBox
                                                                            checked={
                                                                                formValues.emirates_match ==
                                                                                1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            label={
                                                                                "UAE Residents"
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                toggleCheck(
                                                                                    "emirates_match",
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked
                                                                                        ? 1
                                                                                        : 0
                                                                                );
                                                                                if (
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked
                                                                                ) {
                                                                                    let emirates =
                                                                                        [];

                                                                                    options.emirates.forEach(
                                                                                        function (
                                                                                            item
                                                                                        ) {
                                                                                            if (
                                                                                                item.value !=
                                                                                                "All emirates of residency"
                                                                                            ) {
                                                                                                emirates.push(
                                                                                                    item.value
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    setFormValue(
                                                                                        "emirates",
                                                                                        emirates
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            emirates_match: 1,
                                                                                            emirates:
                                                                                                emirates,
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                } else {
                                                                                    setFormValue(
                                                                                        "emirates",
                                                                                        []
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            emirates_match: 0,
                                                                                            emirates:
                                                                                                [],
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                }
                                                                            }}
                                                                            id="residency_uae"
                                                                        />
                                                                        {formValues.emirates_match ==
                                                                            1 && (
                                                                            <Chips>
                                                                                {options.emirates.map(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        if (
                                                                                            item.value ==
                                                                                            "All emirates of residency"
                                                                                        ) {
                                                                                            return (
                                                                                                <>

                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        return (
                                                                                            <Chip
                                                                                                key={
                                                                                                    "emirate" +
                                                                                                    item.value
                                                                                                }
                                                                                                title={
                                                                                                    item.value
                                                                                                }
                                                                                                value={
                                                                                                    item.value
                                                                                                }
                                                                                                type={
                                                                                                    formValues.emirates.includes(
                                                                                                        item.value
                                                                                                    )
                                                                                                        ? "selected"
                                                                                                        : "default"
                                                                                                }
                                                                                                onClick={(
                                                                                                    value
                                                                                                ) => {
                                                                                                    let emirates =
                                                                                                        Object.assign(
                                                                                                            [],
                                                                                                            formValues.emirates
                                                                                                        );

                                                                                                    let exist = false;

                                                                                                    let emiratesNew =
                                                                                                        [];

                                                                                                    emirates.forEach(
                                                                                                        function (
                                                                                                            item
                                                                                                        ) {
                                                                                                            if (
                                                                                                                item ==
                                                                                                                value
                                                                                                            ) {
                                                                                                                exist = true;
                                                                                                            } else {
                                                                                                                emiratesNew.push(
                                                                                                                    item
                                                                                                                );
                                                                                                            }
                                                                                                        }
                                                                                                    );
                                                                                                    let form_data =
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                        };

                                                                                                    if (
                                                                                                        !exist
                                                                                                    ) {
                                                                                                        emirates.push(
                                                                                                            value
                                                                                                        );

                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                emirates:
                                                                                                                    emirates,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.emirates =
                                                                                                            emirates;
                                                                                                    } else {
                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                emirates:
                                                                                                                    emiratesNew,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.emirates =
                                                                                                            emiratesNew;
                                                                                                    }
                                                                                                    if (
                                                                                                        emiratesNew.length ==
                                                                                                            0 ||
                                                                                                        (emiratesNew.length ==
                                                                                                            1 &&
                                                                                                            emiratesNew[0] ==
                                                                                                                "All emirates of residency")
                                                                                                    ) {
                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                emirates_match: 0,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.emirates_match = 0;
                                                                                                    }
                                                                                                    updateTargetGroupApi(
                                                                                                        form_data,
                                                                                                        0
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Chips>
                                                                        )}
                                                                        <CheckBox
                                                                            checked={
                                                                                formValues.ksa_match ==
                                                                                1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            label={
                                                                                "Saudi Arabia Residents"
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                toggleCheck(
                                                                                    "ksa_match",
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked
                                                                                        ? 1
                                                                                        : 0
                                                                                );
                                                                                let form_data =
                                                                                    {
                                                                                        ...formValues,
                                                                                    };
                                                                                if (
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked
                                                                                ) {
                                                                                    let ksa =
                                                                                        [];

                                                                                    options.ksa.forEach(
                                                                                        function (
                                                                                            item
                                                                                        ) {
                                                                                            if (
                                                                                                item.value !=
                                                                                                "All provinces of residency"
                                                                                            ) {
                                                                                                ksa.push(
                                                                                                    item.value
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    setFormValue(
                                                                                        "ksa",
                                                                                        ksa
                                                                                    );
                                                                                    form_data.ksa =
                                                                                        ksa;
                                                                                    form_data.ksa_match = 1;
                                                                                    //setFormValue("ksa",[{value: "All provinces of residency", label: "All provinces of residency"}]);
                                                                                } else {
                                                                                    form_data.ksa =
                                                                                        [];
                                                                                    form_data.ksa_match = 0;
                                                                                }
                                                                                updateTargetGroupApi(
                                                                                    form_data,
                                                                                    0
                                                                                );
                                                                            }}
                                                                            id="residency_ksa"
                                                                        />
                                                                        {formValues.ksa_match ==
                                                                            1 && (
                                                                            <Chips>
                                                                                {options.ksa.map(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        if (
                                                                                            item.value ==
                                                                                            "All provinces of residency"
                                                                                        ) {
                                                                                            return (
                                                                                                <>

                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        return (
                                                                                            <Chip
                                                                                                key={
                                                                                                    "kas" +
                                                                                                    item.value
                                                                                                }
                                                                                                title={
                                                                                                    item.value
                                                                                                }
                                                                                                value={
                                                                                                    item.value
                                                                                                }
                                                                                                type={
                                                                                                    formValues.ksa.includes(
                                                                                                        item.value
                                                                                                    )
                                                                                                        ? "selected"
                                                                                                        : "default"
                                                                                                }
                                                                                                onClick={(
                                                                                                    value
                                                                                                ) => {
                                                                                                    let ksa =
                                                                                                        Object.assign(
                                                                                                            [],
                                                                                                            formValues.ksa
                                                                                                        );

                                                                                                    let exist = false;

                                                                                                    let ksaNew =
                                                                                                        [];

                                                                                                    let form_data =
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                        };

                                                                                                    ksa.forEach(
                                                                                                        function (
                                                                                                            item
                                                                                                        ) {
                                                                                                            if (
                                                                                                                item ==
                                                                                                                value
                                                                                                            ) {
                                                                                                                exist = true;
                                                                                                            } else {
                                                                                                                ksaNew.push(
                                                                                                                    item
                                                                                                                );
                                                                                                            }
                                                                                                        }
                                                                                                    );

                                                                                                    if (
                                                                                                        !exist
                                                                                                    ) {
                                                                                                        ksa.push(
                                                                                                            value
                                                                                                        );
                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                ksa: ksa,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.ksa =
                                                                                                            ksa;
                                                                                                    } else {
                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                ksa: ksaNew,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.ksa =
                                                                                                            ksaNew;
                                                                                                    }
                                                                                                    if (
                                                                                                        ksaNew.length ==
                                                                                                            0 ||
                                                                                                        (ksaNew.length ==
                                                                                                            1 &&
                                                                                                            ksaNew[0] ==
                                                                                                                "All provinces of residency")
                                                                                                    ) {
                                                                                                        setFormValues(
                                                                                                            {
                                                                                                                ...formValues,
                                                                                                                ksa_match: 0,
                                                                                                            }
                                                                                                        );

                                                                                                        form_data.ksa_match = 0;
                                                                                                    }
                                                                                                    updateTargetGroupApi(
                                                                                                        form_data,
                                                                                                        0
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Chips>
                                                                        )}
                                                                        <div className="other-countries-wrap">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                Other
                                                                                countries{" "}
                                                                            </Text>

                                                                            <CountryDropdown
                                                                                id={
                                                                                    "country_others"
                                                                                }
                                                                                name="country_others"
                                                                                title="Select levels of education..."
                                                                                searchable={[
                                                                                    "Search for country level",
                                                                                    "No matching country level",
                                                                                ]}
                                                                                type={
                                                                                    "country"
                                                                                }
                                                                                value={
                                                                                    formValues.countries
                                                                                }
                                                                                onChange={(
                                                                                    items
                                                                                ) => {
                                                                                    let selected =
                                                                                        [];

                                                                                    items.forEach(
                                                                                        function (
                                                                                            item
                                                                                        ) {
                                                                                            selected.push(
                                                                                                item.value
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                    setFormValue(
                                                                                        "countries",
                                                                                        selected
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            countries:
                                                                                                selected,
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                }}
                                                                                label={
                                                                                    "Select your country"
                                                                                }
                                                                                isClearable={
                                                                                    true
                                                                                }
                                                                                isMulti={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Accordian>

                                                                <Accordian
                                                                    title={
                                                                        "Nationalities"
                                                                    }
                                                                    tooltip={
                                                                        "We have a diverse panel of participants. Choose the nationalities you would like to target."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "nationalities"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "nationalities"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }

                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        We have
                                                                        a
                                                                        diverse
                                                                        panel of
                                                                        participants.
                                                                        Choose
                                                                        the
                                                                        nationalities
                                                                        you
                                                                        would
                                                                        like to
                                                                        target.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap predefined-categories">
                                                                        {/*<Text type={"subtitle-2"} fontWeight={"semi-bold-font"}>Predefined Categories</Text>*/}

                                                                        <div className="other-countries-wrap">
                                                                            <CountryDropdown
                                                                                id={
                                                                                    "nationality_others"
                                                                                }
                                                                                name="nationality_others"
                                                                                title="Select levels of education..."
                                                                                searchable={[
                                                                                    "Search for country level",
                                                                                    "No matching country level",
                                                                                ]}
                                                                                value={
                                                                                    formValues.nationalities
                                                                                }
                                                                                onChange={(
                                                                                    items
                                                                                ) => {
                                                                                    let selected =
                                                                                        [];

                                                                                    items.forEach(
                                                                                        function (
                                                                                            item
                                                                                        ) {
                                                                                            selected.push(
                                                                                                item.value
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                    //setFormValue("nationalities", selected);

                                                                                    let nationalities_match =
                                                                                        {
                                                                                            ...formValues.nationalities_match,
                                                                                        };

                                                                                    for (var key in options.group_nationalities) {
                                                                                        var exist = false;

                                                                                        options.group_nationalities[
                                                                                            key
                                                                                        ].forEach(
                                                                                            function (
                                                                                                item
                                                                                            ) {
                                                                                                var nationality_exist = false;

                                                                                                selected.forEach(
                                                                                                    function (
                                                                                                        nationality
                                                                                                    ) {
                                                                                                        if (
                                                                                                            nationality.value ==
                                                                                                            item
                                                                                                        ) {
                                                                                                            nationality_exist = true;
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                                if (
                                                                                                    nationality_exist
                                                                                                ) {
                                                                                                    exist = true;
                                                                                                }
                                                                                            }
                                                                                        );
                                                                                        if (
                                                                                            exist
                                                                                        ) {
                                                                                            nationalities_match[
                                                                                                key
                                                                                            ] = 1;
                                                                                        } else {
                                                                                            nationalities_match[
                                                                                                key
                                                                                            ] = 0;
                                                                                        }
                                                                                    }

                                                                                    setFormValues(
                                                                                        {
                                                                                            ...formValues,
                                                                                            nationalities:
                                                                                                selected,
                                                                                            nationalities_match:
                                                                                                nationalities_match,
                                                                                        }
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            nationalities:
                                                                                                selected,
                                                                                            nationalities_match:
                                                                                                nationalities_match,
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                }}
                                                                                label={
                                                                                    "Select your nationality"
                                                                                }
                                                                                isClearable={
                                                                                    true
                                                                                }
                                                                                isMulti={
                                                                                    true
                                                                                }
                                                                                type={
                                                                                    "nationality"
                                                                                }
                                                                                categories={renderNationalityCategories()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Accordian>
                                                                <Accordian
                                                                    title={
                                                                        "Minimum level of education"
                                                                    }
                                                                    tooltip={
                                                                        "Select the minimum education level you require for your participants."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "education"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "education"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );
                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Select
                                                                        the
                                                                        minimum
                                                                        education
                                                                        level
                                                                        you
                                                                        require
                                                                        for your
                                                                        participants.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap">
                                                                        <DropdownComponent
                                                                            id={
                                                                                "education_level"
                                                                            }
                                                                            name="education_level"
                                                                            title="Select levels of education..."
                                                                            searchable={[
                                                                                "Search for education level",
                                                                                "No matching education level",
                                                                            ]}
                                                                            value={
                                                                                formValues.education
                                                                            }
                                                                            options={
                                                                                options.education
                                                                            }
                                                                            onChange={(
                                                                                item
                                                                            ) => {
                                                                                if (
                                                                                    item
                                                                                ) {
                                                                                    setFormValue(
                                                                                        "education",
                                                                                        item.value
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            education:
                                                                                                item.value,
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                } else {
                                                                                    setFormValue(
                                                                                        "education",
                                                                                        null
                                                                                    );

                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            education:
                                                                                                null,
                                                                                        },
                                                                                        0
                                                                                    );
                                                                                }
                                                                            }}
                                                                            label={
                                                                                formValues.education
                                                                                    ? "Education level"
                                                                                    : "Select education level"
                                                                            }
                                                                            isClearable={
                                                                                true
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Accordian>
                                                                <Accordian
                                                                    title={
                                                                        "Confidence with technology"
                                                                    }
                                                                    tooltip={
                                                                        "Choose participants with different technical competencies."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "technology"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "technology"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );

                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Choose
                                                                        participants
                                                                        with
                                                                        different
                                                                        technical
                                                                        competencies.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap average-time-wrap">
                                                                        <Text
                                                                            type={
                                                                                "body-text-1"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            Daily
                                                                            hours
                                                                            spent
                                                                            online
                                                                        </Text>
                                                                        <MultiSelecteDropdownList
                                                                            className="mt-32 big-multi-select"
                                                                            type={
                                                                                "text"
                                                                            }
                                                                            id={
                                                                                "hours_spend_online"
                                                                            }
                                                                            name="hours_spend_online"
                                                                            label="Daily hours spent online"
                                                                            value={
                                                                                formValues.hours_spend_online
                                                                            }
                                                                            options={
                                                                                options.hours_spend_online
                                                                            }
                                                                            onChange={(
                                                                                items
                                                                            ) => {
                                                                                let selected =
                                                                                    [];

                                                                                items.forEach(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        selected.push(
                                                                                            item
                                                                                        );
                                                                                    }
                                                                                );

                                                                                setFormValue(
                                                                                    "hours_spend_online",
                                                                                    selected
                                                                                );

                                                                                updateTargetGroupApi(
                                                                                    {
                                                                                        ...formValues,
                                                                                        hours_spend_online:
                                                                                            selected,
                                                                                    },
                                                                                    0
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="fav-device-wrap mt-32">
                                                                        <Text
                                                                            type={
                                                                                "body-text-1"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            Select
                                                                            user's
                                                                            favorite
                                                                            devices
                                                                        </Text>

                                                                        <ImageCheckBoxes
                                                                            options={
                                                                                options.devices
                                                                            }
                                                                            selected={
                                                                                formValues.devices
                                                                            }
                                                                            onChange={(
                                                                                devices
                                                                            ) => {
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        devices:
                                                                                            devices,
                                                                                    }
                                                                                );
                                                                                updateTargetGroupApi(
                                                                                    {
                                                                                        ...formValues,
                                                                                        devices:
                                                                                            devices,
                                                                                    },
                                                                                    0
                                                                                );
                                                                            }}
                                                                        />
                                                                        {/*<ul>
                                        {options.devices.map(function(device){
                                            return (<li key={"device"+device.value}><span  
                                                className={`image-btn-option ${formValues.devices.includes(device.value)?"image-btn-option-selected":""}`}
                                                onClick={()=>{
                                                    let localDevices = Object.assign([], formValues.devices);

                                                    var exist = false;

                                                    var newDevices = [];

                                                    localDevices.forEach(function(device_item){
                                                        if(device_item==device.value){
                                                            exist= true;
                                                        } else {
                                                            newDevices.push(device.value);
                                                        }
                                                    })
                                                    if(exist){
                                                        setFormValues({...formValues, devices:newDevices});

                                                        updateTargetGroupApi({...formValues, devices: newDevices},0);

                                                    } else {
                                                        localDevices.push(device.value);

                                                        setFormValues({...formValues, devices:localDevices});

                                                        updateTargetGroupApi({...formValues, devices: localDevices},0);
                                                    }
                                                }}
                                                >{device.value}</span></li>);
                                        })}
                                    </ul>*/}
                                                                    </div>

                                                                    <div className="fav-device-wrap most-used-apps">
                                                                        <Text
                                                                            type={
                                                                                "body-text-1"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            Most
                                                                            used
                                                                            apps
                                                                        </Text>

                                                                        <Chips>
                                                                            {options.apps.map(
                                                                                function (
                                                                                    app
                                                                                ) {
                                                                                    return (
                                                                                        <Chip
                                                                                            key={
                                                                                                "app-" +
                                                                                                app.value
                                                                                            }
                                                                                            title={
                                                                                                app.value
                                                                                            }
                                                                                            value={
                                                                                                app.value
                                                                                            }
                                                                                            type={
                                                                                                formValues.apps.includes(
                                                                                                    app.value
                                                                                                )
                                                                                                    ? "selected"
                                                                                                    : "default"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                let localApps =
                                                                                                    Object.assign(
                                                                                                        [],
                                                                                                        formValues.apps
                                                                                                    );

                                                                                                var exist = false;

                                                                                                var newApps =
                                                                                                    [];

                                                                                                localApps.forEach(
                                                                                                    function (
                                                                                                        app_item
                                                                                                    ) {
                                                                                                        if (
                                                                                                            app_item ==
                                                                                                            app.value
                                                                                                        ) {
                                                                                                            exist = true;
                                                                                                        } else {
                                                                                                            newApps.push(
                                                                                                                app_item
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                );

                                                                                                if (
                                                                                                    exist
                                                                                                ) {
                                                                                                    setFormValues(
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                            apps: newApps,
                                                                                                        }
                                                                                                    );

                                                                                                    updateTargetGroupApi(
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                            apps: newApps,
                                                                                                        },
                                                                                                        0
                                                                                                    );
                                                                                                } else {
                                                                                                    localApps.push(
                                                                                                        app.value
                                                                                                    );

                                                                                                    console.log(
                                                                                                        localApps
                                                                                                    );

                                                                                                    setFormValues(
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                            apps: localApps,
                                                                                                        }
                                                                                                    );

                                                                                                    updateTargetGroupApi(
                                                                                                        {
                                                                                                            ...formValues,
                                                                                                            apps: localApps,
                                                                                                        },
                                                                                                        0
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Chips>
                                                                    </div>
                                                                </Accordian>
                                                                <Accordian
                                                                    title={
                                                                        "Employment status"
                                                                    }
                                                                    tooltip={
                                                                        "Choose different employment types for your participants."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "employment"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "employment"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );

                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Choose
                                                                        different
                                                                        employment
                                                                        types
                                                                        for your
                                                                        participants.
                                                                    </Text>
                                                                    <div className="vertical-checkboxes-wrap employment-type-wrap">
                                                                        <MultiSelecteDropdownList
                                                                            className="big-multi-select"
                                                                            type={
                                                                                "text"
                                                                            }
                                                                            id={
                                                                                "employment_status"
                                                                            }
                                                                            name="employment_status"
                                                                            label="Current employment status"
                                                                            value={
                                                                                formValues.employment
                                                                            }
                                                                            options={
                                                                                options.employment
                                                                            }
                                                                            onChange={(
                                                                                items
                                                                            ) => {
                                                                                let selected =
                                                                                    [];

                                                                                items.forEach(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        selected.push(
                                                                                            item
                                                                                        );
                                                                                    }
                                                                                );
                                                                                setFormValue(
                                                                                    "employment",
                                                                                    selected
                                                                                );

                                                                                var employmentFieldsDisabled = true;

                                                                                selected.forEach(
                                                                                    function (
                                                                                        option
                                                                                    ) {
                                                                                        if (
                                                                                            ![
                                                                                                "Student",
                                                                                                "Unemployed",
                                                                                                "Homemaker",
                                                                                                "Retired",
                                                                                            ].includes(
                                                                                                option
                                                                                            )
                                                                                        ) {
                                                                                            employmentFieldsDisabled = false;
                                                                                        }
                                                                                    }
                                                                                );
                                                                                if (
                                                                                    selected.length ==
                                                                                    0
                                                                                ) {
                                                                                    employmentFieldsDisabled = false;
                                                                                }

                                                                                if (
                                                                                    employmentFieldsDisabled
                                                                                ) {
                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            employment:
                                                                                                selected,
                                                                                            industry:
                                                                                                [],
                                                                                            department:
                                                                                                [],
                                                                                        },
                                                                                        0
                                                                                    );

                                                                                    setEmploymentFieldsReadOnly(
                                                                                        true
                                                                                    );
                                                                                } else {
                                                                                    updateTargetGroupApi(
                                                                                        {
                                                                                            ...formValues,
                                                                                            employment:
                                                                                                selected,
                                                                                        },
                                                                                        0
                                                                                    );

                                                                                    setEmploymentFieldsReadOnly(
                                                                                        false
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />

                                                                        <MultiSelecteDropdownList
                                                                            className="mt-32 big-multi-select"
                                                                            type={
                                                                                "text"
                                                                            }
                                                                            name="industry"
                                                                            label="Industry"
                                                                            readOnly={
                                                                                employmentFieldsReadOnly
                                                                            }
                                                                            value={
                                                                                formValues.industry
                                                                            }
                                                                            options={
                                                                                options.industry
                                                                            }
                                                                            onChange={(
                                                                                items
                                                                            ) => {
                                                                                let selected =
                                                                                    [];

                                                                                items.forEach(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        selected.push(
                                                                                            item
                                                                                        );
                                                                                    }
                                                                                );
                                                                                setFormValue(
                                                                                    "industry",
                                                                                    selected
                                                                                );

                                                                                updateTargetGroupApi(
                                                                                    {
                                                                                        ...formValues,
                                                                                        industry:
                                                                                            selected,
                                                                                    },
                                                                                    0
                                                                                );
                                                                            }}
                                                                        />
                                                                        <MultiSelecteDropdownList
                                                                            className="mt-32 big-multi-select"
                                                                            type={
                                                                                "text"
                                                                            }
                                                                            id={
                                                                                "department"
                                                                            }
                                                                            name="department"
                                                                            label="Department"
                                                                            readOnly={
                                                                                employmentFieldsReadOnly
                                                                            }
                                                                            value={
                                                                                formValues.department
                                                                            }
                                                                            options={
                                                                                options.department
                                                                            }
                                                                            onChange={(
                                                                                items
                                                                            ) => {
                                                                                let selected =
                                                                                    [];

                                                                                items.forEach(
                                                                                    function (
                                                                                        item
                                                                                    ) {
                                                                                        selected.push(
                                                                                            item
                                                                                        );
                                                                                    }
                                                                                );
                                                                                setFormValue(
                                                                                    "department",
                                                                                    selected
                                                                                );

                                                                                updateTargetGroupApi(
                                                                                    {
                                                                                        ...formValues,
                                                                                        department:
                                                                                            selected,
                                                                                    },
                                                                                    0
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Accordian>
                                                                <Accordian
                                                                    title={
                                                                        "Household and earnings"
                                                                    }
                                                                    tooltip={
                                                                        "Choose from participants with specific household numbers and income levels."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "earnings"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "earnings"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );

                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Choose
                                                                        from
                                                                        participants
                                                                        with
                                                                        specific
                                                                        household
                                                                        numbers
                                                                        and
                                                                        income
                                                                        levels.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap employment-type-wrap household-number-wrap">
                                                                        <div className="row align-items-center select-household">
                                                                            <div className="col-md-8">
                                                                                <DropdownComponent
                                                                                    id={
                                                                                        "household"
                                                                                    }
                                                                                    name="household"
                                                                                    title="Department"
                                                                                    searchable={[
                                                                                        "Search for household",
                                                                                        "No matching household",
                                                                                    ]}
                                                                                    value={
                                                                                        formValues.household_members_condition
                                                                                    }
                                                                                    options={[
                                                                                        {
                                                                                            label: "Exact number of members in household",
                                                                                            value: "Exact number of members in household",
                                                                                        },
                                                                                        {
                                                                                            label: "Minimum number of members in household",
                                                                                            value: "Minimum number of members in household",
                                                                                        },
                                                                                        {
                                                                                            label: "Maximum number of members in household",
                                                                                            value: "Maximum number of members in household",
                                                                                        },
                                                                                    ]}
                                                                                    onChange={(
                                                                                        item
                                                                                    ) => {
                                                                                        if (
                                                                                            item
                                                                                        ) {
                                                                                            setFormValue(
                                                                                                "household_members_condition",
                                                                                                item.value
                                                                                            );

                                                                                            updateTargetGroupApi(
                                                                                                {
                                                                                                    ...formValues,
                                                                                                    household_members_condition:
                                                                                                        item.value,
                                                                                                },
                                                                                                0
                                                                                            );
                                                                                        } else {
                                                                                            //setFormValue("household_members_condition", null);

                                                                                            setFormValues(
                                                                                                {
                                                                                                    ...formValues,
                                                                                                    household_members_condition:
                                                                                                        null,
                                                                                                    people: 1,
                                                                                                }
                                                                                            );

                                                                                            updateTargetGroupApi(
                                                                                                {
                                                                                                    ...formValues,
                                                                                                    household_members_condition:
                                                                                                        null,
                                                                                                    people: 1,
                                                                                                },
                                                                                                0
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    label={
                                                                                        "Household"
                                                                                    }
                                                                                    isClearable={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <IncDecCounter
                                                                                    min={
                                                                                        1
                                                                                    }
                                                                                    max={
                                                                                        10
                                                                                    }
                                                                                    readOnly={
                                                                                        formValues.household_members_condition
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                    value={
                                                                                        formValues.people
                                                                                    }
                                                                                    onChange={(
                                                                                        value
                                                                                    ) => {
                                                                                        if (
                                                                                            !value ||
                                                                                            value ==
                                                                                                ""
                                                                                        ) {
                                                                                            setFormValues(
                                                                                                {
                                                                                                    ...formValues,
                                                                                                    people: value,
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            if (
                                                                                                value >=
                                                                                                0
                                                                                            ) {
                                                                                                setFormValues(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        people: value,
                                                                                                    }
                                                                                                );
                                                                                                updateTargetGroupApi(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        people: value,
                                                                                                    },
                                                                                                    0
                                                                                                );
                                                                                            } else {
                                                                                                setFormValues(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        people: value,
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                        /*if(value!=formValues.people){
                                                                setFormValues({ ...formValues, people: value })
                                                                updateTargetGroupApi({ ...formValues, people: value }, 0);
                                                                
                                                                
                                                            }*/
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-32">
                                                                            <div className="income-slab-wrap">
                                                                                <MultiSelecteDropdownList
                                                                                    className="mt-32 big-multi-select"
                                                                                    type={
                                                                                        "text"
                                                                                    }
                                                                                    id={
                                                                                        "income_slab"
                                                                                    }
                                                                                    name="income_slab"
                                                                                    label="Household income $ per month"
                                                                                    value={
                                                                                        formValues.income_slab
                                                                                    }
                                                                                    options={
                                                                                        options.income_levels
                                                                                    }
                                                                                    onChange={(
                                                                                        items
                                                                                    ) => {
                                                                                        let selected =
                                                                                            [];

                                                                                        items.forEach(
                                                                                            function (
                                                                                                item
                                                                                            ) {
                                                                                                selected.push(
                                                                                                    item
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                        setFormValue(
                                                                                            "income_slab",
                                                                                            selected
                                                                                        );
                                                                                        updateTargetGroupApi(
                                                                                            {
                                                                                                ...formValues,
                                                                                                income_slab:
                                                                                                    selected,
                                                                                            },
                                                                                            0
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Accordian>
                                                                <Accordian
                                                                    title={
                                                                        "Additional requirements"
                                                                    }
                                                                    tooltip={
                                                                        "Tell us your project audience and demographic requirements and we’ll source the right participants for you."
                                                                    }
                                                                    open={
                                                                        accordians[
                                                                            "requirements"
                                                                        ]
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        let new_accordians =
                                                                            Object.assign(
                                                                                {},
                                                                                accordians
                                                                            );

                                                                        for (var key in accordians) {
                                                                            if (
                                                                                key ==
                                                                                "requirements"
                                                                            ) {
                                                                                if (
                                                                                    accordians[
                                                                                        key
                                                                                    ]
                                                                                ) {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = false;
                                                                                } else {
                                                                                    new_accordians[
                                                                                        key
                                                                                    ] = true;
                                                                                }
                                                                            }
                                                                        }

                                                                        ReactSession.set(
                                                                            "accordians_recruitment_test",
                                                                            new_accordians
                                                                        );

                                                                        setAccordians(
                                                                            new_accordians
                                                                        );
                                                                    }}
                                                                >
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        Tell us
                                                                        your
                                                                        project
                                                                        audience
                                                                        and
                                                                        demographic
                                                                        requirements
                                                                        and
                                                                        we’ll
                                                                        source
                                                                        the
                                                                        right
                                                                        participants
                                                                        for you.
                                                                    </Text>

                                                                    <div className="vertical-checkboxes-wrap additional-req-wrap">
                                                                        <a
                                                                            className="button-link"
                                                                            href="/r/support/contact?reason=Help with recruitment"
                                                                            target={
                                                                                "_blank"
                                                                            }
                                                                        >
                                                                            <Button
                                                                                type={
                                                                                    "primary"
                                                                                }
                                                                                label={
                                                                                    "Send request"
                                                                                }
                                                                                size={
                                                                                    "large"
                                                                                }
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </Accordian>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="screening_options_group mt-40">
                                                        <div className="screen-question-radio">
                                                            <ToggleButton
                                                                label={
                                                                    "Screening questions"
                                                                }
                                                                isChecked={
                                                                    formValues.is_screening_questions ===
                                                                    1
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            is_screening_questions:
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                                    ? 1
                                                                                    : 0,
                                                                        }
                                                                    );

                                                                    if (
                                                                        !e
                                                                            .target
                                                                            .checked
                                                                    ) {
                                                                        if (
                                                                            formValues.questions &&
                                                                            formValues
                                                                                .questions
                                                                                .length >
                                                                                0
                                                                        ) {
                                                                            setClearQuestionModal(
                                                                                {
                                                                                    ...clearQuestionModal,
                                                                                    open: true,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            updateTargetGroupApi(
                                                                                {
                                                                                    ...formValues,
                                                                                    is_screening_questions:
                                                                                        e
                                                                                            .target
                                                                                            .checked
                                                                                            ? 1
                                                                                            : 0,
                                                                                },
                                                                                0
                                                                            );
                                                                        }
                                                                    } else {
                                                                        updateTargetGroupApi(
                                                                            {
                                                                                ...formValues,
                                                                                is_screening_questions:
                                                                                    e
                                                                                        .target
                                                                                        .checked
                                                                                        ? 1
                                                                                        : 0,
                                                                            },
                                                                            0
                                                                        );
                                                                    }
                                                                }}
                                                                tooltip="Filter participants based on answers to initial questions. You'll only be charged for those that proceed to complete the test. Response times may be slower when using screening questions. You can add up to 3 screening questions."
                                                            />
                                                        </div>

                                                        {formValues.is_screening_questions ==
                                                            1 && (
                                                            <>
                                                                <ScreeningQuestions
                                                                    cleanFormErrors={() => {
                                                                        if (
                                                                            formErrors.questions
                                                                        ) {
                                                                            setFormErrors(
                                                                                {
                                                                                    ...formErrors,
                                                                                    questions:
                                                                                        null,
                                                                                }
                                                                            );
                                                                        }
                                                                        setStepperError(
                                                                            false
                                                                        );
                                                                    }}
                                                                    updateQuestions={(
                                                                        questions
                                                                    ) => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                questions:
                                                                                    questions,
                                                                            }
                                                                        );
                                                                    }}
                                                                    questionsProp={
                                                                        formValues.questions
                                                                    }
                                                                    test={test}
                                                                    setIsSaved={
                                                                        setIsSaved
                                                                    }
                                                                    questionErrors={
                                                                        questionErrors
                                                                    }
                                                                    onAddQuestion={(
                                                                        question
                                                                    ) => {
                                                                        setAddQuestion(
                                                                            question
                                                                        );
                                                                        if (
                                                                            !question
                                                                        ) {
                                                                            setQuestionErrors(
                                                                                {
                                                                                    ...questionErrors,
                                                                                    question:
                                                                                        null,
                                                                                    options:
                                                                                        [],
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                    onEditQuestion={(
                                                                        question
                                                                    ) => {
                                                                        console.log(
                                                                            question
                                                                        );
                                                                        setAddQuestion(
                                                                            question
                                                                        );
                                                                        if (
                                                                            !question
                                                                        ) {
                                                                            setQuestionErrors(
                                                                                {
                                                                                    ...questionErrors,
                                                                                    question:
                                                                                        null,
                                                                                    options:
                                                                                        [],
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <div id="question_error">
                                                                    {formErrors &&
                                                                        formErrors.questions && (
                                                                            <span className="error red-text">
                                                                                {
                                                                                    formErrors.questions
                                                                                }
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="recruit-only-tab mt-40 ">
                                                        <div className="row">
                                                            <div className="col-lg-7 ">
                                                                <Text
                                                                    type={
                                                                        "subtitle-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    Custom
                                                                    recruitment
                                                                    for your
                                                                    study
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-3"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses={
                                                                        "mt-8 gray-text"
                                                                    }
                                                                >
                                                                    With prices
                                                                    starting at
                                                                    $10 per
                                                                    response, we
                                                                    can recruit
                                                                    participants
                                                                    tailored to
                                                                    your needs
                                                                    to complete
                                                                    any UserQ
                                                                    study.
                                                                </Text>
                                                            </div>
                                                            <div className="col-lg-5 tab-recruitment-container">
                                                                <Button
                                                                    type={
                                                                        "secondary"
                                                                    }
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    cssclass={
                                                                        " ml-20"
                                                                    }
                                                                    iconLeft={
                                                                        <Icon
                                                                            value={
                                                                                "team-member"
                                                                            }
                                                                            colorClass={
                                                                                "gray-50-svg add-icon add-icon-dashboard"
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        "Send a request"
                                                                    }
                                                                    onClick={() => {
                                                                        setIsRecruitmentModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {isLoading && (
                                                <div className="recritment-section-loader">
                                                    <LoadingIcon />
                                                </div>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="btns-wrap-bottom">
                                <Button
                                    type={"ghost"}
                                    label={"Previous"}
                                    size={"large"}
                                    iconLeft={
                                        <Icon
                                            value="back-arrow"
                                            colorClass="gray-900-svg"
                                            size={"large"}
                                        />
                                    }
                                    onClick={() => {
                                        navigate(
                                            "/r/test/" + test.id + "/thank-you"
                                        );
                                    }}
                                />

                                <Button
                                    state={
                                        !checkBtnState() ||
                                        formLoading ||
                                        ageDistLoading
                                            ? "disabled"
                                            : "active"
                                    }
                                    type={"primary"}
                                    label={"Next"}
                                    size={"large"}
                                    iconRight={
                                        <Icon
                                            value="forward-arrow"
                                            colorClass="gray-50-svg"
                                            size={"large"}
                                        />
                                    }
                                    onClick={() => {
                                        buildTargetGroupStep();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="three-column-layout-right estimated-panel-side-bar">
                        {formLoading && (
                            <div className="create-test-preview-area estimated-panel-size">
                                <div className="create-test-preview-area-inner skeleton-loading-estimated-panel">
                                    <>
                                        <Skeleton width={246} height={29} />

                                        <div className="estimatepanel-panel-mid-area">
                                            <div
                                                className={`estimate-progress-wrap `}
                                            >
                                                <h1 className="heading h1">
                                                    <Skeleton
                                                        width={50}
                                                        height={30}
                                                    />
                                                </h1>
                                                <Skeleton
                                                    width={"100%"}
                                                    height={5}
                                                />
                                                <div className="estimate-responses-wrap">
                                                    <Skeleton
                                                        width={"20"}
                                                        height={5}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                        >
                                            <Skeleton
                                                width={"100%"}
                                                height={16}
                                            />
                                        </Text>

                                        <div className="estimate-count-area">
                                            <Skeleton width={200} height={45} />
                                        </div>

                                        <div className="estimate-panel-footer">
                                            <Text
                                                type={"body-text-3"}
                                                fontWeight={"medium-font"}
                                            >
                                                <Skeleton
                                                    width={140}
                                                    height={16}
                                                />
                                            </Text>
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                <Skeleton
                                                    width={66}
                                                    height={16}
                                                />
                                            </Text>
                                        </div>
                                    </>
                                </div>
                            </div>
                        )}
                        {!formLoading && (
                            <div className="create-test-preview-area estimated-panel-size">
                                <div className="create-test-preview-area-inner">
                                    <>
                                        <div className="tooltip-deviceinfo tooltip-estimatedpanel">
                                            <Text type={"h4"}>
                                                Estimated panel size
                                            </Text>

                                            <span>
                                                <Icon
                                                    value={"information"}
                                                    colorClass={"gray-600-svg"}
                                                    size="medium"
                                                />
                                                <Tooltip
                                                    type={"bottom"}
                                                    data={
                                                        <>
                                                            This displays an
                                                            approximate count of
                                                            the number of people
                                                            in the UserQ panel,
                                                            based on the
                                                            recruitment criteria
                                                            you have selected,
                                                            along with the
                                                            estimated response
                                                            time.
                                                        </>
                                                    }
                                                    className="tooltipChart"
                                                />
                                            </span>
                                        </div>
                                        <div className="estimatepanel-panel-mid-area mb-40">
                                            <EstimatedPanel
                                                panel_color={getEstimatedPanelColor()}
                                                panel_size={
                                                    formValues.panel_size
                                                }
                                            />
                                        </div>
                                        {order && (
                                            <OrderSummary
                                                workspace_id={test.workspace_id}
                                                order={order}
                                                participants={
                                                    formData.participants_selected
                                                }
                                                researcher_fee={
                                                    test.researcher_fee
                                                }
                                            />
                                        )}
                                        {/*<Accordian
                            title={"Test fees"}
                            Accordion_small={true}
                            open={accordians['Test fees'] ? true : false}
                            onChange={() => {
                                let new_accordians = Object.assign({}, accordians);
                                ReactSession.set("accordians_recruitment_test",new_accordians);
                                setAccordians(new_accordians);
                            }}

                        >
                            <div className="ordersummary-table-wrap">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Test publishing fee</Text></td>
                                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{publishing_credits} credits</Text></td>
                                        </tr>
                                        <tr>
                                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{formData.participants_selected}{formData.participants_selected>1?" participants":" participant"} - UserQ panel</Text></td>
                                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{perParticipantsCreditCharge * formData.participants_selected} credits</Text></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Accordian>*/}
                                        {/*<Text type={'body-text-2'} fontWeight={'semibold-font'}>Order Summary</Text>
                        
                        <div className="ordersummary-table-wrap"> 
                            <table>
                                <tbody>
                                    <tr>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Credits required</Text></td>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{perParticipantsCreditCharge * formData.participants_selected + publishing_credits}  credits</Text></td>
                                    </tr>
                                    <tr>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Available in your wallet</Text></td>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}>10 credits</Text></td>
                                    </tr>
                                    <tr>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}> Order total</Text></td>
                                        <td><Text type={'body-text-3'} fontWeight={'medium-font'}>300 credits</Text></td>
                                    </tr>
                                    {/*<tr>
                                        <td>Recruitment fee ({formData.participants_selected}{formData.participants_selected>1?" participants":" participant"})</td>
                                        <td>{perParticipantsCreditCharge * formData.participants_selected} credits</td>
                                    </tr>
                                    <tr>
                                        <td>Test publishing fee</td>
                                        <td><div className="order-summary-publishing-fees"><strong>{publishing_credits} credits</strong></div></td>
                                    </tr>
                                    <tr className="order-credits-required-row">
                                        <td><strong>Credits required</strong></td>
                                        <td><strong>{perParticipantsCreditCharge * formData.participants_selected + publishing_credits} credits</strong></td>
                        </tr>*/}
                                        {/* </tbody>
                            </table>
                        </div>*/}
                                        {/*<Text type={"body-text-2"} fontWeight={"medium-font"}>How many participants do you want to recruit?</Text>

                        <div className='estimate-count-area'>
                            <IncDecCounter readOnly={false} value={formData.participants_selected} min={5} max={6000} onChange={(value) => {
                                if (parseInt(value) < 1) {
                                    value = 1;
                                }
                                setFormData({ ...formData, participants_selected: value });
                                var form_data = { ...formData, participants_selected: value };
                                
                                setValidateForm(true);

                                saveTargetGroupApi(form_data, 0);
                            }} />
                            <Text type={"caption"} fontWeight={"medium-font"} >{perParticipantsCreditCharge} credits for each participant</Text>
                        </div>

                        <div className='estimate-panel-footer'>
                            <Text type={"body-text-3"} fontWeight={"semi-bold-font"}>Credits required</Text>
                            <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>{perParticipantsCreditCharge * formData.participants_selected} credits</Text>
                        </div>*/}
                                    </>
                                </div>
                                {getEstimatedPanelColor() == "red" && (
                                    <div className="inline-error-message">
                                        <Icon
                                            value={"error"}
                                            colorClass={"danger-200-svg"}
                                            size={"medium"}
                                        />
                                        <Text
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                        >
                                            You may get results{" "}
                                            <span className="text-color-red">
                                                very slowly
                                            </span>
                                            . Try to reduce the number of
                                            participants or change the selected
                                            criteria.
                                        </Text>
                                    </div>
                                )}
                                {formValues.is_screening_questions == 1 && (
                                    <div className="inline-error-message">
                                        <Icon
                                            value={"warning"}
                                            colorClass={"warning-200-svg"}
                                            size={"medium"}
                                        />
                                        <Text
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                        >
                                            Please note that the responses may
                                            be slower due to the specific
                                            requirements you have mentioned.
                                        </Text>
                                    </div>
                                )}
                            </div>
                        )}
                        <a
                            onClick={(e) => {
                                e.preventDefault();

                                setShowSupportPanel(true);
                            }}
                            href={process.env.REACT_APP_URL + "r/support"}
                            target="_blank"
                            className="support-button"
                        >
                            <Icon
                                value={"support"}
                                colorClass={"gray-50-svg"}
                                size={"medium"}
                            />
                            Support
                        </a>
                    </div>
                </>
            )}
            <ConfirmationModal
                openModal={clearSelectionsModal.open}
                confirm_title="Reset selections"
                confirm_message={
                    "Are you sure you want to clear your selections?"
                }
                confirm_btn_title={"Yes, reset"}
                cancel_btn_title={"No, keep"}
                close={() => {
                    setClearSelectionsModal({
                        ...clearSelectionsModal,
                        open: false,
                    });
                }}
                btn_reverse={true}
                confirm={() => {
                    resetSelections("all");
                }}
            />

            <ConfirmationModal
                openModal={clearQuestionModal.open}
                confirm_title="Delete screening questions"
                confirm_message={
                    "Are you sure you want to delete your screening questions?"
                }
                confirm_btn_title={"Yes, delete"}
                cancel_btn_title={"No, keep"}
                close={() => {
                    setFormValues({ ...formValues, is_screening_questions: 1 });
                    setClearQuestionModal({
                        ...clearQuestionModal,
                        open: false,
                    });
                }}
                btn_reverse={true}
                confirm={() => {
                    removeQuestions();
                    resetSelections("question");
                }}
            />
            <RecruitmentModal
                open={isRecruitmentModal}
                close={() => {
                    setIsRecruitmentModal(false);
                }}
            />
        </>
    );
}
