
import { useEffect, useRef, useState } from 'react';
import { load } from 'recaptcha-v3';
import { ReactSession } from '../../lib/secure_reactsession';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../data/settings';
import AccountInUseModal from './modals/AccountInUseModal';

let login_token = null;

let verify_token = null;


const loadScript = (src) =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })

const GoogleAuth = ({ role, redirect_to, referralCode, setLoading, setErrorMessage, fromSignup, shortBtn, title, className, planType, inviteCode, alreadyLogin }) => {

    const [formValues, setFormValues] = useState({ email: '', password: '' });
    const [socialLoading, setSocialLoading] = useState(false);
    // const [errorMsg, setErrorMessage] = useState(null);

    const googleButton = useRef(null);
    const navigate = useNavigate();

    const [accountInUseModal, setAccountInUseModal] = useState({open:false});
    
    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = process.env.REACT_APP_GOOGLE_ID

        

        loadScript(src)
            .then(() => {
                /*global google*/
                // console.log(google)
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                })
                if(role == "tester"){
                    google.accounts.id.renderButton(
                        googleButton.current,
                        { theme: 'outline', size: 'large',shape:'pill', width:380 }
                    )

                }else{
                    google.accounts.id.renderButton(
                        googleButton.current,
                        { theme: 'outline', size: 'large',shape:'pill',width:800}
                    )
                    
                }
                
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [inviteCode])

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const generateCaptchaLogin = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;

                setFormValues({ ...formValues, login_token: login_token });

                callback(token);

            })
        })
    }

    function handleCredentialResponse(response) {
        // console.log("Encoded JWT ID token: " + response.credential);

        const responsePayload = parseJwt(response.credential);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);

        setSocialLoading(true);
        setLoading(true);

        const requestPayload = {};

        if (role == "researcher") {


            generateCaptchaLogin(function (token) {
                requestPayload['captcha'] = token;
                requestPayload['tokenId'] = response.credential;
                requestPayload['email'] = responsePayload.email;
                requestPayload['name'] = responsePayload.name;
                requestPayload['familyName'] = responsePayload.family_name;
                requestPayload['givenName'] = responsePayload.given_name;
                requestPayload['googleId'] = responsePayload.sub;
                requestPayload['imageUrl'] = responsePayload.picture;
                if(fromSignup && inviteCode){
                    requestPayload['invite_code'] = inviteCode;
                } else {
                    requestPayload['signup_plan'] = planType;
                }
                


                fetch(process.env.REACT_APP_API_END_POINT + 'social-login/google/Researcher', {
                    method: 'POST',
                    body: JSON.stringify(requestPayload),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => res.json())
                    .then(response => {
                        setSocialLoading(false);
                        setLoading(false);

                        if (response.success) {

                            ReactSession.set("token", response.token);
                            ReactSession.set("user", response.user);

                            Cookies.set("last_login_method_researcher","Google", { expires: 365*24*60*60 });

                            

                            var  date = new Date();

                            Cookies.set("expires_at", date.getTime()+24*60*60*1000);

                            if(response.user.role === "Researcher"){

                                
                                if(response.researcher && response.researcher.completed_step === 1){

                                    window.dataLayer = window.dataLayer || [];
                                    
                                    window.dataLayer.push({
                                        'event' : 'sign_in',
                                        'usertype': 'researcher',
                                        'method': 'google',
                                        'userID' : response.user.user_id
                                    });

                                    var user =  ReactSession.get("user");

                                    user["completed_step"] = response.researcher.completed_step;

                                    ReactSession.set("user", user);
                                    
                                    if(planType){
                                        if(planType === "Team"){
                                            navigate('/r/create-team-workspace');
                                        } else {

                                            ReactSession.set("workspace", {workspace_id: response.workspace.id, type: response.workspace.type});
                                            navigate(`/wsp/${response.workspace_id}`);
                                        }
                                    } else {
                                        if(redirect_to){
                                            navigate(redirect_to);
                                        } else {
                                            if(response.workspace){
                                                ReactSession.set("workspace", {workspace_id: response.workspace.id, type: response.workspace.type});
                                                navigate('/wsp/'+response.workspace.id);    
                                            } else if(response.completed_step==1 && response.user.signup_plan=="Team"){
                                                navigate('/r/create-team-workspace');
                                            } else if(response.completed_step==0){
                                                navigate('/researcher/profile/complete');
                                            }
                                        }
                                    }
                
                                    
                                } else {
                                    navigate('/r/profile/complete');
                                }
                            } else {

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event' : 'sign_in',
                                    'usertype': 'researcher',
                                    'method': 'google',
                                    'userID' : response.user.user_id
                                });
                                if(redirect_to){
                                    navigate(redirect_to);
                                } else {
                                    navigate('/dashboard');
                                }
                            }

                        } else {

                            if(response.message=="Already loggedin."){
                                if(alreadyLogin){
                                    alreadyLogin();
                                }
                            } else {
                                setErrorMessage(response.message);
                            }
                        }
                    });
            })

        }

        if (role == "tester") {

            generateCaptchaLogin(function (token) {
                requestPayload['captcha'] = token;
                requestPayload['tokenId'] = response.credential;
                requestPayload['referralCode'] = referralCode;
                requestPayload['test_result_id'] = ReactSession.get("test_result_id");
                requestPayload['email'] = responsePayload.email;
                requestPayload['name'] = responsePayload.name;
                requestPayload['familyName'] = responsePayload.family_name;
                requestPayload['givenName'] = responsePayload.given_name;
                requestPayload['googleId'] = responsePayload.sub;
                requestPayload['imageUrl'] = responsePayload.picture;

                fetch(process.env.REACT_APP_API_END_POINT + 'social-login/google/Tester', {
                    method: 'POST',
                    body: JSON.stringify(requestPayload),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => res.json())
                    .then(response => {
                        setSocialLoading(false);
                        setLoading(false);

                        if (response.success) {
                            ReactSession.set("token", response.token);
                            
                            ReactSession.set("user", response.user);

                            var date = new Date();
                            Cookies.set("expires_at", date.getTime()+10*24*60*60*1000);

                            Cookies.set("last_login_method_tester","Google", { expires: 365*24*60*60 });

                            if (response.user.role === "Tester") {

                                
                                
                                if (response.tester && response.tester.completed_step === 8) {

                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'event' : 'sign_in',
                                        'usertype': 'tester',
                                        'method': 'google',
                                        'userID' : response.user.user_id
                                    });
                                    
                                    var user =  ReactSession.get("user");

                                    user["completed_step"] = response.tester.completed_step;

                                    ReactSession.set("user", user);

                                    if(response.tester.tester_profile_step > 0){
                                        navigate('/tester/profile/complete/step'+response.tester.tester_profile_step);
                                    } else {
                                        navigate('/dashboard');
                                    }

                                    
                                } else {
                                    navigate('/tester/profile/step1');
                                }
                            } else if (response.user.role === "Researcher") {

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event' : 'sign_in',
                                    'usertype': 'researcher',
                                    'method': 'google',
                                    'userID' : response.user.user_id
                                });
                                navigate('/dashboard');

                            } else {

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event' : 'sign_in',
                                    'usertype': 'tester',
                                    'method': 'google',
                                    'userID' : response.user.user_id
                                });
                                navigate('/dashboard');
                            }

                        } else {

                            showError(response.message);
                        }
                    });
            })

        }

    }
    const showError = (error) => {
        
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    return (
        <>
        {role=="researcher" && (
        <>
        <div className={`position-relative google-icon-hold researcher-google-icon-hold ${className} `}>
            <div ref={googleButton} className={`custom-google-signin ${role == "researcher" ? "researcher-google-signin" : "tester-google-signin"}`}></div>
            <a href="#" id="r_google_sign_in" className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn">
                {title
                ?
                <>{title}</>
                :
                <>
                <img width={24} height={24} src={process.env.REACT_APP_URL+"images/sign-in-wgoogle.svg"} alt="icon"/> 
                {fromSignup?"Sign up":"Sign in"} with Google
                </>
                }
            </a>
        </div>
        </>
        )}
        {role=="tester" && (
            <>
            {shortBtn &&
            <span className='position-relative google-icon-hold'>
                <div ref={googleButton} className={`custom-google-signin ${role == "researcher" ? "researcher-google-signin" : "tester-google-signin"}`}></div>
                <a id='t_google_sign_in' href="#" ><img width={24} height={24} src={process.env.REACT_APP_URL+"img/g.svg"} alt="icon"/></a></span>
            }
            {!shortBtn &&
            <div className={`position-relative google-icon-hold researcher-google-icon-hold ${className}`} style={{width:"100%"}}>
                <div ref={googleButton} className={`custom-google-signin ${role == "researcher" ? "researcher-google-signin" : "tester-google-signin"}`}></div>
                <a href="#" id="t_google_sign_in" className="btn btn-block form-btn justify-content-center sign-in-google-btn errorsociallogin">
                    {title
                    ?
                    <>{title}</>
                    :
                    <>
                    <img width={24} height={24} src={process.env.REACT_APP_URL+"images/sign-in-wgoogle.svg"} alt="icon"/> {fromSignup?"Sign up":"Sign in"} with Google
                    </>
                    }
                </a>
            </div>
            }
            </>

        )}
        </>
    )
}

export default GoogleAuth