import {
  useNavigate,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useRef, useState } from "react";
import { getTestData, saveSurveyQuestions } from "../../../services/test";
import {
  createSurveyQuestion,
  deleteSingleSurveyQuestion,
  updateSingleSurveyQuestion,
  reorderSurveyQuestion,
  duplicateSurveyQuestion,
  updateLogicSurveyQuestion,
} from "../../../services/survey_create_test";
import moment from "moment";
import toast from "react-hot-toast";
import { LayoutResearcher } from "./../../themes/userq/Layouts/layout_researcher";
import { Stepper } from "./../../themes/userq/Stepper/Stepper";
import { Button } from "./../../themes/userq/Button/Button";
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from "./../../themes/userq/Accordion/Accordion";
import { Icon } from "./../../themes/userq/Icon/Icon";
import Header from "./components/header";
import { Text } from "../../themes/userq/Text/Text";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import { Chip } from "../../themes/userq/Chips/Chips";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Input } from "../../themes/userq/Input/Input";
import InputField from "../../layouts/elements/fields/input";
import {
  copyToClipboard,
  encryptId,
  renderTestTitle,
  renderTestTitleCreate,
  showToast,
} from "../../../lib/helpers";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import { QuestionInput } from "../../themes/userq/Question/QuestionInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { IntroductionQuestions } from "./introduction_questions.js";
import { ConclusionQuestions } from "./conclusion_questions";
import EditQuestion from "./survey/editQuestion";
import Frame from "../study/frame/frame";
import Questions from "./survey/questions";
import { isEmpty } from "lodash";
import _ from "lodash";
import { QuestionCard } from "../../themes/userq/QuestionCard/QuestionCard";
import { Question } from "../../themes/userq/Question/Question";
import { EditQuestionInput } from "../../themes/userq/Question/EditQuestionInput";
import { settings } from "../../../data/settings.js";
import { GuideLink } from "../../themes/userq/GuideLink/GuideLink.js";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";
import Skeleton from "react-loading-skeleton";
import QuestionsSkeleton from "../../themes/userq/QuestionsSkeleton/QuestionsSkeleton.js";
import SurveyCreatorAi from "./survey/survey_creator_ai.js";
import AIModal from "../../themes/userq/AIModal/AIModal.js";
import HelpModal from "../../themes/userq/Modal/HelpModal.js";

var skeleton_survey_questions_length = 0;

export default function Survey() {
  let { test_id } = useParams();
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const dataDuplicateFetchedRef = useRef(false);
  const dataReorderFetchedRef = useRef(false);
  ReactSession.setStoreType("localStorage");
  const [formValues, setFormValues] = useState({
    tree: [],
    tasks: [],
  });
  const [pageLoading, setPageLoading] = useState(false);

  const { updateTestLength, getTestLength, allowEditAccess } =
    useTestBuilderData();

  const [surveyStepLoading, setSurveyStepLoading] = useState(false);
  const [language, setLanguage] = useState("en");
  const [questionType, setQuestionType] = useState("");
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  const [lastSaved, setLastSaved] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(false);
  const [edittingSurveyQuestions, setEdittingSurveyQuestions] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);

  const [confirmModal, setConfirmModal] = useState({ open: false });

  const [test, setTest] = useState("");

  const [step, setStep] = useState(0);
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [dataSaving, setDataSaving] = useState(false);

  const [isSaved, setIsSaved] = useState({ status: "", message: "" });
  const [credits, setCredits] = useState(0);
  const [formErrors, setFormErrors] = useState({
    questions: null,
    error_class: null,
  });

  const [stepperError, setStepperError] = useState(false);
  const [searchParams] = useSearchParams();

  const [introCount, setIntroCount] = useState(0);
  const [concCount, setConcCount] = useState(0);
  const [introductionQuestions, setIntroductionQuestions] = useState(null);
  const [conclusionQuestions, setConclusionQuestions] = useState(null);

  const [accordians, setAccordians] = useState({
    introduction: false,
    survey: searchParams.get("error") == "true" ? true : false,
    conclusion: false,
  });

  const disableNextButton = useRef(false);
  const [nextbtnDisabled, SetNextbtnDisabled] = useState(false);
  // const questionsArraywithErrorLogics = useRef([]);
  const questionsArraywithUpdatedLogics = useRef({});
  const [surveyError, setSurveyError] = useState(null);

  // check question validate error ref
  const questionInputValidateRef = useRef(null);
  const introductionQuestionRef = useRef(null); // introduction ref
  const conclusionQuestionRef = useRef(null); // conclusion ref

  const [show_support_panel, setShowSupportPanel] = useState(false);

  const [questionsSkeletonLoading, setQuestionsSkeletonLoading] =
    useState(false);

  const [surveyCreatorMode, setSurveyCreatorMode] = useState("classic");

  const [showAIModal, setShowAIModal] = useState({ type: "", show: false });

  const [surveyGoalModal, setSurveyGoalModal] = useState({ open: false });

  const [isAIGenerated, setAIGenerated] = useState(false);

  var update_logic_called_after_service = "";

  var duplicate_question_index = null;

  var jump_to_last_question_index = null;

  const cancelQuestionHandler = () => {
    if (surveyQuestions.length != 0) {
      setAddingSurveyQuestions(true);
    }
    setQuestionType("");
    setEdittingSurveyQuestions(false);
  };
  const cancelEditQuestionHandler = () => {
    setEdittingSurveyQuestions(false);
    setEditQuestionId(null);
  };

  const removequestion = (index) => {
    /* const list = [...surveyQuestions];
        console.log(list)
        var deletedQuestionId = list[index].id;
        console.log(deletedQuestionId)
        list.splice(index, 1);
        setSurveyQuestions(list);
        if (list.length == 0) {
            setAddingSurveyQuestions(true);
        }

        updatelogicafterQuestionDelete(index);*/

    const list = [...surveyQuestions];

    var deletedQuestionId = list[index].id;

    let token = ReactSession.get("token");

    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    data.append("id", deletedQuestionId);

    update_logic_called_after_service = "delete_question";

    skeleton_survey_questions_length = surveyQuestions.length;

    setQuestionsSkeletonLoading(true);

    deleteSingleSurveyQuestion(data, token).then((response) => {
      if (response.success) {
        setQuestionsSkeletonLoading(true);

        getTestLength(test_id);

        setIsSaved({ status: "success", message: "" });

        const list = [...surveyQuestions];

        list.splice(index, 1);

        setSurveyQuestions(list);

        if (list.length == 0) {
          setAddingSurveyQuestions(true);

          setAIGenerated(false);
        }
        update_logic_called_after_service = "delete_question";

        if (index == surveyQuestions.length - 1) {
          jump_to_last_question_index = index - 1;
        } else {
          jump_to_last_question_index = null;
        }

        updatelogicafterQuestionDelete(index);
      } else {
        setQuestionsSkeletonLoading(false);

        setIsSaved({ status: "error", message: response.message });

        showError(response.message);
      }
    });
  };

  const updateApiAfterLogicsUpdated = (
    questionindex,
    surveyQuestionsEditData
  ) => {
    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    data.append("survey_questions", JSON.stringify(surveyQuestionsEditData));
    data.append("index_questions", questionindex + 1);
    updateSingleSurveyQuestion(data, token).then((response) => {
      if (response.success) {
        getTestLength(test_id);
        setLastSaved(moment.now());
        setIsSaved({ status: "success", message: "" });
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });
  };

  // updated logics question api
  const updateLogicSurveyQuestionUpdated = (dataQuestion) => {
    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    dataQuestion.forEach((item) => {
      data.append("survey_questions[]", JSON.stringify(item));
    });
    setQuestionsSkeletonLoading(true);

    updateLogicSurveyQuestion(data, token).then((response) => {
      setQuestionsSkeletonLoading(false);

      if (response.success) {
        getTestLength(test_id);
        setLastSaved(moment.now());
        setIsSaved({ status: "success", message: "" });

        if (update_logic_called_after_service == "duplicate_question") {
          var fixedElementHeight =
            document.querySelector(".researcher-header").offsetHeight +
            document.querySelector(".three-column-layout-top-header")
              .offsetHeight;
          var offsetTop = Position(
            document.getElementById("ques-" + duplicate_question_index)
          );
          window.scrollTo({
            top: offsetTop - fixedElementHeight + 0,
            behavior: "smooth",
          });

          // add class and remove classs
          var duplicateQuestionElement = document.getElementById(
            "question-" + (duplicate_question_index + 1)
          );
          if (duplicateQuestionElement) {
            duplicateQuestionElement.classList.add(
              "question-duplicate-animation"
            );

            setTimeout(function () {
              duplicateQuestionElement.classList.remove(
                "question-duplicate-animation"
              );
              // console.log(duplicateQuestionElement);
            }, 5000);
          }
        } else if (update_logic_called_after_service == "delete_question") {
          if (jump_to_last_question_index) {
            var fixedElementHeight =
              document.querySelector(".researcher-header").offsetHeight +
              document.querySelector(".three-column-layout-top-header")
                .offsetHeight;
            var offsetTop = Position(
              document.getElementById("question-" + jump_to_last_question_index)
            );
            window.scrollTo({
              top: offsetTop - fixedElementHeight - 20,
              behavior: "smooth",
            });
          }
        }
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });
  };

  const updatelogicafterQuestionDelete = (deletedQuestionIndex) => {
    var showlogicupdatedtoast = false;
    var addLogicsArrayUpdated = [];

    console.log(surveyQuestions);

    surveyQuestions.forEach((question, index) => {
      if (index < deletedQuestionIndex && question.is_logic == 1) {
        if (question.questionType != "singlechoice") {
          if (
            question.jump_to_question != "End Survey" &&
            question.error == 0
          ) {
            if (question.jump_to_question > deletedQuestionIndex + 1) {
              question.jumpTo = question.jumpTo - 1;
              question.jump_to_question = question.jump_to_question - 1;
              if (question.manuallyupdated == true) {
                question.logicupdated = true;
                showlogicupdatedtoast = true;
              }
              questionsArraywithUpdatedLogics.current[index] = true;
              surveyQuestions.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one run api
            } else if (question.jump_to_question == deletedQuestionIndex + 1) {
              if (
                deletedQuestionIndex == surveyQuestions.length - 1 &&
                question.manuallyupdated == false
              ) {
                question.jumpTo = "End Survey";
                question.jump_to_question = "End Survey";
              }
              if (question.manuallyupdated == true) {
                question.error = 1;
                question.errorvalue = "This question no longer exists";
                showlogicupdatedtoast = true;
              }
              surveyQuestions.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one run api
            }
          }
        } else {
          var jumparray = [];
          var logicupdatedarray = [];
          var error = [];
          var errorValue = [];
          question.jumpTo.forEach((jumptooption, i) => {
            if (jumptooption != "End Survey" && question.error[i] == 0) {
              if (jumptooption > deletedQuestionIndex + 1) {
                // console.log("questionwhosejumptogreater than deleted question");
                jumparray.push(jumptooption - 1);
                errorValue.push("");
                error.push(0);
                if (question.manuallyupdated[i] == true) {
                  logicupdatedarray.push(true);
                  showlogicupdatedtoast = true;
                } else {
                  logicupdatedarray.push(false);
                }
              } else if (jumptooption == deletedQuestionIndex + 1) {
                // console.log("questionwhosejumpto equals deleted question");
                if (
                  deletedQuestionIndex == surveyQuestions.length - 1 &&
                  question.manuallyupdated[i] == false
                ) {
                  jumparray.push("End Survey");
                } else {
                  jumparray.push(jumptooption);
                }
                if (question.manuallyupdated[i] == true) {
                  error.push(1);
                  errorValue.push("This question no longer exists");
                  logicupdatedarray.push(true);
                  showlogicupdatedtoast = true;
                } else {
                  error.push(0);
                  errorValue.push("");
                  logicupdatedarray.push(false);
                }
              } else {
                jumparray.push(jumptooption);
                error.push(0);
                errorValue.push("");
                logicupdatedarray.push(false);
              }
            } else {
              jumparray.push(jumptooption);
              if (question.manuallyupdated[i] == true) {
                error.push(question.error[i]);
                errorValue.push(question.errorValue[i]);
                showlogicupdatedtoast = true;
              } else {
                error.push(0);
                errorValue.push("");
              }
              logicupdatedarray.push(question.logicupdated[i]);
            }
          });
          question.jumpTo = jumparray;
          question.logicupdated = logicupdatedarray;
          question.errorValue = errorValue;
          question.error = error;
          questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
          surveyQuestions.splice(index, 1, question);

          addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one run api
        }
      }
      if (index >= deletedQuestionIndex && question.is_logic == 1) {
        if (question.questionType != "singlechoice") {
          if (
            question.jump_to_question != "End Survey" &&
            question.error == 0
          ) {
            question.jumpTo = question.jumpTo - 1;
            question.jump_to_question = question.jump_to_question - 1;
            if (question.manuallyupdated == true) {
              question.logicupdated = true;
              if (index != deletedQuestionIndex) {
                showlogicupdatedtoast = true;
              }
            }
            questionsArraywithUpdatedLogics.current[index] = true;
            surveyQuestions.splice(index, 1, question);

            addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one run api
          }
        } else {
          var jumparray = [];
          var logicupdatedarray = [];
          var error = [];
          var errorValue = [];
          question.jumpTo.forEach((jumptooption, i) => {
            if (jumptooption != "End Survey" && question.error[i] == 0) {
              jumparray.push(jumptooption - 1);
              if (question.error[i] == 0) {
                errorValue.push("");
                error.push(0);
              } else {
                errorValue.push(question.errorValue[i]);
                error.push(1);
              }
              if (question.manuallyupdated[i] == true) {
                logicupdatedarray.push(true);
                if (index != deletedQuestionIndex) {
                  showlogicupdatedtoast = true;
                }
              } else {
                logicupdatedarray.push(false);
              }
            } else {
              jumparray.push(jumptooption);
              if (question.manuallyupdated[i] == true) {
                error.push(question.error[i]);
                errorValue.push(question.errorValue[i]);
                if (index != deletedQuestionIndex) {
                  showlogicupdatedtoast = true;
                }
              } else {
                error.push(0);
                errorValue.push("");
              }
              logicupdatedarray.push(question.logicupdated[i]);
            }
          });
          question.jumpTo = jumparray;
          question.logicupdated = logicupdatedarray;
          question.errorValue = errorValue;
          question.error = error;
          questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
          surveyQuestions.splice(index, 1, question);

          addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one run api
        }
      }
    });

    // console.log(addLogicsArrayUpdated);
    updateLogicSurveyQuestionUpdated(addLogicsArrayUpdated); // run api

    validatelogic(surveyQuestions, false);
    if (showlogicupdatedtoast == true) {
      showLogicUpdatedToast(
        "The question has been deleted and the logics updated"
      );
    }
  };

  const toggleLogicHandler = (logicstatus, questionindex, questionType) => {
    const surveyQuestionsEdit = [...surveyQuestions];
    if (questionType === "singlechoice") {
      surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
      surveyQuestionsEdit[questionindex]["jumpTo"] = [];
      var jumptolist = [];
      var errorlist = [];
      var logicupdatedlist = [];
      var manuallyupdatedlist = [];
      var errorValuelist = [];
      surveyQuestionsEdit[questionindex].options.forEach(() => {
        jumptolist.push(
          questionindex + 1 < surveyQuestionsEdit.length
            ? questionindex + 2
            : "End Survey"
        );
        errorlist.push(0);
        errorValuelist.push("");
        manuallyupdatedlist.push(false);
        logicupdatedlist.push(false);
      });
      surveyQuestionsEdit[questionindex]["jumpTo"] = jumptolist;
      surveyQuestionsEdit[questionindex]["error"] = errorlist;
      surveyQuestionsEdit[questionindex]["errorValue"] = errorValuelist;
      surveyQuestionsEdit[questionindex]["manuallyupdated"] =
        manuallyupdatedlist;
      surveyQuestionsEdit[questionindex]["logicupdated"] = logicupdatedlist;

      setSurveyQuestions(surveyQuestionsEdit);
    } else {
      surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
      surveyQuestionsEdit[questionindex]["jump_to_question"] =
        questionindex + 1 < surveyQuestionsEdit.length
          ? questionindex + 2
          : "End Survey";
      surveyQuestionsEdit[questionindex]["error"] = 0;
      surveyQuestionsEdit[questionindex]["errorvalue"] = "";
      surveyQuestionsEdit[questionindex]["manuallyupdated"] = false;
      surveyQuestionsEdit[questionindex]["logicupdated"] = false;
      setSurveyQuestions(surveyQuestionsEdit);
    }

    console.log(surveyQuestionsEdit);

    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    data.append(
      "survey_questions",
      JSON.stringify(surveyQuestionsEdit[questionindex])
    );
    data.append("index_questions", questionindex + 1);
    updateSingleSurveyQuestion(data, token).then((response) => {
      if (response.success) {
        getTestLength(test_id);

        setLastSaved(moment.now());
        setIsSaved({ status: "success", message: "" });
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });

    validatelogic(surveyQuestionsEdit, false);
  };

  const saveOptionJumpToHandler = (
    optionindex,
    value,
    questionType,
    questionindex
  ) => {
    const surveyQuestionsEdit = [...surveyQuestions];
    if (questionType === "singlechoice") {
      let question = Object.assign({}, surveyQuestions[questionindex]);
      question["jumpTo"][optionindex] = value;
      if (question["error"]) {
        question["error"][optionindex] = 0;
      }
      if (question["logicupdated"]) {
        question["logicupdated"][optionindex] = false;
      }
      question["manuallyupdated"][optionindex] = true;

      surveyQuestionsEdit.splice(questionindex, 1, question);
      setSurveyQuestions([...surveyQuestionsEdit]);
    } else {
      let question = Object.assign({}, surveyQuestions[questionindex]);
      question["jump_to_question"] = value;
      if (question["error"]) {
        question["error"] = 0;
      }
      if (question["logicupdated"]) {
        question["logicupdated"] = false;
      }
      question["manuallyupdated"] = true;
      surveyQuestionsEdit.splice(questionindex, 1, question);
      setSurveyQuestions([...surveyQuestionsEdit]);

      // var errorArray=questionsArraywithErrorLogics.current;
      // if(errorArray.includes(questionindex)){          //checking weather array contain the id
      //     errorArray.splice(errorArray.indexOf(questionindex), 1);               //adding to array because value doesnt exists
      // }
      // questionsArraywithErrorLogics.current=errorArray;
    }

    console.log(surveyQuestionsEdit);

    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    data.append(
      "survey_questions",
      JSON.stringify(surveyQuestionsEdit[questionindex])
    );
    data.append("index_questions", questionindex + 1);

    //setQuestionsSkeletonLoading(true);

    updateSingleSurveyQuestion(data, token).then((response) => {
      //setQuestionsSkeletonLoading(false);

      if (response.success) {
        getTestLength(test_id);
        setLastSaved(moment.now());
        setIsSaved({ status: "success", message: "" });
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });

    validatelogic(surveyQuestionsEdit, false);
  };

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }

  const duplicatequestion = (index) => {
    if (!edittingSurveyQuestions) {
      if (surveyQuestions.length < 120) {
        // let listExisting = [...surveyQuestions];
        // let questionExisting = _.cloneDeep(surveyQuestions[index]);
        // listExisting.splice(index, 0, questionExisting);

        // duplicate question api call
        if (dataDuplicateFetchedRef.current) {
          dataDuplicateFetchedRef.current.abort();
        }
        const controller = new AbortController();

        dataDuplicateFetchedRef.current = controller;

        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test_id);
        data.append("duplicateRowIndex", index + 1);
        data.append("duplicateRowId", surveyQuestions[index].id);

        setIsSaved({ status: "loading", message: "" });

        // listExisting.forEach((item) => {
        //     data.append("survey_questions[]", JSON.stringify(item));
        // });
        skeleton_survey_questions_length = surveyQuestions.length;

        setQuestionsSkeletonLoading(true);

        duplicateSurveyQuestion(
          data,
          token,
          dataDuplicateFetchedRef.current?.signal
        ).then((response) => {
          if (response.success) {
            getTestLength(test_id);

            update_logic_called_after_service = "duplicate_question";

            let list = [...surveyQuestions];
            // let question = Object.assign({},surveyQuestions[index]);
            let question = _.cloneDeep(surveyQuestions[index]);
            // console.log(question)
            // list.push(question);
            list.splice(index, 0, question);

            list[index + 1].id = response.newAddedDuplicateQuestionId;
            // console.log(list)
            setSurveyQuestions(list);

            duplicate_question_index = index;

            updatelogicafterQuestionDuplicate(index, list);

            //scrolling to duplicated question

            var fixedElementHeight =
              document.querySelector(".researcher-header").offsetHeight +
              document.querySelector(".three-column-layout-top-header")
                .offsetHeight;
            var offsetTop = Position(document.getElementById("ques-" + index));
            window.scrollTo({
              top: offsetTop - fixedElementHeight + 0,
              behavior: "smooth",
            });

            // add class and remove classs
            var duplicateQuestionElement = document.getElementById(
              "question-" + (index + 1)
            );
            if (duplicateQuestionElement) {
              duplicateQuestionElement.classList.add(
                "question-duplicate-animation"
              );

              setTimeout(function () {
                duplicateQuestionElement.classList.remove(
                  "question-duplicate-animation"
                );
                // console.log(duplicateQuestionElement);
              }, 5000);
            }
            //console.log(duplicateQuestionElement)

            setLastSaved(moment.now());
            setIsSaved({ status: "success", message: "" });
          } else {
            setQuestionsSkeletonLoading(false);
            setIsSaved({ status: "error", message: response.message });
            showError(response.message);
          }
        });
      } else {
        showError(
          "You've reached the maximum number of questions. If you wish to add more, please consider removing some first."
        );
      }
    } else {
      showError("Finish editing question");
    }
  };

  const updatelogicafterQuestionDuplicate = (duplicateQuestionIndex, list) => {
    var showlogicupdatedtoast = false;
    var addLogicsArrayUpdated = [];
    list.forEach((question, index) => {
      if (question.is_logic == 1) {
        if (question.questionType != "singlechoice") {
          if (question.manuallyupdated == true) {
            if (question.jump_to_question != "End Survey") {
              if (question.jump_to_question > duplicateQuestionIndex + 1) {
                question.jumpTo = question.jumpTo + 1;
                question.jump_to_question = question.jump_to_question + 1;
                question.logicupdated = true;
                showlogicupdatedtoast = true;
                questionsArraywithUpdatedLogics.current[index] = true;
                list.splice(index, 1, question);

                addLogicsArrayUpdated.push({
                  indexing: index + 1,
                  data: question,
                }); // push data in array for one
              }
            }
          } else {
            if (index == list.length - 1) {
              question.jumpTo = "End Survey";
              question.jump_to_question = "End Survey";
              // question.logicupdated = true;
              questionsArraywithUpdatedLogics.current[index] = true;
              list.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
            } else {
              question.jumpTo = index + 2;
              question.jump_to_question = index + 2;
              // question.logicupdated = true;
              questionsArraywithUpdatedLogics.current[index] = true;
              list.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
            }
          }
        } else {
          var jumparray = [];
          var logicupdatedarray = [];
          question.jumpTo.forEach((jumptooption, i) => {
            if (question.manuallyupdated[i] == true) {
              if (jumptooption != "End Survey") {
                if (jumptooption > duplicateQuestionIndex + 1) {
                  jumparray.push(jumptooption + 1);
                  logicupdatedarray.push(true);
                  showlogicupdatedtoast = true;
                } else {
                  jumparray.push(jumptooption);
                  logicupdatedarray.push(question.manuallyupdated[i]);
                }
              } else {
                jumparray.push(jumptooption);
                logicupdatedarray.push(false);
              }
            } else {
              if (index == list.length - 1) {
                jumparray.push("End Survey");
              } else {
                jumparray.push(index + 2);
              }
              logicupdatedarray.push(false);
            }
          });
          question.jumpTo = jumparray;
          question.logicupdated = logicupdatedarray;
          questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
          list.splice(index, 1, question);

          addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one
        }
      }
    });
    setSurveyQuestions(list);

    updateLogicSurveyQuestionUpdated(addLogicsArrayUpdated); // run api

    validatelogic(list, false);
    if (showlogicupdatedtoast == true) {
      showLogicUpdatedToast("Logic has been updated");
    }
  };

  const edittingQuestionsHandler = (index) => {
    if (!edittingSurveyQuestions) {
      setEdittingSurveyQuestions(true);
      setEditQuestionId(index);
    } else {
      showError("Finish editing earlier question.");
    }
  };

  const resetFormErrors = () => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    setFormErrors(formErrorsLocal);

    return formErrorsLocal;
  };

  const validateFullForm = (surveyQuestions, showToast) => {
    var error = false;
    var form_errors = resetFormErrors();

    let nonoptionalcount = 0;
    surveyQuestions.forEach((question) => {
      if (question.is_optional == 0) {
        nonoptionalcount = 1;
      }
    });
    if (nonoptionalcount == 0) {
      error = true;
      if (surveyQuestions.length == 0) {
        // form_errors = {
        //     ...form_errors,
        //     questions: "Required field",
        //     error_class: "input_error"
        // };
        if (showToast) {
          showError(
            "Please add at least one question for the survey before proceeding."
          );
        }
        localStorage.setItem(
          "stepper_error",
          "Please add at least one question for the survey before proceeding."
        );
        setStepperError(true);
        setSurveyError("At least one question is required");
      } else {
        // form_errors = {
        //     ...form_errors,
        //     questions: "Atleast one question should not be optional.",
        //     error_class: "input_error"
        // };
        if (showToast) {
          showError("At least one question should not be optional.");
        }
        localStorage.setItem(
          "stepper_error",
          "At least one question should not be optional."
        );
        setStepperError(true);
        setSurveyError("At least one question should not be optional.");
      }
      // setFormErrors(form_errors);
    }
    if (error) {
      document.getElementById("survey-error").scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    return error;
  };

  const surveyValidateForm = () => {
    var error = false;

    var checkIntroductionQuestionIfErrorExits = true;

    //
    if (introductionQuestionRef.current) {
      checkIntroductionQuestionIfErrorExits =
        introductionQuestionRef.current.questionValidateForm(true);
    }

    // check if any error exists in introduction question
    if (
      !checkIntroductionQuestionIfErrorExits &&
      checkIntroductionQuestionIfErrorExits != undefined
    ) {
      console.log("error exits intro");

      return false;
    }

    error = validateFullForm(surveyQuestions, true);

    // check if any question has enable editing then show error
    if (edittingSurveyQuestions && questionInputValidateRef.current) {
      // showError("Some error")
      var checkIfErrorExits =
        questionInputValidateRef.current.handleQuestionSubmit(true);
      // console.log(questionInputValidateRef.current)
      //handleQuestionSubmitValidate();
      if (!checkIfErrorExits) {
        handleSubmitTest(error);
      }
      return false;
    } else {
      handleSubmitTest(error);
    }
  };

  const handleSubmitTest = (error) => {
    console.log("yes");

    var checkConclusionQuestionIfErrorExits = true;

    if (conclusionQuestionRef.current) {
      checkConclusionQuestionIfErrorExits =
        conclusionQuestionRef.current.questionValidateForm(true);
    }

    // check if any error exists in conclusion question
    if (
      !checkConclusionQuestionIfErrorExits &&
      checkConclusionQuestionIfErrorExits != undefined
    ) {
      console.log("error exits conclusion");

      return false;
    }

    if (!error) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "create_test_step2",
        type: test.methodology,
        userID: ReactSession.get("user")
          ? ReactSession.get("user").user_id
          : "",
      });
      navigate("/r/test/" + test_id + "/thank-you/");
    }
  };

  const saveSurvey = (saveUsingNavigation) => {
    var error = false;
    if (saveUsingNavigation == false) {
      error = validateFullForm(surveyQuestions, true);
    }

    if (!error) {
      /*if (dataFetchedRef.current) {
                dataFetchedRef.current.abort();

            }
            const controller = new AbortController();

            dataFetchedRef.current = controller;*/

      let token = ReactSession.get("token");
      // if (!saveUsingNavigation) {
      //     setSurveyStepLoading(true);
      // }
      var data = new FormData();

      data.append("test_id", test_id);

      if (!saveUsingNavigation) {
        data.append("saved_step", 2);
      }

      //setIsSaved({ 'status': 'loading', 'message': '' });

      surveyQuestions.forEach((item) => {
        data.append("survey_questions[]", JSON.stringify(item));
      });
      /*saveSurveyQuestions(data, token, dataFetchedRef.current?.signal).then((response) => {
                setSurveyStepLoading(false);
                if (response.success) {

                    setIsSaved({ 'status': 'success', 'message': '' });

                    setLastSaved(moment.now());
                    if (!saveUsingNavigation) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'create_test_step2',
                            'type': test.methodology,
                            'userID': ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                        });
                        navigate("/r/test/" + test_id + "/thank-you/");
                    }
                } else {
                    setIsSaved({ 'status': 'error', 'message': response.message });
                    showError(response.message);
                }
            });*/
    }
  };

  const showError = (error) => {
    toast(
      <ToastMessage
        type={"error"}
        message={error}
        closable={false}
        onClose={() => {
          toast.dismiss();
        }}
      />,
      {
        id: "error",
        className: "errortoast",
        position: "bottom-center",
        duration: settings.toast_duration,
      }
    );
  };

  const showLogicUpdatedToast = (message) => {
    toast(
      <ToastMessage
        type={"logic"}
        message={message}
        closable={false}
        onClose={() => {
          toast.dismiss();
        }}
      />,
      {
        className: "successtoast",
        position: "bottom-center",
        duration: 5000,
      }
    );
  };

  const getTestApi = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    getTestData({ test_id: test_id }, token).then((response) => {
      setLoading(false);

      if (response.success) {
        if (response.test.workspace_project) {
          if (response.workspace_project_team_member) {
            if (response.workspace_project_team_member.status != "active") {
              navigate(
                "/wsp/" +
                  response.test.workspace_project.workspace_id +
                  "/p/" +
                  response.test.workspace_project.id
              );
            }
          }
        }
        allowEditAccess({
          test_id: test_id,
          workspace_id: response.test.workspace_id,
          workspace_project_id: response.test.workspace_project_id,
        });

        getTestLength(test_id);

        setTest(response.test);
        setLanguage(response.test.language);
        setCredits(response.settings.publishing_credits);
        setIntroCount(response.test.briefingQuestion.length);
        setConcCount(response.test.debriefingQuestion.length);
        setIntroductionQuestions(response.test.briefingQuestion);
        setConclusionQuestions(response.test.debriefingQuestion);

        if (response.test.status) {
          if (response.test.status == "published") {
            navigate("/r/project/" + response.test.project_id);
          } else {
            if (
              response.test.welcome_title == "" ||
              response.test.welcome_description == ""
            ) {
              localStorage.setItem(
                "stepper_error",
                "The welcome page cannot be empty! Please enter a title and a description."
              );
              navigate("/r/test/" + test_id + "/welcome?error=true");
              showError(
                "The welcome page cannot be empty! Please enter a title and a description."
              );
            }
          }
        }

        setLastSaved(moment(response.test.updated_at));

        let questions = [];
        response.test.surveyQuestionSplitApi.forEach((questionData) => {
          var questionList = JSON.parse(questionData.questions);
          questionList.id = questionData.id;
          questionList.is_ai_generated = questionData.is_ai_generated;

          if (questionData.is_ai_generated >= 1) {
            setAIGenerated(true);
          }
          questions.push(questionList);
        });
        // if (questions.length < 1) {
        setAddingSurveyQuestions(true);
        // }

        //console.log(questions)
        setSurveyQuestions(questions);

        if (searchParams.get("error") == "true") {
          validateFullForm(questions, false);
          validatelogic(questions, true);
        } else {
          validatelogic(questions, false);
        }
        setFormValues({ tree: response.test.tree, tasks: response.test.tasks });
      } else {
        if (response.message == "Project not found.") {
          navigate("/404");
        } else {
          showError(response.message);
        }
      }
    });
  };

  useEffect(() => {
    //document.title = process.env.REACT_APP_NAME + " - Survey";

    getTestApi();

    if (searchParams.get("error") == "true") {
      setStepperError(true);
    }
  }, []);

  useEffect(() => {
    if (nodeRef.current) {
      nodeRef.current.focus();
    }
    if (test && test.status != "published" && !edittingSurveyQuestions) {
      saveSurvey(true);
    }
  }, [surveyQuestions, edittingSurveyQuestions]);

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop
      ) +
      [...draggedDOM.parentNode.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          const marginTop = parseFloat(style.marginTop);
          return total + curr.clientHeight + marginTop;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
      background: "#CCCCCC",
      border: "none",
    });
  };

  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;

    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        const marginTop = parseFloat(style.marginTop);
        return total + curr.clientHeight + marginTop;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
      background: "none",
      border: "1px dashed",
    });
  };

  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const validatelogic = (questions, scroll) => {
    var error = false;
    var firsterrorid = null;
    questions.forEach((question, index) => {
      if (question.questionType != "singlechoice") {
        if (question.is_logic && question.is_logic == 1) {
          if (question.is_logic == 1 && question.jump_to_question < index + 2) {
            error = true;
            firsterrorid =
              firsterrorid == null ? "question-" + index : firsterrorid;
          }
          if (question.error && question.error == 1) {
            error = true;
            firsterrorid =
              firsterrorid == null ? "question-" + index : firsterrorid;
          }
        }
      } else {
        if (question.is_logic && question.is_logic == 1) {
          question.jumpTo.forEach((jumptoOption, i) => {
            if (jumptoOption != "End Survey" && jumptoOption < index + 2) {
              error = true;
              firsterrorid =
                firsterrorid == null ? "question-" + index : firsterrorid;
            }
            if (question.error && question.error[i] == 1) {
              error = true;
              firsterrorid =
                firsterrorid == null ? "question-" + index : firsterrorid;
            }
          });
        }
      }
    });

    if (error == true) {
      disableNextButton.current = true;
      if (firsterrorid && scroll) {
        document.getElementById(firsterrorid).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setStepperError(true);
      }
    } else {
      disableNextButton.current = false;
      if (questions.length > 0) {
        setStepperError(false);
      }
    }
    return error;
  };

  const updateLogic = (srcI, desI) => {
    if (srcI != desI) {
      var showlogicupdatedtoast = false;
      var addLogicsArrayUpdated = [];
      surveyQuestions.forEach((question, index) => {
        if (index > desI && index <= srcI) {
          // console.log("question moving down due to dragging up");
          if (question.is_logic == 1) {
            if (question.questionType != "singlechoice") {
              if (question.error == 0) {
                if (
                  question.jump_to_question == "End Survey" &&
                  question.manuallyupdated == false
                ) {
                  question.jumpTo = index + 2;
                  question.jump_to_question = index + 2;
                  // question.logicupdated=true;
                  questionsArraywithUpdatedLogics.current[index] = true;
                  surveyQuestions.splice(index, 1, question);

                  addLogicsArrayUpdated.push({
                    indexing: index + 1,
                    data: question,
                  }); // push data in array for one
                } else {
                  if (
                    question.jump_to_question > desI + 1 &&
                    question.jump_to_question < srcI + 1 &&
                    question.manuallyupdated == true
                  ) {
                    if (question.jump_to_question != "End Survey") {
                      question.jumpTo = question.jumpTo + 1;
                      question.jump_to_question = question.jump_to_question + 1;
                      question.logicupdated = true;
                      showlogicupdatedtoast = true;
                      questionsArraywithUpdatedLogics.current[index] = true;
                      surveyQuestions.splice(index, 1, question);

                      addLogicsArrayUpdated.push({
                        indexing: index + 1,
                        data: question,
                      }); // push data in array for one
                    }
                  }
                  if (question.manuallyupdated == false) {
                    question.jumpTo = index + 2;
                    question.jump_to_question = index + 2;
                    // question.logicupdated=true;
                    questionsArraywithUpdatedLogics.current[index] = true;
                    surveyQuestions.splice(index, 1, question);
                    addLogicsArrayUpdated.push({
                      indexing: index + 1,
                      data: question,
                    }); // push data in array for one
                  }
                }
              }
            } else {
              var jumparray = [];
              var logicupdatedarray = [];
              question.jumpTo.forEach((jumptooption, i) => {
                if (jumptooption != "End Survey" && question.error[i] == 0) {
                  if (question.manuallyupdated[i] == false) {
                    jumparray.push(index + 2);
                    logicupdatedarray.push(false);
                  } else {
                    if (jumptooption > desI + 1 && jumptooption < srcI + 1) {
                      jumparray.push(jumptooption + 1);
                      logicupdatedarray.push(true);
                      showlogicupdatedtoast = true;
                    } else {
                      jumparray.push(jumptooption);
                      logicupdatedarray.push(
                        question.manuallyupdated[i]
                          ? question.logicupdated[i]
                          : false
                      );
                      showlogicupdatedtoast = question.manuallyupdated[i];
                      // showlogicupdatedtoast=true;
                    }
                  }
                } else {
                  jumparray.push(jumptooption);
                  logicupdatedarray.push(false);
                }
              });
              question.jumpTo = jumparray;
              question.logicupdated = logicupdatedarray;
              questionsArraywithUpdatedLogics.current[index] =
                logicupdatedarray;
              surveyQuestions.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
            }
          }
        }
        if (index < desI && index >= srcI) {
          // console.log("question moving up due to draaging down");
          if (question.is_logic == 1) {
            if (question.questionType != "singlechoice") {
              if (question.error == 0) {
                if (
                  question.jump_to_question == "End Survey" &&
                  question.manuallyupdated == false
                ) {
                  question.jumpTo = index + 2;
                  question.jump_to_question = index + 2;
                  // question.logicupdated=true;
                  questionsArraywithUpdatedLogics.current[index] = true;
                  surveyQuestions.splice(index, 1, question);
                  addLogicsArrayUpdated.push({
                    indexing: index + 1,
                    data: question,
                  }); // push data in array for one
                } else {
                  if (
                    question.jump_to_question <= desI + 1 &&
                    question.jump_to_question != srcI + 1 &&
                    question.manuallyupdated == true
                  ) {
                    if (question.jump_to_question != "End Survey") {
                      question.jumpTo = question.jumpTo - 1;
                      question.jump_to_question = question.jump_to_question - 1;
                      question.logicupdated = true;
                      showlogicupdatedtoast = true;
                      questionsArraywithUpdatedLogics.current[index] = true;
                      surveyQuestions.splice(index, 1, question);

                      addLogicsArrayUpdated.push({
                        indexing: index + 1,
                        data: question,
                      }); // push data in array for one
                    }
                  }
                  if (question.manuallyupdated == false) {
                    question.jumpTo = index + 2;
                    question.jump_to_question = index + 2;
                    // question.logicupdated=true;
                    questionsArraywithUpdatedLogics.current[index] = true;
                    surveyQuestions.splice(index, 1, question);
                    addLogicsArrayUpdated.push({
                      indexing: index + 1,
                      data: question,
                    }); // push data in array for one
                  }
                }
              }
            } else {
              var jumparray = [];
              var logicupdatedarray = [];
              question.jumpTo.forEach((jumptooption, i) => {
                if (
                  jumptooption != "End Survey" &&
                  jumptooption != srcI + 1 &&
                  question.error[i] == 0
                ) {
                  if (question.manuallyupdated[i] == false) {
                    jumparray.push(index + 2);
                    logicupdatedarray.push(false);
                  } else {
                    if (jumptooption <= desI + 1) {
                      jumparray.push(jumptooption - 1);
                      logicupdatedarray.push(true);
                      showlogicupdatedtoast = true;
                    } else {
                      jumparray.push(jumptooption);
                      logicupdatedarray.push(
                        question.manuallyupdated[i]
                          ? question.logicupdated[i]
                          : false
                      );
                      showlogicupdatedtoast = question.manuallyupdated[i];
                    }
                  }
                } else {
                  jumparray.push(jumptooption);
                  logicupdatedarray.push(false);
                }
              });
              question.jumpTo = jumparray;
              question.logicupdated = logicupdatedarray;
              questionsArraywithUpdatedLogics.current[index] =
                logicupdatedarray;
              surveyQuestions.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
            }
          }
        }
        if (index < desI && index < srcI) {
          // console.log("question above the source and destination of dragg");
          if (question.is_logic == 1) {
            // showlogicupdatedtoast=true;
            if (question.questionType != "singlechoice") {
              if (question.error == 0) {
                if (
                  question.jump_to_question == "End Survey" &&
                  question.manuallyupdated == false
                ) {
                  question.jumpTo = index + 2;
                  question.jump_to_question = index + 2;
                  // question.logicupdated=true;
                  questionsArraywithUpdatedLogics.current[index] = true;
                  surveyQuestions.splice(index, 1, question);
                  addLogicsArrayUpdated.push({
                    indexing: index + 1,
                    data: question,
                  }); // push data in array for one
                } else {
                  if (srcI < desI) {
                    if (
                      question.jump_to_question > srcI + 1 &&
                      question.jump_to_question <= desI + 1 &&
                      question.manuallyupdated == true
                    ) {
                      // console.log("question drag down");
                      if (question.jump_to_question != "End Survey") {
                        question.jumpTo = question.jumpTo - 1;
                        question.jump_to_question =
                          question.jump_to_question - 1;
                        question.logicupdated = true;
                        showlogicupdatedtoast = true;
                        questionsArraywithUpdatedLogics.current[index] = true;
                        surveyQuestions.splice(index, 1, question);

                        addLogicsArrayUpdated.push({
                          indexing: index + 1,
                          data: question,
                        }); // push data in array for one
                        updateApiAfterLogicsUpdated(index, question); // run api if any changes
                        return;
                      }
                    }
                    if (
                      question.jump_to_question > srcI + 1 &&
                      question.jump_to_question <= desI + 1 &&
                      question.manuallyupdated == false
                    ) {
                      // console.log("question drag down");
                      question.jumpTo = index + 2;
                      question.jump_to_question = index + 2;
                      // question.logicupdated=true;
                      questionsArraywithUpdatedLogics.current[index] = true;
                      surveyQuestions.splice(index, 1, question);

                      addLogicsArrayUpdated.push({
                        indexing: index + 1,
                        data: question,
                      }); // push data in array for one
                      updateApiAfterLogicsUpdated(index, question); // run api if any changes
                      return;
                    }
                  }
                  if (srcI > desI) {
                    if (
                      question.jump_to_question < srcI + 1 &&
                      question.jump_to_question >= desI + 1 &&
                      question.manuallyupdated == true
                    ) {
                      // console.log("question drag up")
                      if (question.jump_to_question != "End Survey") {
                        question.jumpTo = question.jumpTo + 1;
                        question.jump_to_question =
                          question.jump_to_question + 1;
                        // question.logicupdated=true;
                        questionsArraywithUpdatedLogics.current[index] = true;
                        surveyQuestions.splice(index, 1, question);

                        addLogicsArrayUpdated.push({
                          indexing: index + 1,
                          data: question,
                        }); // push data in array for one
                        updateApiAfterLogicsUpdated(index, question); // run api if any changes
                        return;
                      }
                    }
                    if (
                      question.jump_to_question < srcI + 1 &&
                      question.jump_to_question >= desI + 1 &&
                      question.manuallyupdated == false
                    ) {
                      // console.log("question drag up")
                      question.jumpTo = index + 2;
                      question.jump_to_question = index + 2;
                      // question.logicupdated=true;
                      questionsArraywithUpdatedLogics.current[index] = true;
                      surveyQuestions.splice(index, 1, question);

                      addLogicsArrayUpdated.push({
                        indexing: index + 1,
                        data: question,
                      }); // push data in array for one
                      updateApiAfterLogicsUpdated(index, question); // run api if any changes
                      return;
                    }
                  }
                }
              }
            } else {
              var jumparray = [];
              var logicupdatedarray = [];
              var changed = false;
              question.jumpTo.forEach((jumptooption, i) => {
                if (question.manuallyupdated[i] == false) {
                  jumparray.push(index + 2);
                  logicupdatedarray.push(false);
                } else {
                  if (jumptooption != "End Survey" && question.error[i] == 0) {
                    if (srcI < desI) {
                      if (jumptooption > srcI + 1 && jumptooption <= desI + 1) {
                        jumparray.push(jumptooption - 1);
                        logicupdatedarray.push(true);
                        showlogicupdatedtoast = true;
                      } else if (jumptooption == srcI + 1) {
                        // console.log("questionjumpdownequaltosource");
                        jumparray.push(desI + 1);
                        logicupdatedarray.push(true);
                        showlogicupdatedtoast = true;
                      } else {
                        jumparray.push(jumptooption);
                        logicupdatedarray.push(
                          question.manuallyupdated[i]
                            ? question.logicupdated[i]
                            : false
                        );
                        showlogicupdatedtoast = question.manuallyupdated[i];
                      }
                      changed = true;
                    }
                    if (srcI > desI) {
                      if (jumptooption < srcI + 1 && jumptooption >= desI + 1) {
                        // console.log("questionjumpup");
                        jumparray.push(jumptooption + 1);
                        logicupdatedarray.push(true);
                        showlogicupdatedtoast = true;
                      } else if (jumptooption == srcI + 1) {
                        // console.log("questionjumpdownequaltosource");
                        jumparray.push(desI + 1);
                        logicupdatedarray.push(true);
                        showlogicupdatedtoast = true;
                      } else {
                        jumparray.push(jumptooption);
                        logicupdatedarray.push(
                          question.manuallyupdated[i]
                            ? question.logicupdated[i]
                            : false
                        );
                        showlogicupdatedtoast = question.manuallyupdated[i];
                      }
                      changed = true;
                    }
                  } else {
                    jumparray.push(jumptooption);
                    logicupdatedarray.push(false);
                  }
                }
              });
              question.jumpTo = jumparray;
              question.logicupdated = logicupdatedarray;
              questionsArraywithUpdatedLogics.current[index] =
                logicupdatedarray;
              surveyQuestions.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
              if (changed == true) {
                return;
              }
            }
          }
        }
        if (question.is_logic == 1 && index != surveyQuestions.length - 1) {
          if (question.questionType != "singlechoice") {
            if (question.error == 0) {
              if (question.jump_to_question == "End Survey") {
                question.jumpTo = index + 2;
                question.jump_to_question = index + 2;
                // question.logicupdated=true;
                questionsArraywithUpdatedLogics.current[index] = true;
                surveyQuestions.splice(index, 1, question);
                addLogicsArrayUpdated.push({
                  indexing: index + 1,
                  data: question,
                }); // push data in array for one
              } else {
                if (
                  question.jump_to_question == srcI + 1 &&
                  question.manuallyupdated == true
                ) {
                  // console.log("question whose jumpto matches to source")
                  question.jumpTo = desI + 1;
                  question.jump_to_question = desI + 1;
                  question.logicupdated = true;
                  showlogicupdatedtoast = true;
                  questionsArraywithUpdatedLogics.current[index] = true;
                  surveyQuestions.splice(index, 1, question);

                  addLogicsArrayUpdated.push({
                    indexing: index + 1,
                    data: question,
                  }); // push data in array for one
                }
                if (index == desI && question.manuallyupdated == false) {
                  // console.log("question whose jumpto matches to destination")
                  question.jumpTo = index + 2;
                  question.jump_to_question = index + 2;
                  // question.logicupdated=true;
                  questionsArraywithUpdatedLogics.current[index] = true;
                  surveyQuestions.splice(index, 1, question);
                  addLogicsArrayUpdated.push({
                    indexing: index + 1,
                    data: question,
                  }); // push data in array for one
                }
              }
            }
          } else {
            var jumparray = [];
            var logicupdatedarray = [];
            question.jumpTo.forEach((jumptooption, i) => {
              if (question.manuallyupdated[i] == false) {
                jumparray.push(index + 2);
                logicupdatedarray.push(false);
              } else {
                if (jumptooption != "End Survey" && question.error[i] == 0) {
                  if (
                    jumptooption == srcI + 1 &&
                    question.manuallyupdated[i] == true
                  ) {
                    jumparray.push(desI + 1);
                    logicupdatedarray.push(true);
                    showlogicupdatedtoast = true;
                  } else {
                    jumparray.push(jumptooption);
                    logicupdatedarray.push(question.logicupdated[i]);
                  }
                } else {
                  jumparray.push(jumptooption);
                  logicupdatedarray.push(question.logicupdated[i]);
                }
              }
            });
            question.jumpTo = jumparray;
            question.logicupdated = logicupdatedarray;
            questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
            surveyQuestions.splice(index, 1, question);

            addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one
          }
        }
        if (question.is_logic == 1 && index == surveyQuestions.length - 1) {
          if (question.questionType != "singlechoice") {
            if (
              question.jump_to_question != "End Survey" &&
              question.error == 0
            ) {
              question.jumpTo = "End Survey";
              question.jump_to_question = "End Survey";
              if (question.manuallyupdated == true) {
                question.logicupdated = true;
                showlogicupdatedtoast = true;
              }
              questionsArraywithUpdatedLogics.current[index] = true;
              surveyQuestions.splice(index, 1, question);
              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              }); // push data in array for one
            }
          } else {
            var jumparray = [];
            var logicupdatedarray = [];
            question.jumpTo.forEach((jumptooption, i) => {
              if (jumptooption != "End Survey" && question.error[i] == 0) {
                jumparray.push("End Survey");
                if (question.manuallyupdated[i] == true) {
                  logicupdatedarray.push(true);
                  showlogicupdatedtoast = true;
                } else {
                  logicupdatedarray.push(false);
                }
              } else {
                jumparray.push(jumptooption);
                logicupdatedarray.push(false);
              }
            });
            question.jumpTo = jumparray;
            question.logicupdated = logicupdatedarray;
            questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
            surveyQuestions.splice(index, 1, question);
            addLogicsArrayUpdated.push({ indexing: index + 1, data: question }); // push data in array for one
          }
        }
      });

      updateLogicSurveyQuestionUpdated(addLogicsArrayUpdated); // run api
      validatelogic(surveyQuestions, false);
      if (showlogicupdatedtoast == true) {
        showLogicUpdatedToast("Logic has been updated");
      }
    }
    saveSurvey(true);
  };

  const updateLogicsAferQuestionAdd = (list) => {
    var addLogicsArrayUpdated = [];

    list.forEach((question, index) => {
      if (index == list.length - 2) {
        if (question.is_logic == 1) {
          if (question.questionType != "singlechoice") {
            if (
              question.jump_to_question == "End Survey" &&
              question.manuallyupdated == false
            ) {
              question.jumpTo = index + 2;
              question.jump_to_question = index + 2;
              // question.logicupdated=true;
              questionsArraywithUpdatedLogics.current[index] = true;
              list.splice(index, 1, question);

              addLogicsArrayUpdated.push({
                indexing: index + 1,
                data: question,
              });
            }
          } else {
            var jumparray = [];
            var logicupdatedarray = [];
            question.jumpTo.forEach((jumptooption, i) => {
              if (
                jumptooption == "End Survey" &&
                (question.manuallyupdated[i] == false ||
                  question.manuallyupdated[i] == null)
              ) {
                jumparray.push(index + 2);
                logicupdatedarray.push(false);
              } else {
                jumparray.push(jumptooption);
                logicupdatedarray.push(question.manuallyupdated[i]);
              }
            });
            question.jumpTo = jumparray;
            question.logicupdated = logicupdatedarray;
            questionsArraywithUpdatedLogics.current[index] = logicupdatedarray;
            list.splice(index, 1, question);
            addLogicsArrayUpdated.push({ indexing: index + 1, data: question });
          }
        }
      }
    });

    if (addLogicsArrayUpdated.length > 0) {
      updateLogicSurveyQuestionUpdated(addLogicsArrayUpdated); // run api
    }
    setSurveyQuestions(list);
    validatelogic(list, false);
  };

  // add new question logic
  const addNewQuestion = (question) => {
    console.log(question);

    // save question
    setAddingSurveyQuestions(true);
    setQuestionType("");

    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    // if (!saveUsingNavigation) {
    //     data.append("saved_step", 2);
    // }

    setIsSaved({ status: "loading", message: "" });

    data.append("survey_questions", JSON.stringify(question));
    createSurveyQuestion(data, token).then((response) => {
      if (response.success) {
        getTestLength(test_id);
        setIsSaved({ status: "success", message: "" });

        setLastSaved(moment.now());

        // set id after adding question
        question.id = response.question.id;
        let newQuestion = Object.assign({}, question);
        let list = [...surveyQuestions];
        list.push(newQuestion);
        // setSurveyQuestions(list); // already commented
        //setAddingSurveyQuestions(true);
        //setQuestionType("");
        updateLogicsAferQuestionAdd(list);
        setSurveyError(null);
        setStepperError(false);
        setEdittingSurveyQuestions(false);

        // if (!saveUsingNavigation) {
        //     window.dataLayer = window.dataLayer || [];
        //     window.dataLayer.push({
        //         'event': 'create_test_step2',
        //         'type': test.methodology,
        //         'userID': ReactSession.get("user") ? ReactSession.get("user").user_id : ""
        //     });
        //     navigate("/r/test/" + test_id + "/thank-you/");
        // }
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });
  };

  const updateSingleQuestion = (index, questionData) => {
    console.log(questionData);

    if (surveyQuestions[index].is_ai_generated) {
      questionData.is_ai_generated = surveyQuestions[index].is_ai_generated;
    }

    surveyQuestions.splice(index, 1, questionData);
    setSurveyQuestions([...surveyQuestions]);
    setSurveyError(null);
    setStepperError(false);

    let token = ReactSession.get("token");
    var data = new FormData();

    data.append("test_id", test_id);

    setIsSaved({ status: "loading", message: "" });

    data.append("survey_questions", JSON.stringify(questionData));
    data.append("index_questions", index + 1);

    //setQuestionsSkeletonLoading(true);

    updateSingleSurveyQuestion(data, token).then((response) => {
      //setQuestionsSkeletonLoading(false);

      if (response.success) {
        surveyQuestions[index].is_ai_generated =
          response.question.is_ai_generated;

        setSurveyQuestions(surveyQuestions);

        setSurveyQuestions([...surveyQuestions]);

        getTestLength(test_id);
        setLastSaved(moment.now());
        setIsSaved({ status: "success", message: "" });
      } else {
        setIsSaved({ status: "error", message: response.message });
        showError(response.message);
      }
    });
  };

  const dragEndUpdateQuestion = (param) => {
    setPlaceholderProps({});
    if (edittingSurveyQuestions) {
      showError("Finish editing your question first.");
    } else {
      const srcI = param.source ? param.source.index : null;
      const desI = param.destination ? param.destination.index : null;
      if (desI == null || srcI == null || srcI == desI) {
        return;
      }
      let abc = Object.assign([], surveyQuestions);

      /*surveyQuestions.splice(
                desI,
                0,
                surveyQuestions.splice(srcI, 1)[0]
            );*/
      // saveSurvey(true);

      /*console.log(surveyQuestions);
            console.log(surveyQuestions[srcI]);
            console.log(surveyQuestions[desI]);
            console.log(srcI);
            console.log(desI);*/

      // reorder question api call
      if (dataReorderFetchedRef.current) {
        dataReorderFetchedRef.current.abort();
      }
      const controller = new AbortController();

      dataReorderFetchedRef.current = controller;

      let token = ReactSession.get("token");
      var data = new FormData();

      data.append("test_id", test_id);

      setIsSaved({ status: "loading", message: "" });

      /*surveyQuestions.forEach((item) => {
                data.append("survey_questions[]", JSON.stringify(item));
            });*/
      data.append("source_id", abc[srcI].id);
      data.append("destination_id", abc[desI].id);
      data.append("destination_index", desI);

      skeleton_survey_questions_length = surveyQuestions.length;

      setQuestionsSkeletonLoading(true);

      reorderSurveyQuestion(
        data,
        token,
        dataReorderFetchedRef.current?.signal
      ).then((response) => {
        setQuestionsSkeletonLoading(false);

        if (response.success) {
          surveyQuestions.splice(desI, 0, surveyQuestions.splice(srcI, 1)[0]);
          updateLogic(srcI, desI);
          setLastSaved(moment.now());
          setIsSaved({ status: "success", message: "" });
        } else {
          setIsSaved({ status: "error", message: response.message });
          showError(response.message);
        }
      });
    }
  };
  const renderQuestionsSkeleton = () => {
    var skeletons = [...Array(skeleton_survey_questions_length).keys()].map(
      (i) => i + 1
    );

    return skeletons.map((item, index) => {
      return <QuestionsSkeleton key={"skeleton" + index} />;
    });
  };
  const handleShowModal = (type) => {
    setShowAIModal({ ...showAIModal, type: type, show: true });
  };

  const handleCloseModal = () => {
    setShowAIModal({ ...showAIModal, show: false });
  };

  return (
    <LayoutResearcher
      wrapClass={`create-test-data-wrapper ${
        test && test.language == "ar" ? "arabic_wrapper_main" : ""
      }`}
      openSupportPanel={show_support_panel}
      hideSupportPanel={() => {
        setShowSupportPanel(false);
      }}
    >
      <Helmet>
        <title>Create survey - step 2 | UserQ</title>
      </Helmet>
      <div className="three-column-layout">
        <Header
          test={test}
          isLoading={isLoading}
          isSaved={isSaved}
          credits={credits}
        />
        <div className="three-column-layout-body">
          <div
            className={`three-column-layout-left ${
              addingSurveyQuestions && questionType != ""
                ? "disable-stepper"
                : ""
            }`}
          >
            <Stepper
              test={test}
              completed_step={1}
              stepCallback={() => {
                // introductionsubmitHandler(true);
              }}
              error={stepperError}
            />
          </div>
          {isLoading && (
            <div className="test-section-loader">
              {" "}
              <LoadingIcon />
            </div>
          )}
          {!isLoading && (
            <>
              {surveyGoalModal && surveyGoalModal.open && (
                <HelpModal
                  cssClass={"survey-goal-modal"}
                  open={surveyGoalModal.open}
                  close={() => {
                    setSurveyGoalModal({ ...surveyGoalModal, open: false });
                  }}
                  heading={"Survey goal"}
                  body={
                    <div className="prototype-criteria-modal-data">
                      <div className="mb-32">
                        <Text
                          cssClasses={"gray-text"}
                          type={"body-text-3"}
                          fontWeight={"medium-font"}
                        >
                          {test.survey_goal}
                        </Text>
                      </div>

                      <div className="d-flex justify-content-between duplicate-test-modal-action">
                        <Button
                          label={"Edit and regenerate"}
                          type={"primary"}
                          size={"large"}
                          iconLeft={
                            <Icon value="edit" colorClass="gray-50-svg" />
                          }
                          onClick={() => {
                            ReactSession.set("survey_goal", test.survey_goal);
                            setSurveyCreatorMode("AI");
                            setSurveyGoalModal({
                              ...surveyGoalModal,
                              open: false,
                            });
                          }}
                        />
                        <Button
                          label={"Copy survey goal"}
                          type={"primary"}
                          size={"large"}
                          iconLeft={
                            <Icon value="duplicate" colorClass="gray-50-svg" />
                          }
                          onClick={() => {
                            copyToClipboard(test.survey_goal);

                            showToast("Survey goal copied", "success");
                          }}
                        />
                      </div>
                    </div>
                  }
                />
              )}
              {showAIModal && (
                <AIModal
                  close={handleCloseModal}
                  showModal={showAIModal.show}
                  type={showAIModal.type}
                  setSurveyCreatorMode={setSurveyCreatorMode}
                />
              )}
              <div className="three-column-layout-center">
                {surveyCreatorMode == "AI" ? (
                  <SurveyCreatorAi
                    switchToManual={(fetch = false) => {
                      setSurveyCreatorMode("manual");

                      ReactSession.set("goal", "");

                      let new_accordians = Object.assign({}, accordians);

                      for (var key in accordians) {
                        if (key == "survey") {
                          new_accordians[key] = true;
                        }
                      }
                      setAccordians(new_accordians);

                      if (fetch) {
                        getTestApi();
                      }
                    }}
                  />
                ) : (
                  <div className="card-sorting-creating-test-wrap">
                    <div className="section-minimum-height">
                      <Accordian
                        title={"Introduction questions"}
                        subtitle={"(Optional)"}
                        tooltip={"Some cool text"}
                        open={accordians["introduction"] ? true : false}
                        onChange={() => {
                          let new_accordians = Object.assign({}, accordians);

                          for (var key in accordians) {
                            if (key == "introduction") {
                              if (accordians[key]) {
                                new_accordians[key] = false;
                              } else {
                                new_accordians[key] = true;
                              }
                            }
                            // else {
                            //     new_accordians[key] = false;
                            // }
                          }
                          setAccordians(new_accordians);
                        }}
                        questionCount={introCount}
                      >
                        <IntroductionQuestions
                          ref={introductionQuestionRef}
                          questions={introductionQuestions}
                          test={test}
                          setLoading={(status) => setIsSaved(status)}
                          changeCount={(newCount) => {
                            setIntroCount(newCount);
                          }}
                          updateQuestions={(questions) => {
                            setIntroductionQuestions([...questions]);
                          }}
                        />
                      </Accordian>

                      <Accordian
                        cssClasses="generate-with-AI-accordian"
                        title={<>Survey</>}
                        subtitle={
                          <>
                            {isAIGenerated == true && (
                              <>
                                <Icon
                                  value={"eye"}
                                  size={"medium"}
                                  cssClasses={
                                    "none-for-big-screen ai-icon mr-2"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSurveyGoalModal({
                                      ...surveyGoalModal,
                                      open: true,
                                    });
                                    console.log("ok");
                                  }}
                                />
                                <Button
                                  type={"ghost"}
                                  size={"medium"}
                                  cssclass={"none-for-small-screen "}
                                  iconLeft={
                                    <Icon value={"eye"} size={"medium"} />
                                  }
                                  label={"View survey goal"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSurveyGoalModal({
                                      ...surveyGoalModal,
                                      open: true,
                                    });
                                  }}
                                />
                              </>
                            )}

                            <Button
                              type={"primary"}
                              size={"medium"}
                              cssclass={"navy-btn ai-btn"}
                              iconLeft={
                                <Icon
                                  value={"Ai"}
                                  size={"medium"}
                                  colorClass={"gray-50-svg"}
                                  cssClasses="tag-reverse "
                                />
                              }
                              label={
                                isAIGenerated == true
                                  ? "Regenerate [BETA]"
                                  : "Generate with AI [BETA]"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (isAIGenerated) {
                                  handleShowModal("regenerate");
                                } else {
                                  if (surveyQuestions.length > 0) {
                                    handleShowModal("generate");
                                  } else {
                                    setSurveyCreatorMode("AI");
                                  }
                                }
                              }}
                            />
                          </>
                        }
                        tooltip={"Some cool text"}
                        open={accordians["survey"] ? true : false}
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let new_accordians = Object.assign({}, accordians);

                          for (var key in accordians) {
                            if (key == "survey") {
                              if (accordians[key]) {
                                new_accordians[key] = false;
                              } else {
                                new_accordians[key] = true;
                              }
                            }
                            // else {
                            //     new_accordians[key] = false;
                            // }
                          }
                          setAccordians(new_accordians);
                        }}
                        questionCount={surveyQuestions.length}
                      >
                        <div className="targer-group-left-wrap pb-0 border-0">
                          {/* <p className="page-step-count">Page 2</p> */}
                          {/* <h3 className="mb-0">Survey</h3> */}
                          {surveyQuestions.length === 0 ? (
                            <div className="nodata-wrap"></div>
                          ) : (
                            <div
                              className="added-question-wrap mb-32"
                              style={{ position: "relative" }}
                            >
                              {questionsSkeletonLoading && (
                                <>{renderQuestionsSkeleton()}</>
                              )}
                              {!questionsSkeletonLoading && (
                                <DragDropContext
                                  onDragEnd={(param) => {
                                    dragEndUpdateQuestion(param);
                                  }}
                                  onDragStart={handleDragStart}
                                  onDragUpdate={handleDragUpdate}
                                >
                                  <Droppable
                                    droppableId="droppable-1"
                                    isCombineEnabled
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {surveyQuestions.map(
                                          (question, index) => (
                                            <div
                                              key={index}
                                              className="survey-added-question-repeat"
                                            >
                                              <Draggable
                                                draggableId={
                                                  "draggable-" + index
                                                }
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={
                                                      snapshot.isDragging
                                                        ? "question-answers-dragging draggable-element-start"
                                                        : ""
                                                    }
                                                  >
                                                    {editQuestionId ===
                                                    index ? (
                                                      <></>
                                                    ) : (
                                                      <div
                                                        key={index}
                                                        id={"question-" + index}
                                                        className={`survey-question-preview ${
                                                          snapshot.isDragging
                                                            ? "draggable-inner-element-start"
                                                            : "grey-bg"
                                                        }`}
                                                      >
                                                        <div
                                                          className={`question-answer-holder ${
                                                            test.language ==
                                                            "en"
                                                              ? ""
                                                              : "arabic_wrapper"
                                                          }`}
                                                        >
                                                          <div
                                                            {...provided.dragHandleProps}
                                                          >
                                                            <Question
                                                              index={index}
                                                              question={
                                                                question
                                                              }
                                                              onCopy={() =>
                                                                duplicatequestion(
                                                                  index
                                                                )
                                                              }
                                                              onEdit={() =>
                                                                edittingQuestionsHandler(
                                                                  index
                                                                )
                                                              }
                                                              onDelete={() =>
                                                                removequestion(
                                                                  index
                                                                )
                                                              }
                                                              allQuestions={
                                                                surveyQuestions
                                                              }
                                                              saveJumpTo={(
                                                                optionindex,
                                                                value
                                                              ) =>
                                                                saveOptionJumpToHandler(
                                                                  optionindex,
                                                                  value,
                                                                  question.questionType,
                                                                  index
                                                                )
                                                              }
                                                              onToggleLogic={(
                                                                logicstatus,
                                                                indexQ
                                                              ) =>
                                                                toggleLogicHandler(
                                                                  logicstatus,
                                                                  indexQ,
                                                                  question.questionType
                                                                )
                                                              }
                                                              type="survey"
                                                              language={
                                                                test.language
                                                              }
                                                              // errorarray={questionsArraywithErrorLogics.current}
                                                              updatedLogicArray={
                                                                questionsArraywithUpdatedLogics.current
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            id={"ques-" + index}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </Draggable>
                                              <div
                                                className={`question-edit ${
                                                  test.language == "ar"
                                                    ? "arabic_wrapper"
                                                    : ""
                                                }`}
                                              >
                                                {edittingSurveyQuestions &&
                                                  editQuestionId === index && (
                                                    <EditQuestionInput
                                                      questionSection="survey"
                                                      questionDetails={
                                                        surveyQuestions[index]
                                                      }
                                                      cancelEditQuestion={
                                                        cancelEditQuestionHandler
                                                      }
                                                      editQuestions={(
                                                        question
                                                      ) => {
                                                        updateSingleQuestion(
                                                          index,
                                                          question
                                                        );
                                                      }}
                                                      language={language}
                                                      ref={
                                                        questionInputValidateRef
                                                      }
                                                      other={
                                                        test.language == "en"
                                                          ? question.questionType ==
                                                            "free"
                                                            ? "Other"
                                                            : surveyQuestions[
                                                                index
                                                              ].options.slice(
                                                                -1
                                                              ) == "Other"
                                                            ? true
                                                            : false
                                                          : question.questionType ==
                                                            "free"
                                                          ? "آخر"
                                                          : surveyQuestions[
                                                              index
                                                            ].options.slice(
                                                              -1
                                                            ) == "آخر"
                                                          ? true
                                                          : false
                                                      }
                                                      questionno={index + 1}
                                                      questionlist={
                                                        surveyQuestions
                                                      }
                                                      changeQuestionType={(
                                                        questionType
                                                      ) => {
                                                        surveyQuestions[
                                                          index
                                                        ].questionType = questionType;
                                                      }}
                                                      addLikertSubquestions={() => {
                                                        surveyQuestions[
                                                          index
                                                        ].subQuestions = [""];
                                                      }}
                                                      islogic={true}
                                                      onDelete={() =>
                                                        removequestion(index)
                                                      }
                                                      methodology={
                                                        test.methodology
                                                      }
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                        {provided.placeholder}
                                        {!isEmpty(placeholderProps) && (
                                          <div
                                            className="placeholder"
                                            style={{
                                              top: placeholderProps.clientY,
                                              left: placeholderProps.clientX,
                                              height:
                                                placeholderProps.clientHeight,
                                              width:
                                                placeholderProps.clientWidth,
                                              position: "absolute",
                                              borderColor: "#000000",
                                              background:
                                                placeholderProps.background,
                                              borderRadius: "10px",
                                              border: placeholderProps.border,
                                              margin: "-15px 0px 0px",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              )}
                            </div>
                          )}
                          {!edittingSurveyQuestions &&
                            (!addingSurveyQuestions ? (
                              <Button
                                iconLeft={<Icon value="add" />}
                                label="Add question"
                                size={"medium"}
                                type={"ghost"}
                                onClick={() => {
                                  if (surveyQuestions.length < 120) {
                                    setAddingSurveyQuestions(true);
                                  } else {
                                    showError(
                                      "You've reached the maximum number of questions. If you wish to add more, please consider removing some first."
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <></>
                            ))}
                          {addingSurveyQuestions && questionType === "" ? (
                            <div className="ans-list-wrap question-prev-wrap  survey-test-option-wrap mb-0">
                              {surveyQuestions.length < 120 && (
                                <>
                                  {surveyQuestions.length > 0 && (
                                    <div className="add-more-question-heading">
                                      <Text
                                        type="body-text-2"
                                        fontWeight="medium-font"
                                      >
                                        Add more question(s)
                                      </Text>
                                    </div>
                                  )}
                                  <div className="stud-tiles-hold mt-32">
                                    <div className="add-more-question-option">
                                      <div className="stud-tiles-hold mt-32 d-flex">
                                        <QuestionCard
                                          questiontype="Free Text"
                                          onClick={() => {
                                            setQuestionType("free");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                        <QuestionCard
                                          questiontype="Single choice"
                                          onClick={() => {
                                            setQuestionType("singlechoice");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                        <QuestionCard
                                          questiontype="Multiple-choice"
                                          onClick={() => {
                                            setQuestionType("multiplechoice");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                        <QuestionCard
                                          questiontype="Likert scale"
                                          onClick={() => {
                                            setQuestionType("likertscale");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                        <QuestionCard
                                          questiontype="Rating scale"
                                          onClick={() => {
                                            setQuestionType("ratingscale");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                        <QuestionCard
                                          questiontype="Ranking scale"
                                          onClick={() => {
                                            setQuestionType("rankingscale");
                                            setEdittingSurveyQuestions(true);
                                          }}
                                        />
                                      </div>
                                      <div className="create-test-preview-area">
                                        <Text
                                          type={"overline"}
                                          children={"Preview"}
                                        />
                                        <div className="create-test-preview-area-inner questionpreviewinner">
                                          choose a survey question <br /> to
                                          preview
                                        </div>
                                        <GuideLink methodology={"survey"} />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className={`${
                                test.language == "en" ? "" : "arabic_wrapper"
                              }`}
                            >
                              <QuestionInput
                                addQuestions={(question) => {
                                  addNewQuestion(question);
                                }}
                                cancelQuestion={cancelQuestionHandler}
                                ref={questionInputValidateRef}
                                changeQuestionType={(questionType) => {
                                  setQuestionType(questionType);
                                }}
                                language={language}
                                questionType={questionType}
                                questionlist={surveyQuestions}
                                questionno={surveyQuestions.length + 1}
                                islogic={true}
                                methodology={test.methodology}
                              />
                            </div>
                          )}

                          {surveyError && (
                            <span
                              className={"input_error followup-question-error"}
                              id="survey-error"
                            >
                              {surveyError}
                            </span>
                          )}
                        </div>
                      </Accordian>
                      <Accordian
                        title={"Conclusion questions"}
                        subtitle={"(Optional)"}
                        tooltip={"Some cool text"}
                        open={accordians["conclusion"] ? true : false}
                        onChange={() => {
                          let new_accordians = Object.assign({}, accordians);

                          for (var key in accordians) {
                            if (key == "conclusion") {
                              if (accordians[key]) {
                                new_accordians[key] = false;
                              } else {
                                new_accordians[key] = true;
                              }
                            }
                          }
                          setAccordians(new_accordians);
                        }}
                        questionCount={concCount}
                      >
                        <ConclusionQuestions
                          ref={conclusionQuestionRef}
                          questions={conclusionQuestions}
                          test={test}
                          setLoading={(status) => setIsSaved(status)}
                          changeCount={(newCount) => {
                            setConcCount(newCount);
                          }}
                          updateQuestions={(questions) => {
                            setConclusionQuestions([...questions]);
                          }}
                        />
                      </Accordian>
                    </div>

                    <div className="btns-wrap-bottom">
                      <Button
                        onClick={() => {
                          navigate("/r/test/" + test_id + "/welcome");
                        }}
                        type={"ghost"}
                        label={"Previous"}
                        size={"large"}
                        iconLeft={
                          <Icon
                            value="back-arrow"
                            colorClass="gray-900-svg"
                            size={"large"}
                          />
                        }
                      />
                      {surveyQuestions.length > 0 &&
                      disableNextButton.current == false ? (
                        <Button
                          onClick={surveyValidateForm}
                          iconRight={
                            <Icon
                              value="forward-arrow"
                              colorClass="gray-50-svg"
                            />
                          }
                          label="Next"
                          size={"large"}
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            var checkIntroductionQuestionIfErrorExits = true;

                            //
                            if (introductionQuestionRef.current) {
                              checkIntroductionQuestionIfErrorExits =
                                introductionQuestionRef.current.questionValidateForm(
                                  true
                                );
                            }

                            // check if any error exists in introduction question
                            if (
                              !checkIntroductionQuestionIfErrorExits &&
                              checkIntroductionQuestionIfErrorExits != undefined
                            ) {
                              console.log("error exits intro");

                              return false;
                            } else if (
                              edittingSurveyQuestions &&
                              questionInputValidateRef.current
                            ) {
                              setStepperError(false);
                              setSurveyError("");

                              // showError("Some error")
                              questionInputValidateRef.current.handleQuestionSubmit(
                                true
                              );
                              // console.log(questionInputValidateRef.current)
                              //handleQuestionSubmitValidate();
                              return false;
                            } else {
                              console.log(questionInputValidateRef.current);
                              if (!questionInputValidateRef.current) {
                                if (surveyQuestions.length == 0) {
                                  showError(
                                    "Please add at least one question for the survey before proceeding."
                                  );
                                  localStorage.setItem(
                                    "stepper_error",
                                    "Please add at least one question for the survey before proceeding."
                                  );
                                  setStepperError(true);
                                  setSurveyError(
                                    "At least one question is required"
                                  );
                                  document
                                    .getElementById("survey-error")
                                    .scrollIntoView({
                                      behavior: "smooth",
                                      block: "end",
                                    });
                                } else {
                                  validatelogic(surveyQuestions, true);
                                  showError(
                                    "Update the correct logic to proceed further."
                                  );
                                }
                              }
                            }
                          }}
                          iconRight={
                            <Icon
                              value="forward-arrow"
                              colorClass="gray-50-svg"
                            />
                          }
                          label="Next"
                          size={"large"}
                          state={"disabled"}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="three-column-layout-right">
                <a
                  onClick={(e) => {
                    e.preventDefault();

                    setShowSupportPanel(true);
                  }}
                  href={process.env.REACT_APP_URL + "r/support"}
                  target="_blank"
                  className="support-button"
                >
                  <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                  />
                  Support
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </LayoutResearcher>
  );
}
