import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from "../../lib/secure_reactsession";
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";
import { Text } from "../themes/userq/Text/Text";
import { Input } from "../themes/userq/Input/Input";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {
    getProfile,
    getTesterProfile,
    updateStep1,
    updateStep2,
    updateStep3,
    updateStep6,
    updateWelcomeMessage,
} from "../../services/user.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { toast } from "react-hot-toast";
import { settings } from "../../data/settings";
import { PageProgressBar } from "../themes/userq/ProgressBar/page_progress_bar";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter";
import { Chip, Chips } from "../themes/userq/Chips/Chips";
import { TesterSlider } from "./tester_slider";
import { SignUpInformationalCard } from "../themes/userq/SignUpInformationalCard/sign_up_informational_card";
import { MembersDropdownList } from "../themes/userq/MembersDropdownList/MembersDropdownList.js";
import { ProfileCompletionSteps } from "../themes/userq/ProfileCompletionSteps/ProfileCompletionSteps.js";

export default function Step1({ existingTester }) {
    const [startPosition, setStartPosition] = useState(0);

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const hoursOptions = [
        { label: "1-3 hours", value: "1-3 hours" },
        { label: "4-6 hours", value: "4-6 hours" },
        { label: "more than 6 hours", value: "more than 6 hours" },
    ];

    const gadgetsOptions = [
        { label: "Desktop", value: "Desktop" },
        { label: "Smartphone", value: "Smartphone" },
        { label: "Tablet", value: "Tablet" },
    ];

    const appsOptions = [
        "Shopping",
        "Hobbies",
        "Social media",
        "News",
        "Gaming",
        "Chat",
        "Collaboration",
        "Banking & finance",
        "Health",
        "Transportation",
        "Travel",
        "Office",
        "Mail",
        "Food delivery",
        "Content creation",
        "Learning",
        "Maintenance",
        "Streaming",
        "Messaging",
        "Fitness",
    ];

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        hours_spend_online: "",
        apps: [],
        tech_gadgets: "",
        mobile_user_type: "",
        desktop_user_type: "",
        owned_devices: [],
        role: "Tester",
    });

    const [formErrors, setFormErrors] = useState({
        hours_spend_online: null,
        apps: null,
        tech_gadgets: null,
        mobile_user_type: null,
        desktop_user_type: null,
        owned_devices: null,
        error_class: null,
    });

    const [lovCategories, setLovCategories] = useState([]);

    const [steps, setSteps] = useState([
        {
            label: "Personal info",
            percentage: 100,
            link: "/tester/profile/complete/step1",
        },
        {
            label: "Contact details",
            percentage: 100,
            link: "/tester/profile/complete/step2",
        },
        {
            label: "Personal background",
            percentage: 100,
            link: "/tester/profile/complete/step3",
        },
        {
            label: "Household",
            percentage: 100,
            link: "/tester/profile/complete/step4",
        },
        {
            label: "Employment & career",
            percentage: 100,
            link: "/tester/profile/complete/step5",
        },
        {
            label: "Technology usage and preferences",
            percentage: 100,
            link: "/tester/profile/complete/step6",
        },
        {
            label: "Lifestyle",
            percentage: 100,
            link: "/tester/profile/complete/step7",
        },
        {
            label: "Financial & services information",
            percentage: 100,
            link: "/tester/profile/complete/step8",
        },
    ]);

    useEffect(() => {
        window.animate();
        document.title = "Step 6 | " + process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        var formData = {};
        if (existingTester) {
            formData.existing_tester = 1;
        }
        formData.step = 6;

        getTesterProfile(formData, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (response.data.user.completed_step != 8) {
                        navigate("/tester/profile/step1");
                    }
                } else {
                    if (response.data.user.completed_step < 5) {
                        navigate("/tester/profile/step5");
                    }
                }
                if (response.data.lov_categories) {
                    setLovCategories(response.data.lov_categories);
                }

                var mobile_user_type = [];

                var desktop_user_type = [];

                var owned_devices = [];

                response.data.lov_categories.forEach(function (category) {
                    category.active_lov.forEach(function (item) {
                        response.data.tester_lov.forEach(function (lov) {
                            if (
                                category.name == "Mobile user type" &&
                                item.id == lov.lov_id
                            ) {
                                mobile_user_type.push(item.id);
                            }
                            if (
                                category.name == "Desktop user type" &&
                                item.id == lov.lov_id
                            ) {
                                desktop_user_type.push(item.id);
                            }
                            if (
                                category.name == "Owned devices" &&
                                item.id == lov.lov_id
                            ) {
                                owned_devices.push(item.id);
                            }
                        });
                    });
                });
                setFormValues({
                    ...formValues,
                    hours_spend_online:
                        response.data.user.hours_spend_online &&
                        response.data.user.hours_spend_online != 0
                            ? response.data.user.hours_spend_online
                            : "",
                    tech_gadgets: response.data.user.tech_gadgets
                        ? response.data.user.tech_gadgets
                        : "",
                    apps: response.data.user.apps
                        ? response.data.user.apps.split(",")
                        : [],
                    mobile_user_type: mobile_user_type,
                    desktop_user_type: desktop_user_type,
                    owned_devices: owned_devices,
                });

                setSteps(response.data.steps);
            }
        });
    }, []);
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage
                type={"success"}
                message={success}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = (navigateStep) => {
        setFormLoading(true);

        const token = ReactSession.get("token");

        if (existingTester) {
            formValues.existingTester = 1;
        }

        updateStep6(formValues, token).then((response) => {
            setFormLoading(false);

            if (response.success) {
                if (existingTester) {
                    if (navigateStep) {
                        navigate(navigateStep);
                    } else {
                        navigate("/tester/profile/complete/step7");
                    }
                } else {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "sign_up",
                        usertype: "tester",
                        method: response.user.signup_type,
                        userID: response.user.user_id,
                    });

                    let user = ReactSession.get("user");

                    user.completed_step = response.user.completed_step;

                    ReactSession.set("user", user);

                    navigate("/tester/profile/step7");
                }
            } else {
                showError(response.message);
            }
        });
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = (focus, key, value, inline) => {
        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "hours_spend_online") {
            var field_value = key ? value : formValues.hours_spend_online;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    hours_spend_online: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "hours_spend_online" : firsterrorid;
            }
        }
        if (!key || key == "tech_gadgets") {
            var field_value = key ? value : formValues.tech_gadgets;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    tech_gadgets: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "tech_gadgets" : firsterrorid;
            }
        }
        if (!key || key == "apps") {
            var field_value = key && inline ? value : formValues.apps;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    apps: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "apps" : firsterrorid;
            }
        }
        if (!key || key == "mobile_user_type") {
            var field_value =
                key && inline ? value : formValues.mobile_user_type;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    mobile_user_type: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "mobile_user_type" : firsterrorid;
            }
        }
        if (!key || key == "desktop_user_type") {
            var field_value =
                key && inline ? value : formValues.desktop_user_type;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    desktop_user_type: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "desktop_user_type" : firsterrorid;
            }
        }
        if (!key || key == "owned_devices") {
            var field_value = key && inline ? value : formValues.owned_devices;

            if (field_value.length == 0) {
                form_errors = {
                    ...form_errors,
                    owned_devices: "Required field",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "owned_devices" : firsterrorid;
            }
        }
        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const checkBtnState = () => {
        var error = false;

        if (!formValues.hours_spend_online) {
            error = true;
        }
        if (!formValues.tech_gadgets) {
            error = true;
        }

        if (formValues.apps.length == 0) {
            error = true;
        }
        if (formValues.mobile_user_type.length == 0) {
            error = true;
        }
        if (formValues.desktop_user_type == 0) {
            error = true;
        }

        if (formValues.owned_devices.length == 0) {
            error = true;
        }
        return error;
    };
    const onSubmit = async (event, navigateStep) => {
        if (event) {
            event.preventDefault();
        }
        if (!isLoading) {
            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile(navigateStep);
            }
        }

        return false;
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value,
                    }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : "", true);
        };
    };
    const clearFormValue = (name) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={existingTester ? true : true}
            wrapClass={"mb-0"}
        >
            {steps.length > 0 && existingTester && (
                <div className="col-12 border-bottom-gray">
                    <div className="container">
                        <ProfileCompletionSteps
                            isLoading={isLoading || formLoading}
                            current_step={6}
                            steps={steps}
                            onClick={(navigateStep) => {
                                onSubmit(null, navigateStep);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="container">
                {isLoading && (
                    <div className="accountloading-box">
                        {" "}
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && (
                    <form
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit(e, null);
                        }}
                    >
                        <div className="register-page-min-hegiht">
                            <div className="row tester_sign_up_step_row justify-content-center">
                                <div className="col-md-6 graytext researcher-profilebox-right-outerbox tester-profile-step3box progress-max">
                                    {!existingTester && (
                                        <PageProgressBar
                                            current_step={5}
                                            total_steps={8}
                                        />
                                    )}
                                    <div className="mt-24">
                                        <Text
                                            type={"subtitle-2"}
                                            fontWeight={"semi-bold-font"}
                                        >
                                            Your tech habits and preferences
                                        </Text>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mt-12"}
                                        >
                                            Let us know what devices and apps
                                            keep you going
                                        </Text>
                                    </div>

                                    <div className="login-form-inner-data researcher-profilebox mt-32">
                                        <div
                                            className="formbox"
                                            id="hours_spend_online"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    options={hoursOptions}
                                                    value={
                                                        formValues.hours_spend_online
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                hours_spend_online:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                hours_spend_online:
                                                                    "",
                                                            });
                                                        }
                                                        validateFullForm(
                                                            false,
                                                            "hours_spend_online",
                                                            item
                                                                ? item.value
                                                                : "",
                                                            true
                                                        );
                                                    }}
                                                    isClearable={true}
                                                    label="Daily screen time"
                                                    error={
                                                        formErrors.hours_spend_online
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox"
                                            id="tech_gadgets"
                                        >
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    options={gadgetsOptions}
                                                    value={
                                                        formValues.tech_gadgets
                                                    }
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFormValues({
                                                                ...formValues,
                                                                tech_gadgets:
                                                                    item.value,
                                                            });
                                                        } else {
                                                            setFormValues({
                                                                ...formValues,
                                                                tech_gadgets:
                                                                    "",
                                                            });
                                                        }
                                                        validateFullForm(
                                                            false,
                                                            "tech_gadgets",
                                                            item
                                                                ? item.value
                                                                : "",
                                                            true
                                                        );
                                                    }}
                                                    isClearable={true}
                                                    label="Most used device"
                                                    error={
                                                        formErrors.tech_gadgets
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox tester-apps-select"
                                            id="apps"
                                        >
                                            <MembersDropdownList
                                                value={formValues.apps}
                                                options={appsOptions.map(
                                                    (app) => {
                                                        return {
                                                            value: app,
                                                            label: app,
                                                        };
                                                    }
                                                )}
                                                onChange={(selected) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        apps: selected,
                                                    });
                                                    setFormErrors({
                                                        ...formErrors,
                                                        apps: null,
                                                    });
                                                }}
                                                isClearable={true}
                                                label="Which apps do you rely on every day?"
                                                error={formErrors.apps}
                                                error_class={
                                                    formErrors.error_class
                                                }
                                            />
                                        </div>
                                        <div
                                            className="formbox w-fix"
                                            id="mobile_user_type"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={
                                                        formValues.mobile_user_type
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Mobile user type"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            mobile_user_type:
                                                                selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            mobile_user_type:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Mobile user type"
                                                    error={
                                                        formErrors.mobile_user_type
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="formbox w-fix"
                                            id="desktop_user_type"
                                        >
                                            <div className="profile-form-group">
                                                <MembersDropdownList
                                                    value={
                                                        formValues.desktop_user_type
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Desktop user type"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            desktop_user_type:
                                                                selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            desktop_user_type:
                                                                null,
                                                        });
                                                    }}
                                                    isClearable={true}
                                                    label="Desktop user type"
                                                    error={
                                                        formErrors.desktop_user_type
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox w-fix">
                                            <div
                                                className="profile-form-group"
                                                id="owned_devices"
                                            >
                                                <MembersDropdownList
                                                    value={
                                                        formValues.owned_devices
                                                    }
                                                    options={lovCategories
                                                        .filter(
                                                            (category) =>
                                                                category.name ==
                                                                "Owned devices"
                                                        )
                                                        .flatMap(
                                                            (category) =>
                                                                category.active_lov
                                                        )
                                                        .map((active_lov) => {
                                                            return {
                                                                value: active_lov.id,
                                                                label: active_lov.lov_value,
                                                            };
                                                        })}
                                                    iconLeft={"add"}
                                                    label={"Owned devices"}
                                                    no_results_message={
                                                        "Nothing is there"
                                                    }
                                                    onChange={(selected) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            owned_devices:
                                                                selected,
                                                        });
                                                        setFormErrors({
                                                            ...formErrors,
                                                            owned_devices: null,
                                                        });
                                                    }}
                                                    error={
                                                        formErrors.owned_devices
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sign-up-btn-wrap tester_signup_btns mt-32">
                                        <Button
                                            type="secondary"
                                            size="large"
                                            id="r_registration"
                                            label="Back"
                                            iconLeft={
                                                <Icon
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href={
                                                existingTester
                                                    ? "/tester/profile/complete/step5"
                                                    : "/tester/profile/step5"
                                            }
                                        />

                                        <Button
                                            type="primary"
                                            size="large"
                                            id="r_registration"
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    colorClass="gray-50-svg"
                                                    value="forward-arrow"
                                                    size="large"
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={
                                                checkBtnState()
                                                    ? "disabled"
                                                    : "active"
                                            }
                                        />
                                    </div>
                                </div>
                                {!existingTester && (
                                    <div className="col-md-6 graytext none-for-mobile">
                                        <SignUpInformationalCard
                                            icon={
                                                <Icon
                                                    colorClass={
                                                        "secondary-purple-200"
                                                    }
                                                    value={"flag"}
                                                />
                                            }
                                            title="Be part of the change"
                                            info="Help shape digital products from some of the coolest brands in the MENA region."
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LayoutTester>
    );
}
