import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams,useLocation} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import TesterTestFrame from "./elements/tester_test_frame";
import NewTesterConcludeTestModal from "./dialog/new_tester_conclude_test_modal";
import { waitForAllSettled } from "recoil";
import ExistingTesterConcludeTestModal from './dialog/existing_tester_conclude_test_modal';
import { encryptId } from "../../lib/helpers";
import {Text} from "../themes/userq/Text/Text";
import {Icon} from "../themes/userq/Icon/Icon";
import {Button} from "../themes/userq/Button/Button";

export default function ConclusionPage({test,conclusionCallback,similarTest,settings,isAssignsCredits}) {
    const [isActiveMoreTest, setActiveMoreTest] = useState(false );
    const [isCompleteMyProfile, setCompleteMyProfile] = useState(true);
    const [openNewTesterConcludeTestModal, setOpenNewTesterConcludeTestModal] = useState(true);
    const [openExistingTesterConcludeTestModal, setOpenExistingTesterConcludeTestModal] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const user=ReactSession.get("user");

    useEffect(()=>{
        // location scroll top
        window.scrollTo(0, 0);
    },[test])
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap tester-thankyou-page-wrap">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold flex-wrap">
                    
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                {(test.is_concl_img_removed == 1 && test.conclusion_image == null)?(
                                    <></>
                                ):(
                                <img src={
                                    (test && test.conclusion_image)?
                                        process.env.REACT_APP_IMG_URL+''+test.conclusion_image   :
                                        process.env.REACT_APP_URL+"img/welcome-img.png"
                                }/>
                                )
                                }
                            </div>
                        </div>
                        <div className={`selected-page-left-side ${test.language=="ar"?"arabic_wrapper":""}`}>
                            <Text type={"h1"}>
                                {
                                    test && test.thankyou_title
                                }
                            </Text>

                            <Text type={"subtitle-2"} fontWeight={'medium-font'}>
                                {
                                    test && test.thankyou_description
                                }
                            </Text>

                            <div className="button-wrapper none-for-mobile ">
                                {/*{!(ReactSession.get("token")) &&*/}

                                {/*<a href={process.env.REACT_APP_SITE_URL+"become-a-tester"} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</a>*/}
                                {/*}*/}
                                {/*{(ReactSession.get("token")) &&*/}
                                {/*<Link to={'/dashboard'} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</Link>*/}
                                {/*}*/}
                                {(ReactSession.get("token")) &&
                                <>{test.language === 'en'?
                                    <Button
                                        size={'large'}
                                        iconLeft={<Icon size={"medium"} colorClass="gray-50-svg" value={"back-arrow"} />}
                                        label={test.language === 'en'?"Back to dashboard":"العودة إلى لوحة القيادة"}
                                        onClick={() => {
                                            let user = ReactSession.get("user");

											if(user && user.role === "Researcher" && test){
												navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
											} else {
												navigate('/dashboard');
											}
                                        }}
                                    />
                                    :
                                    <Button
                                        size={'large'}
                                        iconRight={<Icon size={"medium"} colorClass="gray-50-svg" value={"back-arrow"} />}
                                        label={test.language === 'en'?"Back to dashboard":"العودة إلى لوحة القيادة"}
                                        onClick={() => {
                                            let user = ReactSession.get("user");

                                            if(user && user.role === "Researcher" && test){
                                                navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
                                            } else {
                                                navigate('/dashboard');
                                            }
                                        }}
                                    />}
                                    {/*<Link to={'/dashboard'} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</Link>*/}
                                </>
                                }
                                {!(ReactSession.get("token")) &&
                                <>
                                {test.language === 'en'?
                                    <Button
                                        size={'large'}
                                        iconLeft={<Icon size={"medium"} colorClass={"gray-50-svg"} value={'back-arrow'} />}
                                        label={test.language === 'en'?"Back to home":"العودة إلى المنزل"}
                                        onClick={() => {
                                            window.location = process.env.REACT_APP_SITE_URL+"become-a-tester";
                                            //navigate('/'+process.env.REACT_APP_SITE_URL+"become-a-tester");
                                        }}
                                    />:
                                    <Button
                                        size={'large'}
                                        iconRight={<Icon size={"medium"} colorClass={"gray-50-svg"} value={'back-arrow'} />}
                                        label={test.language === 'en'?"Back to home":"العودة إلى المنزل"}
                                        onClick={() => {
                                            window.location = process.env.REACT_APP_SITE_URL+"become-a-tester";
                                            //navigate('/'+process.env.REACT_APP_SITE_URL+"become-a-tester");
                                        }}
                                    />}
                                    {/*<a href={process.env.REACT_APP_SITE_URL+"become-a-tester"} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</a>*/}
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    
                
                    {/*{(ReactSession.get("token")) && location.pathname.substr(1,1) === 't' &&*/}
                    {/*<div className="find-more-test-wrap">*/}
                    {/*    <div className="find-sim-btn-hold">*/}
                    {/*        <span className="find-similar-btn" onClick={()=>setActiveMoreTest(true)} id="open-test">Find similar tests<img*/}
                    {/*            src={process.env.REACT_APP_URL + "images/more-load.svg"} alt="img"/></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}
                </div>
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    {(ReactSession.get("token")) &&
                        <>{test.language === 'en'?
                            <Button
                                size={'large'}
                                iconLeft={<Icon value={'back-arrow'}/>}
                                label={test.language === 'en'?"Back to dashboard":"العودة إلى لوحة القيادة"}
                                onClick={() => {
                                    let user = ReactSession.get("user");

                                    if(user && user.role === "Researcher" && test){
                                        navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
                                    } else {
                                        navigate('/dashboard');
                                    }
                                }}
                            />
                            :
                            <Button
                                size={'large'}
                                iconRight={<Icon value={'back-arrow'}/>}
                                label={test.language === 'en'?"Back to dashboard":"العودة إلى لوحة القيادة"}
                                onClick={() => {
                                    let user = ReactSession.get("user");

                                    if(user && user.role === "Researcher" && test){
                                        navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
                                    } else {
                                        navigate('/dashboard');
                                    }
                                }}
                            />}
                            {/*<Link to={'/dashboard'} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</Link>*/}
                    </>
                    }
                    {!(ReactSession.get("token")) &&
                        <>
                        {test.language === 'en'?
                            <Button
                                size={'large'}
                                iconLeft={<Icon value={'back-arrow'}/>}
                                label={test.language === 'en'?"Back to home":"العودة إلى المنزل"}
                                onClick={() => {
                                    window.location = process.env.REACT_APP_SITE_URL+"become-a-tester";
                                }}
                            />:
                            <Button
                                size={'large'}
                                iconRight={<Icon value={'back-arrow'}/>}
                                label={test.language === 'en'?"Back to home":"العودة إلى المنزل"}
                                onClick={() => {
                                    window.location = process.env.REACT_APP_SITE_URL+"become-a-tester";
                                }}
                            />
                            }
                            {/*<a href={process.env.REACT_APP_SITE_URL+"become-a-tester"} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</a>*/}
                        </>
                    }
                </div>
            </div>

            {!(ReactSession.get("token")) &&

            <NewTesterConcludeTestModal
                cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
                test={test} isCompleteMyProfile={isCompleteMyProfile} openNewTesterConcludeTestModal={openNewTesterConcludeTestModal} closeNewTesterConcludeTestModal={()=>{setOpenNewTesterConcludeTestModal(false)}}/>
            // <div
            //     className={`page-fixed-info-wrap d-flex align-items-center justify-content-center test-register-info-wrap ${!isCompleteMyProfile?'d-none':''}`}>
            //     <div>
            //         {test.language === 'en'?
            //         <>Want to get paid for your insights? <b>Register with UserQ to fill out more tests like this!</b></>
            //         :<>&nbsp; هل تريد أن تحصل على أموال مقابل افكارك؟&nbsp;<b>سجل مع UserQ لملء المزيد من الاختبارات المشابهة.</b></>}

            //     </div>
            //     <Link to={'/tester/sign-in'} className="button primary-btn small-button">{test.language === 'en'?"Complete my profile":"أكمل ملفي الشخصي"}</Link>

            //     <span className="close-profile-complete-noti" onClick={()=>{
            //         setCompleteMyProfile(false);
            //     }}><img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/></span>
            // </div>
            }
            {(ReactSession.get("token")) && 
            <>
            {(user.role=="Tester") &&
            <ExistingTesterConcludeTestModal
                cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
                test={test} isAssignsCredits={isAssignsCredits}  openExistingTesterConcludeTestModal={openExistingTesterConcludeTestModal} closeExistingTesterConcludeTestModal={()=>{setOpenExistingTesterConcludeTestModal(false)}}/>
            }
            </>
            }

            {/*<div className={`similar-test-data-hold ${isActiveMoreTest?'show-modal':''}`}>*/}
            {/*    <div className="find-sim-btn-hold">*/}
            {/*        <span className="find-similar-btn none-for-mdobile" id="close-test">*/}
            {/*            Find similar tests*/}

            {/*            <img*/}
            {/*            src={process.env.REACT_APP_URL + "images/more-load.svg"} alt="img"/></span>*/}

            {/*        <span className="find-similar-btn none-for-desktop" onClick={()=>setActiveMoreTest(false)} id="close-test-2"><img src={process.env.REACT_APP_URL + "images/cross.svg"}*/}
            {/*                                                                                   alt="img"/></span>*/}

            {/*    </div>*/}
            {/*    <div className="container inner-page-container">*/}
            {/*        <div className="das-ongoing-test-wrap similar-test-data-inner">*/}
            {/*            <div className="project-das-data">*/}

            {/*                {similarTest && similarTest.length === 0 &&*/}
            {/*                    <h2 className="no-test-available">We don’t have any studies for you right now :(<br/> We’ll let you know when one becomes available.</h2>*/}
            {/*                }*/}

            {/*                {similarTest && similarTest.map((test)=>{*/}
            {/*                    return (<TesterTestFrame*/}
            {/*                        key={"Testframe" + test.id}*/}
            {/*                        settings={settings}*/}
            {/*                        test={test}*/}
            {/*                        onClick={(e) => {*/}
            {/*                            e.preventDefault();*/}
            {/*                            navigate("/t/" + encryptId(test.id, test));*/}
            {/*                        }}*/}
            {/*                    />)*/}
            {/*                })}*/}


            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    );
}
