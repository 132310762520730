import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tag } from "../../../themes/userq/Tag/Tag";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { TextArea } from "../../../themes/userq/TextArea/TextArea";
import "./survey_creator_ai.css";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton";
import { buildSurveyAIService } from "../../../../services/test";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useParams } from "react-router-dom";
import { showError } from "../../../../lib/helpers";
import { useEffect, useState } from "react";
import AILoaderModal from "../../../themes/userq/AILoaderModal/AILoaderModal";

export default function SurveyCreatorAI({switchToManual}){

    let { test_id } = useParams();

    const [formValues, setFormValues] = useState({goal: "", length: "", "question_type":"" });

    const [formErrors, setFormErrors] = useState({goal: null, length: null, "question_type":null });

    const [AILoading, setAILoading] = useState(false);

    const lengthOptions = [{
            label: "To cover the basics",
            value: "short",
            img:'length-short',
            sublabel:'Short',
            tagLabel: '5 to 7 questions'
            
        },
        {
            label: "To get some detailed info",
            value: "medium",
            img:'length-medium',
            sublabel:'Medium',
            tagLabel: '8 to 12 questions'
        },
        {
            label: "To deep dive into the topic",
            value: "long",
            img:'length-long',
            sublabel:'Long',
            tagLabel: '13 to 20 questions'
        }
    ];

    const surveyTypeOptions = [
        {
            label: "Closed-ended questions",
            sublabel:
                'Ideal for gathering specific, quantifiable responses like "yes" or "no" answers.',
            value: "close-ended",
        },
        {
            label: "Open-ended questions only",
            sublabel: "Perfect for capturing detailed feedback and deeper insights in respondents' own words.",
            value: "open-ended",
        },
        {
            label: "A balanced mix",
            sublabel:
                "Combines both question types to provide a blend of detailed feedback and quantifiable data.",
            value: "mix",
        },
    ];

    useEffect(()=>{

        var goal = ReactSession.get("survey_goal");

        if(goal){
            setFormValues({...formValues, goal: goal});
        }
        ReactSession.set("survey_goal","");
    },[switchToManual])
    const checkAIStatus = () => {

		var error = false;

        if(!formValues.goal || !formValues.length || !formValues.question_type){
            error = true;
        }
        if(error){
            return error;   
        }
        return false;
	}
    const validateFullForm = () => {

        var error = false;

        var form_errors = {goal: null, length: null, "question_type":null };
        
        var firsterrorid = null;

        if(!formValues.goal) {

            form_errors = {
                ...form_errors,
            goal: "Goal is required",
                error_class: "input_error"
            };
            firsterrorid = firsterrorid == null ? "goal" : firsterrorid;
            error = true
        }
        if(!error && !formValues.length) {

            form_errors = {
                ...form_errors,
                length: "Survey length is required",
                error_class: "input_error"
            };
            firsterrorid = firsterrorid == null ? "length" : firsterrorid;
            error = true
        }
        if(!error && !formValues.question_type) {
            form_errors = {
                ...form_errors,
                question_type: "Question type is required",
                error_class: "input_error"
            };
            firsterrorid = firsterrorid == null ? "question_type" : firsterrorid;
            error = true
        };
        setFormErrors(form_errors);

        if (firsterrorid != null) {
            
            showError("You missed some key information. Please check again and fill out any missing fields.");
            localStorage.setItem("stepper_error", "You missed some key information. Please check again and fill out any missing fields.");
            scrollto(firsterrorid);
            
        }
        return error;
    }
    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    const scrollto = (area) => {

        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight + document.querySelector('.three-column-layout-top-header').offsetHeight;
        
        var offsetTop = Position(document.getElementById(area));
        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

    }

    const buildSurvey = () => {
        
        var error = validateFullForm();

        if(!error) {
            let token = ReactSession.get("token");

            setAILoading(true);
            

            let formData = {test_id: test_id, ...formValues};

            buildSurveyAIService(formData, token).then((response) => {
                //setAddTaskLoading(false);

                setAILoading(false);
                window.scrollTo({
                 top: 0,
                 behavior: 'smooth' // Optional, makes the scrolling smooth
                                });

                if (response.success) {

                    switchToManual(true);
                } else {

                    if(response.error_type && response.error_type=="api_error"){
                        showError(<>
                            AI is currently unavailable. Please contact <a href="mailto://support@userq.com">support@userq.com</a> for more information.
                        </>);
                    } else {
                        showError(response.message);
                    }
                }
            });
        }
	};
    return (
        <>
            <div className="ai-wrap">
                <div className="ai-creator-top d-flex align-items-center justify-content-between">
                    {/* <div className="back-btn none-for-desktop d-none">
                        <a class="back-arrow-btn" href="#">
                            <Icon value={"back-arrow"} />
                        </a>
                    </div> */}
                    <div className="d-flex align-items-center ai-creator-top-left">
                        <Text
                            type={"subtitle-2"}
                            fontWeight={"medium-font"}
                        >
                            Survey creator
                        </Text>

                        <Tag
                            title={"Generate with AI"}
                            type={"navy-tag"}
                            iconRight={
                                <Icon
                                    value={"Ai"}
                                    size={"medium"}
                                    colorClass={"gray-50-svg"}
                                    cssClasses="tag-reverse"
                                    
                                />
                            }
                            size={"small-tag"}
                            cssClasses={'none-for-mobile'}
                        />
                    </div>

                    <div className="d-flex align-items-center ai-creator-top-right ">
                        <Button
                            type={"secondary"}
                            size={"small"}
                            label={"Back to manual"}
                            onClick={()=>{
                                switchToManual();
                            }}
                        />
                    </div>
                </div>

                

                <div id="goal" className="add-ai-logic-form-wrap mt-32">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        Describe your goal
                    </Text>

                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-color mt-8"}
                    >
                        Provide a brief description of what you aim to achieve with this survey. List all the aspects you want to cover to make sure is designed to meet your research objectives effectively.
                    </Text>

                    <div className="ar-textarea-wrap mt-16 ai-radio-wrap">
                        <TextArea
                            autosize={true}
                            label={
                                "Survey goal"
                            }
                            placeholder="e.g., I need to create a survey to understand customer preferences, gather feedback on product features, evaluate user satisfaction with recent updates..."
                            value={formValues.goal}
                            onChange={(e)=>{
                                setFormErrors({...formErrors, goal:null});
                                setFormValues({...formValues, goal: e.target.value});
                            }}
                            error={formErrors.goal}
                        />
                    </div>
                </div>

                <div id="length"  className="add-ai-logic-form-wrap mt-32">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        Select the length of the survey
                    </Text>

                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-color mt-8"}
                    >
                        Choose how many questions you would like the AI to generate for your survey.
                    </Text>

                    <div className="ar-textarea-wrap mt-20 device-selection-radio d-flex row">
                        {lengthOptions.map(function(option){
                            return <RadioButton key={option.label} tagLabel={option.tagLabel} label={option.label} isChecked={option.value==formValues.length?true:false} onChange={(option)=>{ setFormErrors({...formErrors, length:null}); setFormValues({...formValues, length: option.target.value}); }} value={option.value} img={option.img} cssClasses="col-md-4 radiobox" sublabel={option.sublabel} radioBox={true}/>
                        })}
                        
                    </div>
                    {formErrors.length &&
                    <span className="error red-text">{formErrors.length}</span>
                    }
                </div>
                <div id="question_type" className="add-ai-logic-form-wrap mt-32">
                    <Text
                        type={"body-text-1"}
                        fontWeight={"medium-font"}
                    >
                        What type of insights are you looking for?
                    </Text>


                    <div className="ar-textarea-wrap mt-16 ai-radio-wrap">
                        {surveyTypeOptions.map(function(option){
                            return <RadioButton key={option.label} sublabel={option.sublabel} isChecked={option.value==formValues.question_type?true:false} onChange={(option)=>{  setFormErrors({...formErrors, question_type:null}); setFormValues({...formValues, question_type: option.target.value}); }} label={option.label} value={option.value} />
                        })}
                        
                    </div>
                    {formErrors.question_type &&
                    <span className="error red-text">{formErrors.question_type}</span>
                    }
                </div>

                <div className="generate-ai-btn text-center">
                    {AILoading && <AILoaderModal showModal={true} />}

                    <Button
                        type={"primary"}
                        size={"large"}
                        label={"Generate"}
                        //microLoading={AILoading ? true : false}
                        iconRight={
                            <Icon
                                value={"Ai"}
                                colorClass={"gray-50-svg"}
                            />
                        }
                        onClick={()=>{
                            buildSurvey();
                        }}
                        state={checkAIStatus() ? "disabled" : "active"}
                    />
                </div>

                <div className="back-to-manual-btn">
                    <Button
                        type={"ghost"}
                        size={"large"}
                        label={"Back to manual"}
                        iconLeft={
                            <Icon
                                value={"back-arrow"}
                                colorClass={"gray-900-svg"}
                            />
                        }
                        onClick={()=>{
                            switchToManual();
                        }}
                    />
                </div>
            </div>
        </>
    ); 
};