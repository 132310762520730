import {handleApiErrorResponse} from "../lib/helpers";

export function getProfile(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTesterProfile(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile?existing_tester='+(formValues && formValues.existing_tester==1?"1":"0")+(formValues && formValues.step?"&step="+formValues.step:""), {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function completeResearcherProfile(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'researcher/profile/complete', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getSettings(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/settings', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export async function checkIfEmailUnique(email, token){
    const response =  await fetch(process.env.REACT_APP_API_END_POINT+'user/email/unique?email='+email, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    });
    const responsebody = await response.json();
    return responsebody;
}
export function getActivePlanService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'researcher/billing/plans/active_plan', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getCurrentPlanService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'researcher/billing/plans/current', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep1(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step1',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep2(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step2',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep3(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step3',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep4(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step4',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep5(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step5',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep6(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step6',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep7(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step7',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateStep8(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/profile/step8',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateResearcherProfile(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/researcher/profile',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function disconnectSocialAccountService(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/disconnect-social-account',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateTesterProfile(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/tester/profile',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deleteAccount(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/account/delete',{
        method: 'POST',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deleteResearcherAccount(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/researcher/account/delete',{
        method: 'POST',
        body:JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateWelcomeMessage(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/welcome-message',{
        method: 'POST',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateUnblockedPopup(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/unblocked-popup',{
        method: 'POST',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateNewFeatureService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/new-feature/update',{
        method: 'POST',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function changePassword(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/changepassword', {
        method: 'POST',
		body:  formValues,
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function setPassword(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/setpassword', {
        method: 'POST',
		body:  formValues,
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function checkPassword(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/checkpassword', {
        method: 'POST',
		body:  formValues,
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateSettings(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/settings/update',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateAccountsSettings(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/accountsettings/update',{
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getBillingInfoService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/billing-info/get',{
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateBillingInfoService(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/billing-info/update',{
        method: 'POST',
        body: JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function logoutService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/logout',{
        method: 'POST',
        body: JSON.stringify({}),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updatetesterbankdetails(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/tester/updatetesterbankdetails", {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function deletetesterbankdetails(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/tester/deletetesterbankdetails", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function requesttesterpayout(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/tester/requesttesterpayout", {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function gettesterbankdetails(token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/tester/gettesterbankdetails", {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getLoadMoreWalletHistory(formValues,token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/tester/loadmorewalletDetails?page="+
        formValues.page+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function sendChangeEmailToUser(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'user/change-email',{
        method: 'POST',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getCollaboratorByVerificationCode(formValues){
    return fetch(process.env.REACT_APP_API_END_POINT+'get-collaborator-by-verification-code', {
        method: "POST",
        body: formValues,
       // headers:{"Content-Type":"application/json","Accept":"application/json"}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function createCollaboratorByVerificationCode(formValues){
    return fetch(process.env.REACT_APP_API_END_POINT+'create-collaborator-by-verification-code', {
        method: "POST",
        body: formValues,
        // headers:{"Content-Type":"application/json","Accept":"application/json"}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function researcherTesterSupportContact(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher-tester-support-contact", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "Content-Type":"application/json","Accept":"application/json",Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function researcherEarnInviteInvitation(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/earn-invite-invitation", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "Content-Type":"application/json","Accept":"application/json",Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTesterAccountDetails(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'tester/account/details', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getUserRatingApi(token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT +
        "researcher/rating",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }
  export function updateReferalLinkWorkspaceService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "user/referal-link/workspace/update", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "Content-Type":"application/json","Accept":"application/json",Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}