import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../themes/userq/Button/Button";
import { Defaultcard } from "../../themes/userq/Test/DefaultCard";
import { testDefaultCardsData } from "../../../data/test-default-card";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { Tag } from "../../themes/userq/Tag/Tag";
import { roundNumber } from "../../../lib/helpers";

export default function ChooseMethodologyModal(props) {
    const wrapperRef = useRef(null);
    const [methodology, setMethodology] = useState("");

    const closeModal = () => {
        props.closeModal(false);
    };

    const gotoTestSettings = () => {
        if (methodology === "Recruitment") {
            closeModal();
            props.isRecruitment();
        } else if (methodology != "") {
            props.enterTestDetails(methodology);
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    } 

    useOutsideAlerter(wrapperRef);

    const getTestPublishingFees = (methodology)=>{
        
        let map_object = {
            "Prototype test": "publishing_credits_prototype_test",
            "Tree Test":"publishing_credits_tree_test",
            "Preference Test" : "publishing_credits_preference_test",
            "Survey": "publishing_credits_survey",
            "Card Sorting" : "publishing_credits_card_sorting",
            "Five Seconds Test": "publishing_credits_five_seconds_test",
            "First click": "publishing_credits_first_click"
        };
        if(props.publishing_credits_meth && props.publishing_credits_meth[map_object[methodology]]){
            return props.publishing_credits_meth[map_object[methodology]];
        }
        return 0;
    }

    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap create-new-test-modal  big-modal-wrap"
        >
            <Modal.Body ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <div className="test-publish-fee-header-left align-items-center d-flex">
                            <Text type="h3">Get started by choosing the test type</Text>
                            {props.workspace_type === "Individual" &&
                            <>
                            {props.days_left>0 &&
                            <Text type={"body-text-3"} fontWeight={"medium-font"}>No publishing fee</Text>
                            }
                            {props.days_left>0 &&
                            <Tag type="purple-tag" iconLeft={"time"} colorClass="secondary-purple-200-svg path" title={props.days_left+" day"+(props.days_left>1?"s":"")+" left"} />
                            }
                            {/*props.publishing_credits>0 &&
                            <Tag type="pink-tag" iconLeft={"credit"} title={"Publishing fee: "+roundNumber(props.publishing_credits,2)+" credits"} />
                            */}
                            </>
                            }
                            
                        </div>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                        </button>
                    </div>

                    <div className="test-methodologies creat-test-cards-wrapper create-test-modal">
                        {testDefaultCardsData.map((items) => {
                            return (
                                <Defaultcard
                                    isAIActive={
                                        items.isAIActive
                                    }
                                    key={items.title}
                                    title={items.title}
                                    image={items.image}
                                    isComingSoon={items.isComingSoon}
                                    onClick={() => {
                                        //if (items.methodology !== "First click") {
                                            setMethodology(items.methodology);
                                            document.getElementById("continue-button").scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                            });
                                        //}    
                                    }}
                                    isInfo={true}
                                    description={items.description}
                                    selected={items.methodology == methodology ? true : false}
                                    publishing_credits={props.workspace_type === "Individual" ? getTestPublishingFees(items.methodology) : 0}
                                /> 
                            );
                        })}
                    </div>

                    <div className="modal-button" id="continue-button"> 
                        <Button
                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                            label="Continue"
                            onClick={gotoTestSettings} size="large"  state={methodology != "" ? 'active' : 'disabled'}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}