import { useEffect, useRef, useState } from "react";
import "./MembersDropdownList.css";
import { CheckBox } from "../CheckBox/CheckBox.js";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Chip } from "../Chips/Chips.js";
import { SearchDropdown } from "../SearchDropdown/SearchDropdown.js";
export const MembersDropdownList = ({
    label,
    error,
    defaultMembers = [],
    options,
    readOnly,
    onChange,
    value,
    className,
    no_results_message,
    icon,
}) => {
    const [selected, setSelected] = useState([]);
    const [showDropdown, setShowDropDown] = useState(false);
    const [filterText, setFilterText] = useState("");

    const [searchEnabled, setSearchEnabled] = useState(false);

    const searchRef = useRef(null);

    const selectRef = useRef(null);

    const selectDropRef = useRef(null);

    const [dropdownHeight, setDropdownHeight] = useState("300px");

    useEffect(() => {
    const handleResize = () => {
      if (selectRef.current) {
        const triggerRect = selectRef.current.getBoundingClientRect();
        
        const availableSpace = window.innerHeight - triggerRect.bottom - 10; // 10px padding
        setDropdownHeight(`${availableSpace}px`);
      }
    };

    if (showDropdown) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showDropdown]);

    useEffect(() => {
        if (searchEnabled && searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchEnabled]);

    const filteredMembers = options.filter((option) => {
        let labelValue;

        // Safely check if the label is a JSX element or a string
        if (typeof option.label === "string") {
            labelValue = option.label;
        } else if (option.label.props && option.label.props.children) {
            labelValue = option.label.props.children[1]; // or adjust this based on the structure
        }

        return labelValue
            ? labelValue.toLowerCase().includes(filterText.toLowerCase())
            : false;
    });

    const ref = useRef(null);

    useEffect(() => {
        if (value) {
            const locValue = value.filter((item) => item !== "");
            setSelected(locValue);
        }
    }, [value]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropDown(false);
                setSearchEnabled(false);
                setFilterText("");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const renderSelectedValues = () => {
        const optionsRender = [];

        defaultMembers.forEach((item) => {
            optionsRender.push(<Chip title={item.label} />);
        });

        selected.forEach((value) => {
            let label = "";

            options.forEach((item) => {
                if (item.value === value) {
                    label = item.label;
                }
            });

            optionsRender.push(
                <Chip
                    key={"chip" + value}
                    type={"icon-right"}
                    title={label}
                    value={value}
                    onRemove={(value) => {
                        const locSelected = [...selected];
                        const index = locSelected.indexOf(value);
                        if (index > -1) {
                            locSelected.splice(index, 1);
                        }
                        setSelected(locSelected);
                        if (onChange) onChange(locSelected);
                    }}
                />
            );
        });

        return <>{optionsRender}</>;
    };

    const renderSelectBox = () => {
        return (
            <div
                ref={selectRef}
                className={`w-100 ${searchEnabled ? "input-active" : ""}`}
                onClick={(e) => {
                    e.preventDefault();

                    if (filterText && filterText.length > 0) {
                        setShowDropDown(true);
                    } else {
                        if (!searchEnabled) {
                            setShowDropDown(!showDropdown);
                        }
                    }
                    if(!searchEnabled){
                        setSearchEnabled(true);
                        setShowDropDown(true);
                    }
                    
                }}
            >
                <div className="d-flex align-items-center select-member-dropdown">
                    {searchEnabled ? (
                        <>
                            {icon}
                            <Text type={"body-text-2"}>
                                <input
                                    ref={searchRef}
                                    autoComplete="off"
                                    type="text"
                                    name="search"
                                    value={filterText}
                                    onChange={(e) => {
                                        setFilterText(e.target.value);

                                        if (e.target.value) {
                                            setShowDropDown(true);
                                        }
                                    }}
                                    /*onClick={(e)=>{
                                alert(2)
                                e.preventDefault();
                                e.stopPropagation();
                                setShowDropDown(true);
                            }}*/
                                    onBlur={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (!e.target.value) {
                                            setSearchEnabled(false);
                                        }
                                    }}
                                    style={{ zIndex: 2 }}
                                />
                            </Text>
                        </>
                    ) : (
                        <Text type={"body-text-2"} fontWeight={"normal-font"}>
                            {label ? label : "Search"}
                        </Text>
                    )}
                    {
                        <Text type={"caption"} cssClasses={"project-label"}>
                            {label ? label : "Search"}
                        </Text>
                    }
                </div>
                <div className="multiselectlist-dropdown-dropdown-icon-container"
                    onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        if(filterText && filterText.length > 0 ) {
                            setShowDropDown(false);
                            setFilterText("");
                            setSearchEnabled(false);
                        } else {
                            setShowDropDown(!showDropdown);
                            
                        }
                    }}
                >
                    <Icon
                        colorClass={"gray-900-svg"}
                        value={showDropdown ? "Chevron up" : "Chevron"}
                        hover={true}
                    />
                </div>
            </div>
        );
    };

    const renderDropDown = () => {
        if (!showDropdown) return null;

        return (
            <div
                ref={selectDropRef}
                className="multiselectlist-dropdown-options"
                style={{
                    position: "absolute",
                    background: "#fff",
                    width: "200px",
                    maxHeight: dropdownHeight,
                }}
            >
                {options.length === 0 && no_results_message ? (
                    <div
                        key={"select-option-end"}
                        className="multiselectlist-dropdown-option active-option"
                    >
                        <div>{no_results_message}</div>
                    </div>
                ) : filteredMembers.length > 0 ? (
                    filteredMembers.map((option, index) => (
                        <div
                            key={"select-option" + index}
                            className={`multiselectlist-dropdown-option ${
                                selected.indexOf(option.value) !== -1
                                    ? "disabled-option"
                                    : "active-option"
                            } `}
                            onClick={(e) => {
                                e.preventDefault();
                                const locSelected = [...selected];
                                const idx = locSelected.indexOf(option.value);

                                if (idx > -1) locSelected.splice(idx, 1);
                                else locSelected.push(option.value);

                                setSelected(locSelected);
                                //setSearchEnabled(false);
                                if (onChange) onChange(locSelected);
                            }}
                        >
                            <span className="member-label d-flex align-items-center">
                                {option.label}
                            </span>

                            <span className="added-member d-none align-items-center">
                                <Icon
                                    colorClass={"success-green-200-svg"}
                                    value={"check mark"}
                                    size={"small"}
                                />
                                <Text
                                    type={"caption"}
                                    fontWeight={"medium-font"}
                                    cssClasses={"green-color"}
                                >
                                    Added
                                </Text>
                            </span>
                        </div>
                    ))
                ) : (
                    <div className="multiselectlist-dropdown-option active-option">
                        No search data found
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={`multiselectlist-dropdown-box members-multiselect-dropdown position-relative ${
                error ? "field-error" : ""
            } ${className ? className : ""} ${
                readOnly ? "multiselectlist-dropdown-readonly" : ""
            }`}
        >
            <div
                ref={ref}
                className="multiselectlist-dropdown-box-container  p-0"
            >
                {renderSelectBox()}
                {renderDropDown()}
            </div>
            <div className="members-multiselect-dropdown-inner">
                <div className="multiselectlist-dropdown-selected-values-container">
                    {renderSelectedValues()}
                </div>
            </div>
            {error && <span className="caption">{error}</span>}
        </div>
    );
};
