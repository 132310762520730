import {handleApiErrorResponse} from "../lib/helpers";

export function getResearcherTestResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/get?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}

export function getResearcherTestResultOverviewData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/get-overview?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTestParicipantsData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/participants?test_id="+
        formValues.test_id+"&type="+
        formValues.type+"&guest_token="+
        formValues.guest_token+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")
        +(formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:''),
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/question?test_id="+
        formValues.test_id+"&type="+
        formValues.type+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getPrototypeTestResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/prototype-test?test_id="+
        formValues.test_id+"&type="+
        formValues.type+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getFirstClickResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/first-click?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getFiveSecondsTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/five-seconds-test-question?test_id="+
        formValues.test_id+"&type="+
        formValues.type+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getPreferenceTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/preference-test/questions?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getLoadMoreQuestionData(formValues, token, signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-question?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        (formValues.search?formValues.search:'')+"&rating="+
        (formValues.rating ? formValues.rating.join(",") : "")+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getLoadMoreSurveyQuestionData(formValues, token, signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-question?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        (formValues.search?formValues.search:'')+"&rating="+
        (formValues.rating ? formValues.rating.join(",") : "")+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&questiontype="+
        formValues.questiontype+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal
        }).then((res) => res.json());
}

export function deleteAnswerResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/delete-answer", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function hideResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/hide-result", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function reportIssueAnswer(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/report-issue-answer", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function ExportTestResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/export-test-result", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function ReportTesterIntructionTestResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/report-tester-instruction", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function ReportTesterParticipantTestResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/report-tester-participant", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function ReportTesterParticipantDeletedTestResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/delete-report-tester-participant", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestTaskData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/task?outcome_filter="+formValues.outcome_filter+"&test_id="+
        formValues.test_id+"&page="+formValues.page+"&order="+formValues.order+"&direction="+formValues.direction+"&task_id="+formValues.task_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestTaskFirstClickData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/task/first-click?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id,
        {
        method: "get",
        headers: {"Content-Type":"application/json","Accept":"application/json", Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestPreferenceTestData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/preference-test?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
        method: "get",
        headers: {"Content-Type":"application/json","Accept":"application/json",  Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTestPreferenceTestTaskData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/preference-test/task?test_id="+
        formValues.test_id+"&page="+formValues.page+"&order="+formValues.order+"&direction="+formValues.direction+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingRCategoryesultData(formValues, token,signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-category?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:"")+
        "&page="+formValues.page,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal:signal
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}

export function getTestCardSortingByUserResultData(formValues, token,signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-by-user?test_id="+
        formValues.test_id+"&guest_result_token="+formValues.guest_result_token+"&admin_result_token="+formValues.admin_result_token,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal:signal
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingByUserLoadMoreResultData(formValues, token, signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-load-more-by-user?test_id="+
        formValues.test_id+"&page="+
        formValues.page+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal:signal
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingCardsResultData(formValues, token,signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-cards?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:"")+
        "&page="+formValues.page,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal:signal
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingMatrixResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-matrix?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingSimilarityMatrixResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-similarity-matrix?test_id="+
        formValues.test_id+"&guest_result_token="+formValues.guest_result_token
        +"&admin_result_token="+formValues.admin_result_token,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getFirstClickData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/first-click?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id+"&guest_result_token="+formValues.guest_result_token
        +"&admin_result_token="+formValues.admin_result_token,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTreeChartData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/get-chart?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id+"&guest_result_token="+formValues.guest_result_token
        +"&admin_result_token="+formValues.admin_result_token,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function loadMorePrototypeResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-prototype-results?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        (formValues.search?formValues.search:'')+"&rating="+
        (formValues.rating ? formValues.rating.join(",") : "")+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&questiontype="+
        formValues.questiontype+"&guest_result_token="+formValues.guest_result_token+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res) => res.json());
}

export function loadMoreFirstClickResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-first-click-results?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        (formValues.search?formValues.search:'')+"&rating="+
        (formValues.rating ? formValues.rating.join(",") : "")+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&questiontype="+
        formValues.questiontype+"&guest_result_token="+formValues.guest_result_token
        +(formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res) => res.json());
}

export function loadMoreTreeTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/load-more-tree-test-question-results?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        (formValues.search?formValues.search:'')+"&rating="+
        (formValues.rating ? formValues.rating.join(",") : "")+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&questiontype="+
        formValues.questiontype+"&guest_result_token="+formValues.guest_result_token
        +(formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res) => res.json());
}
export function getTestMethologyService(formValues, token=null) {
    return fetch(process.env.REACT_APP_API_END_POINT+'test/sharetest?test_id='+formValues.test_id, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}