import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Icon } from './../Icon/Icon';

export const IncDecCounter = ({ value, readOnly, onChange,  min, max, disabled, cssClasses }) => {
    const [counterValue, setCounterValue] = useState(value);

    useEffect(()=>{

        setCounterValue(value)

    },[value])
    
    const changeCounterValue = (e) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
		if (e.target.value === "" || re.test(e.target.value)) {
            if(Number(e.target.value)==0){
                setCounterValue("");

                if(onChange){
                
                    onChange("");
                }
                
            }
            else
            {
                if(Number(e.target.value)>max){
                    return;
                }
                
                if(Number(e.target.value)>max){
                    setCounterValue(max);

                    if(onChange){
                    
                        onChange(max);
                    }
                } else {
                    setCounterValue(Number(e.target.value));

                    if(onChange){
                    
                        onChange(Number(e.target.value));
                    }
                }
            }
		}
	};
    const updateInputValue = (e) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
		if(e.target.value === "" || re.test(e.target.value)) {
            
            if(Number(e.target.value)>max){
                return;
            }
            if(onChange){
                onChange(e.target.value);
            }
            
		} else {
            return false;
        }
	};

    return (
        <div
            className={`inc-dec-counter ${readOnly?'readonly-counter':''} ${cssClasses}`}
        >
            <button
                type="button"
                id="sub"
                className={`sub ${counterValue==min?'disable-dec-btn':''} `}
                onClick={() => { if(readOnly){
                    return;
                } if(counterValue>min){setCounterValue(Number(counterValue) - 1);} if(onChange){ if(counterValue>min){onChange(Number(counterValue) - 1);}else{onChange(Number(counterValue));} } } }
            >
                <Icon value="minimize" size="small" />
            </button>
            <input
                className="counter-input"
                readOnly={readOnly==true?true:false}
                type="text"
                id="people"
                name="people"
                value={counterValue}
                onChange={updateInputValue}
                onBlur={(e)=>{
                    if(!counterValue){
                        setCounterValue(min);

                        if(onChange){
                                
                            onChange(min);
                        }
                    } else {
                        if(Number(e.target.value)<min){

                            setCounterValue(min);

                            if(onChange){
                                
                                onChange(min);
                            }
                        } else {
                            changeCounterValue(e);
                        }
                    }
                }}
                min={min ? min : 0}
                max={max ? max : 10}
            />

            <button
                type="button"
                id="add"
                className={`add ${counterValue==max?'disable-max-btn':''}`}
                onClick={() => 
                    { 
                        if(readOnly){
                            return;
                        } 
                        if(Number(counterValue)<max){
                            setCounterValue(Number(counterValue) + 1); 
                                if(onChange){ 
                                    onChange(Number(counterValue) + 1); 
                                } 
                        }
                    } 
                }
                >
                <Icon value="add" size="small" />
            </button>
        </div>
    );
};

IncDecCounter.propTypes = {
    value: PropTypes.number,
    readOnly: PropTypes.bool,

};
IncDecCounter.defaultProps = {
    value: 1,
    readOnly:true

};